/* eslint-disable no-confusing-arrow */
import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

const ReactHighcharts = require('react-highcharts');

const styles = theme => ({
	root: {},
});

const prepareData = data => data.map((item) => {
	const newItem = item;
	newItem.D = new Date(item.D);
	return [new Date(item.D).valueOf(), item.V];
});


function createOptions(mainChartdata, additionalChartsData, YlabelsFormat, title, color, TooltipFormat) {
	const options = {
		chart: {},
		credits: { enabled: false },
		title: { text: '' },
		xAxis: {
			type: 'datetime',
			dateTimeLabelFormats: {
				second: '%Y-%m-%d<br/>%H:%M:%S',
				minute: '%Y-%m-%d<br/>%H:%M',
				hour: '%Y-%m-%d<br/>%H:%M',
				day: '%Y<br/>%m-%d',
				week: '%Y<br/>%m-%d',
				month: '%Y-%m',
				year: '%Y',
			},
			title: { text: title },
			legend: {
				enabled: false,
			},
		},
		yAxis: {
			title: {
				text: '',
			},
			tickPixelInterval: 30,
			labels: (typeof YlabelsFormat !== 'string') ? YlabelsFormat	: {
				format: YlabelsFormat,
			},
			legend: {
				enabled: false,
			},
		},
		tooltip: {
			formatter: TooltipFormat && TooltipFormat.formatter,
		},
	};

	options.series = [];
	options.series.push({
		name: mainChartdata.legendTitle,
		data: prepareData(mainChartdata.chartData),
		showInLegend: true,
		color,
	});

	if (additionalChartsData && additionalChartsData.length > 0) {
		additionalChartsData.forEach((item) => {
			options.series.push({
				name: item.legendTitle,
				data: prepareData(item.chartData),
				showInLegend: true,
				color: '#78aaff',
			});
		});
	}
	return options;
}


class Chart extends React.Component {
	render() {
		const {
			mainChartdata,
			YlabelsFormat,
			TooltipFormat,
			title,
			additionalChartsData,
			classes,
			color,
			theme,
		} = this.props;
		const config = createOptions(mainChartdata, additionalChartsData, YlabelsFormat, title, color, TooltipFormat);
		return (
			<div className={classes.root}>
				<ReactHighcharts config={config} />
			</div>
		);
	}
}

Chart.propTypes = {
	YlabelsFormat: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.shape({
			formatter: PropTypes.func.isRequired,
		}),
	]),
	TooltipFormat: PropTypes.shape({
		formatter: PropTypes.func,
	}),
	title: PropTypes.string,
	color: PropTypes.string,
	mainChartdata: PropTypes.shape({
		legendTitle: PropTypes.string.isRequired,
		chartData: PropTypes.array.isRequired,
	}).isRequired,
	additionalChartsData: PropTypes.arrayOf(PropTypes.shape({
		legendTitle: PropTypes.string.isRequired,
		chartData: PropTypes.array.isRequired,
	})),
};

Chart.defaultProps = {
	additionalChartsData: [],
	YlabelsFormat: '{value} %',
	TooltipFormat: {},
	title: 'Date & Time (UTC)',
	color: '#11d87c',
};

export default compose(
	withStyles(styles),
	withTheme,
)(Chart);
