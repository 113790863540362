import { createFormAction } from 'redux-form-saga';
import { createApiActions } from '../extensions';

const wireDepositInfoFormSubmitActionPrefix = 'WIRE_DEPOSIT_INFO_FORM_SUBMIT';
const wireDepositInfoFormActionCreator = createFormAction(wireDepositInfoFormSubmitActionPrefix);

const actions = {
	// actions
	SHOW_DEPOSIT_FUNDS_MODAL: 'DEPOSIT_FUNDS/SHOW',
	HIDE_DEPOSIT_FUNDS_MODAL: 'DEPOSIT_FUNDS/HIDE',
	SHOW_DEPOSIT_FUNDS_BROKER_MODAL: 'DEPOSIT_FUNDS_BROKER/SHOW',
	HIDE_DEPOSIT_FUNDS_BROKER_MODAL: 'DEPOSIT_FUNDS_BROKER/HIDE',
	INIT_DEPOSIT_WIRE_INFO: 'DEPOSIT_FUNDS/INIT_DEPOSIT_WIRE_INFO',

	showDepositFundsModal: (bankAccount, tradeAccount) => ({
		type: actions.SHOW_DEPOSIT_FUNDS_MODAL,
		payload: {
			bankAccount,
			tradeAccount,
		},
	}),
	hideDepositFundsModal: () => ({
		type: actions.HIDE_DEPOSIT_FUNDS_MODAL,
	}),
	showDepositFundsBrokerModal: (bankAccount, tradeAccount) => ({
		type: actions.SHOW_DEPOSIT_FUNDS_BROKER_MODAL,
		payload: {
			bankAccount,
			tradeAccount,
		},
	}),
	hideDepositFundsBrokerModal: () => ({
		type: actions.HIDE_DEPOSIT_FUNDS_BROKER_MODAL,
	}),
	initDepositWireInfo: () => ({
		type: actions.INIT_DEPOSIT_WIRE_INFO,
	}),

	SHOW_DEPOSIT_FUNDS_WIRE_MODAL: 'DEPOSIT_FUNDS/SHOW_WIRE_MODAL',
	HIDE_DEPOSIT_FUNDS_WIRE_MODAL: 'DEPOSIT_FUNDS/HIDE_WIRE_MODAL',

	showDepositFundsWireModal: bankAccount => ({
		type: actions.SHOW_DEPOSIT_FUNDS_WIRE_MODAL,
		payload: {
			bankAccount,
		},
	}),
	hideDepositFundsWireModal: () => ({
		type: actions.HIDE_DEPOSIT_FUNDS_WIRE_MODAL,
	}),

	depositAchFundsForm: createFormAction('DEPOSIT_FUNDS/ACH_DEPOSIT_FUNDS_FORM'),
	
	depositBrokerFundsForm: createFormAction('DEPOSIT_FUNDS/BROKER_DEPOSIT_FUNDS_FORM'),

	WIRE_DEPOSIT_INFO_FORM_SUBMIT_REQUEST: wireDepositInfoFormActionCreator.REQUEST,
	WIRE_DEPOSIT_INFO_FORM_SUBMIT_SUCCESS: wireDepositInfoFormActionCreator.SUCCESS,
	WIRE_DEPOSIT_INFO_FORM_SUBMIT_FAILURE: wireDepositInfoFormActionCreator.FAILURE,

	wireDepositInfoFormRequest: payload => wireDepositInfoFormActionCreator.request(payload),
	wireDepositInfoFormSuccess: payload => wireDepositInfoFormActionCreator.success(payload),
	wireDepositInfoFormFailure: error => wireDepositInfoFormActionCreator.failure(error),
	wireDepositInfoFormSubmitHandler: wireDepositInfoFormActionCreator,
	
	getAccountFundingInfo: createApiActions('DEPOSIT_FUNDS/GET_ACCOUNT_FUNDING_INFO'),

	CLEAR_ACCOUNT_FUNDING_INFO: 'DEPOSIT_FUNDS/CLEAR_ACCOUNT_FUNDING_INFO',
	clearAccountFundingInfo: () => ({
		type: actions.CLEAR_ACCOUNT_FUNDING_INFO,
	}),
};

export default actions;
