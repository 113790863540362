import actions from './actions';
import auth from '../auth';

const initialState = {
	submitting: false,
	commissionFree: {
		submitting: false,
		error: false,
		submitSucceeded: false,
	},
	openTradexUrl: {
		submitting: false,
	},
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
	case actions.SHOW_J2T_LITE_MODAL:
		return {
			...state,
			submitting: false,
		};

	case actions.SHOW_J2T_PLUS_MODAL:
		return {
			...state,
			submitting: false,
		};

	case actions.SHOW_J2T_PRO_MODAL:
		return {
			...state,
			submitting: false,
		};

	case actions.SHOW_MT5_MODAL:
		return {
			...state,
			submitting: false,
		};

	case actions.SHOW_STPRO_MODAL:
		return {
			...state,
			submitting: false,
		};
	case actions.ACTIVATE_PLATFORM_REQUEST:
		return {
			...state,
			submitting: true,
		};
	case actions.ACTIVATE_PLATFORM_SUCCESS:
		return {
			...state,
			submitting: false,
		};
	case actions.ACTIVATE_PLATFORM_FAILURE:
		return {
			...state,
			submitting: false,
		};

	case actions.activateCommissionFree.REQUEST:
		return {
			...state,
			commissionFree: {
				submitting: true,
				error: false,
				submitSucceeded: false,
			},
		};

	case actions.activateCommissionFree.SUCCESS:
		return {
			...state,
			commissionFree: {
				submitting: false,
				error: false,
				submitSucceeded: true,
			},
		};

	case actions.activateCommissionFree.FAILURE:
		return {
			...state,
			commissionFree: {
				submitting: false,
				error: true,
				submitSucceeded: false,
			},
		};

	case actions.openTradexUrl.REQUEST:
		return {
			...state,
			openTradexUrl: {
				submitting: true,
			},
		};

	case actions.openTradexUrl.SUCCESS:
	case actions.openTradexUrl.FAILURE:
		return {
			...state,
			openTradexUrl: {
				submitting: false,
			},
		};
	case auth.actions.LOGOUT_SUCCESS:
		return { ...initialState };

	default:
		return state;
	}
};
