import { createApiActions } from '../extensions';

const actions = {
	// actions
	SHOW_J2T_LITE_MODAL: 'TRADE_PLATFORMS/SHOW_J2T_LITE_MODAL',

	showJ2tLiteModal: () => ({
		type: actions.SHOW_J2T_LITE_MODAL,
	}),


	SHOW_J2T_PLUS_MODAL: 'TRADE_PLATFORMS/SHOW_J2T_PLUS_MODAL',

	showJ2tPlusModal: () => ({
		type: actions.SHOW_J2T_PLUS_MODAL,
	}),

	SHOW_J2T_PRO_MODAL: 'TRADE_PLATFORMS/SHOW_J2T_PRO_MODAL',

	showJ2tProModal: () => ({
		type: actions.SHOW_J2T_PRO_MODAL,
	}),


	SHOW_MT5_MODAL: 'TRADE_PLATFORMS/SHOW_MT5_MODAL',

	showMt5Modal: () => ({
		type: actions.SHOW_MT5_MODAL,
	}),


	SHOW_STPRO_MODAL: 'TRADE_PLATFORMS/SHOW_STPRO_MODAL',

	showStProModal: () => ({
		type: actions.SHOW_STPRO_MODAL,
	}),


	ACTIVATE_PLATFORM_REQUEST: 'TRADE_PLATFORMS/ACTIVATE_PLATFORM_REQUEST',
	ACTIVATE_PLATFORM_SUCCESS: 'TRADE_PLATFORMS/ACTIVATE_PLATFORM_SUCCESS',
	ACTIVATE_PLATFORM_FAILURE: 'TRADE_PLATFORMS/ACTIVATE_PLATFORM_FAILURE',

	activatePlatformRequest: (account, tradePlatform) => ({
		type: actions.ACTIVATE_PLATFORM_REQUEST,
		payload: {
			account,
			tradePlatform,
		},
	}),
	activatePlatformSuccess: payload => ({
		type: actions.ACTIVATE_PLATFORM_SUCCESS,
		payload,
	}),
	activatePlatformFailure: error => ({
		type: actions.ACTIVATE_PLATFORM_FAILURE,
		payload: {
			error,
		},
	}),


	activateCommissionFree: createApiActions('TRADE_PLATFORMS/ACTIVATE_COMMISSION_FREE'),

	// open tradex url with one time token for end-to-end transition
	openTradexUrl: createApiActions('TRADE_PLATFORMS/OPEN_TRADEX_URL'),
};

export default actions;
