import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import MUIListItem from '@material-ui/core/ListItem';
import { cabinetLog } from '@global';

const styles = theme => ({

});

class ListItem extends React.Component {
	handleClick = () => {
		if (process.env.NODE_ENV === 'production' && this.props.gaUrl) {
			cabinetLog(`send to ${this.props.gaUrl}`);
			ReactGA.modalview(this.props.gaUrl);
		}
		if (this.props.onClick) {
			this.props.onClick();
		}
	}

	render() {
		const {
			onClick,
			gaUrl,
			...props
		} = this.props;

		return (
			<MUIListItem
				onClick={this.handleClick}
				{...props}
			>
				{this.props.children}
			</MUIListItem>
		);
	}
}

ListItem.propTypes = {
	/**
	 * mouse click handler
	 */
	onClick: PropTypes.func,
	/**
	 * If exists, send this url to Google Analytics
	 */
	gaUrl: PropTypes.string,
	/**
	 * If `true`, the list item will be a button (using `ButtonBase`).
	 */
	button: PropTypes.bool,
	/**
	 * The content of the component.
	 */
	children: PropTypes.node.isRequired,
	/**
	 * Useful to extend the style applied to components.
	 */
	classes: PropTypes.object.isRequired,
	/**
	 * If `true`, a 1px light border is added to the bottom of the list item.
	 */
	divider: PropTypes.bool,
};

ListItem.defaultProps = {
	button: false,
	divider: false,
	gaUrl: null,
	onClick: null,
};

export default compose(withStyles(styles))(ListItem);
