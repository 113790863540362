import React from 'react';

const Svg = (
	<g fill="none" fillRule="nonzero">
		<path fill="#f9fcfd" d="M33.733 43.714l-3.7-3.363 20.12-22.037 3.693 3.372-20.113 22.028zm-5.811 6.365l-.965 1.057a3.5 3.5 0 0 1-4.94.23L8.318 38.912l3.364-3.7.697.634-.06.066L27.921 50.08z" opacity=".75" />
		<path fill="#f9fcfd" d="M34 46.5L59.5 18l4.346 3.686-26.89 29.45a3.5 3.5 0 0 1-4.939.23L18.318 38.912l3.364-3.7L34 46.5z" />
	</g>
);

export default Svg;
