import { Select } from '@components';
import { createComponent, mapError } from '../utils';

export default createComponent(Select, ({
	input: {
		onChange, value, onBlur, ...inputProps
	},
	onChange: onChangeFromField,
	defaultValue,
	...props
}) => ({
	...mapError(props),
	...inputProps,
	value,
	onChange: (event) => {
		onChange(event.target.value);
		if (onChangeFromField) {
			onChangeFromField(event.target.value);
		}
	},
	onBlur: () => onBlur(value),
}));
