import actions from './actions';
import auth from '../auth';

const initialState = {
	partnerCabinetRedirect: {
		submitting: false,
		loaded: false,
		url: null,
	},
	modals: {},
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.agentRegistration.REQUEST:
		case actions.partnerCabinetRedirect.REQUEST:
			return {
				...state,
				partnerCabinetRedirect: {
					submitting: true,
				},
			};
		case actions.agentRegistration.SUCCESS:
		case actions.partnerCabinetRedirect.SUCCESS:
			return {
				...state,
				partnerCabinetRedirect: {
					Url: payload.PartnerCabinetRedirectUrl,
					submitting: false,
					loaded: true,
				},
			};
		case actions.agentRegistration.FAILURE:
		case actions.partnerCabinetRedirect.FAILURE:
			return {
				...state,
				partnerCabinetRedirect: {
					...initialState.partnerCabinetRedirect,
					submitting: false,
					loaded: true,
				},
			};
		case auth.actions.LOGOUT_SUCCESS:
			return { ...initialState };
		default:
			return state;
	}
};
