import actions from './actions';
import authActions from '../auth/actions';

const initialState = {
	isVisible: false,
	message: null,
};


export default (state = initialState, { type, payload }) => {
	switch (type) {
	case actions.SHOW_ERROR_MESSAGE:
		return {
			...state,
			message: { ...payload },
			isVisible: true,
		};
	case actions.HIDE_ERROR_MESSAGE:
		return {
			...state,
			isVisible: false,
			// message: null,  // for correct animation
		};
	case actions.SHOW_MESSAGE:
		return {
			...state,
			message: { ...payload },
			isVisible: true,
		};
	case actions.HIDE_MESSAGE:
		return {
			...state,
			isVisible: false,
			// message: null,  // for correct animation
		};
	case authActions.LOGIN_USER:
		return {
			...initialState,
		};

	default:
		return state;
	}
};
