import React from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { userAccounts, withdrawFunds } from '@redux';
import { connect } from 'react-redux';
import {
	reduxForm,
	Field,
} from 'redux-form';
import { withStyles, withTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';

import {
	FormSelect,
	Button,
	WithdrawAmount,
	withTranslate,
} from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';
import { RequireConfirmationCodeItem } from '@shared';

import { template } from '@templates';
import LongArrow from './arrow.svg';
import { LoadFreeMarginType } from '@components';

const styles = theme => ({
	root: {
		position: 'relative',
	},
	row: {
		display: 'flex',
	},
	inputItem: {
		marginTop: '30px',
		paddingLeft: '50px',
	},
	item: {
		marginTop: '30px',
		paddingLeft: '74px',
	},
	divider: {
		marginTop: '30px',
	},
	bankAccountContainer: {
		marginTop: '-15px',
	},
	accountContainer: {
	},
	logo: {
		padding: '16px 16px 4px 16px',
		position: 'relative',
		width: '80px',
		height: '44px',
	},
	arrow: {
		paddingLeft: '24px',
		height: '36px',
	},
	inputContainer: {
		display: 'flex',
		width: '100%',
		...theme.customstyles.title,
	},
	account: {
		width: '100%',
		textAlign: 'left',
	},
	accountBalance: {
		width: '100%',
		textAlign: 'right',
	},
	warning: {
		padding: '12px 16px',
		textAlign: 'justify',
		fontSize: '12px',
		lineHeight: '20px',
	},
	button: {
		...theme.mixins.stickyButton,
	},
});


const CY_TRANSFER_BETWEEN_ACCOUNTS_FORM_ID = 'CYTransferBetweenAccountsFormId';
const mapStateToProps = (state, ownProps) => {
	const currentTradeAccount = userAccounts.selectors.currentTradeAccount(state);
	let myAccounts = userAccounts.selectors.accounts(state);
	myAccounts = myAccounts.filter(it => it.TradeCode !== currentTradeAccount.tradeCode && it.CanTransferTo);

	return {
		initialValues: {
			TradeCodeFrom: currentTradeAccount.tradeCode,
			ClearingFirmFrom: currentTradeAccount.clearerFirm,
			TradeCodeTo: myAccounts[0].TradeCode,
			ClearingFirmTo: myAccounts[0].ClearingFirm,
			SummCurrency: 'USD',
		},
		accounts: myAccounts,
		currentAccountData: userAccounts.selectors.currentAccountData(state),
		currentTradeAccount,
		freeMargin: withdrawFunds.selectors.freeMarginAmount(state),
		currentAccountDisplayFullName: userAccounts.selectors.currentAccountDisplayFullName(state),
	};
};


const mapDispatchToProps = dispatch => ({
	actions: {
		refreshAccountPortfolio: tradeCode =>
			dispatch(userAccounts.actions.getAccountPortfolio.request({ tradeCode })),
	},
});


class CYTransferBetweenAccountsForm extends React.Component {
	static getCashToWithdraw(props) {
		const { freeMargin, currentAccountData: { submitting } } = props;
		return !submitting && freeMargin;
	}

	static validate(formvalues, props) {
		const values = formvalues;
		const errors = {};
		const cashToWithdraw = CYTransferBetweenAccountsForm.getCashToWithdraw(props);

		if (!values) {
			return errors;
		}

		if (!values.Summ || values.Summ <= 0) {
			errors.Summ = 'required';
		} else if (typeof cashToWithdraw === 'number') {
			if (cashToWithdraw < values.Summ) {
				errors.Summ = 'Available amount for withdrawal is less than requested';
			}
		}

		if (!values.ConfirmationCode) {
			errors.ConfirmationCode = 'required';
		}

		return errors;
	}


	render() {
		const {
			classes,
			accounts,
			fromUserAccount,
			handleSubmit,
			selectedBankAccount,
			submitSucceeded,
			amount,
			theme,
			currentTradeAccount,
			currentAccountDisplayFullName,
			currentAccountData,
			translate,
			...props
		} = this.props;

		const commissionText = '';
		const submitting = currentAccountData.submitting;
		const fromCurrency = currentAccountData.baseParams.CurrencyCode;

		return (
			<form
				onSubmit={handleSubmit}
				className={classes.root}
			>
				<div className={classes.accountContainer}>
					<div className={classes.row}>
						<div className={classes.logo}>
							<template.firmLogo />
						</div>
						<Field
							component={FormSelect}
							name="TradeCodeFrom"
							label={translate('shared_account_from', 'Account From')}
							disabled
						>
							<MenuItem value={currentTradeAccount.tradeCode}>
								<div className={classes.inputContainer}>
									<div className={classes.account}>{currentAccountDisplayFullName}</div>
								</div>
							</MenuItem>
						</Field>
					</div>
				</div>
				<div className={classes.arrow}>
					<img alt="Long Arrow Down" src={LongArrow} />
				</div>
				<div className={classes.bankAccountContainer}>
					<div className={classes.row}>
						<div className={classes.logo}>
							<template.firmLogo />
						</div>
						<Field
							component={FormSelect}
							name="TradeCodeTo"
							label={translate('shared_account_to', 'Account To')}
						>
							{
								accounts &&	accounts.length > 0 &&
								accounts.filter(acc => !acc.IsDemo).map((brokerAccount, index) => (
									<MenuItem value={brokerAccount.TradeCode} key={index}>
										<div className={classes.inputContainer}>
											<div className={classes.account}>{brokerAccount.DisplayFullName}</div>
										</div>
									</MenuItem>
								))
							}
						</Field>
					</div>
				</div>

				<WithdrawAmount
					name="Summ"
					loadFreeMarginType={LoadFreeMarginType.TRANSFER}
					loading={submitting || false}
					disabled={amount != null}
					currency={fromCurrency}
					additionalText={commissionText}
					onClick={() => { this.props.actions.refreshAccountPortfolio(currentTradeAccount.tradeCode); }}
				/>

				<RequireConfirmationCodeItem
					customClasses={classes}
					name="ConfirmationCode"
					buttonTitle={<R id="shared_withdraw" defaultValue="Withdraw" />}
					reason="MoneyTransferApply"
					disabled={props.invalid || props.submitting}
					disableConfirmation
				>

					<Button
						fullWidth
						type="submit"
						variant="contained"
						color="primary"
						disabled={props.invalid || props.submitting}
					>
						{props.submitting ?
							<CircularProgress
								style={{ color: theme.palette.common.white }}
								size={18}
							/>
							: <R id="common_confirm_order" defaultValue="Confirm" /> // "Withdraw"
						}
					</Button>

				</RequireConfirmationCodeItem>

			</form>
		);
	}
}

CYTransferBetweenAccountsForm.propTypes = {
	classes: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	currentAccountData: PropTypes.object.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
	selectedBankAccount: PropTypes.object,
};

export default compose(
	withStyles(styles),
	withTheme,
	withTranslate,
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: CY_TRANSFER_BETWEEN_ACCOUNTS_FORM_ID,
		onSubmitSuccess: (result, dispatch, props) => {
			if (props.onSuccess && (typeof props.onSuccess === 'function')) {
				props.onSuccess();
			}
		},
		validate: CYTransferBetweenAccountsForm.validate,
	}),
)(CYTransferBetweenAccountsForm);
