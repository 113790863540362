import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { userAccounts } from '@redux';
import compose from 'recompose/compose';
import { constants, env } from '@config';
import { scrollTo } from '@global';
import {
	A,
	ProgressContainer,
} from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';
import { ReportItem, TransmissionItems } from './components';
import { CYReport } from './CYReport';


const styles = theme => ({
	header: {
		...theme.customstyles.headline1,
		...theme.palette.text.primary,
		padding: '8px 0',
	},
	tabsRoot: {
		borderBottom: '1px solid #deeaef',
	},
	root: {
		...theme.customstyles.body,
		padding: '16px 72px',
		[theme.breakpoints.down('md')]: {
			padding: '8px 24px',
		},
	},
	splitter: {
		height: '30px',
	},
	reportItem: {
		marginBottom: '30px',
	}
});


const mapStateToProps = state => ({
	currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
	reportsAndConfirmations: userAccounts.selectors.reportsAndConfirmations(state),
});


const mapDispatchToProps = dispatch => ({
	actions: {
		setCurrentPage: page => dispatch(push(page)),
		refreshReportsAndConfirmations: (account) => {
			dispatch(userAccounts.actions.refreshReportsAndConfirmationsRequest(account));
		},
	},
});
const getStatementLabel = docType =>
	[
		{ DocType: 'Statement', Label: 'Statements' },
		{ DocType: 'Statement Daily', Label: 'Statements Daily' },
		{ DocType: 'Statement Monthly', Label: 'Statements Monthly' },
	].find(x => x.DocType === docType).Label;

const ReportsIframe = ({ Url, thirdPartyCookies }) => {
	if (!thirdPartyCookies) {
		return (
			<iframe
				title="repAndConf"
				src={Url}
				width="100%"
				height="600px"
			/>
		);
	}
	const formReports = useRef(null);
	useEffect(() => {
		formReports.current.submit();
	}, []);
	return (
		<React.Fragment>
			<form ref={formReports} target="_blank" action={Url} method="GET" />
			If reports didn't open in new tab try <A href={Url} target="_blank">this link</A>
		</React.Fragment>
	);
};

class Reports extends React.Component {
	static GetDocTypes(clearer) {
		return clearer === 'spcfutures' ? ['Statement Daily', 'Statement Monthly'] : ['Statement'];
	}

	constructor(props) {
		super(props);
		this.state = {
			currentTab: null,
		};
	}

	componentDidMount() {
		const {
			actions,
			currentTradeAccount,
		} = this.props;

		actions.refreshReportsAndConfirmations(currentTradeAccount);
	}

	componentDidUpdate() {
		if (this.props.location.hash) {
			scrollTo(this.props.location.hash.substr(1));
		}
	}

	setCurrentTab = (event, currentTab) => {
		this.setState({ currentTab });
	}

	toggleOpen = (open) => {
		const {
			actions,
			currentTradeAccount,
		} = this.props;

		if (open) {
			actions.refreshReportsAndConfirmations(currentTradeAccount);
		}
	};

	render() {
		const {
			classes,
			currentTradeAccount,
			reportsAndConfirmations: {
				loading,
				Url,
				Documents,
				Transmissions,
			},
		} = this.props;
		const docTypesToShow = Reports.GetDocTypes(currentTradeAccount.clearerFirm);
		const currentTab = this.state.currentTab || docTypesToShow[0];

		if ([constants.LIME_INT, constants.LIME_NSR, constants.LIME_LME, constants.FIRM_J2TX].some(x => x === env.firm)) {
			return (
				<div className={classes.root}>
					<div className={classes.header}>
						<R id="common_menu_reports" defaultValue="Reports" />
					</div>
					<ProgressContainer submitting={loading} display="left">
						<div className={classes.reportItem}>
							<CYReport />
						</div>
					</ProgressContainer>
				</div>
			)
		}

		return (
			<div className={classes.root}>
				<div className={classes.header}>
					<R id="common_menu_reports" defaultValue="Reports" />
				</div>
				<ProgressContainer submitting={loading} display="left">
					<React.Fragment>
						{Url && <ReportsIframe Url={Url} thirdPartyCookies={currentTradeAccount.clearerFirm === 'cor'}/>}
						{!Url && Documents &&
							<React.Fragment>
								<Tabs
									value={currentTab}
									onChange={this.setCurrentTab}
									indicatorColor="primary"
									textColor="primary"
									classes={{ root: classes.tabsRoot }}
									// centered
								>
									{docTypesToShow.map((dt, index) => <Tab label={getStatementLabel(dt)} value={dt} key={index} />)}
									<Tab label="Confirms" value="Confirm" />
									{Transmissions &&
										<Tab label="Transmissions" value="Transmission" />
									}
								</Tabs>
								<div className={classes.splitter} />
								{Documents.filter(it => it.DocType === currentTab).map((document, index) => (
									<ReportItem
										account={currentTradeAccount}
										document={document}
										nodeKey={`doc_${index}`}
									/>
								))}
								{currentTab === 'Transmission' && Transmissions && Transmissions.length > 0 &&
									<TransmissionItems
										account={currentTradeAccount}
										items={Transmissions}
									/>
								}
							</React.Fragment>
						}
						{!Url && Transmissions && Transmissions.length > 0 &&
							<TransmissionItems
								account={currentTradeAccount}
								items={Transmissions}
							/>
						}
						{!Url && !Documents && (!Transmissions || Transmissions.length === 0) &&
							<p><R id="shared_no_data_available" defaultValue="No data available" /></p>
						}
					</React.Fragment>
				</ProgressContainer>
			</div>
		);
	}
}

Reports.propTypes = {
	actions: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
	reportsAndConfirmations: PropTypes.object.isRequired,
};

export default compose(
	withTheme,
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(Reports);
