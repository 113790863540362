import { createFormAction } from 'redux-form-saga';

const loginFormSubmitActionPrefix = 'LOGIN_FORM_SUBMIT';

const actions = {
	login: createFormAction(loginFormSubmitActionPrefix),

	SET_CAPTCHA_IS_REQUIRED: `${loginFormSubmitActionPrefix}/SET_CAPTCHA_IS_REQUIRED`,

	setCaptchaIsRequired: (value) => ({ type: actions.SET_CAPTCHA_IS_REQUIRED, payload: { value } }),

	SET_TWO_FACTOR_IS_REQUIRED: `${loginFormSubmitActionPrefix}/SET_TWO_FACTOR_IS_REQUIRED`,

	setTwoFactorIsRequired: (value) => ({ type: actions.SET_TWO_FACTOR_IS_REQUIRED, payload: { value } }),
};

export default actions;
