import { createFormAction } from 'redux-form-saga';

const actions = {

	HIDE_NON_TRADING_ORDER_MODAL: 'NON_TRADING_ORDERS/HIDE_MODAL',
	hideNonTradingOrderModal: modalId => ({
		type: actions.HIDE_NON_TRADING_ORDER_MODAL,
		payload: modalId,
	}),

	sendNonTradingOrderCommonForm: createFormAction('NON_TRADING_ORDERS/COMMON'),
	sendNonTradingOrderIdentityForm: createFormAction('NON_TRADING_ORDERS/IDENTITY'),
	sendNonTradingOrderLeverageForm: createFormAction('NON_TRADING_ORDERS/LEVERAGE'),
	sendNonTradingOrderRegistrationForm: createFormAction('NON_TRADING_ORDERS/REGISTRATION'),
	sendNonTradingOpenAccount: createFormAction('NON_TRADING_ORDERS/OPEN_ACCOUNT'),
	sendNonTradingOpenDemoAccount: createFormAction('NON_TRADING_ORDERS/OPEN_DEMO_ACCOUNT'),
};

export default actions;
