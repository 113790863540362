import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';
import PropTypes from 'prop-types';
import MUISnackbar from '@material-ui/core/Snackbar';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import IconButton from '@material-ui/core/IconButton';
import { SvgIcon } from '@components';
import { snackbar } from '@redux';


const styles = theme => ({
	root: {
		backgroundColor: theme.palette.error.light,
		color: theme.palette.error.dark,
		flexWrap: 'nowrap',
	},
	success: {
		backgroundColor: theme.palette.success.light,
		color: theme.palette.success.dark,
		flexWrap: 'nowrap',
	},
	closeButtonSuccess: {
		color: theme.palette.success.dark,
		'&:hover': {
			background: green[100]
		},
		'&:active': {
			background: green[200]
		}
	},
	closeButtonError: {
		color: theme.palette.error.dark,
		'&:hover': {
			background: red[100]
		},
		'&:active': {
			background: red[200]
		}
	},
});

const mapStateToProps = state => ({
	snackbarInfo: snackbar.selectors.snackbarInfo(state),
});


const mapDispatchToProps = dispatch => ({
	actions: {
		hide: () => dispatch(snackbar.actions.hideErrorMessage()),
	},
});

class Snackbar extends React.Component {
	render() {
		const {
			classes,
			snackbarInfo: {
				isVisible,
				message,
			},
			actions,
		} = this.props;

		const getCloseButtonClassname = (messageType) => {
			switch (messageType) {
				case 'success':
					return classes.closeButtonSuccess;
				default:
					return classes.closeButtonError;
			}
		}
		
		return (
			<div>
				<MUISnackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					ContentProps={{
						classes: {
							root: (message && message.type && message.type !== 'error') ? classes[message.type] : classes.root,
						}, // only root (can be message and action)
					}}
					open={isVisible}
					autoHideDuration={20000}
					onClose={actions.hide}
					message={message &&
						<div>
							{message.title &&
								<p>{message.title}</p>
							}
							{/* eslint-disable-next-line react/no-danger */}
							{message.hasHtml &&	<div dangerouslySetInnerHTML={{ __html: message.text }} />}
							{!message.hasHtml && <p>{message.text}</p>}
						</div>
					}
					action={
						<IconButton
							onClick={actions.hide}
							classes={{ root: getCloseButtonClassname(message?.type) }}
						>
							<SvgIcon icon="Close" />
						</IconButton>
					}
				/>
			</div>
		);
	}
}

Snackbar.propTypes = {
	classes: PropTypes.object.isRequired,
	hasHtml: PropTypes.bool,
};

Snackbar.defaultProps = {
	hasHtml: false,
};
export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(Snackbar);
