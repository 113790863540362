/* eslint-disable no-return-assign */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { Button } from '@components';

import PropTypes from 'prop-types';
import SignaturePad from 'signature_pad';

const styles = theme => ({
	root: {
		position: 'relative',
	},
	canvas: {
		width: '100%',
		minHeight: '150px',
		// backgroundColor: '#fff',
	},
	buttons: {
		position: 'absolute',
		bottom: '10px',
		right: '0',
	},
	button: {
		color: theme.palette.primary.main,
	},
});

class SignatureArea extends React.Component {

	constructor(props) {
		super(props);
		this.signaturePad = null;
		this.canvasElement = null;

		this.resizeCanvas = this.resizeCanvas.bind(this);
		this.clearCanvas = this.clearCanvas.bind(this);
		this.isEmpty = this.isEmpty.bind(this);
		this.getImage = this.getImage.bind(this);
	}
	componentDidMount() {
		this.props.onRef(this);
		this.signaturePad = new SignaturePad(this.canvasElement);
		window.addEventListener('resize', this.resizeCanvas);
		this.resizeCanvas();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.resizeCanvas);
	}
	getImage() {
		return this.signaturePad.toDataURL();
	}
	clearCanvas() {
		this.signaturePad.clear();
	}
	isEmpty() {
		return this.signaturePad.isEmpty();
	}

	resizeCanvas() {
		const ratio = Math.max(window.devicePixelRatio || 1, 1);
		this.canvasElement.width = this.canvasElement.offsetWidth * ratio;
		this.canvasElement.height = this.canvasElement.offsetHeight * ratio;
		this.canvasElement.getContext('2d').scale(ratio, ratio);
		if (this.signaturePad !== null) {
			this.signaturePad.clear();
		}
	}

	render() {
		const { classes } = this.props;
		return (
			<div className={classes.root}>
				<canvas
					className={classes.canvas}
					ref={element => this.canvasElement = element}
				/>
				<div className={classes.buttons}>
					<Button
						onClick={this.clearCanvas}
						className={classes.button}
					>
						Clear
					</Button>
				</div>
			</div>
		);
	}
}

SignatureArea.propTypes = {
	classes: PropTypes.object.isRequired,
	/**
	 * Pass callback to get te component instance
	 */
	onRef: PropTypes.func,
};

SignatureArea.defaultProps = {
	onRef: null,
};

export default compose(withStyles(styles))(SignatureArea);
