import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Route } from 'react-router-dom';
import { ModalPopups } from '@enums';
import { profile } from '@redux';
import {
	CYChangePasswordModal,
	ChangeProfileAddressModal,
	ChangeProfileEmailModal,
	ChangeProfilePhoneModal,
	ConfirmProfileEmailModal,
	ChangeEmploymentModal,
	ChangeMarketDataSubscriberStatusModal,
	ChangeMarketDataSubscriptionsModal,
	EnableThirdPartyMarketDataModal,
	ChangeDependentsModal,
	ChangeTwoFactorAuthorizationModal,
} from './components';

const styles = theme => ({

});

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
	actions: {
		hideConfirmProfileEmailModal: () => dispatch(profile.actions.hideConfirmEmailModal()),
		hideProfileModal: modalId => dispatch(profile.actions.hideProfileModal(modalId)),
	},
});

class ProfileModals extends React.Component {
	getProfileModal = (item) => {
		// !!! Modals must be in current order !!!

		switch (item) {
		case ModalPopups.CY_CHANGE_PASSWORD:
			return (
				<CYChangePasswordModal
					key={`${ModalPopups.CY_CHANGE_PASSWORD}Modal`}
					onClose={() => this.closeModal(ModalPopups.CY_CHANGE_PASSWORD)}
				/>);
		case ModalPopups.CHANGE_PROFILE_ADDRESS:
			return (
				<ChangeProfileAddressModal
					key={`${ModalPopups.CHANGE_PROFILE_ADDRESS}Modal`}
					onClose={() => this.closeModal(ModalPopups.CHANGE_PROFILE_ADDRESS)}
				/>);
		case ModalPopups.CHANGE_PROFILE_EMAIL:
			return (
				<ChangeProfileEmailModal
					key={`${ModalPopups.CHANGE_PROFILE_EMAIL}Modal`}
					onClose={() => this.closeModal(ModalPopups.CHANGE_PROFILE_EMAIL)}
				/>);
		case ModalPopups.CHANGE_PROFILE_PHONE:
			return (
				<ChangeProfilePhoneModal
					key={`${ModalPopups.CHANGE_PROFILE_PHONE}Modal`}
					onClose={() => this.closeModal(ModalPopups.CHANGE_PROFILE_PHONE)}
				/>);
		case ModalPopups.CHANGE_DEPENDENTS:
			return (
				<ChangeDependentsModal
					key={`${ModalPopups.CHANGE_DEPENDENTS}Modal`}
					onClose={() => this.closeModal(ModalPopups.CHANGE_DEPENDENTS)}
				/>);
		case ModalPopups.CHANGE_EMPLOYMENT:
			return (
				<ChangeEmploymentModal
					key={`${ModalPopups.CHANGE_EMPLOYMENT}Modal`}
					onClose={() => this.closeModal(ModalPopups.CHANGE_EMPLOYMENT)}
				/>);
		case ModalPopups.CHANGE_MARKETDATA_SUBSCRIBER_STATUS_MODAL:
			return (
				<ChangeMarketDataSubscriberStatusModal
					key={`${ModalPopups.CHANGE_MARKETDATA_SUBSCRIBER_STATUS_MODAL}Modal`}
					onClose={() => this.closeModal(ModalPopups.CHANGE_MARKETDATA_SUBSCRIBER_STATUS_MODAL)}
				/>);
		case ModalPopups.CHANGE_MARKETDATA_SUBSCRIPTIONS_MODAL:
			return (
				<ChangeMarketDataSubscriptionsModal
					key={`${ModalPopups.CHANGE_MARKETDATA_SUBSCRIPTIONS_MODAL}Modal`}
					onClose={() => this.closeModal(ModalPopups.CHANGE_MARKETDATA_SUBSCRIPTIONS_MODAL)}
				/>);
		case ModalPopups.ENABLE_THIRD_PARTY_MARKETDATA_MODAL:
			return (
				<EnableThirdPartyMarketDataModal
					key={`${ModalPopups.ENABLE_THIRD_PARTY_MARKETDATA_MODAL}Modal`}
					onClose={() => this.closeModal(ModalPopups.ENABLE_THIRD_PARTY_MARKETDATA_MODAL)}
				/>);
		case ModalPopups.CHANGE_TWO_FACTOR_AUTHORIZATION:
			return (
				<ChangeTwoFactorAuthorizationModal
					key={`${ModalPopups.CHANGE_TWO_FACTOR_AUTHORIZATION}Modal`}
					onClose={() => this.closeModal(ModalPopups.CHANGE_TWO_FACTOR_AUTHORIZATION)}
				/>);
		default:
			return null;
		}
	}
	closeModal = id => this.props.actions.hideProfileModal(id);

	render() {
		const {
			actions,
			location,
		} = this.props;


		const modals = location.hash && location.hash.substr(1).split('/');
		const topModal = modals && modals.length > 0 && this.getProfileModal(modals && modals.pop());

		const choseModalToDisplay = ({ match }) => (
			match ?
				<ConfirmProfileEmailModal onClose={actions.hideConfirmProfileEmailModal} />
				: topModal
		);

		return (
			<React.Fragment>
				<Route path="/confirmEmail/:token">
					{choseModalToDisplay}
				</Route>
			</React.Fragment>
		);
	}
}

ProfileModals.propTypes = {
	actions: PropTypes.object.isRequired,
};

export default compose(
	withRouter,
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(ProfileModals);
