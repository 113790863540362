import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { ProgressContainer } from '@components';
import { constants, env } from '@config';
import { userAccounts } from '@redux';
import { DemoAccountsTabs } from '@enums';
import {
	TransactionsHistory,
	IndividualCashAccount,
	CurrentHoldings,
	TransactionsHistoryLME,
} from './components';
import { DemoAccounts } from '../../components'

import { J2TAccountBriefingPanel } from './components/IndividualCashAccount/J2TAccountBriefingPanel';
import { AccountBriefingPanelLME } from './components/IndividualCashAccount/AccountBriefingPanelLME';

const styles = theme => ({
	loader: {
		color: theme.palette.primary.main,
	},
	contentItem: {
		marginBottom: '30px',
	},
	endItem: {
		height: '30px',
	},
});

const mapStateToProps = state => ({
	loading: userAccounts.selectors.loading(state),
	accounts: userAccounts.selectors.accounts(state),
	currentAccountIsDemo: userAccounts.selectors.currentAccountIsDemo(state),
	userInfo: userAccounts.selectors.userInfo(state),
});


class MainContent extends React.Component {
	render() {
		const { classes, loading, accounts, currentAccountIsDemo, userInfo} = this.props;
		if (userInfo.Guid == null) return (<DemoAccounts viewType={DemoAccountsTabs.CARD}/>)
		if (!loading && !accounts?.length) return null;
		return (
			<ProgressContainer
				classes={{ loader: classes.loader }}
				submitting={loading}
			>
				<div className={classes.content}>
					{(constants.FIRM_J2TX === env.firm) &&
						<h1>Dashboard J2TX</h1>
					}
					{[constants.LIME_INT, constants.LIME_NSR, constants.LIME_LME, constants.FIRM_J2TX].some(x => x === env.firm) &&
						<React.Fragment>
							<div className={classes.contentItem}>
								{([constants.LIME_LME, constants.FIRM_J2TX].every(x => x !== env.firm))
									? <J2TAccountBriefingPanel />
									: <AccountBriefingPanelLME />
								}
							</div>
							{([constants.LIME_LME, constants.FIRM_J2TX].every(x => x !== env.firm)) &&
								<div className={classes.contentItem}>
									<CurrentHoldings />
								</div>
							}
							{!currentAccountIsDemo &&
								<div className={classes.contentItem}>
									{[constants.LIME_LME, constants.FIRM_J2TX].some(x => x === env.firm)
										? <TransactionsHistoryLME />
										: <TransactionsHistory />
									}
								</div>
							}
						</React.Fragment>
					}
					{(env.firm === constants.LIME_FIN) &&
						<React.Fragment>
							<div className={classes.contentItem}>
								<IndividualCashAccount />
							</div>
							<div className={classes.contentItem}>
								<CurrentHoldings />
							</div>
							<div className={classes.contentItem}>
								<TransactionsHistory />
							</div>
						</React.Fragment>
					}

					<div className={classes.endItem} />
				</div>
			</ProgressContainer>
		);
	}
}

MainContent.propTypes = {
	classes: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
)(MainContent);
