import { delay } from 'redux-saga';
import { all, call, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { auth, profile, signalR, notifications, userAccounts } from '@redux';
import { push } from 'connected-react-router';
import { constants, env } from '@config';
import { ModalPopups } from '@enums';
import { cabinetLog, addHashToUrl, getDateTimeEst, dateDiffInDays, captureError } from '@global';
import actions from './actions';

function* dashboardInitSaga({ payload: { tradeCode } }) {
	try {

		if (!!env.signalRUrl) {
			yield put(signalR.actions.connectSignalR());
		}

		yield put(userAccounts.actions.getAccountsRequest());
		yield put(userAccounts.actions.getAllAccountsInfo.request());

		yield take([
			userAccounts.actions.GET_ACCOUNTS_SUCCESS,
			userAccounts.actions.GET_ACCOUNTS_FAILURE,
		]);

		const demoAccount = yield select(userAccounts.selectors.getDemoAccount);
		const anyActiveAccountExists = yield select(userAccounts.selectors.anyActiveAccountExists);

		if (demoAccount && !anyActiveAccountExists) {
			const currentDateTimeEst = getDateTimeEst();
			const expirationDate = new Date(demoAccount.ExpirationDate);
			const diffDays = dateDiffInDays(expirationDate, currentDateTimeEst);

			if (diffDays < 0) {
				yield put(profile.actions.showDemoAccountExpirationModal());
				return;
			}
		}

		const accounts = (yield select(userAccounts.selectors.accounts));
		cabinetLog(accounts);

		const haveAccounts = accounts && accounts.length > 0;
		if ([constants.LIME_LME].includes(env.firm) && !haveAccounts) {
			return;
			//throw new Error('Has no account');
		}

		const currentAccount = localStorage.getItem('curacc');
		let accCurrent = accounts.find(it => it.TradeCode === tradeCode || it.TradeCode === currentAccount);
		if(accCurrent?.IsDemo){
			accCurrent = accounts.find(f => !f.IsDemo);
			if(!accCurrent)
				return;
				//throw new Error('Has no account');
		}
		// init start's popups
		yield put(actions.initComplete());
		cabinetLog('init complete');

		if (haveAccounts) {
			const liveAccount = accounts.find(it => !it.IsDemo) || accounts[0];
			yield all([
				yield put(userAccounts.actions.setCurrentAccount(accCurrent || liveAccount)),
			]);
		} else {
			yield put(notifications.actions.getNotifications.request());
		}
	} catch (error) {
		captureError(error);
		console.error('init error', error);
	}
}


function* dashboardInitWelcomePageSaga() {
	try {
		yield all([
			// welcome page required profile.IsInstantTrading
			take(profile.actions.GET_PROFILE_SUCCESS),
			// welcome page required accountInfo.platforms.ActiveTradePlatform
			take(userAccounts.actions.GET_ACCOUNT_INFO_SUCCESS),
		]);
		const userInfo = yield select(userAccounts.selectors.userInfo);
		if (userInfo && userInfo.IsPreopened) {
			// get current location
			const location = yield select(state => state.router.location);
			// show modal
			yield put(push(addHashToUrl(location, ModalPopups.WELCOME_PAGE)));
		}
	} catch (error) {
		captureError(error);
	}
}


// function* dashboardPingAuthFlowSaga() {
// 	try {
// 		while (window.localToken) {
// 			yield call(auth.saga.pingAuthRequestSaga);
// 			yield call(delay, 60 * 1000);
// 		}
// 	} catch (error) {
// 		captureError(error);
// 	}
// }


// Watchers
function* watchInit() {
	yield takeEvery(actions.INIT, dashboardInitSaga);
}

function* watchInitWelcomePage() {
	yield takeLatest(actions.INIT_COMPLETED, dashboardInitWelcomePageSaga);
}

// function* watchPingAuth() {
// 	// only one working thread (previous will be cancelled)
// 	yield takeLatest(actions.INIT_COMPLETED, dashboardPingAuthFlowSaga);
// }

// Root saga
const rootSaga = [
	fork(watchInit),
	fork(watchInitWelcomePage),
	// fork(watchPingAuth),
];

export default {
	rootSaga,
};

