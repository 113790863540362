/* eslint-disable no-shadow */
import { RadioGroup } from '@components';
import { createComponent, mapError } from '../utils';

export default createComponent(RadioGroup, ({
	input: { onChange, value, ...inputProps },
	onChange: onChangeFromField,
	...props
}) => ({
	...inputProps,
	...mapError(props),
	value,
	onChange: (event, value) => {
		onChange(value);
		if (onChangeFromField) {
			onChangeFromField(value);
		}
	},
}));
