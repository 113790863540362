import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { app, userAccounts } from '@redux';
import { SvgIcon } from '@components';
import {
	FeeItem,
	FundItem,
	TradeItem,
	InstrumentItem
} from './components';


const styles = theme => ({
	root: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
	},
	headContainer: {
		width: '100%',
		marginRight: '14px',
	},
	headContent: {
		...theme.customstyles.title,
		color: theme.palette.primary.dark,
		width: '100%',
	},
	headToggleIcon: {
		color: theme.palette.text.secondary,
	},
	arrowIcon: {
		stroke: theme.palette.text.secondary,
	},
	content: {
		paddingTop: '16px',
	},
	rightPadding: {
		paddingRight: '62px',
	},
});

const mapStateToProps = state => ({
	account: userAccounts.selectors.currentTradeAccount(state),
});

const mapDispatchToProps = dispatch => ({
	actions: {
		cancelOrder: payload => dispatch(userAccounts.actions.cancelOrder.request(payload)),
		showConfirmModal: payload => dispatch(app.actions.showConfirmModal(payload)),
	},
});

class Item extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
		};
	}

	toggleOpen = () => {
		this.setState({
			open: !this.state.open,
		});
	}

	cancelOrder = () => {
		this.props.actions.showConfirmModal({
			text: 'Please click confirm you want to cancel the request',
			action: onEndRequest => this.props.actions.cancelOrder({
				account: this.props.account,
				orderCode: this.props.item.RequestCode,
				onEndRequest,
			}),
		});
	}

	render() {
		const {
			item,
			classes,
		} = this.props;

		const opened = this.state.open;
		const Icon = opened ?
			<SvgIcon className={classes.arrowIcon} icon="ArrowUp" />
			: <SvgIcon className={classes.arrowIcon} icon="ArrowDown" />;

		const contentCssClass = classNames({
			[classes.content]: true,
			[classes.opened]: opened,
			[classes.closed]: !opened,
		});

		let ItemComponent;

		switch (item.Type) {
		case 'fund':
			ItemComponent = FundItem;
			break;

		case 'trade':
			ItemComponent = TradeItem;
			break;

		case 'instrument':
			ItemComponent = InstrumentItem;
			break;

		default:
			ItemComponent = FundItem;
		}

		if (item.Fees && item.Fees.length > 0) {
			return (
				<div className={classes.root}>
					<div className={classes.headContainer}>
						<div className={classes.headContent}>
							<ItemComponent item={item} />
						</div>
						{opened &&
							<div className={contentCssClass}>
								<FeeItem item={item} />
							</div>
						}
					</div>
					<div>
						<IconButton onClick={this.toggleOpen}>
							{Icon}
						</IconButton>
					</div>
				</div>
			);
		}

		if (item.CanBeCanceled) {
			return (
				<div className={classes.root}>
					<div className={classes.headContainer}>
						<div className={classes.headContent}>
							<ItemComponent item={item} />
						</div>
					</div>
					<div>
						<IconButton onClick={this.cancelOrder}>
							<SvgIcon className={classes.headToggleIcon} icon="Close" />
						</IconButton>
					</div>
				</div>
			);
		}

		return <div className={classes.rightPadding}><ItemComponent item={item} /></div>;
	}
}

Item.propTypes = {
	account: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	item: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(Item);
