import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import NSRLogoSvg from './nsr_logo.svg';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(theme => createStyles({
	root: {
		position: 'relative',
		width: '74px',
		height: '48px',
		marginLeft: '-17px',
		marginTop: '-6px',
	},
}));

const NSRLogo = () => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<img src={NSRLogoSvg} alt="Lending Robot logo" />
		</div>
	);
};

export default NSRLogo;
