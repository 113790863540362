import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userAccounts } from '@redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { R } from '@dev2t/react-ui-kit/src';
import { A } from '@components';
import { InputEditableItem } from '@shared';

const useStyles = makeStyles((theme) => ({
	content: {
		...theme.customstyles.body,
		padding: '16px 72px',
		[theme.breakpoints.down('md')]: {
			padding: '8px 24px',
		},
	},
	header: {
		...theme.customstyles.headline1,
		...theme.palette.text.primary,
	},
	pendingInfoText: {
		color: theme.palette.text.disabled
	}
}));

const AccountFeaturesLMI = () => {
	const dispatch = useDispatch();
	const limeTraderAccountInfo = useSelector(userAccounts.selectors.getLimeTraderAccountInfo);
	const currentTradeAccount = useSelector(userAccounts.selectors.currentTradeAccount);
	const classes = useStyles();

	useEffect(() => {
		if (!currentTradeAccount.tradeCode) {
			return;
		}

		dispatch(userAccounts.actions.getLimeTraderAccountInfo.request({tradeCode: currentTradeAccount.tradeCode}));
	}, [currentTradeAccount.tradeCode]);

	return (
		<Box mb="30px" classes={{root: classes.content}}>
			<Box p="8px" classes={{root: classes.header}}>
				<R id="common_account_features" defaultValue="Account Features" />
			</Box>
			<InputEditableItem
				title="Margin Type"
				value={limeTraderAccountInfo.data?.MarginType ?? '-'}
			/>
			<InputEditableItem
				title="Options"
				value={limeTraderAccountInfo.data?.OptionLevel ?? '-'}
			/>
			<Box p="8px">
				To change please check&nbsp;
				<A href="https://int.lime.co/pricing" target='_blank'>
					your eligibility
				</A> and create a&nbsp;
				<A href="#NonTradingOrderCommonModal">
					Free form order
				</A>.
			</Box>
		</Box>
	)
}	


export default AccountFeaturesLMI;
