import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import MUISvgIcon from '@material-ui/core/SvgIcon';
import LimeLogoSvg from './lime_logo.svg';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(theme => createStyles({
	root: {
		position: 'relative',
		maxWidth: '48px',
		height: '48px',
	},
}));
const LimeLogo = ({ alt, classes, className }) => {
	const mergedclasses = {
		...useStyles(),
		...classes,
	};
	return (
		<React.Fragment>
			<div className={classNames(mergedclasses.root, className)}>
				<img src={LimeLogoSvg} alt="Lime logo" />
				{/* <SvgIcon
					icon="LimeLogo"
					alt={alt}
					viewBox="50 0 380 370"
					style={{
						width: '100%',
						height: '100%',
					}}
				/> */}
			</div>
		</React.Fragment>
	);
};

LimeLogo.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	alt: PropTypes.string,
};
LimeLogo.defaultProps = {
	alt: 'Lime logo',
	classes: {},
	className: undefined,
};

export default LimeLogo;
