import React from 'react';
import PropTypes from 'prop-types';
import { R } from '@dev2t/react-ui-kit/src/components';
import { CircularProgress, Box } from '@material-ui/core';


function ExchangeRate({
	rate,
	currencyFrom,
	currencyTo,
}) {
	return (
		<React.Fragment>
			<R id="actual_rate" defaultValue="Actual rate" /> 1 {currencyFrom} = {rate} {currencyTo}
		</React.Fragment>
	);
}

function Timer({ timer }) {
	return (
		<React.Fragment>
			<R id="next_rate_update" defaultValue="Next rate update in" /> 00:{timer < 10 ? `0${timer}` : timer}
		</React.Fragment>
	);
}

function LoadableRate({
	loading,
	success,
	rate,
	currencyFrom,
	currencyTo,
	timer,
}) {
	const verticalAligning = loading ? 'center' : 'flex-end';
	const horizontalAligning = loading ? 'center' : 'flex-start';
	return (
		<Box display="flex" alignItems={verticalAligning} justifyContent={horizontalAligning} height="60px" width={'43%'}>
			{loading && <CircularProgress size={40} />}
			{!loading && success && <ExchangeRate rate={rate} currencyFrom={currencyFrom} currencyTo={currencyTo} />}
			{!loading && !success && <R id="rate_update_error" defaultValue="Couldn't get the current exchange rate" />}
			<br />
			{!loading && <Timer timer={timer} />}
		</Box>
	);
}

function ExchangeLimitationsTitle({ min, max, currency }) {
	return (
		<React.Fragment>
			<R id="min_exchange_amount" defaultValue="Minimum exchnage rate" /> {min} {currency}
			<br />
			<R id="max_exchange_amount" defaultValue="Maximum exchnage rate" /> {max} {currency}
		</React.Fragment>
	);
}


export default function AdditionalInfo({
	loading,
	success,
	rate,
	currencyFrom,
	currencyTo,
	timer,
	min,
	max,
}) {
	return (
		<Box fontSize="12px" lineHeight="18px">
			<LoadableRate
				loading={loading}
				success={success}
				rate={rate}
				currencyFrom={currencyFrom}
				currencyTo={currencyTo}
				timer={timer}
			/>
			<ExchangeLimitationsTitle
				min={min}
				max={max}
				currency={currencyFrom}
			/>
			<br />
			<br />
		</Box>
	);
}

LoadableRate.propTypes = {
	loading: PropTypes.bool,
	success: PropTypes.bool,
	rate: PropTypes.number,
	currencyFrom: PropTypes.string,
	currencyTo: PropTypes.string,
	timer: PropTypes.number,
};

LoadableRate.defaultProps = {
	loading: true,
	success: true,
	rate: 0,
	currencyFrom: '',
	currencyTo: '',
	timer: 0,
};

AdditionalInfo.propTypes = {
	loading: PropTypes.bool,
	success: PropTypes.bool,
	rate: PropTypes.number,
	currencyFrom: PropTypes.string,
	currencyTo: PropTypes.string,
	timer: PropTypes.number,
	min: PropTypes.number,
	max: PropTypes.number,
};

AdditionalInfo.defaultProps = {
	loading: true,
	success: true,
	rate: 0,
	currencyFrom: '',
	currencyTo: '',
	timer: 0,
	min: 0,
	max: 0,
};

ExchangeLimitationsTitle.propTypes = {
	min: PropTypes.number,
	max: PropTypes.number,
	currency: PropTypes.string,
};

ExchangeLimitationsTitle.defaultProps = {
	min: 0,
	max: 0,
	currency: '',
};

ExchangeRate.propTypes = {
	rate: PropTypes.number,
	currencyFrom: PropTypes.string,
	currencyTo: PropTypes.string,
};

ExchangeRate.defaultProps = {
	rate: 0,
	currencyFrom: '',
	currencyTo: '',
};

Timer.propTypes = {
	timer: PropTypes.number,
};

Timer.defaultProps = {
	timer: 0,
};
