import actions from './actions';
import auth from '../auth';

const initialState = {
	depositWireInfo: { // for preopened clients
		isVisible: false,
	},
	depositWireModal: {
		bankAccount: null,
	},
	depositAchModal: {
		bankAccount: null,
		tradeAccount: null,
	},
	accountFundingInfo: [],
};

export const initialFundingInfo = {
	loading: false,
	error: false,
	tradeCode: null,
	currency: null,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.getAccountFundingInfo.REQUEST:
			return {
				...state,
				accountFundingInfo: [
					...state.accountFundingInfo.filter(x => x.tradeCode !== payload.tradeCode || x.currency !== payload.currency),
					{
						...initialFundingInfo,
						...(state.accountFundingInfo.find(x => x.tradeCode === payload.tradeCode && x.currency === (payload.currency || '')) || {}),
						tradeCode: payload.tradeCode,
						currency: payload.currency || '',
						loading: true,
					},
				],
			};
		case actions.getAccountFundingInfo.SUCCESS:
			return {
				...state,
				accountFundingInfo: [
					...state.accountFundingInfo.map(x => (x.tradeCode !== payload.request.tradeCode || x.currency !== (payload.request.currency || '')
						? x :
						{
							...x,
							value: payload.response.Value,
							loading: false,
							error: false,
						}
					)),
				],
			};
		case actions.getAccountFundingInfo.FAILURE:
			return {
				...state,
				accountFundingInfo: [
					...state.accountFundingInfo.map(x =>
						(!x.loading // не возможно точно определить какой из запросов упал, поэтому ставим ошибку по всем начатым
							? x :
							{
								...x,
								value: null,
								loading: false,
								error: true,
							}
						)),
				],
			};
		case actions.CLEAR_ACCOUNT_FUNDING_INFO:
			return {
				...state,
				accountFundingInfo: initialState.accountFundingInfo,
			};
		case actions.SHOW_DEPOSIT_FUNDS_MODAL:
			return {
				...state,
				depositAchModal: {
					bankAccount: payload.bankAccount,
					tradeAccount: payload.tradeAccount,
				},
			};
		case actions.HIDE_DEPOSIT_FUNDS_MODAL:
			return {
				...state,
				depositAchModal: {
					bankAccount: null,
					tradeAccount: null,
				},
			};
		case actions.SHOW_DEPOSIT_FUNDS_WIRE_MODAL:
			return {
				...state,
				depositWireModal: {
					bankAccount: payload.bankAccount,
				},
			};
		case actions.HIDE_DEPOSIT_FUNDS_WIRE_MODAL:
			return {
				...state,
				depositWireModal: {
					bankAccount: null,
				},
			};
		case actions.WIRE_DEPOSIT_INFO_FORM_SUBMIT_SUCCESS:
			return {
				...state,
				depositWireInfo: {
					...payload,
					isVisible: true,
				},
			};
		case actions.INIT_DEPOSIT_WIRE_INFO:
			return {
				...state,
				depositWireInfo: {
					isVisible: false,
				},
			};
		case auth.actions.LOGOUT_SUCCESS:
			return { ...initialState };
		default:
			return state;
	}
};
