import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { VictoryChart } from 'victory-chart';
import { VictoryLine } from 'victory-line';
import { VictoryAxis } from 'victory-axis';
import { VictoryTheme } from 'victory-core';
import PropTypes from 'prop-types';

const styles = theme => ({
});

const prepareData = data => data.map((item, index) => {
	if (!item) {
		return item;
	}
	const newItem = item;
	newItem.D = new Date(item.D);
	newItem.Dot = index;
	return newItem;
});

class ChartThumbnail extends React.Component {
	render() {
		const {
			data,
			axisY,
			useDots,
			width,
			height,
			resolution,
			classes,
			lineColor,
		} = this.props;

		if (!data || !data.length) {
			return '';
		}

		const preparedData = prepareData(data);

		return (
			<div style={{ width, height }}>
				<VictoryChart
					theme={VictoryTheme.grayscale}
					scale={{ x: useDots ? 'linear' : 'time' }}
					width={resolution.width}
					height={resolution.height}
				>
					{!axisY &&
					<VictoryAxis
						tickFormat={() => null}
						style={{
							axis: {
								stroke: '#bfcacf',
							},
						}}
					/>
					}
					{axisY &&
					<VictoryAxis
						tickFormat={() => null}
						offsetY={-100}
						style={{
							axis: {
								stroke: '#bfcacf',
							},
						}}
					/>
					}
					{axisY &&
					<VictoryLine
						data={[
							{ x: axisY.start, y: axisY.value },
							{ x: axisY.end, y: axisY.value }]}
						style={{
							data: {
								stroke: '#bfcacf',
								strokeWidth: 4,
							},
						}}
					/>
					}
					<VictoryLine
						data={preparedData}
						style={{
							data: {
								stroke: lineColor,
								strokeWidth: 4,
							},
							parent: { border: '2px solid #bfcacf' },
						}}
						x={useDots ? 'Dot' : 'D'}
						y="V"
					/>
				</VictoryChart>
			</div>
		);
	}
}

ChartThumbnail.propTypes = {
	data: PropTypes.array.isRequired,
	width: PropTypes.string,
	height: PropTypes.string,
	resolution: PropTypes.object,
	axisY: PropTypes.object,
	lineColor: PropTypes.string,
};

ChartThumbnail.defaultProps = {
	width: '100%',
	height: '100%',
	resolution: { width: 450, height: 300 },
	lineColor: '#11d87c',
	axisY: null,
};

export default compose(withStyles(styles))(ChartThumbnail);
