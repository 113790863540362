import { createFormAction } from 'redux-form-saga';
import { createApiActions } from '../extensions';

const plaidSelectAccountFormSubmitActionPrefix = 'PLAID_SELECT_ACCOUNT_FORM_SUBMIT';
const plaidSelectAccountFormActionCreator =
	createFormAction(plaidSelectAccountFormSubmitActionPrefix);

const actions = {

	getLinkedAccounts: createApiActions('LINKED_ACCOUNTS/GET_LINKED_ACCOUNTS'),


	GET_PLAID_ACCOUNTS_REQUEST: 'LINKED_ACCOUNTS/GET_PLAID_ACCOUNTS_REQUEST',
	GET_PLAID_ACCOUNTS_SUCCESS: 'LINKED_ACCOUNTS/GET_PLAID_ACCOUNTS_SUCCESS',
	GET_PLAID_ACCOUNTS_FAILURE: 'LINKED_ACCOUNTS/GET_PLAID_ACCOUNTS_FAILURE',

	getPlaidAccountsRequest: (tradeCode, token) => ({
		type: actions.GET_PLAID_ACCOUNTS_REQUEST,
		payload: {
			tradeCode,
			token,
		},
	}),
	getPlaidAccountsSuccess: payload => ({
		type: actions.GET_PLAID_ACCOUNTS_SUCCESS,
		payload,
	}),
	getPlaidAccountsFailure: error => ({
		type: actions.GET_PLAID_ACCOUNTS_FAILURE,
		payload: {
			error,
		},
	}),

	PLAID_SELECT_ACCOUNT_FORM_SUBMIT_REQUEST: plaidSelectAccountFormActionCreator.REQUEST,
	PLAID_SELECT_ACCOUNT_FORM_SUBMIT_SUCCESS: plaidSelectAccountFormActionCreator.SUCCESS,
	PLAID_SELECT_ACCOUNT_FORM_SUBMIT_FAILURE: plaidSelectAccountFormActionCreator.FAILURE,

	plaidSelectAccountFormSubmitHandler: plaidSelectAccountFormActionCreator,
	plaidSelectAccountFormRequest: (...args) =>
		plaidSelectAccountFormActionCreator.request({ ...args }),
	plaidSelectAccountFormSuccess: payload => plaidSelectAccountFormActionCreator.success(payload),
	plaidSelectAccountFormFailure: error => plaidSelectAccountFormActionCreator.failure(error),


	achManualLinkingForm: createFormAction('LINKED_ACCOUNTS/ACH_MANUAL_LINKING_FORM'),

	wireManualLinkingForm: createFormAction('LINKED_ACCOUNTS/WIRE_MANUAL_LINKING_FORM'),

	achUnlinkBankAccount: createApiActions('LINKED_ACCOUNTS/ACH_UNLINK_BANK_ACCOUNT'),

	wireUnlinkBankAccount: createApiActions('LINKED_ACCOUNTS/WIRE_UNLINK_BANK_ACCOUNT'),
};

export default actions;
