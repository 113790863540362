import { constants } from '@config'

export const allowedWithdrawalByCountry = {
    [constants.LIME_LME]: {
        "BankAccountItemMalayBankCY": ["MY", "IN"],
        "BankAccountItemKoreanBankCY": ["KR"],
        "BankAccountItemKenyanBankCY": ["KE"],
        "BankAccountItemNigerianBankCY": ["NG"],
        "BankAccountItemIndianBankCY": ["IN"],
    },
}

export default allowedWithdrawalByCountry;