import React from 'react';

const Svg = (
	<React.Fragment>
		<g>
			<rect fill="none" height="24" width="24"/>
		</g>
		<g>
			<g>
				<g>
					<polygon points="14,19.88 14,22 16.12,22 21.29,16.83 19.17,14.71"/>
				</g>
				<g>
					<path d="M20,8l-6-6H6C4.9,2,4.01,2.9,4.01,4L4,20c0,1.1,0.89,2,1.99,2H12v-2.95l8-8V8z M13,9V3.5L18.5,9H13z"/>
				</g>
				<g>
					<path d="M22.71,14L22,13.29c-0.39-0.39-1.02-0.39-1.41,0L19.88,14L22,16.12l0.71-0.71C23.1,15.02,23.1,14.39,22.71,14z"/>
				</g>
			</g>
		</g>
	</React.Fragment>
);

export default Svg;
