import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { ModalPopups } from '@enums';
import { withdrawFunds, userAccounts } from '@redux';
import { ErrorMessage, ProgressContainer } from '@components';
import { Modal } from '@dev2t/react-ui-kit/src/components';
import { connect } from 'react-redux';
import { AchWithdrawalForm } from '@shared';

const styles = {};

const mapStateToProps = (state) => {
	const resProps = {
		fromUserAccount: withdrawFunds.selectors.fromUserAccount(state),
		toBankAccount: withdrawFunds.selectors.toBankAccount(state),
		canShow: true,
		loading: false,
	};

	if (resProps.fromUserAccount && resProps.toBankAccount) {
		return resProps;
	}
	// default if not set
	const loading = userAccounts.selectors.loading(state) || userAccounts.selectors.accountInfo(state).loading;
	const currentTradeAccount = userAccounts.selectors.currentTradeAccount(state);
	const linkedAccounts = userAccounts.selectors.linkedAccounts(state);
	const accountStatus = userAccounts.selectors.currentAccountStatus(state);

	return {
		fromUserAccount: {
			tradeCode: currentTradeAccount.tradeCode,
			clearer: currentTradeAccount.clearerFirm,
		},
		toBankAccount: loading ? null : linkedAccounts.find(x => x.Type === 'ACH'),
		canShow: accountStatus && accountStatus.CanWithdrawal && accountStatus.CanAchLink,
		loading,
	};
};

class WithdrawFundsAchModal extends React.Component {
	render() {
		const {
			onClose,
			fromUserAccount,
			toBankAccount,
			canShow,
			loading,
		} = this.props;
		
		return (
			<Modal
				title="Withdraw"
				onClose={onClose}
				className={`QA-${ModalPopups.WITHDRAW_FUNDS_ACH}`}
			>
				<ProgressContainer submitting={loading}>
					<div>
						{canShow && !toBankAccount && <ErrorMessage onClick={onClose} text={`Account ${fromUserAccount.tradeCode} is not linked.`} />}
						{!canShow &&
							<ErrorMessage onClick={onClose} text={`Unavailable for account ${fromUserAccount.tradeCode}.`} />
						}
						{toBankAccount && canShow &&
							<AchWithdrawalForm
								fromUserAccount={fromUserAccount}
								toBankAccount={toBankAccount}
							/>
						}
					</div>
				</ProgressContainer>
			</Modal>
		);
	}
}

WithdrawFundsAchModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	canShow: PropTypes.bool.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
)(WithdrawFundsAchModal);
