import React from 'react';
import { withRouter } from 'react-router';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import { env } from '@config';


const styles = theme => ({
	root: {
		borderRadius: '50%',
		padding: '7px',
		paddingTop: '6px',
		width: '40px',
		height: '40px',
	},
	image: {
		width: '100%',
		height: '100%',
		margin: 'auto',
	},
	lightTooltip: {
		background: theme.palette.common.white,
		color: theme.palette.text.primary,
		boxShadow: theme.shadows[1],
		fontSize: 11,
	},
});

class BankLogo extends React.Component {
	render() {
		const {
			classes,
			imgUrl,
			imgRelativePath,
			bankAccount,
			theme,
		} = this.props;

		const bgColor = bankAccount.LogoColors ?
			bankAccount.LogoColors.primary
			: theme.palette.text.secondary;
		const bgBorder = bankAccount.LogoColors && bankAccount.LogoColors.border || '0px';

		const noBankLogoSrc = `${env.baseUrl}${imgRelativePath}/no-bank-icon.png`;
		const withBankName = !!bankAccount.BankName;

		const srcLogo = bankAccount.LogoFullFileName || (
			bankAccount.LogoFileName ?
				`${env.baseUrl}${imgRelativePath}/${bankAccount.LogoFileName}`
				: noBankLogoSrc
		);

		const Logo = (
			<img
				src={srcLogo}
				alt="Bank Logo"
				className={classes.image}
			/>);


		return (
			<div className={classes.root} style={{ backgroundColor: bgColor, border: bgBorder }}>
				{withBankName &&
				<Tooltip
					classes={{ tooltip: classes.lightTooltip }}
					TransitionComponent={Fade}
					TransitionProps={{ timeout: 600 }}
					title={bankAccount.BankName}
				>
					{Logo}
				</Tooltip>
				}
				{!withBankName &&
				<div>
					{ Logo }
				</div>
				}
			</div>
		);
	}
}

BankLogo.propTypes = {
	classes: PropTypes.object.isRequired,
	bankAccount: PropTypes.shape({
		LogoFileName: PropTypes.string,
		LogoColors: PropTypes.shape({
			primary: PropTypes.string,
			dark: PropTypes.string,
			light: PropTypes.string,
			darker: PropTypes.string,
		}),
	}).isRequired,
};

BankLogo.defaultProps = {
	imgRelativePath: '/static/banklogos',
};

export default compose(
	withRouter,
	withStyles(styles),
	withTheme,
)(BankLogo);
