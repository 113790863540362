import React from 'react';
import { useSelector } from 'react-redux';
import { userAccounts, notifications } from '@redux';
import { R } from '@dev2t/react-ui-kit/src/components';
import GenericTextAlert from './GenericTextAlert';

const NoAccountsAlert = () => {
	const loading = useSelector(userAccounts.selectors.loading);
	const accounts = useSelector(userAccounts.selectors.accounts);

	const notificationItems = useSelector(notifications.selectors.notifications)?.items || [];
	const notificationsLoading = useSelector(notifications.selectors.loading);
	const notificationExist = notificationItems.some(item => item.AlertType === 'alert');
	if (loading || accounts?.length > 0 || notificationsLoading || notificationExist) return null;

	return (
		<GenericTextAlert
			text={<R id="shared_no_accounts" defaultValue="You have no accounts" />}
		/>
	);
};

export default NoAccountsAlert;
