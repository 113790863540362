import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import J2tLogoImg from './j2t_logo.png';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(theme => createStyles({
	root: {
		position: 'relative',
		width: '74px',
		height: '48px',
		marginLeft: '4px',
		marginTop: '4px',
	},
}));

const NSRLogo = () => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<img src={J2tLogoImg} alt="Lime Ltd logo" />
		</div>
	);
};

export default NSRLogo;
