import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { toUsaFormat } from '@global';

const styles = theme => ({
	root: {

	},
	quantity: {
		...theme.customstyles.subheadingSm,
		color: theme.palette.text.primary,
	},
	marketValuePercent: {
		...theme.customstyles.body,
		color: theme.palette.text.secondary,
	},
});

class Quantity extends React.Component {
	render() {
		const {
			classes,
			quantity,
			marketValuePercent,
		} = this.props;
		return (
			<div className={classes.root}>
				<div className={classes.quantity}>{toUsaFormat(quantity, 0)}</div>
				<div className={classes.marketValuePercent}>{toUsaFormat(marketValuePercent)}%</div>
			</div>
		);
	}
}

Quantity.propTypes = {
	quantity: PropTypes.number.isRequired,
	marketValuePercent: PropTypes.number.isRequired,
};

export default compose(withStyles(styles))(Quantity);
