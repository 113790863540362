import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ModalPopups } from '@enums';
import { userAccounts, withdrawFunds } from '@redux';
import { SuccessMessage, ErrorMessage, ProgressContainer } from '@components';
import { R, Modal } from '@dev2t/react-ui-kit/src/components';
import CYWithdrawalBankTransferForm from './components/CYWithdrawalBankTransferForm';
import CYWithdrawalAmountForm from './components/CYWithdrawalAmountForm';


const styles = {};

const mapStateToProps = (state) => {
	const resProps = {
		fromUserAccount: withdrawFunds.selectors.fromUserAccount(state),
		toBankAccount: withdrawFunds.selectors.toBankAccount(state),
		canShow: true,
		loading: false,
	};
	if (resProps.fromUserAccount && resProps.toBankAccount) {
		return resProps;
	}
	// default if not set
	const loading = userAccounts.selectors.loading(state) || userAccounts.selectors.accountInfo(state).loading;
	const currentTradeAccount = userAccounts.selectors.currentTradeAccount(state);
	const linkedAccounts = userAccounts.selectors.linkedAccounts(state);
	const accountStatus = userAccounts.selectors.currentAccountStatus(state);
	const toBankAccount = (loading ? null : linkedAccounts.find(x => x.Type === 'BankTransfer')) || { Type: 'BankTransfer' };
	return {
		fromUserAccount: {
			tradeCode: currentTradeAccount.tradeCode,
			clearer: currentTradeAccount.clearerFirm,
		},
		toBankAccount,
		canShow: !!(accountStatus && accountStatus.CanWithdrawal && (toBankAccount.Code || accountStatus.CanWireLink)),
		loading,
	};
};

// to link two forms into one
const CY_BANK_LINK_AND_WITHDRAWAL_FORM = 'cyBankLinkAndWithdrawalForm';

class CYWithdrawalBankTransferModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			step: 'unavailable',
		};
	}
	componentDidMount() {
		this.checkFirstPage();
	}

	componentDidUpdate() {
		this.checkFirstPage();
	}

	onSuccess = () => {
		this.setState({ step: 'succeeded' });
	}

	onFail = () => this.setState({ step: 'failed' });

	handleGotoAmount = (result, dispatch, props) => {
		this.setState({ step: 'amountform' });
	}

	checkFirstPage = () => {
		const { step } = this.state;
		const { loading, canShow } = this.props;
		if (step === 'unavailable' && !loading && canShow) {
			const isLinked = !!this.props.toBankAccount.Template;
			this.setState({ step: isLinked ? 'amountform' : 'linkform' });
		}
	}

	render() {
		const {
			onClose,
			fromUserAccount,
			toBankAccount,
			loading,
		} = this.props;

		const isLinked = !!this.props.toBankAccount.Template;
		const handleAmountSubmit = isLinked ?
			withdrawFunds.actions.withdrawFundsWireFormSubmitHandler :
			withdrawFunds.actions.linkAndWithdrawFundsWireForm;

		const { step } = this.state;
		return (
			<Modal
				title={<R id="shared_withdraw" defaultValue="Withdraw" />}
				onClose={onClose}
				className={`QA-${ModalPopups.CY_WITHDRAWAL_BANK_TRANSFER}`}
			>
				<ProgressContainer submitting={loading}>
					<div>
						{step === 'unavailable' &&
							<ErrorMessage onClick={onClose} text={`Unavailable for account ${fromUserAccount.tradeCode}.`} />
						}
						{step === 'succeeded' &&
							<SuccessMessage onClick={onClose} text={<R id="common_banking_transaction_completed" defaultValue="Banking transaction completed" />} />
						}
						{step === 'failed' &&
							<ErrorMessage onClick={onClose} text={<R id="common_could_not_complete_transaction" defaultValue="Could not complete transaction" />} />
						}
						{step === 'linkform' &&
							<CYWithdrawalBankTransferForm
								destroyOnUnmount={false}
								forceUnregisterOnUnmount
								form={CY_BANK_LINK_AND_WITHDRAWAL_FORM}
								enableReinitialize
								onSubmit={this.handleGotoAmount}
								onSubmitFail={this.onFail}
							/>
						}
						{step === 'amountform' &&
							<CYWithdrawalAmountForm
								enableReinitialize
								forceUnregisterOnUnmount
								/* keepDirtyOnReinitialize */
								form={CY_BANK_LINK_AND_WITHDRAWAL_FORM}
								fromUserAccount={fromUserAccount}
								toBankAccount={toBankAccount}
								onSubmit={handleAmountSubmit}
								onSuccess={this.onSuccess}
								onSubmitFail={this.onFail}
							/>
						}
					</div>
				</ProgressContainer>
			</Modal>
		);
	}
}

CYWithdrawalBankTransferModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	fromUserAccount: PropTypes.object.isRequired,
	toBankAccount: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	canShow: PropTypes.bool.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
)(CYWithdrawalBankTransferModal);
