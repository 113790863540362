import React from "react";

const Tile = (
        <React.Fragment>
            <path d="M0 2C0 0.895431 0.895431 0 2 0H9C10.1046 0 11 0.895431 11 2V9C11 10.1046 10.1046 11 9 11H2C0.895431 11 0 10.1046 0 9V2Z"/>
            <path d="M13 2C13 0.895431 13.8954 0 15 0H22C23.1046 0 24 0.895431 24 2V9C24 10.1046 23.1046 11 22 11H15C13.8954 11 13 10.1046 13 9V2Z"/>
            <path d="M0 15C0 13.8954 0.895431 13 2 13H9C10.1046 13 11 13.8954 11 15V22C11 23.1046 10.1046 24 9 24H2C0.895431 24 0 23.1046 0 22V15Z"/>
            <path d="M13 15C13 13.8954 13.8954 13 15 13H22C23.1046 13 24 13.8954 24 15V22C24 23.1046 23.1046 24 22 24H15C13.8954 24 13 23.1046 13 22V15Z"/>
        </React.Fragment>);

export default Tile;