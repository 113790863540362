/* eslint-disable react/no-did-update-set-state,no-param-reassign,consistent-return */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { Input } from '@dev2t/react-ui-kit/src';
import NumberFormat from 'react-number-format';


import PropTypes from 'prop-types';

const styles = theme => ({
	root: {
		color: theme.palette.primary.dark,
	},
	container: {
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'space-between',
	},
	input: {
		// flexGrow: 1,
	},
});

function NumberFormatCustom(props) {
	const { inputRef, onChange, ...restProps } = props;
	return (
		<NumberFormat
			{...restProps}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange(values.value || '');
			}}
		/>
	);
}

class InputNumberFormat extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			value: props.value,
		};

		this.onInputChange = this.onInputChange.bind(this);
		this.onBlurHandler = this.onBlurHandler.bind(this);
	}
	componentDidUpdate(prevProps) {
		if (this.props.value !== prevProps.value) {
			this.setState({
				value: this.props.value,
			});
		}
	}
	onInputChange(value) {
		this.setState({
			value,
		});
		this.props.onChange(value);
	}
	onBlurHandler() {
		if (this.props.onBlur) {
			this.props.onBlur(this.state.value);
		}
	}
	render() {
		const {
			classes,
			onChange,
			value,
			type,
			onBlur,
			format,
			mask,
			removeFormatting,
			...props
		} = this.props;
		return (
			<div className={classes.container}>
				<div className={classes.input}>
					<Input
						classes={{ root: classes.root }}
						onChange={this.onInputChange}
						value={this.state.value}
						onBlur={this.onBlurHandler}
						inputComponent={NumberFormatCustom}
						inputProps={{
							format, mask, removeFormatting,
						}}
						{...props}
					/>
				</div>
			</div>
		);
	}
}

InputNumberFormat.propTypes = {
	format: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
	mask: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	onChange: PropTypes.func.isRequired,
	onBlur: PropTypes.func,
	removeFormatting: PropTypes.func,
};
InputNumberFormat.defaultProps = {
	onBlur: null,
};

InputNumberFormat.defaultProps = {
	format: '####',
	mask: ['R', 'T', 'F', 'M'],
	removeFormatting: null,
};

export default compose(withStyles(styles))(InputNumberFormat);
