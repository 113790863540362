import React from 'react';
import { useSelector } from 'react-redux';
import { userAccounts } from '@redux';
import { makeStyles } from '@material-ui/core/styles';
import { ProgressContainer } from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';

const useStyles = makeStyles(theme => ({
	root: {
		position: 'relative',
	},
	demoLabel: {
		backgroundColor: theme.palette.warning.main,
		color: theme.palette.warning.contrastText,
		borderRadius: '4px',
		padding:'0 0.3em',
		fontSize:'0.7em',
		position: 'absolute',
		right: '0',
		top:'-1.2em',
	}
}));

const HeaderUserInfo = () => {
	const classes = useStyles();
	const { submitting, FirstName, LastName, Guid, DemoUserId } = useSelector(userAccounts.selectors.userInfo);
	const loading = useSelector(userAccounts.selectors.loading);
	const userInfoLoading = submitting || loading;
	const name = [FirstName, LastName].filter(x => !!x).join(' ') || (userInfoLoading ? '' : 'Not specified name');
	const isDemo = !Guid && !!DemoUserId;
	return (
		<div>
			<ProgressContainer submitting={submitting} disableCheckChildren >
				<span className={classes.root}>
					{name}
					{isDemo && <div className={classes.demoLabel}>
						<R id="shared_demo" defaultValue="Demo" />
					</div>}
				</span>
			</ProgressContainer>
		</div>
	);
};

export default HeaderUserInfo;
