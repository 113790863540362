import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { dashboard } from '@redux';
import Drawer from '@material-ui/core/Drawer';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { SidebarContent } from './components';

const drawerWidth = 272;
const maxWidth = 1440;
const styles = theme => ({
	drawerPaper: {
		width: `${drawerWidth}px`,
		height: '100%',
		[theme.breakpoints.up('sm')]: {
			position: 'fixed',
		},
		'@media (min-width:1440px)': {
			left: `calc((100% - ${maxWidth}px) / 2)`,
		},
	},
	drawerContainer: {
		width: `${drawerWidth}px`,
		height: '100%',
		position: 'relative',
	},
	drawer: {
		width: `${drawerWidth}px`,
		height: '100%',
		position: 'absolute',
		transform: 'translate3d(0)',
	},
});

const mapStateToProps = state => ({
	isSidebarOpen: dashboard.selectors.isSidebarOpen(state),
});


const mapDispatchToProps = dispatch => ({
	actions: {
		init: () => dispatch(dashboard.actions.init()),
		sidebarOpen: () => dispatch(dashboard.actions.sidebarOpen()),
		sidebarClose: () => dispatch(dashboard.actions.sidebarClose()),
	},
});

class Sidebar extends React.Component {
	render() {
		const {
			classes,
			isSidebarOpen,
			actions,
		} = this.props;
		return (
			<div>
				<Hidden smUp implementation="css">
					<Drawer
						variant="temporary"
						open={isSidebarOpen}
						onClose={actions.sidebarClose}
						classes={{
							paper: classes.drawerPaper,
						}}
					>
						<SidebarContent />
					</Drawer>
				</Hidden>
				<Hidden xsDown implementation="css">
					<div className={classes.drawerContainer}>
						<div className={classes.drawer}>
							<Drawer
								variant="permanent"
								open={isSidebarOpen}
								classes={{
									paper: classes.drawerPaper,
								}}
							>
								<SidebarContent />
							</Drawer>
						</div>
					</div>
				</Hidden>
			</div>
		);
	}
}

Sidebar.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default compose(
	withRouter,
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(Sidebar);
