import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { app, userAccounts } from '@redux';
import compose from 'recompose/compose';
import { ProgressContainer } from '@components';


const styles = theme => ({
	header: {
		...theme.customstyles.headline1,
		...theme.palette.text.primary,
		padding: '8px 0',
	},
	root: {
		...theme.customstyles.body,
		padding: '16px 72px',
		[theme.breakpoints.down('md')]: {
			padding: '8px 24px',
		},
	},
});


const mapStateToProps = (state) => {
	const accountInfo = userAccounts.selectors.accountInfo(state);
	return {
		platforms: userAccounts.selectors.platforms(state),
		loading: userAccounts.selectors.loading(state) || accountInfo.loading,
		resolution: app.selectors.resolution(state),
		tradeLogin: (accountInfo && !accountInfo.loading && accountInfo.TradeLogin) || null,
	};
};


const mapDispatchToProps = dispatch => ({
	actions: {
		setCurrentPage: page => dispatch(push(page)),
		refreshReportsAndConfirmations: (account) => {
			dispatch(userAccounts.actions.refreshReportsAndConfirmationsRequest(account));
		},
	},
});
const useScript = (url, onload) => {
	useEffect(() => {
		const script = document.createElement('script');
		script.src = url;
		script.async = true;
		script.onload = onload;
		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, [url]);
};
const Terminal = ({ tradeLogin, mobile }) => {
	const divContainer = useRef(null);
	// eslint-disable-next-line no-undef
	const initTerminal = containerId => new MetaTraderWebTerminal(containerId, {
		version: 5,
		servers: ['ScorePriority-MT5'],
		server: 'ScorePriority-MT5',
		demoAllServers: false,
		utmSource: 'www.scorepriority.com',
		startMode: 'login',
		language: 'en',
		colorScheme: 'black_on_white',
		mobile: mobile || undefined,
		login: tradeLogin || undefined,
	});

	useScript('https://metatraderweb.app/trade/widget.js', () => {
		const { id } = divContainer.current;
		initTerminal(id);
	});
	return (
		<React.Fragment>
			<div ref={divContainer} id="webterminal" style={{ width: '100%', height: '600px' }} />
		</React.Fragment>
	);
};
Terminal.propTypes = {
	mobile: PropTypes.bool.isRequired,
	tradeLogin: PropTypes.string,
};
Terminal.defaultProps = {
	tradeLogin: undefined,
};

class TerminalMt5 extends React.Component {

	render() {
		const {
			classes,
			loading,
			platforms: { ActiveTradePlatform },
			resolution,
			tradeLogin,
		} = this.props;

		return (
			<div className={classes.root}>
				<div className={classes.header}>
					MT5 Web
				</div>
				<ProgressContainer submitting={loading} display="left">
					<React.Fragment>
						{ActiveTradePlatform === 'Mt5Spc' &&
							<Terminal mobile={resolution === app.CONFIG.RESOLUTIONS_ENUM.xs} tradeLogin={tradeLogin} />
						}
					</React.Fragment>
				</ProgressContainer>
			</div>
		);
	}
}

TerminalMt5.propTypes = {
	actions: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	platforms: PropTypes.shape({
		ActiveTradePlatform: PropTypes.string,
	}).isRequired,
	resolution: PropTypes.number.isRequired,
	tradeLogin: PropTypes.string,
};

TerminalMt5.defaultProps = {
	tradeLogin: null,
};

export default compose(
	withTheme,
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(TerminalMt5);
