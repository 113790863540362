import React from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	reduxForm,
	Field,
} from 'redux-form';
import { withStyles, withTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';

import {
	FileInput,
	FormSelect,
	Divider,
	Button,
	withTranslate,
} from '@components';
import { R, FormInput } from '@dev2t/react-ui-kit/src/components';
import { validateSecureString, checkLettersForLang } from '@global';
import { nontradingOrders, userAccounts, app } from '@redux';
import { RequireConfirmationCodeItem } from '@shared';
import { template } from '@templates';

const styles = theme => ({
	root: {
		position: 'relative',
	},
	row: {
		display: 'flex',
	},
	inputItem: {
		marginTop: '30px',
	},
	divider: {
		marginTop: '30px',
	},
	fileUploader: {
		marginTop: '16px',
	},
	logo: {
		padding: '16px 16px 4px 16px',
		position: 'relative',
		width: '80px',
		height: '44px',
	},
	inputContainer: {
		display: 'flex',
		width: '100%',
		...theme.customstyles.title,
	},
	account: {
		width: '100%',
		textAlign: 'left',
	},
	button: {
		...theme.mixins.stickyButton,
	},
});


const CY_NON_TRADING_ORDER_COMMON_FORM_ID = 'CYNonTradingOrderCommonForm';
const mapStateToProps = (state, ownProps) => {
	const currentTradeAccount = userAccounts.selectors.currentTradeAccount(state);
	const currentAccountDisplayFullName = userAccounts.selectors.currentAccountDisplayFullName(state);
	const language = app.selectors.language(state);

	return {
		initialValues: {
			TradeCode: currentTradeAccount.tradeCode,
			ClearingFirm: currentTradeAccount.clearerFirm,
		},
		currentTradeAccount,
		currentAccountDisplayFullName,
		language,
	};
};

class CYNonTradingOrderCommonForm extends React.Component {
	static validate(formvalues, props) {
		const values = formvalues;
		const errors = {};
		const language = props.language;

		if (!values) {
			return errors;
		}

		if (!values.Comment) {
			errors.Comment = 'required';
		} else {
			errors.Comment = props.translate(checkLettersForLang(values.Comment, language));
		}

		if (!values.ConfirmationCode) {
			errors.ConfirmationCode = 'required';
		}
		return errors;
	}

	onFilesUpload = (field, files) => this.props.change(field, files);

	render() {
		const {
			classes,
			fromUserAccount,
			handleSubmit,
			submitSucceeded,
			amount,
			theme,
			currentTradeAccount,
			currentAccountDisplayFullName,
			translate,
			...props
		} = this.props;


		return (
			<form
				onSubmit={handleSubmit(nontradingOrders.actions.sendNonTradingOrderCommonForm)}
				className={classes.root}
			>
				<div className={classes.accountContainer}>
					<div className={classes.row}>
						<div className={classes.logo}>
							<template.firmLogo />
						</div>
						<Field
							component={FormSelect}
							name="TradeCode"
							label={translate('common_account', 'Account')}
							disabled
						>
							<MenuItem value={currentTradeAccount.tradeCode}>
								<div className={classes.inputContainer}>
									<div className={classes.account}>{currentAccountDisplayFullName}</div>
								</div>
							</MenuItem>
						</Field>
					</div>
				</div>
				<div className={classes.divider}>
					<Divider />
				</div>
				<div className={classes.inputItem}>
					<Field
						component={FormInput}
						name="Comment"
						label={translate('shared_comment', 'Comment')}
						multiline
						validate={[validateSecureString]}
						validationMessageMode="insteadOfAdditional"
					/>
				</div>
				<p>
					<R	id="common_please_upload_your_files" defaultValue="Please upload your files if needed" />
				</p>
				<div className={classes.fileUploader}>
					<FileInput
						onFilesUpdate={files => this.onFilesUpload('Attachments', files)}
					/>
				</div>
				<RequireConfirmationCodeItem
					customClasses={classes}
					name="ConfirmationCode"
					buttonTitle={<R id="common_send_button_order" defaultValue="Send" />}
					reason="NontradingOrderApply"
					disabled={props.invalid || props.submitting}
				>

					<Button
						fullWidth
						type="submit"
						variant="contained"
						color="primary"
						disabled={props.invalid || props.submitting}
					>
						{props.submitting ?
							<CircularProgress
								style={{ color: theme.palette.common.white }}
								size={18}
							/>
							: <R id="common_confirm_order" defaultValue="Confirm" />
						}
					</Button>

				</RequireConfirmationCodeItem>

			</form>
		);
	}
}

CYNonTradingOrderCommonForm.propTypes = {
	classes: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
	currentAccountDisplayFullName: PropTypes.string.isRequired,
	onSuccess: PropTypes.func.isRequired,
	onFail: PropTypes.func.isRequired,
};

export default compose(
	withStyles(styles),
	withTheme,
	withTranslate,
	connect(mapStateToProps, null),
	reduxForm({
		form: CY_NON_TRADING_ORDER_COMMON_FORM_ID,
		validate: CYNonTradingOrderCommonForm.validate,
		onSubmitSuccess: (result, dispatch, props) => {
			if (props.onSuccess && (typeof props.onSuccess === 'function')) {
				props.onSuccess();
			}
		},
		onSubmitFail: (errors, dispatch, submitError, props) => {
			// eslint-disable-next-line no-underscore-dangle
			if (errors._error && props.onFail) {
				props.onFail();
			}
		},
	}),
)(CYNonTradingOrderCommonForm);
