import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Button } from '@components';
import { ModalPopups } from '@enums';
import { changeHashInUrl } from '@global';


function LinkBankAccountButon(props) {
	const redirectModal = (from, to) => {
		props.history.push(changeHashInUrl(props.location, from, to));
	};
	const handleLinkAccount = () => redirectModal(ModalPopups.PLAID_SELECT_ACCOUNT, ModalPopups.ADD_BANK_ACCOUNT);

	return (
		<Button
			className={props.className}
			onClick={handleLinkAccount}
			variant="text"
			color="primary"
		>
			Link bank account
		</Button>
	);
}

LinkBankAccountButon.propTypes = {
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	className: PropTypes.string,
};
LinkBankAccountButon.defaultProps = {
	className: undefined,
};

export default withRouter(LinkBankAccountButon);
