import { Box, withStyles } from '@material-ui/core';

const ContentContainer = withStyles(theme => ({
	root: {
		fontSize: '14px',
		color: 'inherit',
		fontWeight: 'normal',
		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
		lineHeight: '24px',
		letterSpacing: 'normal',
		padding: '16px 0',
		[theme.breakpoints.down('md')]: {
			padding: '8px 0',
		},
	},
}))(Box);

export default ContentContainer;
