import React from 'react';
import NumberFormat from 'react-number-format';
import { FormInput } from '@dev2t/react-ui-kit/src';
import { Field } from 'redux-form';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { translate } from '@global';

const styles = theme => ({
	error: {
		color: theme.palette.error.main,
		margin: '5px 0px',
	}
});

const TotpCodeInput = ({
	inputRef,
	input: {
		onChange,
		onFocus,
		onBlur,
		value,
	},
	meta: {
		touched,
		error,
		warning,
	},
	classes,
	...restProps
}) => {
	return (
		<>
			<NumberFormat
				{...restProps}
				value={value}
				getInputRef={inputRef}
				onValueChange={(values) => {
					onChange(values.value || '');
				}}
				onFocus={onFocus}
				onBlur={onBlur}
				format="### ###"
				customInput={FormInput}
			/>

			{touched && (error || warning) && <div className={classes.error}>{error || warning}</div>}
		</>
	);
}

const StyledTotpCodeInput = compose(
	withStyles(styles),
)(TotpCodeInput);

const validate = (value) => {
	if (!value) {
		return translate('shared_totp_input_validation_is_required', 'Enter security code');
	}

	if (value.length < 6) {
		return translate('shared_totp_input_validation_length', 'Code must contain 6 digits');
	}
	return null;
}

const TotpCodeField = ({
	name,
	...props
}) => {

	return (
		<Field
			{...props}
			component={StyledTotpCodeInput}
			name={name}
			label={translate('shared_totp_input_label', 'Security code')}
			validate={[validate]}
			parse={value => value.replaceAll(' ', '')}/>
	)
}

export default TotpCodeField;
