import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { ModalPopups } from '@enums';			
import { userAccounts, depositFunds } from '@redux';
import { ErrorMessage, ProgressContainer } from '@components';
import { Modal } from '@dev2t/react-ui-kit/src/components';
import { connect } from 'react-redux';
import {
	AchAutoTabComponent,
	AchManuallyTabComponent,
	WireManuallyTabComponent,
} from './components';

const styles = theme => ({
	root: {
	},
	tabHeader: {
		fontSize: '16px',
		fontWeight: 'normal',
		minWidth: '100px',
		maxWidth: '100%',
		flexGrow: 1,
	},
	wrapper: {
		fontSize: '0.875rem',
	},
});


const mapStateToProps = state => ({
	currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
	currentAccountStatus: userAccounts.selectors.currentAccountStatus(state),
	linkedBanksAccounts: userAccounts.selectors.linkedAccounts(state),
	loading: (userAccounts.selectors.accountInfo(state).loading || userAccounts.selectors.loading(state)),
});

const mapDispatchToProps = dispatch => ({
	actions: {
		initDepositWireInfo: () =>
			dispatch(depositFunds.actions.initDepositWireInfo()),
	},
});


class AddBankAccountModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentTab: null,
		};
		this.setCurrentTab = this.setCurrentTab.bind(this);
	}

	componentDidMount() {
		this.props.actions.initDepositWireInfo();
	}

	setCurrentTab(event, currentTab) {
		this.setState({ currentTab });
	}

	render() {
		const {
			classes,
			onClose,
			currentAccountStatus,
			currentTradeAccount,
			loading,
		} = this.props;

		const { currentTab } = this.state;
		// const haveWireBankAccount = linkedBanksAccounts && linkedBanksAccounts.some(it => it.Type === 'WIRE');
		const tabs = [];
		if (currentAccountStatus.CanAchLinkAuto) tabs.push({ value: 'ACH_AUTO', title: 'ACH', component: AchAutoTabComponent });
		if (currentAccountStatus.CanAchLink) tabs.push({ value: 'ACH_MANUALLY', title: 'ACH MANUALLY', component: AchManuallyTabComponent });
		/* if (!haveWireBankAccount)
			tabs.push({ value: 'WIRE', title: 'WIRE', component: WireManuallyTabComponent }); */

		const curTab = currentTab ? tabs.find(it => it.value === currentTab) : tabs[0];
		const canShow = currentAccountStatus.CanAchLink || currentAccountStatus.CanAchLinkAuto;

		return (
			<Modal
				title="Adding Bank Account"
				onClose={onClose}
				className={`QA-${ModalPopups.ADD_BANK_ACCOUNT}`}
			>
				<ProgressContainer submitting={loading}>
					<div>
						{canShow ?
							<Tabs
								value={curTab.value}
								onChange={this.setCurrentTab}
								indicatorColor="primary"
								textColor="primary"
							>
								{tabs.map(item => (
									<Tab
										classes={{ root: classes.tabHeader, wrapper: classes.wrapper }}
										label={item.title}
										key={`TAB_HEADER_${item.value}`}
										value={item.value}
									/>
								))}
							</Tabs> :
							<ErrorMessage
								onClick={onClose}
								text={`Unavailable for account ${currentTradeAccount.tradeCode}.`}
							/>
						}
					</div>
					{canShow && <curTab.component onClose={onClose} />}
				</ProgressContainer>
			</Modal>
		);
	}
}

AddBankAccountModal.propTypes = {
	classes: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	currentAccountStatus: PropTypes.object.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
	linkedBanksAccounts: PropTypes.array.isRequired,
	loading: PropTypes.bool.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(AddBankAccountModal);
