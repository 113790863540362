import { Button, R } from '@dev2t/react-ui-kit/src';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { twoFactor } from '@redux';
import { TotpCodeField } from '@shared';
import React from 'react';
import compose from 'recompose/compose';
import { reduxForm } from 'redux-form';

const useStyles = makeStyles({
	input: {
		marginBottom: '20px'
    },
});

const DisableTotpForm = ({
    submitting,
    handleSubmit,
}) => {
    const classes = useStyles();

    return (
        <form onSubmit={handleSubmit(twoFactor.actions.disableTwoFactor)}>
            <div>
                <R
                    id="account_totp_disable_instruction"
                    defaultValue="Enter the 6-digit code from the code-generating app to disable the two-factor authentication" />
            </div>

            <div className={classes.input} >
                <TotpCodeField name="Code" />
            </div>

            <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                disabled={submitting}
            >
                {submitting
                    ? <CircularProgress size={18} />
                    : <R id="shared_totp_disable_button" defaultValue="Disable" />}
            </Button>
        </form>
    )
}

export default compose(
	reduxForm({
		form: 'disableTwoFactor',
    })
)(DisableTotpForm);