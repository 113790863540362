import React from 'react';

const Svg = (
	<g fill="none" fillRule="evenodd">
		<circle cx="12" cy="12" r="11" stroke="#78AAFF" strokeWidth="2" opacity=".3" />
		<path fill="#78AAFF" d="M7 11h10v2H7z" />
	</g>
);

export default Svg;
