
import { initialFundingInfo } from './reducer';

export default {
	depositAchModal: state => state.depositFunds.depositAchModal,
	depositWireInfo: state => state.depositFunds.depositWireInfo,
	depositWireModal: state => state.depositFunds.depositWireModal,
	getAccountFundingInfo: state => (tradeCode, currency) =>
		state.depositFunds.accountFundingInfo
			.find(x => x.tradeCode === tradeCode && x.currency === (currency || '')) || initialFundingInfo,
};
