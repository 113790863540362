export default {
	profile: state => state.profile.profile,
	confirmEmail: state => state.profile.confirmEmail,
	getCountries: state => state.profile.CountryList,
	getUsaStates: state => state.profile.UsaStatesList,
	modals: state => state.profile.modals,
	clientSecretCode: state => state.profile.ClientSecretCode,
	qrCode: state => state.profile.QRCode,
	totpAuthentication: state => state.profile.totpAuthentication,
};
