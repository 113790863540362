import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import compose from 'recompose/compose';
import { R } from '@dev2t/react-ui-kit/src/components';
import { app } from '@redux';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import ContactsCard from './ContactsCard';

const styles = theme => ({
	content: {
		...theme.customstyles.body,
		padding: '16px 56px',
		marginBottom: '30px',
		[theme.breakpoints.down('md')]: {
			padding: '8px 24px',
		},
	},
	contentItem: {
		marginBottom: '30px',
	},
	endItem: {
		height: '30px',
	},
	header: {
		...theme.customstyles.headline1,
		...theme.palette.text.primary,
		marginLeft: '16px',
		padding: '8px 0',
	},
});


function Contacts(props) {
	const {
		classes,
	} = props;

	const lang = useSelector(app.selectors.language);

	function selectContactsData(language) {
		const titles = {
			china: <R id="contacts_china_header" />,
			asia: <R id="contacts_southeast_asia_header" />,
			common: <R id="contacts_support_header" />,
			india: <R id="contacts_india_header" />,
			russia: <R id="contacts_russia_header" />,
		};

		const phones = {
			asia: '+603 27 123231',
			china: '400 6799 882',
			common: '+357 25 055 966',
			india: '+91 8041300555',
			outside_asia: '03-2712 3231',
			russia: '+7 495 009-01-95 (многоканальный)',
		};

		const tds = {
			asia: '	+357 95 13 54 75',
			common: '+357 96 370 242',
			russia: '+7 499 009-01-95',
		};

		const mails = {
			common: '24_support@j2t.global',
			asia: 'support@just2trade.asia',
			china: 'support_cn@just2trade.asia',
			china2: 'support@just2trade.cn',
		};

		const data = {
			ms: [
				{
					title: titles.asia,
					email: mails.asia,
					phone: phones.asia,
					td: tds.asia,
				},
				{
					title: titles.china,
					email: mails.china2,
					phone: phones.china,
					td: tds.asia,
				},
				{
					title: titles.common,
					email: mails.common,
					phone: phones.common,
					td: tds.common,
				},
				{
					title: titles.india,
					email: mails.common,
					phone: phones.india,
					td: tds.asia,
				},
			],
			zh: [
				{
					title: titles.china,
					email: mails.china2,
					phone: phones.china,
					td: tds.asia,
				},
				{
					title: titles.asia,
					email: mails.asia,
					phone: phones.asia,
					td: tds.asia,
				},
				{
					title: titles.common,
					email: mails.common,
					phone: phones.common,
					td: tds.common,
				},
				{
					title: titles.india,
					email: mails.common,
					phone: phones.india,
					td: tds.asia,
				},
			],
			en: [
				{
					title: titles.common,
					email: mails.common,
					phone: phones.common,
					td: tds.common,
				},
				{
					title: titles.asia,
					email: mails.asia,
					phone: phones.outside_asia,
					td: tds.common,
				},
				{
					title: titles.china,
					email: mails.china,
					phone: phones.china,
					td: tds.common,
				},
				{
					title: titles.india,
					email: mails.common,
					phone: phones.india,
					td: tds.common,
				},
			],
			ru: [
				{
					title: titles.common,
					email: mails.common,
					phone: phones.common,
					td: tds.common,
				},
				{
					title: titles.russia,
					email: mails.common,
					phone: phones.russia,
					td: tds.russia,
				},
				{
					title: titles.china,
					email: mails.china,
					phone: phones.china,
					td: tds.common,
				},
				{
					title: titles.india,
					email: mails.common,
					phone: phones.india,
					td: tds.common,
				},
			],
		};

		switch (language) {
			case 'ms': return data.ms;
			case 'zh': return data.zh;
			case 'ru': return data.ru;
			default: return data.en;
		}
	}

	return (
		<div className={classes.content}>
			<div>
				<div className={classes.header}>
					<R id="common_menu_contacts" defaultValue="Contacts" />
				</div>
			</div>
			<div className={classes.contentItem}>
				<Box display="flex" flexWrap="wrap" alignItems="center" justifyContent="space-evenly">
					{selectContactsData(lang).map((x, ind) =>
						<ContactsCard key={ind} title={x.title} email={x.email} phone={x.phone} td={x.td} />)}
				</Box>
			</div>
			<div className={classes.endItem} />
		</div>
	);
}

export default compose(
	withRouter,
	withTheme,
	withStyles(styles),
)(Contacts);
