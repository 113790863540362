import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { formActionSaga } from 'redux-form-saga';
import createHistory from 'history/createBrowserHistory';
import { routerMiddleware } from 'connected-react-router';

import createRootReducer from './reducers';
import rootSaga from './sagas';

const initState = {};

export default function configureStore(initialState = initState) {
	const history = createHistory();

	// Create the saga middleware
	const sagaMiddleware = createSagaMiddleware();

	// For Redux devtools browser extension
	const composeEnhancers =
	// eslint-disable-next-line no-underscore-dangle
		typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
			// eslint-disable-next-line no-underscore-dangle
			window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

	const enhancers = composeEnhancers(
		applyMiddleware(sagaMiddleware),
		applyMiddleware(routerMiddleware(history)),
	);
		// applyMiddleware(...middlewares));
	const store = createStore(
		createRootReducer(history), // root reducer with router state
		initialState,
		enhancers,
	);
	window.store = store;

	sagaMiddleware.run(rootSaga);
	sagaMiddleware.run(formActionSaga);

	if (module.hot) {
		// Enable Webpack hot module replacement for reducers
		module.hot.accept('./reducers', () => {
			const nextRootReducer = createRootReducer(history);
			store.replaceReducer(nextRootReducer);
		});
	}

	return {
		history,
		store,
	};
}
