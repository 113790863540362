import React from 'react';

/*
const Svg = (
	<path d="M524.278175,237.278175 L516.5,229.5 C515.718951,228.718951 515.718951,227.452621 516.5,226.671573 L524.278175,218.893398 L525.692388,220.307612 L518.914214,227.085786 L548.085786,227.085786 L548.085786,229.085786 L518.914214,229.085786 L525.692388,235.863961 L524.278175,237.278175 Z" />
);
*/

const Svg = (
	<g id="__Tryouts" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
		<g id="Artboard" transform="translate(-520.000000, -210.000000)" fill="#000000">
			<path stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" d="M524.278175,237.278175 L516.5,229.5 C515.718951,228.718951 515.718951,227.452621 516.5,226.671573 L524.278175,218.893398 L525.692388,220.307612 L518.914214,227.085786 L548.085786,227.085786 L548.085786,229.085786 L518.914214,229.085786 L525.692388,235.863961 L524.278175,237.278175 Z" id="Combined-Shape" transform="translate(532.000000, 228.085786) rotate(-90.000000) translate(-532.000000, -228.085786) " />
		</g>
	</g>
);

export default Svg;

