import { fork, takeEvery, put, select } from 'redux-saga/effects';
import {resetByEmail, resetByOperator, resetPassword, sendConfirmationByEmail} from '@api';
import {BaseGetSagaHandler} from '@redux';
import actions from './actions';


function* watchResetByEmail() {
	yield takeEvery(actions.resetByEmail.REQUEST, BaseGetSagaHandler, {
		apiMethod: resetByEmail,
		errorText: 'Reset By Email',
		handler: actions.resetByEmail,
	});
}

function* watchSendConfirmationForResetByEmail() {
	yield takeEvery(actions.sendConfirmationForResetByEmail.REQUEST, BaseGetSagaHandler, {
		apiMethod: sendConfirmationByEmail,
		errorText: 'Send Reset Confirmation Code',
		handler: actions.sendConfirmationForResetByEmail,
	});
}

function* watchResetPassword() {
	yield takeEvery(actions.resetPassword.REQUEST, BaseGetSagaHandler, {
		apiMethod: resetPassword,
		errorText: 'Reset Password',
		handler: actions.resetPassword,
	});
}

function* watchResetByOperator() {
	yield takeEvery(actions.resetByOperator.REQUEST, BaseGetSagaHandler, {
		apiMethod: resetByOperator,
		errorText: 'Reset By Operator',
		handler: actions.resetByOperator,
	});
}

// Root saga
const rootSaga = [
	fork(watchResetByEmail),
	fork(watchSendConfirmationForResetByEmail),
	fork(watchResetPassword),
	fork(watchResetByOperator),
];

export default {
	rootSaga,
};

