import { useDispatch, useSelector } from 'react-redux';
import { snackbar, userAccounts } from '@redux';
import { cabinetLog, captureException } from '@global';
import { useState, useEffect, useRef, useMemo } from 'react';
import { useAccountFundingInfo } from './useAccountFundingInfo';

let PaymentGateScripts = [];
const usePaymentGateScript = (fundInfo) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const [success, setSuccess] = useState(false);

	const loadedScript = useRef(null);

	const handleScriptLoaded = () => {
		cabinetLog('payment gate widget script has been loaded');
		setLoading(false);
		setSuccess(true);
	};

	useEffect(() => {
		if (fundInfo.loading || (!fundInfo.value && !fundInfo.error)) {
			if (!loading) {
				setLoading(true);
			}
		} else if (fundInfo.error) {
			setSuccess(false);
			setLoading(false);
		} else if (fundInfo.value && fundInfo.value.WidgetJsScriptUrl) {
			const scriptUrl = fundInfo.value.WidgetJsScriptUrl;
			let scriptInfo = PaymentGateScripts.find(x => x.url === scriptUrl);
			const isNewScript = !scriptInfo;
			let script = scriptInfo && scriptInfo.scriptTag;
			if (isNewScript) {
				script = document.createElement('script');
				script.src = scriptUrl;
				script.async = true;
				scriptInfo = { url: scriptUrl, scriptTag: script, loaded: false };
				PaymentGateScripts.push(scriptInfo);
			}
			if (isNewScript || !scriptInfo.loaded) {
				script.addEventListener('load', () => {
					scriptInfo.loaded = true;
					handleScriptLoaded();
				});
			} else {
				handleScriptLoaded();
			}
			script.addEventListener('error', () => {
				console.error('error occured when try load payment gate widget = ', fundInfo.value.WidgetJsScriptUrl);
				captureException(
					'Payment gate load error',
					`error occured when try load payment gate widget = ${fundInfo.value.WidgetJsScriptUrl}`,
				);
				dispatch(snackbar.actions.showErrorMessage({ text: 'Payment gate load error' }));
				setSuccess(false);
				setLoading(false);
			});
			if (isNewScript) {
				document.body.appendChild(script);
			}
			loadedScript.current = script;
			cabinetLog('payment gate widget script load');
		}

		return () => {
			if (loadedScript.current && loadedScript.current.parentElement === document.body) {
				cabinetLog('payment gate widget script unload');
				if (PaymentGateScripts.some(x => x.scriptTag === loadedScript.current)) {
					PaymentGateScripts = PaymentGateScripts.filter(x => x.scriptTag !== loadedScript.current);
				}
				document.body.removeChild(loadedScript.current);
			}
		};
	}, [fundInfo]);
	return [loading, success];
};

/**
 * @param {Object} props All properties of hook
 * @param {Object} props.id Идентификатор кнопки, если необходимо установить значение вручную
 * @param {Object} props.currency Валюта, в которой необходимо пополнение. Если не указана - определяется автоматически самим виджетом 
 * @returns {Object}
 */
export const usePaymentGateWidgetHook = (props) => {
	const { id, currency, tradeCode } = props || {};
	const accountInfo = useSelector(userAccounts.selectors.accountInfo);
	const accountInfoLoading = useSelector(userAccounts.selectors.accountInfoLoading);

	const accountTradeCode = tradeCode || (accountInfo && accountInfo.TradeCode) || null;
	const fundInfo = useAccountFundingInfo({ currency, tradeCode: accountTradeCode });

	const [scriptLoading, scriptLoadSuccess] = usePaymentGateScript(fundInfo);
	const [loading, setLoading] = useState(true);
	const [success, setSuccess] = useState(false);

	const generateId = () => Math.random().toString(36).substr(2, 5);

	const randomId = useMemo(generateId, []);
	const paymentGateWidgetId = useRef(id || randomId);

	useEffect(() => {
		if (accountInfoLoading || scriptLoading || fundInfo.loading) {
			if (!loading) {
				setLoading(true);
			}
		} else if (!accountInfo.Success || !scriptLoadSuccess || fundInfo.error) {
			setSuccess(false);
			setLoading(false);
		} else if (fundInfo.value && fundInfo.value.WidgetJsConfig) {
			let configuration = JSON.parse(fundInfo.value.WidgetJsConfig);
			if (currency) {
				configuration = {
					...configuration,
					account_currency: currency,
					currency,
				};
			}
			// eslint-disable-next-line no-undef
			PaymentGateWidget(paymentGateWidgetId.current, configuration);
			setLoading(false);
			setSuccess(true);
		}
	}, [loading, accountInfo, fundInfo, currency, scriptLoading, scriptLoadSuccess]);
	return [paymentGateWidgetId.current, loading, success];
};
