
import { useAccount } from '@hooks';
import { AccountType } from '@enums';

export default function getDestinationAccounts(sourceAccount) {
	
	const mt5Accounts = useAccount(AccountType.MT5);
	const walletAccounts = useAccount(AccountType.WALLET);
	const stakingAccounts = useAccount(AccountType.STAKING);

	if (!sourceAccount) return undefined;

	if (sourceAccount.IsMT5) return walletAccounts;
	else if (sourceAccount.IsWallet) return [...stakingAccounts, ...mt5Accounts];
	else if (sourceAccount.IsStaking) return walletAccounts;
	else throw new Error('unknown account type');
}
