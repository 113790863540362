import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Box } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
import { app } from '@redux';
import { R } from '@dev2t/react-ui-kit/src/components';
import CustomMomentUtils from '../DatePicker/CustomMomentUtils';

const styles = theme => ({
	calendarsSplit: {
		display: 'inline-flex',
		color: theme.palette.primary.main,
	},
});

const CustomDatePicker = withStyles(theme => ({
	root: {
		'& input': {
			cursor: 'pointer',
			color: theme.palette.primary.main,
			textAlign: 'inherit',
			padding: '4px 0 5px',
		},
	},
}))(DatePicker);

const mapStateToProps = state => ({
	language: app.selectors.language(state),
});

const customUtils = () => new CustomMomentUtils();

class DateRangePicker extends React.Component {
	handleStartDateChange = date => {
		const { onStartDateChange } = this.props;
		if (onStartDateChange) {
			onStartDateChange(date);
		}
	};

	handleEndDateChange = date => {
		const { onEndDateChange } = this.props;
		if (onEndDateChange) {
			onEndDateChange(date);
		}
	};

	render() {
		const {
			classes,
			startDate,
			endDate,
			language,
			cancelLabel = <R id="shared_cancel" defaultValue="Cancel" />,
			okLabel = <R id="shared_ok" defaultValue="OK" />,
			todayLabel = <R id="shared_today_btn" defaultValue="today" />,
		} = this.props;

		let dateFormat;
		if (language === 'zh') {
			dateFormat = 'yyyy年MM月DD日';
		} else {
			dateFormat = 'DD MMM YY';
		}

		return (
			<span>
				<MuiPickersUtilsProvider utils={customUtils}>
					<Box display="flex" justifyContent="center" alignItems="center">
						<CustomDatePicker
							showTodayButton
							format={dateFormat}
							maxDate={endDate}
							style={{ maxWidth: 120, minWidth: 86, textAlign: 'end' }}
							value={startDate}
							onChange={this.handleStartDateChange}
							cancelLabel={cancelLabel}
							okLabel={okLabel}
							todayLabel={todayLabel}
							InputProps={{
								disableUnderline: true,
							}}
						/>
						<i style={{ marginLeft: 10 }} />
						<div className={classes.calendarsSplit}>&mdash;</div>
						<i style={{ marginLeft: 10 }} />
						<CustomDatePicker
							minDate={startDate}
							format={dateFormat}
							showTodayButton
							style={{ maxWidth: 120, minWidth: 86, border: 'none' }}
							value={endDate}
							onChange={this.handleEndDateChange}
							cancelLabel={cancelLabel}
							okLabel={okLabel}
							todayLabel={todayLabel}
							InputProps={{
								disableUnderline: true,
							}}
						/>
					</Box>
				</MuiPickersUtilsProvider>
			</span>
		);
	}
}

DateRangePicker.propTypes = {
	classes: PropTypes.object.isRequired,
	onStartDateChange: PropTypes.func,
	onEndDateChange: PropTypes.func,
	startDate: PropTypes.any,
	endDate: PropTypes.any,
};

DateRangePicker.defaultProps = {
	onStartDateChange: null,
	onEndDateChange: null,
	startDate: new Date(),
	endDate: new Date(),
};

export default withStyles(styles)(
	connect(mapStateToProps, null)(DateRangePicker),
);
