import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
// import MUISelect from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { MUISelect } from './components';

const styles = theme => ({
	hidden: {
		visibility: 'hidden',
		height: 0,
	},
	fullWidth: {
		width: '100%',
	},
	label: {
		...theme.customstyles.title,
		color: theme.palette.text.secondary,
	},
});

const Select = ({
	children,
	isHidden,
	fullWidth,
	classes,
	label,
	disabled,
	error,
	...props
}) => {
	const cssClasses = classNames({
		[classes.fullWidth]: fullWidth,
		[classes.hidden]: isHidden,
	});
	return (
		<FormControl
			className={cssClasses}
			disabled={disabled}
			error={error}
		>
			<InputLabel
				htmlFor={props.name}
				className={classes.label}
			>
				{label}
			</InputLabel>
			<MUISelect
				disableUnderline
				{...props}
			>
				{children}
			</MUISelect>
		</FormControl>
	);
};

Select.propTypes = {
	...MUISelect.propTypes,
	isHidden: PropTypes.bool,
	fullWidth: PropTypes.bool,
	label: PropTypes.string,
	disabled: PropTypes.bool,
};

Select.defaultProps = {
	isHidden: false,
	fullWidth: true,
	label: '',
	disabled: false,
};

export default withStyles(styles)(Select);
