import React from 'react';
import MUButton from '@material-ui/core/Button';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { cabinetLog } from '@global';


const styles = theme => ({
	root: {
		borderRadius: '4px',
		padding: '12px 24px',
	},
	sizeSmall: {
		padding: '4px 10px',
	},
	outlinedPrimary: {
		color: theme.palette.primary.main,
		backgroundColor: 'none',
		border: `solid 1px ${theme.palette.primary.main}`,
		'&$disabled': {
			opacity: '0.35',
		},
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
			// Reset on mouse devices
			'@media (hover: none)': {
				backgroundColor: theme.palette.primary.main,
			},
		},
	},
	outlinedSecondary: {
		color: theme.palette.secondary.contrastText,
		backgroundColor: 'none',
		border: `solid 1px ${theme.palette.secondary.contrastText}`,
		'&$disabled': {
			opacity: '0.35',
		},
		'&:hover': {
			backgroundColor: theme.palette.secondary.contrastText,
			color: theme.palette.secondary.light,
			// Reset on mouse devices
			'@media (hover: none)': {
				backgroundColor: theme.palette.secondary.contrastText,
			},
		},
	},
	textPrimary: {
		color: theme.palette.primary.light,
		'&$disabled': {
			opacity: '0.35',
		},
		'&:hover': {
			color: theme.palette.primary.light,
		},
	},
	textSecondary: {
		color: theme.palette.secondary.contrastText,
		backgroundColor: 'none',
		'&$disabled': {
			opacity: '0.35',
		},
		'&:hover': {
			backgroundColor: theme.palette.common.white,
			color: theme.palette.secondary.light,
			// Reset on mouse devices
			'@media (hover: none)': {
				backgroundColor: theme.palette.secondary.contrastText,
			},
		},
	},
	disabled: {
		opacity: '0.6',
		backgroundColor: 'inherit',
	},
	containedPrimary: {
		color: theme.palette.primary.contrastText,
		// backgroundColor: theme.palette.primary.main,
		boxShadow: '0 5px 10px 0 rgba(61, 65, 71, 0.16)',
		'&$disabled': {
			// opacity: '0.35',
			boxShadow: '0 5px 10px 0 rgba(61, 65, 71, 0.16)',
			backgroundColor: theme.palette.primary.main,
			'& > *': {
				color: theme.palette.primary.contrastText,
			},
		},
		'&:hover': {
			boxShadow: '0 8px 12px 0 rgba(61, 65, 71, 0.16)',
			backgroundColor: theme.palette.primary.light,
			// Reset on mouse devices
			'@media (hover: none)': {
				boxShadow: '0 8px 12px 0 rgba(61, 65, 71, 0.16)',
				backgroundColor: theme.palette.primary.light,
			},
		},

	},
	containedSecondary: {
		color: theme.palette.secondary.contrastText,
		backgroundColor: theme.palette.secondary.main,
		boxShadow: '0 5px 10px 0 rgba(61, 65, 71, 0.16)',
		'&$disabled': {
			boxShadow: '0 5px 10px 0 rgba(61, 65, 71, 0.16)',
			backgroundColor: 'rgba(255,255,255,0.2)',
			'& > *': {
				color: theme.palette.secondary.contrastText,
			},
		},
		'&:hover': {
			boxShadow: '0 8px 12px 0 rgba(61, 65, 71, 0.16)',
			backgroundColor: theme.palette.secondary.main,
			// Reset on mouse devices
			'@media (hover: none)': {
				boxShadow: '0 8px 12px 0 rgba(61, 65, 71, 0.16)',
				backgroundColor: theme.palette.secondary.main,
			},
		},
	},
});

class Button extends React.Component {
	handleClick = (e) => {
		if (process.env.NODE_ENV === 'production' && this.props.gaUrl) {
			cabinetLog(`send to ${this.props.gaUrl}`);
			ReactGA.modalview(this.props.gaUrl);
		}
		if (this.props.onClick) {
			this.props.onClick(e);
		}
	}

	render() {
		const {
			onClick,
			gaUrl,
			...props
		} = this.props;

		return (
			<MUButton
				onClick={this.handleClick}
				{...props}
			/>
		);
	}
}


Button.propTypes = {
	/**
	 * mouse click handler
	 */
	onClick: PropTypes.func,
	/**
	 * If exists, send this url to Google Analytics
	 */
	gaUrl: PropTypes.string,
	/**
	 * The content of the button.
	 */
	children: PropTypes.node.isRequired,
	/**
	 * Useful to extend the style applied to components.
	 */
	classes: PropTypes.object.isRequired,
	/**
	 * The color of the component. It supports those theme colors that make sense for this component.
	 */
	color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
	/**
	 * The component used for the root node.
	 * Either a string to use a DOM element or a component.
	 * The default value is a `button`.
	 */
	component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
	/**
	 * If `true`, the button will be disabled.
	 */
	disabled: PropTypes.bool,
	/**
	 * If `true`, the  keyboard focus ripple will be disabled.
	 * `disableRipple` must also be true.
	 */
	disableFocusRipple: PropTypes.bool,
	/**
	 * If `true`, the ripple effect will be disabled.
	 */
	disableRipple: PropTypes.bool,
	/**
	 * If `true`, the button will take up the full width of its container.
	 */
	fullWidth: PropTypes.bool,
	/**
	 * The URL to link to when the button is clicked.
	 * If defined, an `a` element will be used as the root node.
	 */
	href: PropTypes.string,
	/**
	 * The size of the button.
	 * `small` is equivalent to the dense button styling.
	 */
	size: PropTypes.oneOf(['small', 'medium', 'large']),
	/**
	 * The type of button.
	 */
	startIcon: PropTypes.node,
	/**
	 * The type of button.
	 */
	variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
};

Button.defaultProps = {
	color: 'default',
	disabled: false,
	disableFocusRipple: false,
	fullWidth: false,
	size: 'medium',
	variant: 'outlined',
	component: undefined,
	href: undefined,
	disableRipple: false,
	gaUrl: null,
	onClick: null,
	startIcon: undefined,
};

export default withStyles(styles)(Button);
