import React from 'react';
import PropTypes from 'prop-types';
import { Box, ListItem, makeStyles } from '@material-ui/core';
import { Button, LogoIcon, ListItemContainer, useTranslate } from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';
import { usePaymentGateWidgetHook, useMoneyFormat } from '@hooks';
import { useDispatch, useSelector } from 'react-redux';
import { userAccounts } from '@redux';
import { constants } from '@config';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingLeft: 0,
		paddingRight: 0,
		flexWrap: 'wrap',
		'&:last-child': {
			borderBottom: 'none',
		},
	},
	buttonSection: {
		flex: '1 1 300px',
		display: 'flex',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		// margin: '5px',
		alignItems: 'center',
	},
	button: {
		flex: '1 0 80px',
		minWidth: '80px',
		width: '100%',
		margin: '5px',
	},
	iconBalanceSection: {
		flex: '1 1 100px',
		display: 'flex',
		justifyContent: 'flex-start',
		columnGap: theme.spacing(3),
		alignItems: 'center',
		margin: theme.spacing(0.5),
	},
	balanceContainer: {
		flex: '1 0 auto',
		textAlign: 'center',
		maxWidth: '120px',
	},
}));

const useActions = tradeCode => {
	const dispatch = useDispatch();
	const actions = {
		showTransferWalletToMt5Modal: currencyCode => dispatch(userAccounts.actions.showTransferWalletToMt5Modal({
			tradeCodeFrom: tradeCode,
			currencyCode,
		})),
		showJ2txWithdrawalModal: currencyCode => dispatch(userAccounts.actions.showJ2txWithdrawalModal({
			tradeCode,
			currencyCode,
		})),
		showJ2txChangeCurrencyModal: currencyCode => dispatch(userAccounts.actions.showJ2txChangeCurrencyModal({
			tradeCode,
			currencyFrom: currencyCode,
			currencyTo: currencyCode === 'BTC' ? 'ETH' : 'BTC',
		})),
	};
	return actions;
};

const WalletListItem = props => {
	const { tradeCode, moneyInfoItem } = props;

	const classes = useStyles(props);
	const translate = useTranslate();
	const fiatMoneyFormat = useMoneyFormat({ precision: 2 });
	const cryptoMoneyFormat = useMoneyFormat({ precision: 8, disableCurrencyCode: true });
	const actions = useActions(tradeCode);

	const handleTransferToMt5 = () => actions.showTransferWalletToMt5Modal(moneyInfoItem.CurrencyCode);
	const handleWithdraw = () => actions.showJ2txWithdrawalModal(moneyInfoItem.CurrencyCode);
	const handleExchnageCurrency = () => actions.showJ2txChangeCurrencyModal(moneyInfoItem.CurrencyCode);

	const [paymentWidgetId, loadingWidget, successWidget] = usePaymentGateWidgetHook({ tradeCode, currency: moneyInfoItem.CurrencyCode });
	return (
		<ListItemContainer divider>
			<Box className={classes.iconBalanceSection}>
				<LogoIcon currency={moneyInfoItem.CurrencyCode} />
				<div className={classes.balanceContainer}>
					{constants.J2txWalletAllowedCurrencies.includes(moneyInfoItem.CurrencyCode) 
						? fiatMoneyFormat(moneyInfoItem.Balance)
						: cryptoMoneyFormat(moneyInfoItem.Balance, moneyInfoItem.CurrencyCode)
					}
				</div>
			</Box>
			<Box className={classes.buttonSection}>
				<Button
					color="primary"
					className={classes.button}
					variant="outlined"
					size="small"
					disabled={loadingWidget || !successWidget}
					id={paymentWidgetId}
				>
					<R id="shared_deposit" defaultValue="Deposit" />
				</Button>
				<Button
					className={classes.button}
					color="primary"
					variant="outlined"
					size="small"
					onClick={handleExchnageCurrency}
					disabled={!moneyInfoItem.IsAllowedExchange}
				>
					<R id="wallet_exchange_button" defaultValue="Exchange" />
				</Button>
				<Button
					className={classes.button}
					color="primary"
					variant="outlined"
					size="small"
					onClick={handleTransferToMt5}
					title={translate('title_transfer_to_another_account', "Transfer to another account")} 
					disabled={!moneyInfoItem.IsAllowedTransfer}
				>
					<R id="shared_transfer" defaultValue="Transfer" />
				</Button>
				<Button
					className={classes.button}
					color="primary"
					variant="outlined"
					size="small"
					onClick={handleWithdraw}
					disabled={!moneyInfoItem.IsAllowedOut}
				>
					<R id="shared_withdraw" defaultValue="Withdraw" />
				</Button>
			</Box>
		</ListItemContainer>
	);
};

WalletListItem.propTypes = {
	tradeCode: PropTypes.string.isRequired,
	moneyInfoItem: PropTypes.shape({
		CurrencyCode: PropTypes.string.isRequired,
		Balance: PropTypes.number.isRequired,
	}).isRequired,
};

export default WalletListItem;
