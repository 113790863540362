import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, createStyles } from '@material-ui/core/styles';


// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(theme => createStyles({
	editable: {
		display: 'flex',
		alignItems: 'center',
		gap: '10px',
		marginBottom: '20px',
	},
	editValue: {
		flexGrow: 2,
	},
}));

const CustomEditableItem = (props) => {
	const {
		valueControl,
		editControl,
	} = props;
	const classes = useStyles(props);
	return (
		<div className={classes.editable}>
			<div className={classes.editValue}>
				{valueControl}
			</div>
			{editControl}
		</div>
	);
};

CustomEditableItem.propTypes = {
	classes: PropTypes.object,
	valueControl: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.string,
	]).isRequired,
	editControl: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.string,
	]),
};
CustomEditableItem.defaultProps = {
	editControl: undefined,
	classes: {},
};
export default CustomEditableItem;
