import actions from './actions';

const initialState = {
	crypto: {
		tickers: [],
		submitting: false,
	},
	staking: {
		stakingRates: [],
		submitting: false,
	}
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.getCryptocurrencyRates.SUCCESS:
			return {
				...state,
				crypto: {
					...state.crypto,
					tickers: payload.response.Tickers,
					submitting: false,
				},
			}
		case actions.getCryptocurrencyRates.FAILURE:
			return {
				...state,
				crypto: {
					...state.crypto,
					submitting: false,
				},
			};

		case actions.getStakingRates.REQUEST:
			return {
				...state,
				staking: {
					...state.staking,
					submitting: true,
				},
			};

		case actions.getStakingRates.SUCCESS:
			return {
				...state,
				staking: {
					...state.staking,
					stakingRates: payload.response.Rates,
					stakableCurrencies: payload.response.Stakable,
					submitting: false,
					success: true
				},
			};

		case actions.getStakingRates.FAILURE:
			return {
				...state,
				staking: {
					...state.staking,
					submitting: false,
					success: false,
				},
			};

		default:
			return state;
	}
};
