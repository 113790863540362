import { put, select, fork, takeEvery } from 'redux-saga/effects';
import { 
	getWithdrawCash,
} from '@api';
import { SubmissionError } from 'redux-form';
import { BaseGetSagaHandler, callApi, linkedAccounts, userAccounts, snackbar } from '@redux';
import { push } from 'connected-react-router';
import { ModalPopups } from '@enums';
import { addHashToUrl, removeHashFromUrl, captureError } from '@global';
import actions from './actions';
import { change as changeReduxFormValue } from 'redux-form';
import { MapWireLinkPayload } from '../linkedAccounts/saga';

function* getWithdrawCashHandler({ payload }) {
	try {
		const { account, form, name } = payload;
		yield put(changeReduxFormValue(form, name, { submitting: true }));
		const request = yield callApi(getWithdrawCash, account);

		if (!request.data.Success) {
			yield put(actions.getWithdrawCash.failure(new SubmissionError({
				_error: request.data.Errors[0].Message,
			})));
			yield put(snackbar.actions.showErrorMessage({ text: request.data.Errors[0].Message }));
			yield put(changeReduxFormValue(form, name, { submitting: false }));
		} else {
			const res = request.data.Data;
			yield put(changeReduxFormValue(form, name, {
				submitting: false,
				currencyCode: res && res.CurrencyCode && 'USD',
				cashToWithdrawValue: res && res.FreeMargin,
				totalAccountValue: res && res.Equity,
			}));
			yield put(actions.getWithdrawCash.success(res));
		}
	} catch (error) {
		captureError(error);
		yield put(snackbar.actions.showErrorMessage({ text: error.message }));
	}
}


function* watchGetWithdrawCash() {
	yield takeEvery(actions.getWithdrawCash.REQUEST, getWithdrawCashHandler);
}

// Root saga
const rootSaga = [
	fork(watchGetWithdrawCash),
];

export default {
	rootSaga,
};
