import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import SvgIcon from '../SvgIcon/SvgIcon';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(theme => createStyles({
	root: {
		position: 'relative',
		maxWidth: '48px',
		height: '48px',
	},
}));
const SpcLogo = ({ alt, classes, className }) => {
	const mergedclasses = {
		...useStyles(),
		...classes,
	};
	return (
		<React.Fragment>
			<div className={classNames(mergedclasses.root, className)}>
				<SvgIcon
					icon="SpcLogo"
					alt={alt}
					viewBox="0 0 139 71"
					style={{
						width: '100%',
						height: '100%',
					}}
				/>
			</div>
		</React.Fragment>
	);
};

SpcLogo.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	alt: PropTypes.string,
};
SpcLogo.defaultProps = {
	alt: 'Score Priority logo',
	classes: {},
	className: undefined,
};

export default SpcLogo;
