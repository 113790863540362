import React, { useLayoutEffect } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { app } from '@redux';
import { getAllowedLanguages } from '@config';
import { setLanguageCookie } from '../../../core/global/langutils';

const LanguageRefSwitcher = () => {
	const language = useSelector(app.selectors.language);
	const location = useLocation();
	const langPath = location.pathname.substring(1, 3);
	const noLangPath = location.pathname.substring(3) || '/';

	const dispatch = useDispatch();
	useLayoutEffect(() => {
		if (langPath !== language && getAllowedLanguages().some(x => x.language === langPath)) {
			setLanguageCookie(langPath);
			dispatch(app.actions.changeLanguage.request(langPath));
		}
	}, [langPath, language, dispatch]);

	const noLangLocation = {
		...location,
		pathname: noLangPath,
	};

	return (
		<Redirect to={noLangLocation} />
	);
};

export default LanguageRefSwitcher;
