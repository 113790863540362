import { providerWithAuth } from '@api';

export const cancelOrder = ({ tradeCode, orderCode }) =>
	providerWithAuth().get(`/useraccounts/api_cancelcashactivity/${tradeCode}/${orderCode}/`);

export const getAccountCharts = account =>
	providerWithAuth().get(`/useraccounts/api_accountchart/${account.clearerFirm}/${account.tradeCode}/`);

export const getAccountInfo = account =>
	providerWithAuth().get(`/useraccounts/api_accountinfo/${account.clearerFirm}/${account.tradeCode}/`);

export const getAllAccountInfo = () => providerWithAuth().get('/useraccounts/api_getBalances');

export const getAccountFundingInfo = ({ tradeCode, currency }) =>
	providerWithAuth().post('/useraccounts/api_accountfundinginfo/', { TradeCode: tradeCode, Currency: currency });

export const getAccountPortfolio = payload => providerWithAuth().get(`/useraccounts/api_getaccountportfolio/${payload.tradeCode}`);

export const getTradeSystemCredentials = account =>
	providerWithAuth().get(`/useraccounts/api_gettradesystemcredentials/${account.clearerFirm}/${account.tradeCode}/`);

export const getAccounts = () => providerWithAuth().get('/useraccounts/api_accounts');
export const getUserInfo = () => providerWithAuth().get('/useraccounts/api_userInfo');

// non lme
export const getTransactionsHistory = payload => providerWithAuth().post('/useraccounts/api_transactionhistory/', payload);
// lme - webcab-6331
export const getMoneyTransactionHistory = payload => providerWithAuth().post('/useraccounts/api_money-transaction-history/', payload);
export const getNonTradingOrdersHistory = payload => providerWithAuth().post('/useraccounts/api_non-trading-orders-history', payload);

export const refreshReportsAndConfirmations = account =>
	providerWithAuth().get(`/useraccounts/api_refreshReportsAndConfirmations/${account.clearerFirm}/${account.tradeCode}/`);

export const changeMtPassword = payload => providerWithAuth().post('/useraccounts/api_changemtpassword/', payload);

export const addDemoAccount = () => providerWithAuth().post('useraccounts/api_addDemoAccount');

export const resetDemoAccount = payload => providerWithAuth().post('useraccounts/api_resetDemoAccount', payload);

export const getExchangeRate = ({ currencyFrom, currencyTo }) =>
	providerWithAuth().get(`/useraccounts/api_currencyRate/${currencyFrom}/${currencyTo}/`);

export const activateAccount = (tradeCode) => 
	providerWithAuth().get(`/useraccounts/api_activateAccount/${tradeCode}`);
	
export const getLimeTraderAccountInfo = ({ payload }) => {
	return providerWithAuth().get(`/useraccounts/api_limeTraderAccountInfo/${payload.tradeCode}`);
}
