import React from 'react';
import { Typography, Box, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { R } from '@dev2t/react-ui-kit/src/components';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '30px',
        margin: '30px 0',
        '& a': {
            color: theme.palette.primary.main,
            textDecoration: 'none',
        },
    },
}));

const OpenMt5Global = () => {
    const classes = useStyles();

    return (
        <Paper className={classes.root}>
            <Box marginBottom='30px'>
                <Typography variant='h6'>
                    <R
                        id={`open_mt5global_deposit_and_trade_title`}
                        defaultValue="Deposit and Trade"
                    />
                </Typography>
                <R
                    id={`open_mt5global_deposit_and_trade_text`}
                    defaultValue="Deposit your crypto and start trading a wide range of assets. Trade top stocks like Apple, Amazon, and Tesla. Dive into the forex market with major pairs such as EUR/USD, GBP/USD, and USD/JPY. Explore commodities including gold, silver, and oil. Our platform ensures you have all the resources you need to diversify your portfolio and maximize your returns."
                    enableHtml
                /> 
            </Box>
            <Box>
                <Typography variant='h6'>
                    <R
                        id={`open_mt5global_how_it_works_title`}
                        defaultValue="How It Works"
                    />
                </Typography>
                <R
                    id={`open_mt5global_how_it_works_text`}
                    defaultValue={
                        `In order to trade crypto, stocks, forex, or commodities, just follow these simple steps:<br>
                        <br>
                        1. Open an account with our partner<br>
                        2. Complete a quick and simple KYC<br>
                        3. Make a deposit by credit/debit card or with crypto<br>
                        4. Start trading within a few minutes<br>
                        <br>
                        Unlock trading opportunities by <a href="https://j2t.com/solutions/mt5global/" target="_blank">registering for a J2T Global account</a> with our partner.`
                    }
                    enableHtml
                /> 
            </Box>
        </Paper>
    )
}

export default OpenMt5Global;