import { createFormAction } from 'redux-form-saga';
import { createApiActions } from '../extensions';


const actions = {

	getApiKeys: createApiActions('API_KEYS/GET_CLIENTS'),
	revokeApiKey: createApiActions('API_KEYS/DELETE_APIKEY'),

	createApiKey: createFormAction('API_KEYS/CREATE_APIKEY'),

	SHOW_CREATE_APIKEY_MODAL: 'API_KEYS/SHOW_CREATEAPIKEY_MODAL',
	showCreateApiKeyModal: () => ({
		type: actions.SHOW_CREATE_APIKEY_MODAL,
	}),

	HIDE_MODAL: 'API_KEYS/HIDE_MODAL',
	hideModal: modalId => ({
		type: actions.HIDE_MODAL,
		payload: modalId,
	}),
};

export default actions;
