
import { ModalPopups } from '@enums';
import actions from './actions';
import authActions from '../auth/actions';

const initialState = {
	profile: {
		submitting: true,
		PersonalInformation: {},
		EmploymentProfile: {},
		PendingOrders: {},
		Documents: [],
		MarketDataSubscriptions: [],
		error: false,
	},
	confirmEmail: {
		submitting: true,
	},
	modals: {
	},
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.GET_PROFILE_REQUEST:
			return { ...state };
		case actions.GET_PROFILE_SUCCESS:
			return {
				...state,
				profile: {
					...payload,
					submitting: false,
					error: false,
				},
			};
		case actions.GET_PROFILE_FAILURE:
			return {
				...state,
				profile: {
					submitting: false,
					PersonalInformation: {},
					EmploymentProfile: {},
					PendingOrders: {},
					Documents: [],
					error: true,
				},
			};
		case actions.SHOW_CHANGE_EMAIL_MODAL:
			return {
				...state,
				modals: {
					...state.modals,
					[ModalPopups.CHANGE_PROFILE_EMAIL]: payload,
				},
			};
		case actions.confirmEmailGetUrl.SUCCESS:
			return {
				...state,
				confirmEmail: {
					submitting: false,
					success: true,
				},
			};
		case actions.confirmEmailGetUrl.FAILURE:
			return {
				...state,
				confirmEmail: {
					submitting: false,
					success: false,
					error: payload.error.errors._error,
				},
			};

		case actions.getCountries.SUCCESS:
			return {
				...state,
				CountryList: [...payload.response.Items],
			};
		case actions.getUsaStates.SUCCESS:
			return {
				...state,
				UsaStatesList: [...payload.response.Items],
			};
		case actions.SHOW_CHANGE_EMPLOYMENT_MODAL:
			return {
				...state,
				modals: {
					...state.modals,
					[ModalPopups.CHANGE_EMPLOYMENT]: payload,
				},
			};
		case actions.SHOW_CHANGE_ADDRESS_MODAL:
			return {
				...state,
				modals: {
					...state.modals,
					[ModalPopups.CHANGE_PROFILE_ADDRESS]: payload,
				},
			};
		case actions.SHOW_CHANGE_PHONE_MODAL:
			return {
				...state,
				modals: {
					...state.modals,
					[ModalPopups.CHANGE_PROFILE_PHONE]: payload,
				},
			};
		case actions.HIDE_PROFILE_MODAL:
			return {
				...state,
				modals: {
					...state.modals,
					[payload]: undefined,
				},
			};
		case authActions.LOGIN_USER:
			return {
				...initialState,
			};
		case authActions.LOGOUT_SUCCESS:
			return { ...initialState };
		default:
			return state;
	}
};
