import React from 'react';
import {
	Box,
	List,
	ListItem as MuiListItem,
	Paper,
	Typography,
	useTheme,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { ProgressContainer } from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';
import { userAccounts } from '@redux';
import { AccountType } from '@enums';
import { useMoneyFormat, useBalance, useAccount } from '@hooks';
import { useSelector } from 'react-redux';
import { CryptocurrencyRates } from './components';
import { Link } from 'react-router-dom';

const getUsdEquity = (moneyInfo) => {
	if (!(moneyInfo && moneyInfo.length > 0 && moneyInfo[0])) {
		return null;
	}
	const usdMoneyInfo = moneyInfo.find(x => x.CurrencyCode === 'USD');
	return usdMoneyInfo?.EquityValue;
};

const J2txAccountListItem = ({color, text, sum, to, withDivider = true}) => {
	const theme = useTheme();
	const hasTransition = Boolean(to);

	const ListItem = () => (
		<MuiListItem
			button
			divider={withDivider}
			disabled={!hasTransition}
		>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				gridGap={theme.spacing(1)}
				width="100%"
				padding="4px 0"
			>
				<Box color={color}>{text}</Box>
				{sum}
			</Box>
		</MuiListItem>
	);

	return hasTransition ? (
		<Link to={to} style={{ textDecoration: "none", color: "inherit" }}>
			<ListItem />
		</Link>
	): (
		<Tooltip
			title={<R id="shared_soon" defaultValue="Soon" />}
			placement='left'
			arrow
		>
			<div><ListItem /></div>
		</Tooltip>
	);
}

export default function MainContentJ2TX() {
	
	const theme = useTheme();
	const moneyFormat = useMoneyFormat();

	const loading = useSelector(userAccounts.selectors.accountsInfoLoading);

	const hasAccounts = useSelector(userAccounts.selectors.hasAccounts);
	const mt5Accounts = useAccount(AccountType.MT5);
	const mt5Balances = useBalance(mt5Accounts.map(x => x.TradeCode));
	
	const walletAccounts = useAccount(AccountType.WALLET);
	const walletBalances = useBalance(walletAccounts.map(x => x.TradeCode));

	const stakingAccounts = useAccount(AccountType.STAKING);
	const stakingBalances = useBalance(stakingAccounts.map(x => x.TradeCode));
	
	const allBalances = [
		...mt5Balances?.values() ?? [], 
		...walletBalances?.values() ?? [],
		...stakingBalances?.values() ?? [],
	];

	return (
		<>
			{hasAccounts && (
				<ProgressContainer submitting={loading ?? true} disableCheckChildren>
					<Paper>
						<Box padding={theme.spacing(4, 6)}>
							<Typography variant="subtitle1" style={{ textTransform: 'uppercase' }}>
								<R id="common_total_value" />
								:
								<span style={{ fontWeight: 900, marginLeft: '1em' }}>
									{moneyFormat(allBalances.reduce((acc, x) => acc + getUsdEquity(x), 0), '$')}
								</span>
							</Typography>
							<List component="nav" aria-label="mailbox folders">
								{walletAccounts?.map(x => 
									<J2txAccountListItem
										key={x.TradeCode}
										color="#F17702"
										text={<R id="shared_wallet" defaultValue="Wallet" />}
										sum={moneyFormat(getUsdEquity(walletBalances?.get(x.TradeCode)), '$')}
										to="/dashboard/wallet"
									/>
								)}
								{mt5Accounts?.map(x =>
									<J2txAccountListItem
										key={x.TradeCode}
										color="#cccc00"
										text={x.DisplayFullName}
										sum={moneyFormat(getUsdEquity(mt5Balances?.get(x.TradeCode)), '$')}
										to="/dashboard/mt5global"
									/>
								)}
								{stakingAccounts?.map(x =>
									<J2txAccountListItem
										key={x.TradeCode}
										color="#019ED1"
										text={<R id="shared_staking" defaultValue="Staking" />}
										sum={moneyFormat(getUsdEquity(stakingBalances?.get(x.TradeCode)), '$')}
										to="/dashboard/staking"
									/>
								)}
								<J2txAccountListItem
									key={"Loans"}
									color="#A60580"
									text={<R id="shared_loans" defaultValue="Loans" />}
									sum="-"
								/>
								<J2txAccountListItem
									key={"Exchange"}
									color="#69B22A"
									text={<R id="shared_exchange" defaultValue="Exchange" />}
									sum="-"
									withDivider={false}
								/>
							</List>
						</Box>
					</Paper>
					<CryptocurrencyRates />
				</ProgressContainer>
			)}
		</>
	);
}
