import { createApiActions } from '../extensions';

const actions = {
	// actions

	SAVE_SIGNATURE_REQUEST: 'SIGNATURE/SAVE_SIGNATURE_REQUEST',
	SAVE_SIGNATURE_SUCCESS: 'SIGNATURE/SAVE_SIGNATURE_SUCCESS',
	SAVE_SIGNATURE_FAILURE: 'SIGNATURE/SAVE_SIGNATURE_FAILURE',


	checkSignatures: createApiActions('SIGNATURE/CHECK_SIGNATURES'),


	COLLECT_SIGNATURES_PROCESS_START: 'SIGNATURE/COLLECT_SIGNATURES_PROCESS_START',

	SHOW_CREATE_SIGNATURE_MODAL: 'SIGNATURE/SHOW_CREATE_SIGNATURE_MODAL',
	HIDE_CREATE_SIGNATURE_MODAL: 'SIGNATURE/HIDE_CREATE_SIGNATURE_MODAL',

	showCreateSignatureModal: (account, guid, personFullName) => ({
		type: actions.SHOW_CREATE_SIGNATURE_MODAL,
		payload: {
			account,
			guid,
			personFullName,
		},
	}),
	hideCreateSignatureModal: () => ({
		type: actions.HIDE_CREATE_SIGNATURE_MODAL,
	}),

	saveSignatureRequest: (account, image, guid) => ({
		type: actions.SAVE_SIGNATURE_REQUEST,
		payload: {
			account,
			image,
			guid,
		},
	}),
	saveSignatureSuccess: payload => ({
		type: actions.SAVE_SIGNATURE_SUCCESS,
		payload,
	}),
	saveSignatureFailure: error => ({
		type: actions.SAVE_SIGNATURE_FAILURE,
		payload: {
			error,
		},
	}),
	collectSignaturesProcessStart: successAcion => ({
		type: actions.COLLECT_SIGNATURES_PROCESS_START,
		payload: {
			successAcion,
		},
	}),
	REQUIRE_SIGNATURE_AND_RETURN: 'SIGNATURE/REQUIRE_SIGNATURE_AND_RETURN',
	requireSignatureAndReturn: (successAcion, cancelAction) => ({
		type: actions.REQUIRE_SIGNATURE_AND_RETURN,
		payload: {
			successAcion,
			cancelAction,
		},
	}),
};

export default actions;
