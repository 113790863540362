import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { constants } from '@config';
import { linkedAccounts, userAccounts } from '@redux';
import {
	reduxForm,
	Field,
	formValueSelector,
} from 'redux-form';
import {
	TabContainer,
	FormRadioGroup,
	Radio,
	Divider,
	FileInput,
	Button,
} from '@components';
import { SignatureChecker } from '@shared';
import { AchLinkRemoveComponent } from './components';
import { FormInput } from '@dev2t/react-ui-kit/src';


const styles = theme => ({
	root: {
		position: 'relative',
	},
	row: {
		display: 'flex',
	},
	inputItem: {
		marginTop: '30px',
		paddingLeft: '50px',
	},
	item: {
		marginTop: '30px',
		paddingLeft: '74px',
	},
	divider: {
		marginTop: '30px',
	},
	fileUploader: {
		marginTop: '16px',
	},
	button: {
		...theme.mixins.stickyButton,
	},
});

const formSelector = formValueSelector('addAchManuallyForm');

const mapStateToProps = state => ({
	initialValues: {
		TradeCode: userAccounts.selectors.currentTradeAccount(state).tradeCode,
		AttachmentsBankCheckScan: [],
		AttachmentsBankReference: [],
		AttachmentsBankStatement: [],
		AttachmentsExplanationLetter: [],
	},
	attachmentsBankCheckScanValue: formSelector(state, 'AttachmentsBankCheckScan') || [],
	attachmentsBankReferenceValue: formSelector(state, 'AttachmentsBankReference') || [],
	attachmentsBankStatementValue: formSelector(state, 'AttachmentsBankStatement') || [],
	attachmentsExplanationLetterValue: formSelector(state, 'AttachmentsExplanationLetter') || [],
	currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
	linkedBanksAccounts: userAccounts.selectors.linkedAccounts(state),
	IsForeign: !!userAccounts.selectors.userInfo(state).IsForeign,
});

class AchManuallyTabComponent extends React.Component {
	static validate(values, props) {
		const errors = {};

		if (!values.BankName) {
			errors.BankName = 'required';
		}
		if (!values.BankRoutingNumber) {
			errors.BankRoutingNumber = 'required';
		} else if (!(constants.regExpRoutingNumber.test(values.BankRoutingNumber))) {
			errors.BankRoutingNumber = 'Invalid Format';
		}
		if (!values.BankAccountName) {
			errors.BankAccountName = 'required';
		}
		if (!values.BankAccountNumber) {
			errors.BankAccountNumber = 'required';
		} else if (!(constants.regExpBankAccountNumber.test(values.BankAccountNumber))) {
			errors.BankAccountNumber = 'Invalid Format';
		}
		if (!values.BankAccountType) {
			errors.BankAccountType = 'required';
		}

		if (!props.IsForeign && (!values.AttachmentsBankCheckScan || values.AttachmentsBankCheckScan.length === 0)) {
			errors.AttachmentsBankCheckScan = 'required';
		}
		if (props.IsForeign && (!values.AttachmentsBankReference || values.AttachmentsBankReference.length === 0)) {
			errors.AttachmentsBankReference = 'required';
		}
		if (props.IsForeign && (!values.AttachmentsBankStatement || values.AttachmentsBankStatement.length === 0)) {
			errors.AttachmentsBankStatement = 'required';
		}
		if (props.IsForeign && (!values.AttachmentsExplanationLetter || values.AttachmentsExplanationLetter.length === 0)) {
			errors.AttachmentsExplanationLetter = 'required';
		}

		return errors;
	}

	render() {
		const {
			classes,
			IsForeign,
			handleSubmit,
			invalid,
			submitting,
			attachmentsBankCheckScanValue,
			attachmentsBankReferenceValue,
			attachmentsBankStatementValue,
			attachmentsExplanationLetterValue,
			currentTradeAccount,
			linkedBanksAccounts,
		} = this.props;

		const noAttachments = IsForeign ?
			(attachmentsBankStatementValue.length === 0 || attachmentsBankReferenceValue.length === 0 || attachmentsExplanationLetterValue.length === 0)
			: (!attachmentsBankCheckScanValue || attachmentsBankCheckScanValue.length === 0);

		const achBankAccount = linkedBanksAccounts && linkedBanksAccounts.find(it => it.Type === 'ACH');
		if (achBankAccount) {
			return (
				<TabContainer>
					<AchLinkRemoveComponent
						achBankAccount={achBankAccount}
						tradeAccount={currentTradeAccount}
					/>
				</TabContainer>
			);
		}

		return (
			<TabContainer>
				<SignatureChecker>
					<div>
						<p>
							Enter your bank account credentials to link the account.
							You can also connect the bank automatically by going to ACH tab.
						</p>
						<form
							onSubmit={handleSubmit(linkedAccounts.actions.achManualLinkingForm)}
							className={classes.formroot}
						>
							<div className={classes.inputItem}>
								<Field
									component={FormInput}
									name="BankName"
									label="Bank Name"
								/>
							</div>
							<div className={classes.inputItem}>
								<Field
									component={FormInput}
									name="BankRoutingNumber"
									label="Bank Routing Number"
								/>
							</div>
							<div className={classes.inputItem}>
								<Field
									component={FormInput}
									name="BankAccountName"
									label="Bank Account Name"
								/>
							</div>
							<div className={classes.inputItem}>
								<Field
									component={FormInput}
									name="BankAccountNumber"
									label="Bank Account Number"
								/>
							</div>
							<div className={classes.inputItem}>
								<Field
									component={FormInput}
									name="BankAddress"
									label="Bank Address"
								/>
							</div>
							<div className={classes.inputItem}>
								<Field
									name="BankAccountType"
									showLabel
									placeholder="Bank Account Type"
									orientation="horizontal"
									component={FormRadioGroup}
								>
									<Radio value="Checking" label="Checking" />
									<Radio value="Savings" label="Saving" />
								</Field>
							</div>
							<div className={classes.divider}>
								<Divider />
							</div>
							{!IsForeign &&
								<React.Fragment>
									<p>The document should contain your bank check scan</p>
									<div className={classes.fileUploader}>
										<FileInput
											onFilesUpdate={data => this.props.change('AttachmentsBankCheckScan', data)}
										/>
									</div>
								</React.Fragment>
							}
							{IsForeign &&
								<React.Fragment>
									<p>Please upload your bank statement</p>
									<div className={classes.fileUploader}>
										<FileInput
											onFilesUpdate={data => this.props.change('AttachmentsBankStatement', data)}
										/>
									</div>
									<p>Please upload your bank reference</p>
									<div className={classes.fileUploader}>
										<FileInput
											onFilesUpdate={data => this.props.change('AttachmentsBankReference', data)}
										/>
									</div>
									<p>Please upload explanation letter</p>
									<div className={classes.fileUploader}>
										<FileInput
											onFilesUpdate={data => this.props.change('AttachmentsExplanationLetter', data)}
										/>
									</div>
								</React.Fragment>
							}
							<div className={classes.button}>
								<Button
									fullWidth
									type="submit"
									variant="contained"
									color="primary"
									disabled={noAttachments || invalid || submitting}
								>
									{submitting ?
										<CircularProgress
											
											size={18}
										/>
										: 'Connect bank account manually'
									}
								</Button>
							</div>
						</form>
					</div>
				</SignatureChecker>
			</TabContainer>
		);
	}
}

AchManuallyTabComponent.propTypes = {
	classes: PropTypes.object.isRequired,
	linkedBanksAccounts: PropTypes.array.isRequired,
	IsForeign: PropTypes.bool.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
	reduxForm({
		form: 'addAchManuallyForm',
		validate: AchManuallyTabComponent.validate,
	}),
)(AchManuallyTabComponent);
