import actions from './actions';

const initialState = {
	isSidebarOpen: false,
	isNotificationsOpen: false,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
	case actions.INIT:
		return {
			...state,
		};
	case actions.INIT_COMPLETED:
		return {
			...state,
			...payload,
		};
	case actions.SIDEBAR_OPEN:
		return {
			...state,
			isSidebarOpen: true,
		};
	case actions.SIDEBAR_CLOSE:
		return {
			...state,
			isSidebarOpen: false,
		};
	case actions.NOTIFICATIONS_OPEN:
		return {
			...state,
			isNotificationsOpen: true,
		};
	case actions.NOTIFICATIONS_CLOSE:
		return {
			...state,
			isNotificationsOpen: false,
		};

	default:
		return state;
	}
};
