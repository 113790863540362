import React from 'react';

const Svg = (
	<svg viewBox="-4.054 0 21 21" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid">
		<path fill="#343434" d="m6.444 0-.141.478v13.883l.141.14 6.444-3.809Z" />
		<path fill="#8C8C8C" d="M6.444 0 0 10.692l6.444 3.81V7.763Z" />
		<path fill="#3C3C3B" d="m6.444 15.722-.079.096v4.946l.079.231 6.448-9.081Z" />
		<path fill="#8C8C8C" d="M6.444 20.995v-5.273L0 11.914Z" />
		<path fill="#141414" d="m6.444 14.501 6.444-3.809-6.444-2.929Z" />
		<path fill="#393939" d="m0 10.692 6.444 3.809V7.763Z" />
	</svg>
);


export default Svg;
