
import { useSelector } from 'react-redux';
import { userAccounts } from '@redux';

/**
 * Минимальные суммы переводов, установленные для аккаунта
 *
 * @param tradeCode трейдкод аккаунта
 *
 * @returns объект-конфигурацию, где ключ - валюта, значение - минимальная сумма
 *
 * @example
 *
 *   const minTransferValues = useMinTransferValues('TX001064');
 *   const isValid = transferValue > minTransferValues.BTC;
 */
export function useMinTransferValues(tradeCode) {
	const accounts = useSelector(userAccounts.selectors.accounts);
	const account = accounts.find(x => x.TradeCode === tradeCode);
	
	if (!account) return null;

	if (account.IsMT5) {
		return {
			BTC: 0,
			ETH: 0,
			USDT: 0,
			LTC: 0,
			XRP: 0,
		}
	}
	else if (account.IsWallet) {
		return {
			BTC: 0,
			ETH: 0,
			USDT: 0,
			LTC: 0,
			XRP: 0,
		}
	}
	else if (account.IsStaking) {
		return {
			BTC: 0.001,
			ETH: 0.01,
			USDT: 10,
			LTC: 0.1,
			XRP: 10
		}
	} else {
		throw new Error('unknown account type');
	}
}
