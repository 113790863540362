import React, { useEffect, useState }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SuccessMessage, ErrorMessage, ProgressContainer } from '@components';
import { R, Modal } from '@dev2t/react-ui-kit/src/components';
import { nontradingOrders, userAccounts } from '@redux';
import CYNonTradingOrderCommonForm from './CYNonTradingOrderCommonForm';


export const CYNonTradingOrderCommonModal = ({ onClose }) => {

	const [step, setStep] = useState('openform');
	const loading = useSelector((state) => userAccounts.selectors.loading(state) || userAccounts.selectors.accountInfo(state).loading);

	return (
		<Modal
			title={<R id="common_menu_free_form_order" defaultValue="Free form order" />}
			onClose={onClose}
		>
			<ProgressContainer submitting={loading}>
				<div>
					{step === 'succeeded' &&
						<SuccessMessage
							onClick={onClose}
							text={<R id="common_free_form_order_completed" defaultValue="Free form order completed" />}
						/>
					}
					{step === 'failed' &&
						<ErrorMessage
							onClick={onClose}
							text={<R id="common_free_form_order_failed" defaultValue="Could not complete order" />}
						/>
					}
					{step === 'openform' &&
						<CYNonTradingOrderCommonForm
							enableReinitialize
							forceUnregisterOnUnmount
							onSubmit={nontradingOrders.actions.sendNonTradingOrderCommonForm}
							onSuccess={() => setStep('succeeded')}
							onFail={() => setStep('failed')}
						/>
					}
				</div>
			</ProgressContainer>
		</Modal>
	);
}