import React from 'react';
import { List, ListItem, DropDownMenu, R } from '@dev2t/react-ui-kit/src';
import { A, SvgIcon } from '@components';
import { Link } from 'react-router-dom';
import { withStyles, withTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({ 
    listItemRow: {
        borderTop: '1px solid #deeaef',
        paddingLeft: '5px',
        paddingRight: '5px',
        [theme.breakpoints.down('sm')]: {
			display: 'flex',
            flexDirection: 'column',
		},
    },
    platformFullName: {
		padding: '0px 5px 0px 5px',
		fontSize: '0.875rem',
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		borderRadius: '5px',
		boxShadow: `0px 3px 3px 1px #DCDCDC`,
        width: 'fit-content',
        [theme.breakpoints.down('sm')]: {
			height: '20px',
		},
	},
    buttons: {
		fontSize: '14px',
        color: theme.palette.primary.dark,
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
		},
	},
    buttonLink: {
        color: 'inherit',
        textDecoration: 'none',
        textTransform: 'uppercase',
        textAlign: 'end',
        width: '100%',
    },
    lastLeverage: {
        marginRight: '25px',
        [theme.breakpoints.down('sm')]: {
			display: 'flex',
            width: '100%',
            marginRight: '0px',
		},
    },
    lastTripleDot:{
        color: theme.palette.primary.dark,
        [theme.breakpoints.down('sm')]: {
			position: 'absolute',
            right: '0',
            top: '5px',
		},
    },
    btEdit: {
		margin: '-14px -12px -12px -8px',
	},
}));

const ListItemColumn = withStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        padding: '25px 0',
        flex: '1 1 auto',
        '&:last-child' : {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
        [theme.breakpoints.down('sm')]: {
			flexDirection: 'row',
            padding: '0',
            margin: '0',
            width: '100%',
            '&:last-child' : {
                position: 'relative',
                justifyContent: 'flex-start',
            },
		},
    },
}))(Box);

const KeyItemColumnList = withStyles(theme => ({
    root: {
        marginBottom: '10px',
        [theme.breakpoints.down('sm')]: {
			width: '50%',
		},
    },
}))(Box);

const ValueItemColumnList = withStyles(theme => ({
    root:{
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
			width: '50%',
		},
    },
}))(Box);

const DemoAccountsStandardList = (props) => {
    const { accounts, createTerminalLink, showLeverageDialog } = props;
    const classes = useStyles();
    return (
        <List>
        {accounts.map((acc) => (
        <ListItem key={acc.TradeCode} className={classes.listItemRow}>
            <ListItemColumn>
                <KeyItemColumnList><R id="shared_cy_ui_trade_code" defaultValue="Trade code" /></KeyItemColumnList>
                <ValueItemColumnList>{acc.Login}</ValueItemColumnList>
            </ListItemColumn>
            <ListItemColumn className={classes.listItemColumnLong}>
                <KeyItemColumnList><R id="shared_type_of_account" defaultValue="Type of account" /></KeyItemColumnList>
                <ValueItemColumnList>{acc.ProductType}</ValueItemColumnList>
            </ListItemColumn>
            <ListItemColumn className={classes.listItemColumn}>
                <KeyItemColumnList><R id="shared_platform" defaultValue="Platform" /></KeyItemColumnList>
                <ValueItemColumnList>{ acc.IsForex ? 'MT4' : 'MT5' }</ValueItemColumnList>
            </ListItemColumn>
            <ListItemColumn className={classes.listItemColumnLong}>
                <KeyItemColumnList><R id="common_useraccounts_trade_server" defaultValue="Server" /></KeyItemColumnList>
                <ValueItemColumnList>{acc.ServerName}</ValueItemColumnList>
            </ListItemColumn>
            <ListItemColumn className={classes.listItemColumn}>
                <KeyItemColumnList><R id="shared_balance" defaultValue="Balance" /></KeyItemColumnList>
                <ValueItemColumnList>{acc.Balance.Currencies?.find(x => x !== undefined).Balance ?? "-"}</ValueItemColumnList>
            </ListItemColumn>
            <ListItemColumn className={classes.listItemColumn}>
                <KeyItemColumnList><R id="currency" defaultValue="Currency" /></KeyItemColumnList>
                <ValueItemColumnList>{acc.Balance.Currencies?.find(x => x !== undefined).CurrencyCode ?? "-"}</ValueItemColumnList>
            </ListItemColumn>
            <ListItemColumn className={classes.listItemColumn}>
                <div className={classes.lastLeverage}>
                    <KeyItemColumnList><R id="order_type_account_leverage" defaultValue="Leverage" /></KeyItemColumnList>
                    <ValueItemColumnList>{acc.LeverageText ?? "-"}
                        {/* <IconButton onClick={showLeverageDialog} classes={{ root: classes.btEdit }}>
									<SvgIcon
										icon="Edit"
										color="primary"
										fontSize="small"
										classes={{ root: classes.btEditSmallIcon }}
									/>
						</IconButton> */}
                    </ValueItemColumnList>
                </div>
            </ListItemColumn>
            <div className={classes.lastTripleDot}>
                    <DropDownMenu>
                        <List>
                            <ListItem className={classes.buttons} button>
                                <Link className={classes.buttonLink} to="/dashboard/tradingplatforms">
                                    <R id="common_menu_trading_platforms" defaultValue="Trading Platforms" />
							    </Link>
                            </ListItem>
                            <ListItem className={classes.buttons} button>
                                <A className={classes.buttonLink} href={createTerminalLink(acc)} target="_blank">
                                    <R id="common_trade" defaultValue="Trade" />
							    </A>
                            </ListItem>
                        </List>
                    </DropDownMenu>
            </div>
        </ListItem>))}
    </List>
    );
}

export default DemoAccountsStandardList;