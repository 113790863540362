import React from 'react';
import { ModalPopups } from '@enums';
import { userAccounts } from '@redux';
import { useSelector } from 'react-redux';
import { R, Modal } from '@dev2t/react-ui-kit/src/components';
import { CYReportsFilter, CYReportsPagingTable } from '../../../../../Reports/components';

const ReportsModal = (props) => {
	const { onClose } = props;

	const currentTradeAccount = useSelector(userAccounts.selectors.currentTradeAccount);
	const modalParams = useSelector(userAccounts.selectors.modals)[ModalPopups.REPORTS] || {
		tradeCode: currentTradeAccount.tradeCode,
	};
	return (
		<Modal
			title={<R id="common_menu_reports" defaultValue="Reports" />}
			onClose={onClose}
			maxWidth="md"
			className={`QA-MODAL-${ModalPopups.REPORTS}`}
		>
			<CYReportsFilter tradeCode={modalParams.tradeCode} />
			<CYReportsPagingTable tradeCode={modalParams.tradeCode} />
		</Modal>
	);
};

export default ReportsModal;
