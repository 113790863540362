import PropTypes from 'prop-types';
import MomentUtils from '@date-io/moment';
import {
	DatePicker as MuiDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import moment from 'moment';
// import moment from 'moment-timezone';
import React from 'react';
import classNames from 'classnames';


const styles = theme => ({
	root: {},
	inputRoot: {
		padding: '5px 24px',
		backgroundColor: 'rgba(255,255,255,0.05)',
		borderRadius: '8px',
		color: theme.palette.primary.dark,
		'& input': {
			cursor: 'pointer',
		},
		'& input::placeholder': {
			opacity: 0.75,
		},
		fontSize: '18px',
		'&:before': {
			margin: '0px 24px',
		},
		'&:after': {
			margin: '0px 24px',
		},
	},
	inputDisabled: {
		color: theme.palette.text.disabled,
		'& input': {
			cursor: 'default',
		},
	},
	inputUnderline: {
		'&:before': {
			borderBottom: `1px solid ${
				theme.palette.primary.main
			}`,
		},
		'&:hover:not(.Mui-disabled):before': {
			borderBottom: `2px solid ${
				theme.palette.primary.main
			}`,
		},
	},
	inputFormControl: {
		color: theme.palette.primary.main,
	},
	labelFormControl: {
		...theme.customstyles.title,
		top: '0px',
		left: '24px',
		width: '100%',
	},
	helperText: {
		...theme.customstyles.body,
		color: undefined,
		padding: '0px 24px',
	},
	error: {
		backgroundColor: theme.palette.error.light,
		borderRadius: '8px',
	},
});

const useStyles = makeStyles(styles);

const DatePicker = React.forwardRef((props, ref) => {
	const theme = useTheme();
	const classes = useStyles(props); // props classes+withStyles

	const {
		asUtc = false,
		format = 'DD MMM YY',
		cancelLabel = 'Cancel',
		okLabel = 'Ok',
		todayLabel = 'Today',
		clearLabel = 'Clear',
		value,
		className,
		disableUnderline = true,
		inputVariant,
		timeZone,
		disabled,
		...datePickerProps
	} = props;

	const momentInstance = asUtc ? moment.utc : moment;
	/* if (timeZone) {
		momentInstance = moment.tz.setDefault(timeZone);
	} */
	if (asUtc) {
		// hack for working in MuiPickersUtilsProvider
		momentInstance.weekdaysShort = moment.weekdaysShort;
	}

	return (
		<MuiPickersUtilsProvider utils={MomentUtils} libInstance={momentInstance}>
			<MuiDatePicker
				format={format}
				cancelLabel={cancelLabel}
				className={classNames({
					[classes.root]: !!theme,
					[classes.error]: datePickerProps.error,
					[className]: !!className,
				})}
				okLabel={okLabel}
				todayLabel={todayLabel}
				clearLabel={clearLabel}
				InputLabelProps={{
					classes: {
						formControl: classes.labelFormControl,
					},
				}}
				InputProps={{
					...(inputVariant !== 'outlined' &&
						disableUnderline !== undefined && { disableUnderline }),
					classes: {
						root: classes.inputRoot,
						...(inputVariant !== 'outlined' && {
							underline: classes.inputUnderline,
						}),
						...(inputVariant === 'standard' && {
							formControl: classes.inputFormControl,
						}),
					},
					className: classNames({
						[classes.inputDisabled]: disabled,
					}),
					ref,
				}}
				FormHelperTextProps={{
					classes: {
						root: classes.helperText,
					},
				}}
				value={(value && (asUtc ? moment.utc(value) : moment(value))) || null}
				inputVariant={inputVariant}
				disabled={disabled}
				{...datePickerProps}
			/>
		</MuiPickersUtilsProvider>
	);
});

DatePicker.propTypes = {
	asUtc: PropTypes.bool,
	/** Если указана временная зона,параметр asUtc не используется */
	// timeZone: PropTypes.string,
	disableUnderline: PropTypes.bool,
	format: PropTypes.string.isRequired,
};

DatePicker.defaultProps = {
	asUtc: false,
	// timeZone: undefined,
	disableUnderline: true,
	format: 'DD MMM YYYY',
};
export default DatePicker;
