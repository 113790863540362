import { all, fork, select, take, takeEvery, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import moment from 'moment';
import { translate } from '@global';
import {
	BaseGetSagaHandler,
	callApi,
	linkedAccounts,
	notifications,
	signature,
	snackbar,
	userAccounts,
} from '@redux';
import {
	cancelOrder,
	changeMtPassword,
	getAccountCharts,
	getAccounts,
	getAccountInfo,
	getAllAccountInfo,
	getAccountPortfolio,
	getTradeSystemCredentials,
	getTransactionsHistory,
	getMoneyTransactionHistory,
	getNonTradingOrdersHistory,
	getUserInfo,
	refreshReportsAndConfirmations,
	addDemoAccount,
	resetDemoAccount,
	getExchangeRate,
	activateAccount,
	getLimeTraderAccountInfo,
} from '@api';
import { constants, env } from '@config';
import { ModalPopups } from '@enums';
import {
	addHashToUrl,
	cabinetLog,
	captureException,
	removeHashFromUrl,
} from '@global';
import signatureActions from '../signature/actions';
import actions from './actions';
import selectors from './selectors';

function* activateAccountRequestSaga({ payload: {tradeCode, onEndRequest} }) {
	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: activateAccount,
		errorText: translate('activate_account', 'Activate account'),
		handler: actions.activateAccount,
		onEndRequest,
	}, { payload: tradeCode });

	if (isSuccess) {
		yield put(actions.getAccountInfoRequest({ tradeCode }));
		yield put(snackbar.actions.showMessage({
			title: translate('activate_account', 'Activate account'),
			text: translate('account_activation_request_accepted', 'Your request has been accepted. Please wait, it will be completed soon.'),
			type: 'success',
		}));
	}
}

function* getExchangeRateRequestSaga(payload) {
	try {
		const response = yield callApi(getExchangeRate, payload.payload);

		if (!response.data.Success) {
			yield put(actions.getExchangeRate.failure({ ...response.data }));
		} else {
			yield put(actions.getExchangeRate.success({ ...response.data }));
		}
		return true;
	} catch (error) {
		captureException('getExchangeRateRequestSaga', error);
		yield put(actions.getAccountsFailure(error.message));
		console.error(error);
		throw error;
	}
}

function* getLimeTraderAccountInfoSaga(payload) {
	yield BaseGetSagaHandler({
		apiMethod: getLimeTraderAccountInfo,
		errorText: 'Lime Trader Account Info',
		handler: actions.getLimeTraderAccountInfo,
	}, { payload });
}

function* getUserAccountsRequestSaga() {
	try {
		const response = yield callApi(getAccounts);
		if (!response.data.Success) {
			yield put(actions.getAccountsFailure(response.data.Errors[0]));
			// if (response.data.Errors[0].Code === 'Unauthorized') {
			// 	return false;
			// }
			// throw (response.data.Errors[0].Message);
		} else {
			yield put(actions.getAccountsSuccess(response.data.Accounts));
			yield put(actions.getUserInfo.success({ response: response.data }));
		}
		return true;
	} catch (error) {
		captureException('getUserAccountsRequestSaga', error);
		yield put(actions.getAccountsFailure(error.message));
		console.error(error);
		throw error;
	}
}

function* setCurrentAccountSaga({
	payload: {
		account: { TradeCode, ClearingFirm },
	},
}) {
	localStorage.setItem('curacc', TradeCode);

	yield put(actions.getCurrentAccountBaseDataRequest({
		tradeCode: TradeCode,
		clearerFirm: ClearingFirm,
	}));

	const startDate = new Date(
		moment().year(),
		moment().month() - 2,
		moment().date(),
	);
	const endDate = new Date();

	const isDemo = yield select(userAccounts.selectors.currentAccountIsDemo);
	if (!isDemo) {
		if ([constants.LIME_LME].some(x => x === env.firm)) {
			yield put(actions.getMoneyTransactionsHistory.request({
				TradeCodes: [TradeCode],
				StartDate: moment()
					.locale('en_GB')
					.startOf('day')
					.subtract(2, 'years')
					.format('YYYYMMDD'),
				EndDate: moment(endDate).locale('en_GB').format('YYYYMMDD'),
				Page: 1,
			}));

			yield put(actions.getNonTradingOrdersHistory.request({
				TradeCodes: [TradeCode],
				ClearerFirm: ClearingFirm,
				StartDate: moment(startDate).locale('en_GB').format('YYYYMMDD'),
				EndDate: moment(endDate).locale('en_GB').format('YYYYMMDD'),
				Page: 1,
			}));
		} else if (env.firm === constants.FIRM_J2TX) { return;
		} else {
			yield put(actions.getTransactionsHistory.request({
				TradeCode,
				ClearerFirm: ClearingFirm,
				StartDate: moment(startDate).locale('en_GB').format('YYYYMMDD'),
				EndDate: moment(endDate).locale('en_GB').format('YYYYMMDD'),
				Page: 1,
			}));
		}
	}
	
}

function* GetCurrentAccountBaseDataSaga({ payload }) {
	if (env.firm === constants.LIME_NSR) {
		yield put(actions.getAccountPortfolio.request({ tradeCode: payload.tradeCode }));
		yield put(actions.getAccountInfoRequest(payload));
		yield put(linkedAccounts.actions.getLinkedAccounts.request({
			tradeCode: payload.tradeCode,
		}));

		yield all([
			take([
				actions.getAccountPortfolio.SUCCESS,
				actions.getAccountPortfolio.FAILURE,
			]),
			take([
				actions.GET_ACCOUNT_INFO_SUCCESS,
				actions.GET_ACCOUNT_INFO_FAILURE,
			]),
			take([
				linkedAccounts.actions.getLinkedAccounts.SUCCESS,
				linkedAccounts.actions.getLinkedAccounts.FAILURE,
			]),
		]);

		// fictitious signatures checking, set true force to avoid checks
		yield put(signatureActions.saveSignatureSuccess());
		yield put(actions.getCurrentAccountBaseDataSuccess());

		return;
	}

	if ([constants.LIME_INT, constants.LIME_LME, constants.FIRM_J2TX].some(x => x === env.firm)) {
		yield put(actions.getAccountPortfolio.request({ tradeCode: payload.tradeCode }));
		yield put(actions.getAccountInfoRequest(payload));
		if (env.firm === constants.FIRM_J2TX) {
			yield put(actions.getAllAccountsInfo.request());
		}
		yield put(linkedAccounts.actions.getLinkedAccounts.request({
			tradeCode: payload.tradeCode,
		}));
		yield all([
			take([
				actions.getAccountPortfolio.SUCCESS,
				actions.getAccountPortfolio.FAILURE,
			]),
			take([
				actions.GET_ACCOUNT_INFO_SUCCESS,
				actions.GET_ACCOUNT_INFO_FAILURE,
			]),
			take([
				linkedAccounts.actions.getLinkedAccounts.SUCCESS,
				linkedAccounts.actions.getLinkedAccounts.FAILURE,
			]),
		]);

		// fictitious signatures checking, set true force to avoid checks
		yield put(signatureActions.saveSignatureSuccess());

		const accountInfo = yield select(userAccounts.selectors.accountInfo);
		if (accountInfo.MoneyInfo) {
			yield put(actions.setCurrentAccountBaseParams({
				baseParams: accountInfo.MoneyInfo[0],
			}));
		}

		yield put(actions.getCurrentAccountBaseDataSuccess());
		yield put(notifications.actions.getNotifications.request());
		return;
	}

	yield put(actions.getAccountPortfolio.request({ tradeCode: payload.tradeCode }));
	yield put(actions.getAccountInfoRequest(payload));
	yield put(actions.getAllAccountsInfo.request());
	yield put(linkedAccounts.actions.getLinkedAccounts.request({
		tradeCode: payload.tradeCode,
	}));
	yield put(signature.actions.checkSignatures.request(payload));

	yield all([
		take([
			actions.getAccountPortfolio.SUCCESS,
			actions.getAccountPortfolio.FAILURE,
		]),
		take([actions.GET_ACCOUNT_INFO_SUCCESS, actions.GET_ACCOUNT_INFO_FAILURE]),
		take([
			linkedAccounts.actions.getLinkedAccounts.SUCCESS,
			linkedAccounts.actions.getLinkedAccounts.FAILURE,
		]),
		take([
			signature.actions.checkSignatures.SUCCESS,
			signature.actions.checkSignatures.FAILURE,
		]),
	]);

	yield put(actions.getCurrentAccountBaseDataSuccess());
}

function* getAccountChartsRequestSaga({ payload }) {
	try {
		const response = yield callApi(getAccountCharts, payload);
		cabinetLog('charts', response);
		yield put(actions.getAccountCharts.success(response.data));
	} catch (error) {
		captureException('getAccountChartsRequestSaga', error);
		yield put(actions.getAccountCharts.failure(error));
	}
}

function* getAccountInfoRequestSaga({ payload: { account } }) {
	try {
		const response = yield callApi(getAccountInfo, account);
		if (!response.data.Success) {
			yield put(snackbar.actions.showErrorMessage({
				title: 'Get Account Info',
				text: response.data.Errors[0].Message,
			}));
			yield put(actions.getAccountInfoFailure(response.data.Errors[0].Message));
		} else {
			yield put(actions.getAccountInfoSuccess(response.data));
		}
	} catch (error) {
		captureException('getAccountInfoRequestSaga', error);
		yield put(snackbar.actions.showErrorMessage({
			title: 'Get Account Info',
			text: error.message,
		}));
		yield put(actions.getAccountInfoFailure(error.message));
		console.error(error);
	}
}

function* getAllAccountsInfoRequestSaga() {
	try {
		const response = yield callApi(getAllAccountInfo);

		if (!response.data.Success) {
			yield put(snackbar.actions.showErrorMessage({
				title: 'Get Account Info',
				text: response.data.Errors[0].Message,
			}));
			yield put(actions.getAllAccountsInfo.failure(response.data.Errors[0].Message));
		} else {
			yield put(actions.getAllAccountsInfo.success(response.data));
		}
	} catch (error) {
		captureException('getAccountInfoRequestSaga', error);
		yield put(snackbar.actions.showErrorMessage({
			title: 'Get Account Info',
			text: error.message,
		}));
		yield put(actions.getAllAccountsInfo.failure(error.message));
		console.error(error);
	}
}

function* refreshReportsAndConfirmationsRequestSaga({ payload: { account } }) {
	try {
		let tradeAccount = account;
		if (!account || !account.tradeCode) {
			// set current account waiting
			yield all([
				// Reports and Confirmations required currentTradeAccount
				take(userAccounts.actions.SET_CURRENT_ACCOUNT),
			]);
			tradeAccount = yield select(userAccounts.selectors.currentTradeAccount);
		}
		if (
			[constants.LIME_INT, constants.LIME_NSR, constants.LIME_LME, constants.FIRM_J2TX].some(x => x === env.firm)
		) {
			// refreshReportsAndConfirmations not used for Lime International, Lime Ltd and Lending Robot
			yield put(actions.refreshReportsAndConfirmationsSuccess({}));
			return;
		}

		const response = yield callApi(
			refreshReportsAndConfirmations,
			tradeAccount,
		);
		if (!response.data.Success) {
			yield put(snackbar.actions.showErrorMessage({
				title: 'Get Reports',
				text: response.data.Errors[0].Message,
			}));
			yield put(actions.refreshReportsAndConfirmationsFailure(response.data.Errors[0].Message));
		} else {
			yield put(actions.refreshReportsAndConfirmationsSuccess(response.data));
		}
	} catch (error) {
		captureException('refreshReportsAndConfirmationsRequestSaga', error);
		yield put(snackbar.actions.showErrorMessage({
			title: 'Get Reports',
			text: error.message,
		}));
		yield put(actions.refreshReportsAndConfirmationsFailure(error.message));
	}
}

function* openReportsPageSaga({ payload: { page, account } }) {
	yield* refreshReportsAndConfirmationsRequestSaga({ payload: { account } });
	const reports = yield select(selectors.reportsAndConfirmations);

	if (
		reports.Success &&
    !reports.loading &&
    reports.Url &&
    !reports.Documents &&
    !reports.Transmissons
	) {
		window.open(reports.Url, '_blank');
	} else {
		yield put(push(page));
	}
}

function* cancelOrderRequestSaga({
	payload: { onEndRequest, account, orderCode },
}) {
	const isSuccess = yield BaseGetSagaHandler(
		{
			apiMethod: cancelOrder,
			errorText: 'Cancel Order',
			handler: actions.cancelOrder,
			onEndRequest,
		},
		{ payload: { tradeCode: account.tradeCode, orderCode } },
	);

	if (isSuccess) {
		const cashActivities = yield select(selectors.cashActivities);
		const { StartDate, EndDate } = cashActivities.pagination;

		if ([constants.LIME_LME, constants.FIRM_J2TX].some(x => x === env.firm)) {
			yield put(actions.getMoneyTransactionsHistory.request({
				TradeCodes: [account.tradeCode],
				StartDate,
				EndDate,
				Page: 1,
			}));

			yield put(actions.getNonTradingOrdersHistory.request({
				TradeCodes: [account.tradeCode],
				ClearerFirm: account.clearerFirm,
				StartDate,
				EndDate,
				Page: 1,
			}));
		} else {
			yield put(actions.getTransactionsHistory.request({
				TradeCode: account.tradeCode,
				ClearerFirm: account.clearerFirm,
				StartDate,
				EndDate,
				Page: 1,
			}));
		}
	}
}

function* getTradeSystemCredentialsSaga({ payload }) {
	const isSuccess = yield BaseGetSagaHandler(
		{
			apiMethod: getTradeSystemCredentials,
			errorText: 'Get Trade System Credentials',
			handler: actions.getTradeSystemCredentials,
		},
		{ payload },
	);

	if (isSuccess) {
		yield put(snackbar.actions.showMessage({
			title: 'Get Trade System Credentials',
			text: 'Successfully Submitted! We have sent you an email with login and password information. Please check your inbox.',
			type: 'success',
		}));
	}
}

function* changeMtPasswordRequestSaga({ payload: { NewPassword } }) {
	const currentAccount = yield select(selectors.accountInfo);
	const TradeCode = currentAccount?.TradeCode;
	yield BaseGetSagaHandler(
		{
			apiMethod: changeMtPassword,
			errorText: 'Change Password',
			handler: actions.changeMtPassword,
		},
		{
			payload: { NewPassword, TradeCode },
		},
	);
}

function* reloadTransactionHistory(tradeCode, clearerFirm) {

	const cashActivities = yield select(selectors.cashActivities);
	const StartDate = cashActivities?.pagination?.StartDate ?? cashActivities?.dates?.StartDate;
	const EndDate = cashActivities?.pagination?.EndDate ?? cashActivities?.dates?.EndDate;
	
	const tradeCodes = tradeCode 
		? [tradeCode]
		: cashActivities?.pagination?.TradeCodes;

	if ([constants.LIME_LME, constants.FIRM_J2TX].some(x => x === env.firm)) {
		yield put(actions.getMoneyTransactionsHistory.request({
			TradeCodes: tradeCodes,
			StartDate,
			EndDate,
			Page: 1,
		}));

		// пока что нет элементов с неторговыми поручениями на j2tx
		if (env.firm === constants.FIRM_J2TX) return;

		const nonTraidingActivities = yield select(selectors.nonTraidingActivities);

		yield put(actions.getNonTradingOrdersHistory.request({
			TradeCodes: tradeCodes,
			ClearerFirm: clearerFirm,
			StartDate: nonTraidingActivities.pagination.StartDate,
			EndDate: nonTraidingActivities.pagination.EndDate,
			Page: 1,
		}));
	} else {
		yield put(actions.getTransactionsHistory.request({
			TradeCode: tradeCode,
			ClearerFirm: clearerFirm,
			StartDate,
			EndDate,
			Page: 1,
		}));
	}
}

function* addDemoAccountSaga() {
	try {
		const response = yield callApi(addDemoAccount);
		if (!response.data.Success) {
			yield put(snackbar.actions.showErrorMessage({
				title: 'Cannot add demo account',
				text: response.data.Errors[0],
			}));

			yield put(actions.addDemoAccount.failure(response.data.Errors[0]));
			return;
		}
	} catch (error) {
		captureException('addDemoAccountSaga', error);
		yield put(snackbar.actions.showErrorMessage({
			title: 'Cannot add demo account',
			text: error.message,
		}));

		yield put(actions.addDemoAccount.failure(error.message));

		console.error(error);
		return;
	}

	yield put(userAccounts.actions.getAccountsRequest());
	const accountAction = yield take([
		userAccounts.actions.GET_ACCOUNTS_SUCCESS,
		userAccounts.actions.GET_ACCOUNTS_FAILURE,
	]);

	if (accountAction.type === userAccounts.actions.GET_ACCOUNTS_FAILURE) {
		yield put(snackbar.actions.showErrorMessage({
			title: 'Demo account',
			text: 'Added demo account, but cannot reload accounts',
		}));
		return;
	}

	const accounts = yield select(userAccounts.selectors.accounts);
	const demoAccount = accounts.find(it => it.IsDemo);

	if (demoAccount) {
		yield put(userAccounts.actions.setCurrentAccount(demoAccount));
	}

	yield put(snackbar.actions.showMessage({
		title: 'Demo account',
		text: 'Successfully added demo account',
		type: 'success',
	}));
}

function* resetDemoAccountSaga({
	payload: {
		onEndRequest,
		account: { TradeCode, ClearingFirm },
	},
}) {
	const isSuccess = yield BaseGetSagaHandler(
		{
			apiMethod: resetDemoAccount,
			errorText: 'Cannot reset demo account',
			handler: actions.resetDemoAccount,
			onEndRequest,
		},
		{ payload: { clearingNumber: TradeCode } },
	);

	if (isSuccess) {
		yield put(snackbar.actions.showMessage({
			title: 'Reset demo account',
			text: 'Successfully confirmed account reset',
			type: 'success',
		}));

		const startDate = new Date(
			moment().year(),
			moment().month() - 2,
			moment().date(),
		);
		const endDate = new Date();

		yield put(actions.getAccountPortfolio.request({ tradeCode: TradeCode }));

		if ([constants.LIME_LME, constants.FIRM_J2TX].some(x => x === env.firm)) {
			yield put(actions.getMoneyTransactionsHistory.request({
				TradeCodes: [TradeCode],
				StartDate: moment(startDate).locale('en_GB').format('YYYYMMDD'),
				EndDate: moment(endDate).locale('en_GB').format('YYYYMMDD'),
				Page: 1,
			}));

			yield put(actions.getNonTradingOrdersHistory.request({
				TradeCodes: [TradeCode],
				ClearerFirm: ClearingFirm,
				StartDate: moment(startDate).locale('en_GB').format('YYYYMMDD'),
				EndDate: moment(endDate).locale('en_GB').format('YYYYMMDD'),
				Page: 1,
			}));
		} else {
			yield put(actions.getTransactionsHistory.request({
				TradeCode,
				ClearerFirm: ClearingFirm,
				StartDate: moment(startDate).locale('en_GB').format('YYYYMMDD'),
				EndDate: moment(endDate).locale('en_GB').format('YYYYMMDD'),
				Page: 1,
			}));
		}

		if (env.firm === constants.LIME_INT) {
			// It's all secondary requests for Lime International
			return;
		}

		yield put(actions.getAccountCharts.request({
			tradeCode: TradeCode,
			clearerFirm: ClearingFirm,
		}));
	}
}

function GetModalPopupType(actionType) {
	switch (actionType) {
		case actions.SHOW_REPORTS_MODAL:
			return ModalPopups.REPORTS;

		case actions.SHOW_J2TX_CHANGE_CURRENCY_MODAL:
			return ModalPopups.J2TX_CHANGE_CURRENCY;

		case actions.SHOW_TRANSFER_WALLET_TO_MT5_MODAL:
			return ModalPopups.TRANSFER_WALLET_TO_MT5;

		case actions.SHOW_TRANSFER_MT5_TO_WALLET_MODAL:
			return ModalPopups.TRANSFER_MT5_TO_WALLET;

		case actions.SHOW_TRANSFER_WALLET_TO_STAKING_MODAL:
			return ModalPopups.TRANSFER_WALLET_TO_STAKING;
			
		case actions.SHOW_TRANSFER_STAKING_TO_WALLET_MODAL:
			return ModalPopups.TRANSFER_STAKING_TO_WALLET;

		case actions.SHOW_J2TX_MT5_WITHDRAWAL_MODAL:
			return ModalPopups.J2TX_MT5_WITHDRAWAL;
		case actions.SHOW_J2TX_WALLET_WITHDRAWAL_MODAL:
			return ModalPopups.J2TX_WALLET_WITHDRAWAL;
		default:
			return '';
	}
}

function* showUserAccountsModal({ type }) {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(addHashToUrl(location, GetModalPopupType(type))));
}

function* hideUserAccountsModal({ payload }) {
	const location = yield select(state => state.router.location);
	yield put(push(removeHashFromUrl(location, payload)));
}
// Watchers
function* watchGetUserAccountsRequest() {
	yield takeEvery(actions.GET_ACCOUNTS_REQUEST, getUserAccountsRequestSaga);
}

function* watchGetExchangeRateRequest() {
	yield takeLatest(actions.getExchangeRate.REQUEST, getExchangeRateRequestSaga);
}

function* watchActivateAccountRequest() {
	yield takeEvery(actions.activateAccount.REQUEST, activateAccountRequestSaga);
}

function* watchGetLimeTraderAccountInfoRequest() {
	yield takeLatest(actions.getLimeTraderAccountInfo.REQUEST, getLimeTraderAccountInfoSaga);
}

function* watchGetUserInfoRequest() {
	yield takeEvery(actions.getUserInfo.REQUEST, BaseGetSagaHandler, {
		apiMethod: getUserInfo,
		errorText: 'Get User Info',
		handler: actions.getUserInfo,
	});
}

function* watchSetCurrentAccount() {
	yield takeEvery(actions.SET_CURRENT_ACCOUNT, setCurrentAccountSaga);
}
function* watchGetCurrentAccountBaseData() {
	yield takeEvery(
		actions.GET_CURRENT_ACCOUNT_BASE_DATA_REQUEST,
		GetCurrentAccountBaseDataSaga,
	);
}
function* watchGetAccountChartsRequest() {
	yield takeEvery(
		actions.getAccountCharts.REQUEST,
		getAccountChartsRequestSaga,
	);
}
function* watchGetTransactionsHistoryRequest() {
	yield takeEvery(actions.getTransactionsHistory.REQUEST, BaseGetSagaHandler, {
		apiMethod: getTransactionsHistory,
		errorText: 'Get Transactions History',
		handler: actions.getTransactionsHistory,
		defaults: { Page: 1, Type: 0, PageSize: 10 },
	});
}

function* watchGetMoneyTransactionsHistoryRequest() {
	yield takeEvery(
		actions.getMoneyTransactionsHistory.REQUEST,
		BaseGetSagaHandler,
		{
			apiMethod: getMoneyTransactionHistory,
			errorText: 'Get Transactions History',
			handler: actions.getMoneyTransactionsHistory,
			defaults: { Page: 1, Type: 'ALL', PageSize: 10 },
		},
	);
}

function* watchGetNonTradingOrdersHistoryRequest() {
	yield takeEvery(
		actions.getNonTradingOrdersHistory.REQUEST,
		BaseGetSagaHandler,
		{
			apiMethod: getNonTradingOrdersHistory,
			errorText: 'Get Non Transactions History',
			handler: actions.getNonTradingOrdersHistory,
			defaults: { Page: 1, Type: 0, PageSize: 10 },
		},
	);
}

function* watchGetAccountPortfolioRequest() {
	yield takeEvery(actions.getAccountPortfolio.REQUEST, BaseGetSagaHandler, {
		apiMethod: getAccountPortfolio,
		errorText: 'Get Account Portfolio',
		handler: actions.getAccountPortfolio,
	});
}

function* watchGetTradeSystemCredentialsRequest() {
	yield takeEvery(
		actions.getTradeSystemCredentials.REQUEST,
		getTradeSystemCredentialsSaga,
	);
}
function* watchRefreshReportsAndConfirmationsRequest() {
	yield takeEvery(
		actions.REFRESH_REPORTS_AND_CONFIRMATIONS_REQUEST,
		refreshReportsAndConfirmationsRequestSaga,
	);
}
function* watchOpenReportsPage() {
	yield takeEvery(actions.OPEN_REPORTS_PAGE, openReportsPageSaga);
}
function* watchGetAccountInfoRequest() {
	yield takeEvery(actions.GET_ACCOUNT_INFO_REQUEST, getAccountInfoRequestSaga);
	yield takeEvery(actions.getAllAccountsInfo.REQUEST, getAllAccountsInfoRequestSaga);
}
function* watchCancelOrderRequest() {
	yield takeEvery(actions.cancelOrder.REQUEST, cancelOrderRequestSaga);
}
function* watchChangeMtPasswordRequest() {
	yield takeEvery(
		actions.changeMtPassword.REQUEST,
		changeMtPasswordRequestSaga,
	);
}
function* watchaddDemoAccountRequest() {
	yield takeEvery(actions.addDemoAccount.REQUEST, addDemoAccountSaga);
}
function* watchresetDemoAccountRequest() {
	yield takeEvery(actions.resetDemoAccount.REQUEST, resetDemoAccountSaga);
}
function* watchShowUserAccountsModal() {
	yield takeEvery([
		actions.SHOW_REPORTS_MODAL,
		actions.SHOW_J2TX_CHANGE_CURRENCY_MODAL,

		actions.SHOW_TRANSFER_WALLET_TO_MT5_MODAL,
		actions.SHOW_TRANSFER_MT5_TO_WALLET_MODAL,

		actions.SHOW_TRANSFER_WALLET_TO_STAKING_MODAL,
		actions.SHOW_TRANSFER_STAKING_TO_WALLET_MODAL,

		actions.SHOW_J2TX_MT5_WITHDRAWAL_MODAL,
		actions.SHOW_J2TX_WALLET_WITHDRAWAL_MODAL,
	], showUserAccountsModal);
}
function* watchHideUserAccountsModal() {
	yield takeEvery(actions.HIDE_USERACCOUNTS_MODAL, hideUserAccountsModal);
}

// Root saga
const rootSaga = [
	fork(watchSetCurrentAccount),
	fork(watchGetCurrentAccountBaseData),
	fork(watchGetAccountChartsRequest),
	fork(watchGetUserAccountsRequest),
	fork(watchGetUserInfoRequest),
	fork(watchGetAccountInfoRequest),
	fork(watchGetAccountPortfolioRequest),
	fork(watchGetTradeSystemCredentialsRequest),
	fork(watchRefreshReportsAndConfirmationsRequest),
	fork(watchOpenReportsPage),
	fork(watchGetTransactionsHistoryRequest),
	fork(watchGetMoneyTransactionsHistoryRequest),
	fork(watchGetNonTradingOrdersHistoryRequest),
	fork(watchCancelOrderRequest),
	fork(watchChangeMtPasswordRequest),
	fork(watchaddDemoAccountRequest),
	fork(watchresetDemoAccountRequest),
	fork(watchShowUserAccountsModal),
	fork(watchHideUserAccountsModal),
	fork(watchGetExchangeRateRequest),
	fork(watchActivateAccountRequest),
	fork(watchGetLimeTraderAccountInfoRequest),
];

export default {
	rootSaga,
	getUserAccountsRequestSaga,
	reloadTransactionHistory,
};
