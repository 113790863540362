import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { SvgIcon, ProgressContainer } from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';
import { usePaymentGateWidgetHook } from '@hooks';

const useStyles = makeStyles(theme => ({
	root: {
		...theme.customstyles.button,
		color: theme.palette.primary.main,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',
		marginTop: '20px',
		marginBottom: '20px',
	},
	error: {
		color: theme.palette.error.main,
	},
	text: {
		paddingLeft: '14px',
	},
}));

const FundWidgetButton = () => {
	const [initPaymentGateWidgetId, loadingPg, successPg] = usePaymentGateWidgetHook();
	const classes = useStyles();

	return (
		<div
			id={initPaymentGateWidgetId}
			className={classes.root}
			/* style={{ visibility: successPg && !loadingPg ? 'visible' : 'hidden' }} */
		>
			<ProgressContainer submitting={loadingPg} disableCheckChildren size={24}>
				<SvgIcon
					icon="Deposit"
					className={classNames({
						[classes.error]: !successPg,
					})}
				/>
				<div
					className={classNames({
						[classes.text]: true,
						[classes.error]: !successPg,
					})}
				>
					<R id="common_funds_deposit_title" defaultValue="Fund your account" />
				</div>
			</ProgressContainer>
		</div>
	);
};

export default FundWidgetButton;
