import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import MUIList from '@material-ui/core/List';

const styles = theme => ({

});


class List extends React.Component {
	render() {
		return (
			<MUIList
				{...this.props}
				disablePadding
			>
				{this.props.children}
			</MUIList>
		);
	}
}

List.propTypes = {
	/**
	 * The content of the component.
	 */
	children: PropTypes.node,
	/**
	 * Useful to extend the style applied to components.
	 */
	classes: PropTypes.object.isRequired,
	/**
	 * @ignore
	 */
	className: PropTypes.string,
	/**
	 * The component used for the root node.
	 * Either a string to use a DOM element or a component.
	 */
	component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
	/**
	 * If `true`, compact vertical padding designed for keyboard and mouse input will be used for
	 * the list and list items. The property is available to descendant components as the
	 * `dense` context.
	 */
	dense: PropTypes.bool,
	/**
	 * If `true`, vertical padding will be removed from the list.
	 */
	disablePadding: PropTypes.bool,
	/**
	 * The content of the subheader, normally `ListSubheader`.
	 */
	subheader: PropTypes.node,
};

List.defaultProps = {
	component: 'ul',
	dense: false,
	disablePadding: false,
};

export default compose(withStyles(styles))(List);
export * from './components';
