import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { app, userAccounts  } from '@redux';
import {
	ChangeLink,
	ExpansionPanel,
	useTranslate,
} from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';
import { ModalPopups } from '@enums';
import { InputEditableItem } from '@shared';

export const CYPersonalDocumentsPanel = props => {
	const {
		onToggle,
		panelId,
		isOpen,
		classes,
	} = props;

	const dispatch = useDispatch();
	const translate = useTranslate();
	const {
		Guid,
		DemoUserId,
	} = useSelector(userAccounts.selectors.userInfo);
	const isDemo = !Guid && !!DemoUserId;

	return isDemo
	? null
	: (
		<ExpansionPanel
			header={<R id="common_header_personal_documents" defaultValue="Personal Documents" />}
			id={panelId}
			onToggleOpen={onToggle}
			isOpen={isOpen}
			classes={{ root: classes.expansionPanelRoot, content: classes.expansionPanelContent }}
		>
			<InputEditableItem
				title={translate('common_Personal_identification', 'Personal Identification')}
				// value="*************"
				editControl={
					<ChangeLink onClick={() => dispatch(app.actions.showBaseModal(ModalPopups.CY_NON_TRADING_ORDER_IDENTITY))} />
				}
			/>
			<InputEditableItem
				title={translate('common_address_confirmation', 'Address Confirmation')}
				// value="*************"
				editControl={
					<ChangeLink onClick={() => dispatch(app.actions.showBaseModal(ModalPopups.CY_NON_TRADING_ORDER_REGISTRATION))} />
				}
			/>
		</ExpansionPanel>
	);
};
