import { createApiActions } from '../extensions';

const actions = {
	SET_CAPTCHA_INPUT: 'CAPTCHA/SET_CAPTCHA_INPUT',

    setCaptchaInput: value => ({
        type: actions.SET_CAPTCHA_INPUT,
        payload: {
            value,
        },
    }),

    SET_CAPTCHA_SUCCESS: 'CAPTCHA/SET_CAPTCHA_SUCCESS',

    setSuccess: () => ({
        type: actions.SET_CAPTCHA_SUCCESS,
    }),

    reset: createApiActions('CAPTCHA/RESET_CAPTCHA'),
    validate: createApiActions('CAPTCHA/VALIDATE_CAPTCHA'),
};

export default actions;
