import React from 'react';
import PropTypes from 'prop-types';
import { R } from '@dev2t/react-ui-kit/src/components';

import { CYWithdrawalBankModal } from './components';

const CYWithdrawalIndianBankModal = ({ onClose }) => (
	<CYWithdrawalBankModal
		title={<R id="orders_selectmethod_indian_bank_title" defaultValue="Indian Bank Account" />}
		onClose={onClose}
		target="IMPSBANK"
		currency="USD"
		form="CYWithdrawalIndianBankModal"
		requireIFSC
	/>
);

CYWithdrawalIndianBankModal.propTypes = {
	onClose: PropTypes.func.isRequired,
};

export default CYWithdrawalIndianBankModal;
