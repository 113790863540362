import moment from 'moment';
import { ModalPopups, OperationType } from '@enums';
import actions from './actions';
import authActions from '../auth/actions';
import linkedAccountsActions from '../linkedAccounts/actions';

const initialState = {
	submittingBaseData: false,
	accountInfo: {
		loading: true,
		fetchingAccountActivation: false,
		value: {
			Platforms: {},
			Tariffs: null,
			PendingOrders: {},
			AccountName: '',
			WireRequisites: {},
			Status: {
				CanAcat: false,
				CanAchLink: false,
				CanAchLinkAuto: false,
				CanWireLink: false,
				CanFund: true,
				CanWithdrawal: true,
				CanMarginUpgrade: false,
				CanOptionsUpgrade: false,
				IsClosed: false,
				IsDisabled: false,
				IsArchive: false,
				CanPlatformChange: false,
			},
		},
	},
	currentAccount: '',
	currentClearer: '',
	currentAccountData: {
		submitting: true,
		portfolio: [],
		baseParams: null,
	},
	// currentAccountStatus: {
	// 	CanAchLink: false,
	// 	CanFund: true,
	// 	CanWithdrawal: true,
	// 	CanMarginUpgrade: false,
	// 	CanOptionsUpgrade: false,
	// 	IsClosed: false,
	// 	IsDisabled: false,
	// },
	reportsAndConfirmations: {
		loading: true,
		Url: null,
		Documents: [],
		Transmissions: [],
	},
	charts: {},
	hasMaxTemplatesCount: false,
	linkedAccountsSubmitting: false,
	linkedAccounts: [],
	accounts: [],
	cashActivities: {
		loading: false,
		dates: {
			StartDate: new Date(moment().year(), moment().month() - 2, moment().date()),
			EndDate: new Date(),
		},
		type: OperationType.ALL,
		pagination: {
			Page: 1,
		},
		items: [],
	},
	nonTraidingActivities: {
		loading: true,
		dates: {
			StartDate: new Date(moment().year(), moment().month() - 2, moment().date()),
			EndDate: new Date(),
		},
		type: OperationType.ALL,
		pagination: {
			Page: 1,
		},
		items: [],
	},
	userInfo: {
		submitting: false,
	},
	modals: {},
	rates: {},
	limeTraderAccountInfo: {
		loading: false,
		data: null,
	},
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.activateAccount.REQUEST:
			return {
				...state,
				accountInfo: {
					...state.accountInfo,
					fetchingAccountActivation: true,
				}
			}
		case actions.activateAccount.SUCCESS:
			return {
				...state,
				accountInfo: {
					...state.accountInfo,
					fetchingAccountActivation: false,
				}
			}
		case actions.activateAccount.FAILURE:
			return {
				...state,
				accountInfo: {
					...state.accountInfo,
					fetchingAccountActivation: false,
				}
			}
		case actions.getUserInfo.REQUEST:
			return {
				...state,
				userInfo: {
					...state.userInfo,
					submitting: true,
				},
			};
		case actions.getUserInfo.SUCCESS:
			return {
				...state,
				userInfo: {
					...payload.response.UserInfo,
					submitting: false,
				},
			};
		case actions.getUserInfo.FAILURE:
			return {
				...state,
				userInfo: {
					...state.userInfo,
					submitting: false,
				},
			};
		case actions.GET_ACCOUNTS_REQUEST:
			return {
				...state,
				submittingBaseData: true,
			};
		case actions.GET_ACCOUNTS_SUCCESS:
			return {
				...state,
				...payload,
				submittingBaseData: false,
			};
		case actions.GET_ACCOUNTS_FAILURE:
			return {
				...state,
				...payload,
				submittingBaseData: false,
			};

		case actions.SET_CURRENT_ACCOUNT:
			return {
				...state,
				submittingBaseData: true,
				currentAccount: payload.account.TradeCode,
				currentClearer: payload.account.ClearerFirm || payload.account.ClearingFirm,
				currentAccountIsDemo: payload.account.IsDemo,
				currentAccountData: {
					...initialState.currentAccountData,
				},
				accountInfo: {
					...initialState.accountInfo,
				},
				charts: {},
				hasMaxTemplatesCount: false,
				linkedAccounts: [],
				cashActivities: {
					...initialState.cashActivities,
				},
			};
		case actions.GET_CURRENT_ACCOUNT_BASE_DATA_SUCCESS:
			return {
				...state,
				submittingBaseData: false,
			};

		case actions.REFRESH_REPORTS_AND_CONFIRMATIONS_REQUEST:
			return {
				...state,
				reportsAndConfirmations: {
					...state.reportsAndConfirmations,
					loading: true,
				},
			};
		case actions.REFRESH_REPORTS_AND_CONFIRMATIONS_SUCCESS:
			return {
				...state,
				reportsAndConfirmations: {
					...payload,
					loading: false,
				},
			};
		case actions.REFRESH_REPORTS_AND_CONFIRMATIONS_FAILURE:
			return {
				...state,
				reportsAndConfirmations: {
					...state.reportsAndConfirmations,
					loading: false,
				},
			};

		case actions.getAccountPortfolio.REQUEST:
			return {
				...state,
				currentAccountData: {
					...initialState.currentAccountData,
					submitting: true,
				},
			};
		case actions.SET_ACCOUNT_BASE_PARAMS:
			return {
				...state,
				currentAccountData: {
					...state.currentAccountData,
					...payload.response,
					baseParams: {
						...state.currentAccountData.baseParams,
						...payload.response.baseParams,
					},
				},
			};
		case actions.getAccountPortfolio.SUCCESS:
			return {
				...state,
				currentAccountData: {
					...state.currentAccountData,
					...payload.response,
					baseParams: {
						...payload.response.baseParams,
						MarketValue: payload.response.portfolio?.Positions?.reduce((sum, it) => sum + it.MarketValue, 0),
					},
					submitting: false,
				},
			};
		case actions.getAccountPortfolio.FAILURE:
			return {
				...state,
				currentAccountData: {
					...state.currentAccountData,
					submitting: false,
				},
			};
		case actions.getTradeSystemCredentials.REQUEST:
			return {
				...state,
				tradeSystemCredentialsRequest: {
					submitting: true,
				},
			};
		case actions.getTradeSystemCredentials.SUCCESS:
			return {
				...state,
				tradeSystemCredentialsRequest: {
					...payload.response,
					RequestDate: new Date(),
					submitting: false,
				},
			};
		case actions.getTradeSystemCredentials.FAILURE:
			return {
				...state,
				tradeSystemCredentialsRequest: null,
			};
		case actions.GET_ACCOUNT_INFO_REQUEST:
			return {
				...state,
				accountInfo: {
					...initialState.accountInfo,
					loading: true,
				},
			};
		case actions.GET_ACCOUNT_INFO_SUCCESS: {
			const { Success, ...accInfo } = payload;
			return {
				...state,
				accountInfo: {
					...state.accountInfo,
					value: accInfo,
					loading: false,
					Success,
				},
			};
		}
		case actions.GET_ACCOUNT_INFO_FAILURE:
			return {
				...state,
				accountInfo: {
					...state.accountInfo,
					loading: false,
					Success: false,
				},
			};

		case actions.getAllAccountsInfo.REQUEST:
			return {
				...state,
				allAccountsInfo: {
					...initialState.accountInfo,
					loading: true,
				},
			};
		case actions.getAllAccountsInfo.SUCCESS:
			return {
				...state,
				allAccountsInfo: {
					...payload,
					loading: false,
				},
			};
		case actions.getAllAccountsInfo.FAILURE:
			return {
				...state,
				allAccountsInfo: {
					...initialState.accountInfo,
					loading: false,
				},
			};

		case linkedAccountsActions.getLinkedAccounts.FAILURE:
			return {
				...state,
				linkedAccountsSubmitting: false,
			};
		case linkedAccountsActions.getLinkedAccounts.REQUEST:
			return {
				...state,
				linkedAccountsSubmitting: payload.submitting || false,
			};
		case linkedAccountsActions.getLinkedAccounts.SUCCESS:
			return {
				...state,
				hasMaxTemplatesCount: payload.HasMaxTemplatesCount || false,
				linkedAccountsSubmitting: false,
				linkedAccounts: [...payload.LinkedAccounts],
				// currentAccountStatus: {
				// 	CanAchLink: payload.CanAchLink,
				// 	CanFund: payload.CanFund,
				// 	CanWithdrawal: payload.CanWithdrawal,
				// 	CanMarginUpgrade: payload.CanUpgrade,
				// 	CanOptionsUpgrade: payload.CanOptionsUpgrade,
				// 	IsClosed: payload.IsClosed,
				// 	IsDisabled: payload.IsDisabled,
				// },
			};
		case actions.getTransactionsHistory.REQUEST:
			return {
				...state,
				cashActivities: (payload.Page === 1) ? {
					...initialState.cashActivities,
					loading: true,
					type: payload.Type,
					dates: {
						StartDate: payload.StartDate,
						EndDate: payload.EndDate
					}
				} : {
					...state.cashActivities,
					loading: true,
					type: payload.Type,
					dates: {
						StartDate: payload.StartDate,
						EndDate: payload.EndDate
					}
				},
			};
		case actions.getTransactionsHistory.SUCCESS:
			return {
				...state,
				cashActivities: {
					...state.cashActivities,
					loading: false,
					pagination: {
						...payload.request,
					},
					items: [
						...state.cashActivities.items,
						...payload.response.Items,
					],
				},
			};
		case actions.getTransactionsHistory.FAILURE:
			return {
				...state,
				cashActivities: {
					...state.cashActivities,
					loading: false,
				},
			};


		case actions.getMoneyTransactionsHistory.REQUEST:
			return {
				...state,
				cashActivities: (payload.Page === 1) ? {
					...initialState.cashActivities,
					loading: true,
					type: payload.Type,
					dates: {
						StartDate: payload.StartDate,
						EndDate: payload.EndDate
					}
				} : {
					...state.cashActivities,
					loading: true,
					type: payload.Type,
					dates: {
						StartDate: payload.StartDate,
						EndDate: payload.EndDate
					}
				},
			};
		case actions.getMoneyTransactionsHistory.SUCCESS:
			const items = payload.request.Page === 1 
				? [...payload.response.Items] 
				: [
					...state.cashActivities.items,
					...payload.response.Items,
				];
		
			return {
				...state,
				cashActivities: {
					...state.cashActivities,
					loading: false,
					pagination: {
						...payload.request,
					},
					items: items
				},
			};
		case actions.getMoneyTransactionsHistory.FAILURE:
			return {
				...state,
				cashActivities: {
					...state.cashActivities,
					loading: false,
				},
			};

		case actions.getNonTradingOrdersHistory.REQUEST:
			return {
				...state,
				nonTraidingActivities: (payload.Page === 1) ? {
					...initialState.nonTraidingActivities,
					type: payload.Type,
					dates: {
						StartDate: payload.StartDate,
						EndDate: payload.EndDate
					}
				} : {
					...state.nonTraidingActivities,
					loading: true,
					type: payload.Type,
					dates: {
						StartDate: payload.StartDate,
						EndDate: payload.EndDate
					}
				},
			};
		case actions.getNonTradingOrdersHistory.SUCCESS:
			return {
				...state,
				nonTraidingActivities: {
					...state.nonTraidingActivities,
					loading: false,
					pagination: {
						...payload.request,
					},
					items: [
						...state.nonTraidingActivities.items,
						...payload.response.Items,
					],
				},
			};
		case actions.getNonTradingOrdersHistory.FAILURE:
			return {
				...state,
				nonTraidingActivities: {
					...state.nonTraidingActivities,
					loading: false,
				},
			};

		case actions.CLEAR_TRANSACTIONS_HISTORY:
			return {
				...state,
				cashActivities: {
					...state.cashActivities,
					loading: false,
					pagination: {},
					items: [],
				},
			};

		case actions.getAccountCharts.SUCCESS:
			return {
				...state,
				charts: { ...payload },
			};

		case authActions.LOGIN_USER:
			return {
				...initialState,
			};
		case authActions.LOGOUT_SUCCESS:
			return { ...initialState };

		case actions.addDemoAccount.REQUEST:
			return {
				...state,
				submittingBaseData: true,
			};
		case actions.addDemoAccount.SUCCESS:
			return {
				...state,
				submittingBaseData: false,
			};
		case actions.addDemoAccount.FAILURE:
			return {
				...state,
				submittingBaseData: false,
			};
		case actions.HIDE_USERACCOUNTS_MODAL:
			return {
				...state,
				modals: {
					...state.modals,
					[payload]: undefined,
				},
			};
		case actions.SHOW_REPORTS_MODAL:
			return {
				...state,
				modals: {
					...state.modals,
					[ModalPopups.REPORTS]: payload,
				},
			};
		case actions.SHOW_J2TX_CHANGE_CURRENCY_MODAL:
			return {
				...state,
				modals: {
					...state.modals,
					[ModalPopups.J2TX_CHANGE_CURRENCY]: payload,
				},
			};

		case actions.SHOW_TRANSFER_WALLET_TO_MT5_MODAL:
			return {
				...state,
				modals: {
					...state.modals,
					[ModalPopups.TRANSFER_WALLET_TO_MT5]: payload,
				},
			};

		case actions.SHOW_TRANSFER_MT5_TO_WALLET_MODAL:
			return {
				...state,
				modals: {
					...state.modals,
					[ModalPopups.TRANSFER_MT5_TO_WALLET]: payload,
				},
			};

		case actions.SHOW_TRANSFER_STAKING_TO_WALLET_MODAL:
			return {
				...state,
				modals: {
					...state.modals,
					[ModalPopups.TRANSFER_STAKING_TO_WALLET]: payload,
				},
			};

		case actions.SHOW_TRANSFER_WALLET_TO_STAKING_MODAL:
			return {
				...state,
				modals: {
					...state.modals,
					[ModalPopups.TRANSFER_WALLET_TO_STAKING]: payload,
				},
			};


		case actions.SHOW_J2TX_MT5_WITHDRAWAL_MODAL:
			return {
				...state,
				modals: {
					...state.modals,
					[ModalPopups.J2TX_MT5_WITHDRAWAL]: payload,
				},
			};

		case actions.SHOW_J2TX_WALLET_WITHDRAWAL_MODAL:
			return {
				...state,
				modals: {
					...state.modals,
					[ModalPopups.J2TX_WALLET_WITHDRAWAL]: payload,
				},
			};

		case actions.getExchangeRate.REQUEST:
			return {
				...state,
				rates: {
					...payload,
					loading: true,
					success: undefined,
				},
			};
		case actions.getExchangeRate.SUCCESS:
			return {
				...state,
				rates: {
					...payload,
					loading: false,
					success: true,
				},
			};
		case actions.getExchangeRate.FAILURE:
			return {
				...state,
				rates: {
					...state.rates,
					loading: false,
					success: false,
				},
			};
		case actions.getLimeTraderAccountInfo.REQUEST:
			return {
				...state,
				limeTraderAccountInfo: {
					...state.limeTraderAccountInfo,
					loading: true,
				},
			};
		case actions.getLimeTraderAccountInfo.SUCCESS:
			return {
				...state,
				limeTraderAccountInfo: {
					...state.limeTraderAccountInfo,
					data: {
						MarginType: payload.response.MarginType,
						OptionLevel: payload.response.OptionLevel,
					},
					loading: false,
				},
			};
		case actions.getLimeTraderAccountInfo.FAILURE:
			return {
				...state,
				limeTraderAccountInfo: {
					...state.limeTraderAccountInfo,
					loading: false,
					data: null,
				},
			};
		default:
			return state;
	}
};
