import { ListItem, withStyles } from '@material-ui/core';

const ListItemContainer = withStyles(theme => ({
	root: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		minHeight: '65px',
		flexWrap: 'wrap',
		'&:last-child': {
			borderBottom: 'none',
		},
	},
}))(ListItem);

export default ListItemContainer;
