import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { signature } from '@redux';
import { Button } from '@components';

const styles = theme => ({
	center: {
		display: 'flex',
		justifyContent: 'center',
	},
});

const mapStateToProps = state => ({
	hasSignes: signature.selectors.HasSignes(state),
});

const mapDispatchToProps = dispatch => ({
	actions: {
		collectSignaturesProcessStart: () =>
			dispatch(signature.actions.collectSignaturesProcessStart()),
	},
});

class SignatureChecker extends React.Component {
	render() {
		const {
			classes,
			hasSignes,
			children,
		} = this.props;

		return (!hasSignes ?
			<div>
				<p>
					You’re unable to submit anything without signature.
				</p>
				<div className={classes.button}>
					<Button
						fullWidth
						type="submit"
						variant="contained"
						color="primary"
						onClick={this.props.actions.collectSignaturesProcessStart}
					>
						Add Signature
					</Button>
				</div>
			</div>
			:
			<div>
				{!children ? '' : children}
			</div>
		);
	}
}

SignatureChecker.propTypes = {
	classes: PropTypes.object.isRequired,
	hasSignes: PropTypes.bool.isRequired,
	/**
	 * main content: display if hasSignes is true.
	 */
	children: PropTypes.any.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(SignatureChecker);
