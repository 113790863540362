import appConfig from 'app_config';
import { cabinetLog } from '@global';
import { version as appVersion } from '../../package.json';

const envConfig = {
	production: {
		backendUrl: 'https://myaccountapi.lime.co/',
		pricingUrl: 'https://lime.co/pricing/',
		OAuthServiceUrl: 'https://auth.lime.co/',
		plaidApiEnvironment: 'production',
		plaidApiPublicKey: 'b3ddce858a38efc6c36ccbf8d9b93e',
		GA_TRACKING_ID: null, // 'UA-5574920-1',
		GTM_DATA_LAYER: null, // 'GTM-KZH6ZPM',
		sterlingPcDownloadUrl: 'https://download.sterlingtrader.com/applications/ISSterlingTraderPro_10_3_0.exe',
		visionOptionsSupplementUrl: 'https://lime.co/wp-content/uploads/2022/02/Options-Supplement.pdf',
		uncoveredWrtingRiskDisclosureUrl: 'https://lime.co/wp-content/uploads/2022/03/Special-Options-Risk-Disclosure-Statement.pdf',
		characteristicsRisksStandardizedOptionsUrl: 'https://www.theocc.com/Company-Information/Documents-and-Archives/Options-Disclosure-Document',
		languagesServerPath: '/translation/translations.{lng}.json',
		reloadDicwordsPeriod: 15 * 60 * 1000, // 15 мин
		updateBalancesDelay: 5 * 1000, // 5 сек
		// allowedLangs: ['en', 'ru', 'zh', 'es', 'pl', 'uk', 'ms', 'ko', 'hi', 'el'],
		WEBCAB_3534_checkBrokenEmail: false,
		WEBCAB_4509_transaqChangePwdAvalilableFor: [
			'3065A493-0379-4A66-AD28-0450F7392769',
			'DAF16251-7054-4975-94F2-342FD57675D7',
			'417AEE48-D64E-4CDC-8F85-205198B9AE78',
			'BC7BE6BE-CC1A-4D43-BDBA-7645DDCD5C20',
			'3065A493-0379-4A66-AD28-0450F7392769',
		],
		WEBCAB_4791_hideDepositRequisites: false,
		WEBCAB_5004_walletEnableUsers: [
			'00000000-0000-0000-0000-000000000649',
		],
		WEBCAB_5031_switch_trading_platforms: true,
		WEBCAB_5142_marketData_subscriptions_enable: false,
		WEBCAB_5786_allow_withdrawals: true,
		WEBCAB_5863_allow_attestation: true,
		WEBCAB_5839_allow_demo_account: false,
		WEBCAB_6561_allow_usdt: false,
		WEBCAB_8523_showDisclosures: false,
		WEBCAB_8564_enableMt5Open: [
			'CB44010A-D6D0-4EA8-BD2B-D800442161FF',
			'1356FA59-2B0C-4078-A742-8000C2823C42',
		],
	},
	test: {
		backendUrl: 'https://ustest.tst.just2trade.online/',
		pricingUrl: 'https://lime.co/pricing/',
		OAuthServiceUrl: 'http://nyx-etnae1-ap01.corp.whotrades.eu/auth-uat/',
		plaidApiEnvironment: 'sandbox',
		plaidApiPublicKey: 'b3ddce858a38efc6c36ccbf8d9b93e',
		sterlingPcDownloadUrl: 'https://download.sterlingtrader.com/applications/ISSterlingTraderPro_10_3_0.exe',
		visionOptionsSupplementUrl: 'https://lime.co/wp-content/uploads/2022/02/Options-Supplement.pdf',
		uncoveredWrtingRiskDisclosureUrl: 'https://lime.co/wp-content/uploads/2022/03/Special-Options-Risk-Disclosure-Statement.pdf',
		characteristicsRisksStandardizedOptionsUrl: 'https://www.theocc.com/Company-Information/Documents-and-Archives/Options-Disclosure-Document',
		languagesServerPath: '/translation/translations.{lng}.json',
		reloadDicwordsPeriod: null,
		// allowedLangs: ['en', 'ru', 'zh', 'es', 'pl', 'uk', 'ms', 'ko', 'hi', 'el'],
		WEBCAB_3534_checkBrokenEmail: true,
		WEBCAB_4791_hideDepositRequisites: false,
		WEBCAB_5004_walletEnableUsers: [
			'00000000-0000-0000-0000-000000000649',
		],
		WEBCAB_5031_switch_trading_platforms: true,
		WEBCAB_5142_marketData_subscriptions_enable: true,
		WEBCAB_5786_allow_withdrawals: true,
		WEBCAB_5863_allow_attestation: true,
		WEBCAB_5839_allow_demo_account: true,
		WEBCAB_6561_allow_usdt: false,
		WEBCAB_8523_showDisclosures: false,
		updateBalancesDelay: 5 * 1000, // 5 сек
		WEBCAB_8564_enableMt5Open: [
			'CB44010A-D6D0-4EA8-BD2B-D800442161FF',
			'1356FA59-2B0C-4078-A742-8000C2823C42',
		],
	},
	dev: {
		sentryRavenUrl: '',
		languagesServerPath: 'http://cab-lme.tstcabweb.corp.whotrades.eu/translation/translations.{lng}.json',
		WEBCAB_4523_dateRequireSecondFactor: undefined, // Date.UTC(2021, 9, 1),
		WEBCAB_5004_walletEnableUsers: [
			'00000000-0000-0000-0000-000000000649',
		],
		WEBCAB_5031_switch_trading_platforms: true,
		WEBCAB_5142_marketData_subscriptions_enable: true,
		WEBCAB_5786_allow_withdrawals: true,
		WEBCAB_5863_allow_attestation: true,
		WEBCAB_5839_allow_demo_account: true,
		WEBCAB_6561_allow_usdt: false,
		WEBCAB_8523_showDisclosures: false,
		updateBalancesDelay: 5 * 1000, // 5 сек
		// allowedLangs: ['en', 'ru', 'zh', 'es', 'pl', 'uk', 'ms', 'ko', 'hi', 'el'],
	},
	preprod: {
		backendUrl: 'https://uspreprod.tst.just2trade.online/',
		pricingUrl: 'https://lime.co/pricing/',
		OAuthServiceUrl: 'http://nyx-etnae1-ap01.corp.whotrades.eu/auth-uat/',
		plaidApiEnvironment: 'sandbox',
		plaidApiPublicKey: 'b3ddce858a38efc6c36ccbf8d9b93e',
		sterlingPcDownloadUrl: 'https://download.sterlingtrader.com/applications/ISSterlingTraderPro_10_3_0.exe',
		visionOptionsSupplementUrl: 'https://lime.co/wp-content/uploads/2022/02/Options-Supplement.pdf',
		uncoveredWrtingRiskDisclosureUrl: 'https://lime.co/wp-content/uploads/2022/03/Special-Options-Risk-Disclosure-Statement.pdf',
		characteristicsRisksStandardizedOptionsUrl: 'https://www.theocc.com/Company-Information/Documents-and-Archives/Options-Disclosure-Document',

		languagesServerPath: 'http://cab-lme.tstcabweb.corp.whotrades.eu/translation/translations.{lng}.json',
		reloadDicwordsPeriod: null,
		// allowedLangs: ['en', 'ru', 'zh', 'es', 'pl', 'uk', 'ms', 'ko', 'hi', 'el'],

		WEBCAB_3534_checkBrokenEmail: true,
		WEBCAB_4509_transaqChangePwdAvalilableFor: [
			'3065A493-0379-4A66-AD28-0450F7392769',
			'DAF16251-7054-4975-94F2-342FD57675D7',
			'417AEE48-D64E-4CDC-8F85-205198B9AE78',
			'BC7BE6BE-CC1A-4D43-BDBA-7645DDCD5C20',
			'3065A493-0379-4A66-AD28-0450F7392769',
		],
		WEBCAB_4791_hideDepositRequisites: false,
		WEBCAB_5004_walletEnableUsers: [
			'00000000-0000-0000-0000-000000000649',
		],
		WEBCAB_5031_switch_trading_platforms: true,
		WEBCAB_5142_marketData_subscriptions_enable: false,
		WEBCAB_5786_allow_withdrawals: true,
		WEBCAB_5863_allow_attestation: true,
	},
};

// eslint-disable-next-line import/no-mutable-exports
let env = {
	...envConfig.production,
	...appConfig,
};
cabinetLog(process.env.NODE_ENV);


if (process.env.NODE_ENV !== 'production') {
	env = {
		...envConfig.test,
		...appConfig,
	};
	env.J2tProPcDownloadUrl = `${env.backendUrl}files/programs/J2T_PRO.exe`;
}

if (IS_LOCAL !== undefined && IS_LOCAL) {
	env = {
		...appConfig,
		...envConfig.dev,
	};
}

if (IS_PREPROD !== undefined && IS_PREPROD) {
	env = {
		...envConfig.preprod,
		...appConfig,
	};
	env.J2tProPcDownloadUrl = `${env.backendUrl}files/programs/J2T_PRO.exe`;
}

if (!env.baseUrl) {
	env.baseUrl = window.location.origin;
}
env.version = appVersion;

cabinetLog('env', env);

export default env;
