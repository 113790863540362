import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

const styles = theme => ({
	root: {
		width: '100%',
		height: '1px',
		backgroundColor: '#deeaef',
	},
});

const Divider = ({ classes, color, ...props }) => (
	<div
		className={classes.root}
		style={{ backgroundColor: color }}
		{...props}
	/>);

Divider.propTypes = {
	classes: PropTypes.object.isRequired,
	color: PropTypes.string,
};

Divider.defaultProps = {
	color: undefined,
};

export default compose(withStyles(styles))(Divider);
