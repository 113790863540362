import { createFormAction } from 'redux-form-saga';
import { createApiActions } from '../extensions';

const changeProfileAddressFormSubmitActionPrefix = 'PROFILE_CHANGE_ADDRESS_FORM_SUBMIT';
const changeProfileAddressFormActionCreator =
	createFormAction(changeProfileAddressFormSubmitActionPrefix);

const changeProfileEmailFormSubmitActionPrefix = 'PROFILE_CHANGE_EMAIL_FORM_SUBMIT';
const changeProfileEmailFormActionCreator =
	createFormAction(changeProfileEmailFormSubmitActionPrefix);

const changeProfilePhoneFormSubmitActionPrefix = 'PROFILE_CHANGE_PHONE_FORM_SUBMIT';
const changeProfilePhoneFormActionCreator =
	createFormAction(changeProfilePhoneFormSubmitActionPrefix);

const changeProfileDependentsFormSubmitActionPrefix = 'PROFILE_CHANGE_DEPENDENTS_FORM_SUBMIT';
const changeProfileDependentsFormSubmitActionCreator =
	createFormAction(changeProfileDependentsFormSubmitActionPrefix);

const changeProfileEmploymentFormSubmitActionPrefix = 'PROFILE_CHANGE_EMPLOYMENT_FORM_SUBMIT';
const changeProfileEmploymentFormSubmitActionCreator =
	createFormAction(changeProfileEmploymentFormSubmitActionPrefix);

const actions = {
	// actions

	GET_PROFILE_REQUEST: 'PROFILE/GET_PROFILE_REQUEST',
	GET_PROFILE_SUCCESS: 'PROFILE/GET_PROFILE_SUCCESS',
	GET_PROFILE_FAILURE: 'PROFILE/GET_PROFILE_FAILURE',

	getProfileRequest: () => ({
		type: actions.GET_PROFILE_REQUEST,
	}),
	getProfileSuccess: profile => ({
		type: actions.GET_PROFILE_SUCCESS,
		payload: {
			...profile,
		},
	}),
	getProfileFailure: error => ({
		type: actions.GET_PROFILE_FAILURE,
		payload: {
			error,
		},
	}),

	SHOW_CHANGE_ADDRESS_MODAL: 'PROFILE/SHOW_CHANGE_ADDRESS_MODAL',

	showChangeAddressModal: profile => ({
		type: actions.SHOW_CHANGE_ADDRESS_MODAL,
		payload: profile,
	}),

	CHANGE_ADDRESS_FORM_SUBMIT_REQUEST: changeProfileAddressFormActionCreator.REQUEST,
	CHANGE_ADDRESS_FORM_SUBMIT_SUCCESS: changeProfileAddressFormActionCreator.SUCCESS,
	CHANGE_ADDRESS_FORM_SUBMIT_FAILURE: changeProfileAddressFormActionCreator.FAILURE,

	changeProfileAddressFormSubmitHandler: changeProfileAddressFormActionCreator,
	changeProfileAddressFormRequest: (...args) =>
		changeProfileAddressFormActionCreator.request({ ...args }),
	changeProfileAddressFormSuccess: payload =>
		changeProfileAddressFormActionCreator.success(payload),
	changeProfileAddressFormFailure: error => changeProfileAddressFormActionCreator.failure(error),


	SHOW_CHANGE_EMAIL_MODAL: 'PROFILE/SHOW_CHANGE_EMAIL_MODAL',

	showChangeEmailModal: ({ profile, forceConfirmationEmail = false } = {}) => ({
		type: actions.SHOW_CHANGE_EMAIL_MODAL,
		payload: {
			profile,
			forceConfirmationEmail,
		},
	}),

	changePasswordFormSubmit: createFormAction('PROFILE/CHANGE_PASSWORD_FORM_SUBMIT'),

	CHANGE_EMAIL_FORM_SUBMIT_REQUEST: changeProfileEmailFormActionCreator.REQUEST,
	CHANGE_EMAIL_FORM_SUBMIT_SUCCESS: changeProfileEmailFormActionCreator.SUCCESS,
	CHANGE_EMAIL_FORM_SUBMIT_FAILURE: changeProfileEmailFormActionCreator.FAILURE,

	changeProfileEmailFormSubmitHandler: changeProfileEmailFormActionCreator,
	changeProfileEmailFormRequest: (...args) =>
		changeProfileEmailFormActionCreator.request({ ...args }),
	changeProfileEmailFormSuccess: payload =>
		changeProfileEmailFormActionCreator.success(payload),
	changeProfileEmailFormFailure: error => changeProfileEmailFormActionCreator.failure(error),

	SHOW_CHANGE_MARKETDATA_SUBSCRIBER_STATUS_MODAL: 'PROFILE/SHOW_CHANGE_MARKETDATA_SUBSCRIBER_STATUS_MODAL',
	showChangeMarketDataSubscriberStatusModal: () => ({
		type: actions.SHOW_CHANGE_MARKETDATA_SUBSCRIBER_STATUS_MODAL,
	}),
	changeMarketDataSubscriberStatusForm: createFormAction('PROFILE/CHANGE_MARKETDATA_SUBSCRIBER_STATUS_FORM_SUBMIT'),

	SHOW_CHANGE_MARKETDATA_SUBSCRIPTIONS_MODAL: 'PROFILE/SHOW_CHANGE_MARKETDATA_SUBSCRIPTIONS_MODAL',
	showChangeMarketDataSubscriptionsModal: () => ({
		type: actions.SHOW_CHANGE_MARKETDATA_SUBSCRIPTIONS_MODAL,
	}),
	changeMarketDataSubscriptionsForm: createFormAction('PROFILE/CHANGE_MARKETDATA_SUBSCRIPTIONS_FORM_SUBMIT'),

	SHOW_ENABLE_THIRD_PARTY_MARKETDATA_MODAL: 'PROFILE/SHOW_ENABLE_THIRD_PARTY_MARKETDATA_MODAL',
	showEnableThirdPartyMarketDataModal: () => ({
		type: actions.SHOW_ENABLE_THIRD_PARTY_MARKETDATA_MODAL,
	}),
	enableThirdPartyMarketDataForm: createFormAction('PROFILE/ENABLE_THIRD_PARTY_MARKETDATA_FORM_SUBMIT'),


	SHOW_CHANGE_DEPENDENTS_MODAL: 'PROFILE/SHOW_CHANGE_DEPENDENTS_MODAL',

	showChangeDependentsModal: () => ({
		type: actions.SHOW_CHANGE_DEPENDENTS_MODAL,
	}),


	CHANGE_DEPENDENTS_FORM_SUBMIT_REQUEST: changeProfileDependentsFormSubmitActionCreator.REQUEST,
	CHANGE_DEPENDENTS_FORM_SUBMIT_SUCCESS: changeProfileDependentsFormSubmitActionCreator.SUCCESS,
	CHANGE_DEPENDENTS_FORM_SUBMIT_FAILURE: changeProfileDependentsFormSubmitActionCreator.FAILURE,

	changeProfileDependentsFormSubmitHandler: changeProfileDependentsFormSubmitActionCreator,
	changeProfileDependentsFormRequest: (...args) =>
		changeProfileDependentsFormSubmitActionCreator.request({ ...args }),
	changeProfileDependentsFormSuccess: payload =>
		changeProfileDependentsFormSubmitActionCreator.success(payload),
	changeProfileDependentsFormFailure: error => changeProfileDependentsFormSubmitActionCreator.failure(error),

	SHOW_CHANGE_EMPLOYMENT_MODAL: 'PROFILE/SHOW_CHANGE_EMPLOYMENT_MODAL',

	showChangeEmploymentModal: profile => ({
		type: actions.SHOW_CHANGE_EMPLOYMENT_MODAL,
		payload: profile,
	}),


	CHANGE_EMPLOYMENT_FORM_SUBMIT_REQUEST: changeProfileEmploymentFormSubmitActionCreator.REQUEST,
	CHANGE_EMPLOYMENT_FORM_SUBMIT_SUCCESS: changeProfileEmploymentFormSubmitActionCreator.SUCCESS,
	CHANGE_EMPLOYMENT_FORM_SUBMIT_FAILURE: changeProfileEmploymentFormSubmitActionCreator.FAILURE,

	changeProfileEmploymentFormSubmitHandler: changeProfileEmploymentFormSubmitActionCreator,
	changeProfileEmploymentFormRequest: (...args) =>
		changeProfileEmploymentFormSubmitActionCreator.request({ ...args }),
	changeProfileEmploymentFormSuccess: payload =>
		changeProfileEmploymentFormSubmitActionCreator.success(payload),
	changeProfileEmploymentFormFailure: error => changeProfileEmploymentFormSubmitActionCreator.failure(error),

	SHOW_CHANGE_PHONE_MODAL: 'PROFILE/SHOW_CHANGE_PHONE_MODAL',

	showChangePhoneModal: () => ({
		type: actions.SHOW_CHANGE_PHONE_MODAL,
	}),

	CHANGE_PHONE_FORM_SUBMIT_REQUEST: changeProfilePhoneFormActionCreator.REQUEST,
	CHANGE_PHONE_FORM_SUBMIT_SUCCESS: changeProfilePhoneFormActionCreator.SUCCESS,
	CHANGE_PHONE_FORM_SUBMIT_FAILURE: changeProfilePhoneFormActionCreator.FAILURE,

	changeProfilePhoneFormSubmitHandler: changeProfilePhoneFormActionCreator,
	changeProfilePhoneFormRequest: (...args) =>
		changeProfilePhoneFormActionCreator.request({ ...args }),
	changeProfilePhoneFormSuccess: payload =>
		changeProfilePhoneFormActionCreator.success(payload),
	changeProfilePhoneFormFailure: error => changeProfilePhoneFormActionCreator.failure(error),

	HIDE_CONFIRM_EMAIL_MODAL: 'PROFILE/HIDE_CONFIRM_EMAIL_MODAL',

	hideConfirmEmailModal: () => ({
		type: actions.HIDE_CONFIRM_EMAIL_MODAL,
	}),

	confirmEmailGetUrl: createApiActions('PROFILE/CONFIRM_EMAIL_GET_URL'),

	getCountries: createApiActions('PROFILE/GET_COUNTRIES'),
	getUsaStates: createApiActions('PROFILE/GET_USA_STATES'),

	HIDE_PROFILE_MODAL: 'PROFILE/HIDE_PROFILE_MODAL',
	hideProfileModal: modalId => ({
		type: actions.HIDE_PROFILE_MODAL,
		payload: modalId,
	}),

	SHOW_DEMO_ACCOUNT_EXPIRATION_MODAL: 'PROFILE/SHOW_DEMO_ACCOUNT_EXPIRATION_MODAL',

	showDemoAccountExpirationModal: () => ({
		type: actions.SHOW_DEMO_ACCOUNT_EXPIRATION_MODAL,
	}),
};

export default actions;
