import axios from 'axios';
import { env } from '@config';
import { globalState } from '@global';

export const provider = axios.create({
	baseURL: env.backendUrl,
	withCredentials: true,
	headers: {
		'X-Requested-With': 'XMLHttpRequest',
	},
});

export const providerWithAuth = () => {
	const headers = {
		'X-Requested-With': 'XMLHttpRequest',
	};

	if (!env.useLocalAuth) {
		headers.Authorization = globalState.localToken;
	}

	return axios.create({
		baseURL: env.backendUrl,
		withCredentials: true,
		headers,
	});
};

export const providerFileWithAuth = () => { 
	const headers = {
		'X-Requested-With': 'XMLHttpRequest',
	};

	if (!env.useLocalAuth) {
		headers.Authorization = globalState.localToken;
	}

	return axios.create({
		baseURL: env.backendUrl,
		withCredentials: true,
		responseType: 'blob',
		headers,
	});
};

