import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';

import {
	FundItem,
	MessageItem,
	TradeItem,
} from './components';

const styles = theme => ({
	root: {
	},
});

class Item extends React.Component {
	render() {
		const {
			item,
			classes,
		} = this.props;

		let ItemComponent;

		switch (item.AlertType) {
		case 'fund':
			ItemComponent = FundItem;
			break;

		case 'trade':
			ItemComponent = TradeItem;
			break;

		default:
			ItemComponent = MessageItem;
		}

		return <ItemComponent item={item} />;
	}
}

export default compose(withStyles(styles))(Item);
