import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ModalPopups } from '@enums';
import { userAccounts } from '@redux';
import { useSelector } from 'react-redux';
import {
	ErrorMessage,
	ProgressContainer,
	SuccessMessage,
} from '@components';
import { RequireSignatureModal } from '../../..';
import ChangeMarketDataSubscriptionsForm from './components/ChangeMarketDataSubscriptionsForm';

const mapStateToProps = (state) => {
	const accountInfo = userAccounts.selectors.accountInfo(state);
	const currentAccountData = userAccounts.selectors.currentAccountData(state) || {};
	const isClosed = !!(currentAccountData.baseParams && currentAccountData.baseParams.IsClosed);
	return {
		isClosed,
		loading: accountInfo.loading || currentAccountData.submitting,
		pendingOrder: !accountInfo.loading && accountInfo.PendingOrders.MarketDataSubscription,
	};
};


const ChangeMarketDataSubscriptionsModal = (props) => {
	const [submitSucceeded, setSubmitSucceeded] = useState(false);
	const { onClose } = props;
	const {
		isClosed,
		pendingOrder,
		loading,
	} = useSelector(mapStateToProps);

	const OnSuccess = () => setSubmitSucceeded(true);

	return (
		<RequireSignatureModal
			title="Change Market Data Subscriptions"
			onClose={onClose}
			bgColor="#f9fcfd"
			textColor="#345464"
			className={`QA-${ModalPopups.CHANGE_MARKETDATA_SUBSCRIBER_STATUS_MODAL}`}
		>
			{submitSucceeded &&
				<SuccessMessage
					onClick={onClose}
					text="Your request to change market data subscriptions is processed."
				/>}
			{!submitSucceeded && !loading && pendingOrder &&
				<ErrorMessage
					onClick={onClose}
					text="Another request for market data subscriptions change is in progress. Please try again later."
				/>
			}
			{!submitSucceeded && !loading && isClosed &&
				<ErrorMessage
					onClick={onClose}
					text="Not available for closed account."
				/>
			}
			{!submitSucceeded && !pendingOrder && !isClosed &&
				<ProgressContainer submitting={loading} disableCheckChildren>
					<ChangeMarketDataSubscriptionsForm OnSuccess={OnSuccess} />
				</ProgressContainer>
			}
		</RequireSignatureModal>
	);
}

ChangeMarketDataSubscriptionsModal.propTypes = {
	onClose: PropTypes.func.isRequired,
};

export default ChangeMarketDataSubscriptionsModal;
