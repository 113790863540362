import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { env } from '@config';
import {
	A,
	Button,
} from '@components';
import { Modal } from '@dev2t/react-ui-kit/src/components';
import { ModalPopups } from '@enums';
import { changeHashInUrl } from '@global';
import {
	depositFunds,
	profile,
	signature,
	tradePlatforms,
	userAccounts,
} from '@redux';

const styles = theme => ({
	root: {
		display: 'block',
		position: 'relative',
	},
	progress: {
		display: 'flex',
		justifyContent: 'center',
	},
	title: {
		...theme.customstyles.subheading,
		color: theme.palette.text.primary,
	},
	body: {
		...theme.customstyles.body,
		color: theme.palette.text.primary,
	},
	date: {
		...theme.customstyles.body,
		color: theme.palette.text.secondary,
	},
	button: {
		paddingTop: '12px',
	},
});

const mapStateToProps = state => ({
	currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
	currentAccount: userAccounts.selectors.currentAccount(state),
	currentClearer: userAccounts.selectors.currentClearer(state),
	accounts: userAccounts.selectors.accounts(state),
	linkedAccounts: userAccounts.selectors.linkedAccounts(state),
	platforms: userAccounts.selectors.platforms(state),
	userInfo: userAccounts.selectors.userInfo(state),
	userProfile: profile.selectors.profile(state),
});

const mapDispatchToProps = (dispatch, props) => ({
	actions: {
		// showJ2tLiteModal: () => dispatch(tradePlatforms.actions.showJ2tLiteModal()),
		showJ2tPlusModal: () => dispatch(tradePlatforms.actions.showJ2tPlusModal()),
		showJ2tProModal: () => dispatch(tradePlatforms.actions.showJ2tProModal()),
		showStProModal: () => dispatch(tradePlatforms.actions.showStProModal()),
		showDepositFundsWireModal: bankAccount =>
			dispatch(depositFunds.actions.showDepositFundsWireModal(bankAccount)),
		showDepositFundsModal: (bankAccount, tradeAccount) =>
			dispatch(depositFunds.actions.showDepositFundsModal(bankAccount, tradeAccount)),
	},
});

class WelcomePageModal extends React.Component {

	showDepositWireModal = () => {
		this.props.actions.showDepositFundsWireModal();
	}

	redirectModal = (from, to) => {
		this.props.history.push(changeHashInUrl(this.props.location, from, to));
	}

	showDepositAchModal = () => {
		const {
			currentTradeAccount,
			linkedAccounts,
		} = this.props;

		const achBankAccount = linkedAccounts && linkedAccounts.find(it => it.Type === 'ACH');
		if (achBankAccount) {
			this.props.actions.showDepositFundsModal(achBankAccount, currentTradeAccount);
		} else {
			this.redirectModal(ModalPopups.PLAID_SELECT_ACCOUNT, ModalPopups.ADD_BANK_ACCOUNT);
		}
	}

	render() {
		const {
			classes,
			onClose,
			accounts,
			currentAccount,
			currentClearer,
			userInfo,
			userProfile,
			platforms: {
				ActiveTradePlatform,
				TradeUrl,
			},
			actions: {
				showJ2tPlusModal,
				showJ2tProModal,
				showStProModal,
			},
		} = this.props;

		let tradingAction = '';
		if (ActiveTradePlatform === 'Transaq') tradingAction = showJ2tProModal;
		if (ActiveTradePlatform === 'Just2Trade') tradingAction = showJ2tPlusModal;
		if (ActiveTradePlatform === 'Sterling') tradingAction = showStProModal;

		const accCurrent = accounts.find(it =>
			it.TradeCode === currentAccount && it.ClearingFirm === currentClearer);
		const externalGuid = accCurrent ? accCurrent.ExternalGuid : '';

		const onlyOneAccount = accounts.length === 1;
		const hasAccounts = accounts.length > 0;
		const hasInstantTrading = userProfile && userProfile.IsInstantTrading;

		return (
			<Modal
				title="Welcome!"
				onClose={onClose}
				className={`QA-${ModalPopups.WELCOME_PAGE}`}
			>
				{hasInstantTrading &&
					<div>
						<p>Welcome to Lime Trading!</p>
						<p>
							With our <i>&quot;instant trading&quot;</i> feature,
							you can go to trading platform and start trading right now.
						</p>
						<p>
							In the meantime, this is your account management platform, where you can:
						</p>
						<ol>
							<li>Go to and switch between trading platforms;</li>
							<li>Deposit funds to your brokerage account;</li>
							<li>Check out your portfolio, balances and documents;</li>
							<li>Look through your transactions and other important events and notifications.</li>
						</ol>
						<p>
							Contact us in case you have any questions:<a href={`mailto:${env.supportMail}`}>{env.supportMail}</a>.
						</p>
					</div>
				}
				{!hasInstantTrading &&
					<div>
						<p>Dear {userInfo.LongName},</p>
						<p>
							Congratulations, your Lime Trading account {currentAccount} is now open.
						</p>
						{onlyOneAccount &&
						<ol>
							<li>
								Now you need to submit the mandatory documents
								listed <A target="_blank" href={`${env.AnketaSiteUrl}Account/${externalGuid}/Upload`}>here </A>
								(in case if you have not done so yet).
							</li>

							<li>
							You can also fund your account and start trading, our minimum
								requirement is $2,500 and you can use any of the following methods:
								<ul>
									<li><A onClick={this.showDepositWireModal}>Wire Transfer</A></li>
									<li><A onClick={this.showDepositAchModal}>ACH Free domestic bank Transfer</A></li>
								</ul>
							</li>
						</ol>
						}
						{!onlyOneAccount && hasAccounts &&
						<p>
							Now you need to submit the mandatory documents
							listed <A target="_blank" href={`${env.AnketaSiteUrl}Account/${externalGuid}/Upload`}>here </A>
							(in case if you have not done so yet).
						</p>
						}
						<p>
							<i>
								After this you’ll be able to place orders on our trading platforms.
							</i>
						</p>
						<p>
							In the meantime, take advantage and download our mobile applications.
						</p>
					</div>
				}
				{tradingAction &&
					<div className={classes.button}>
						{(ActiveTradePlatform === 'Transaq') &&
							<A target="_blank" href={TradeUrl}>
								<Button
									fullWidth
									variant="contained"
									color="primary"
								>
									Start Trading
								</Button>
							</A>
						}
						{(ActiveTradePlatform !== 'Transaq') &&
							<Button
								fullWidth
								type="submit"
								variant="contained"
								color="primary"
								onClick={tradingAction}
							>
								Start Trading
							</Button>
						}
					</div>
				}
			</Modal>
		);
	}
}

WelcomePageModal.propTypes = {
	classes: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	linkedAccounts: PropTypes.array.isRequired,
};

export default compose(
	withRouter,
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(WelcomePageModal);
