import { createFormAction } from 'redux-form-saga';

const actions = {

	resetByEmail: createFormAction('RESET/RESET_BY_EMAIL'),
	sendConfirmationForResetByEmail: createFormAction('RESET/RESET_CONFIRMATION_CODE_BY_EMAIL'),
	resetPassword: createFormAction('RESET/RESET_PASSWORD'),
	resetByOperator: createFormAction('RESET/RESET_BY_OPERATOR'),
};

export default actions;
