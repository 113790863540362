import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ModalPopups } from '@enums';
import {
	SuccessMessage, ErrorMessage, ProgressContainer
} from '@components';
import { R, Modal } from '@dev2t/react-ui-kit/src/components';
import { ordersCy, userAccounts } from '@redux';
import { useJ2txCommission } from '@hooks';
import { getUSDTProtocol } from '@global';
import { formValueSelector } from 'redux-form';
import {
	useAvailableCurrencies,
	J2TXWithdrawalAmountForm,
	J2TXWithdrawalConfirmForm,
	J2TXWithdrawalSelectMethod,
} from './components';

const J2TX_MT5_WITHDRAWAL_FORM_ID = ModalPopups.J2TX_MT5_WITHDRAWAL;
const J2TXMT5WithdrawalModal = ({ onClose }) => {
	const [step, setStep] = useState('selectmethod');
	const loading = useSelector(state => userAccounts.selectors.loading(state) || userAccounts.selectors.accountInfo(state).loading);

	// commission for form validation
	const EMoneyOut = useSelector(state => formValueSelector(ModalPopups.J2TX_MT5_WITHDRAWAL)(state, 'EMoneyOut'));
	const commission = useJ2txCommission(EMoneyOut?.AccountCurrency, getUSDTProtocol(EMoneyOut?.TargetAccount));

	// provide initial values into the form
	const { tradeCode, clearerFirm } = useSelector(userAccounts.selectors.modals)[ModalPopups.J2TX_MT5_WITHDRAWAL];

	const platform = useSelector(userAccounts.selectors.accounts)
		.find(x => x.TradeCode === tradeCode && x.ClearingFirm === clearerFirm)
		.Platform;

	const initialValues = {
		NonOrderData: {
			ConfirmationCode: null,
			TradeCode: tradeCode,
			ClearingFirm: clearerFirm,
		},
		EMoneyOut: {
			SelectedAccount: `${tradeCode};${platform}`,
			IsAllOut: false,
			IsCrypto: true,
			Urgently: false,
			Summ: 0,
			Currency: undefined,
			AccountCurrency: undefined,
			Comment: '',
			Reason: 'MONEY:OUT:REASON:NEED_MONEY',
			ReasonComment: '',
			SelectTarget: undefined,
			TargetAccount: '',
		},
	};

	const handleGotoPage = page => (result, dispatch, props) => setStep(page);
	const existCurrency = useAvailableCurrencies(tradeCode).length > 0;
	return (
		<Modal
			title={<R id="shared_withdrawal_funds" defaultValue="Withdrawal Funds" />}
			onClose={onClose}
			maxWidth="sm"
		>
			<ProgressContainer submitting={loading}>
				<div>
					{step === 'succeeded'
						&& (
							<SuccessMessage
								onClick={onClose}
								text={<R id="common_crypto_wallet_completed" defaultValue="Withdrawal order completed" />}
							/>
						)}
					{step === 'failed'
						&& (
							<ErrorMessage
								onClick={onClose}
								text={<R id="common_crypto_wallet_failed" defaultValue="Could not complete order" />}
							/>
						)}
					{step === 'selectmethod' && !existCurrency
						&& (
							<ErrorMessage
								onClick={onClose}
								text={(
									<R
										id="withdraw_no_crypto_in_your_ballance"
										defaultValue="There are no withdrawable cryptocurrencies on the selected account"
									/>
								)}
							/>
						)}
					{step === 'selectmethod' && existCurrency
						&& (
							<J2TXWithdrawalSelectMethod
								destroyOnUnmount={false}
								forceUnregisterOnUnmount
								form={J2TX_MT5_WITHDRAWAL_FORM_ID}
								initialValues={initialValues}
								enableReinitialize
								onSubmit={handleGotoPage('amountform')}
								onSuccess={() => setStep('amountform')}
								onFail={() => setStep('failed')}
							/>
						)}
					{step === 'amountform'
						&& (
							<J2TXWithdrawalAmountForm
								// forceUnregisterOnUnmount
								// enableReinitialize
								form={J2TX_MT5_WITHDRAWAL_FORM_ID}
								commission={commission}
								destroyOnUnmount={false}
								onSubmit={handleGotoPage('confirmform')}
								onSuccess={() => setStep('confirmform')}
								onFail={() => setStep('failed')}
							/>
						)}
					{step === 'confirmform'
						&& (
							<J2TXWithdrawalConfirmForm
								// enableReinitialize
								// forceUnregisterOnUnmount
								form={J2TX_MT5_WITHDRAWAL_FORM_ID}
								onSubmit={ordersCy.actions.cyWithdrawalEmoneyForm}
								onSuccess={() => setStep('succeeded')}
								onFail={() => setStep('failed')}
							/>
						)}
				</div>
			</ProgressContainer>
		</Modal>
	);
};

export default J2TXMT5WithdrawalModal;
