import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'recompose/compose';
import { Field, reduxForm } from 'redux-form';
import { Button, R } from '@dev2t/react-ui-kit/src';
import { Input } from '@material-ui/core';
import { twoFactor } from '@redux';
import { connect } from 'react-redux';
import { TotpCodeField } from '@shared';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(() => ({
	input: {
		marginBottom: '20px'
	},
	title: {
		marginBottom: '0px'
	}
}));

const mapStateToProps = state => ({
	initialValues: {
		Secret: twoFactor.selectors.qrCode.secretCode(state)
	}
})

const AuthCodeForm = ({
	invalid,
	pristine,
	submitting,
	handleSubmit,
}) => {
	const classes = useStyles();

	return (
		<form onSubmit={handleSubmit(twoFactor.actions.enableTwoFactor)} >
			<p className={classes.title}>
				<R id="shared_totp_input_title" defaultValue="Enter the 6-digit code from the code-generating app" />
			</p>

			<div className={classes.input} >
				<TotpCodeField name="Code"/>
			</div>

			<Field
				component={Input}
				name="Secret"
				type="hidden"
			/>

			<Button
				fullWidth
				type="submit"
				variant="contained"
				color="primary"
				disabled={invalid || pristine || submitting}
			>
				{submitting 
					? <CircularProgress size={18} />
					: <R id="common_confirm_order" defaultValue="Confirm" />}
			</Button>
		</form>
	);
}

export default compose(
	connect(mapStateToProps),
	reduxForm({
		form: 'enableTwoFactor',
		enableReinitialize: true,
	}),
)(AuthCodeForm);
