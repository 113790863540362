import React from 'react';
import PropTypes from 'prop-types';
import { signature } from '@redux';
import { connect } from 'react-redux';
import { ProgressContainer } from '@components';
import { Modal } from '@dev2t/react-ui-kit/src/components';

const mapStateToProps = state => ({
	loading: signature.selectors.submitting(state),
	requreSignature: !(signature.selectors.HasSignes(state) || signature.selectors.signatureSubmitted(state)),
});
const mapDispatchToProps = dispatch => ({
	actions: {
		startCheckSignature: () => {
			dispatch(signature.actions.requireSignatureAndReturn());
		},
	},
});

class RequireSignatureModal extends React.Component {
	componentDidMount() {
		this.checkSignature();
	}

	componentDidUpdate() {
		this.checkSignature();
	}

	checkSignature = () => {
		const { actions, loading, requreSignature } = this.props;
		if (!loading && requreSignature) {
			actions.startCheckSignature();
		}
	}

	render() {
		const {
			loading,
			children,
			...otherprops
		} = this.props;
		return (
			<Modal {...otherprops}>
				<ProgressContainer submitting={loading} disableCheckChildren>
					{children}
				</ProgressContainer>
			</Modal>
		);
	}
}

RequireSignatureModal.propTypes = {
	actions: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	requreSignature: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequireSignatureModal);
