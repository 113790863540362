import React from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
	reduxForm,
	formValueSelector,
} from 'redux-form';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { R } from '@dev2t/react-ui-kit/src/components';
import { translate } from '@global';
import { constants, env } from '@config';
import { userAccounts, app } from '@redux';
import { Mt4Img, Mt5Img } from '@content/mtLogos';

const styles = theme => ({
	root: {
		position: 'relative',
	},
	row: {
		display: 'flex',
	},
	inputItem: {
		paddingLeft: '50px',
	},
	logo: {
		padding: '16px 16px 4px 16px',
		position: 'relative',
		width: '80px',
		height: '44px',
	},
	inputContainer: {
		display: 'flex',
		width: '100%',
		...theme.customstyles.title,
	},
	account: {
		width: '100%',
		textAlign: 'left',
	},
	button: {
		...theme.mixins.stickyButton,
	},
	methodsContainer: {
		marginBottom: '24px',
	},
	methodItem: {
		height: '100%',
		textAlign: 'center',
		padding: '20px',
		border: '2px solid #deeaef',
		borderRadius: '8px',
		'&:hover': {
			boxShadow: '0 5px 10px 0 rgba(61, 65, 71, 0.16)',
			borderWidth: '2px',
			transform: 'translate(0, 1.5px) scale(1.01)',
		},
	},
	center: {
		textAlign: 'center',
	},
	methodItemSelected: {
		borderColor: theme.palette.text.secondary,
	},
	methodImg: {
		maxWidth: '120px',
		height: '80px',
		[theme.breakpoints.up('sm')]: {
			height: '120px',
		},
	},
	methodImgBW: {
		filter: 'grayscale(100%)',
		maxWidth: '120px',
		height: '80px',
		[theme.breakpoints.up('sm')]: {
			height: '120px',
		},
	},
	buttonCenter: {
		margin: '0 auto',
		width: '100px',
		display: 'block',
	},
});

const mapStateToProps = (state, ownProps) => {
	const currentTradeAccount = userAccounts.selectors.currentTradeAccount(state);

	const language = app.selectors.language(state);

	const maxLeverage = [constants.LIME_LME, constants.FIRM_J2TX].some(x => x === env.firm) ? 500 : 200;
	const defaulteverage = [constants.LIME_LME, constants.FIRM_J2TX].some(x => x === env.firm) ? 500 : 100;

	const initialValues = {
		ClientData: {
			// ConfirmationCode: null,
			TradeCode: currentTradeAccount.tradeCode,
			ClearingFirm: currentTradeAccount.clearerFirm,

		},
		OpenDemoAccountOut: {
			SelectedPlatform: 'MT4',
			SelectedType: '',
			SelectedLevarage: defaulteverage,
			SelectedBalance: 10000,
			MaxLeverage: maxLeverage,
		},
	};
	const OpenDemoAccountOut = formValueSelector(ownProps.form)(state, 'OpenDemoAccountOut') || initialValues.OpenDemoAccountOut;
	return {
		initialValues,
		OpenDemoAccountOut,
		currentTradeAccount,
		language,
	};
};
const getMethods = () => [
	{
		SelectedPlatform: 'MT4',
		Title: translate('common_open_account_mt4_title', 'MetaTrader 4'),
		Img: Mt4Img,
		Enable: true,
	},
	{
		SelectedPlatform: 'MT5',
		Title: translate('common_open_account_mt5_title', 'MetaTrader 5'),
		Img: Mt5Img,
		Enable: false,
	},
];

class CYOpenDemoAccountChoosePlatform extends React.Component {
	static validate(formvalues, props) {
		const values = formvalues;
		const errors = {};

		if (!values) {
			return errors;
		}

		if (values.OpenDemoAccountOut) {
			errors.OpenDemoAccountOut = {};

			if (!values.OpenDemoAccountOut.SelectedPlatform) {
				errors.OpenDemoAccountOut.SelectedPlatform = 'required';
			}
		}

		return errors;
	}
	selectMethod = (m) => {
		const { OpenDemoAccountOut, change } = this.props;
		change('OpenDemoAccountOut', {
			...OpenDemoAccountOut,
			SelectedPlatform: m.SelectedPlatform,
		});
	};

	render() {
		const {
			classes,
			fromUserAccount,
			handleSubmit,
			submitSucceeded,
			currentTradeAccount,
			theme,
			values,
			OpenDemoAccountOut,
			...props
		} = this.props;

		return (
			<form
				onSubmit={handleSubmit}
				className={classes.root}
			>
				<div className={classes.methodsContainer}>
					<Grid container spacing={3}>
						{getMethods().map(m => (
							<Grid item xs={6} key={m.SelectedPlatform} disabled={!m.Enable}>
								<div
									className={classNames({
										[classes.methodItem]: true,
										[classes.methodItemSelected]: OpenDemoAccountOut.SelectedPlatform === m.SelectedPlatform,
									})}
									onClick={() => {
										if (m.Enable) {
											this.selectMethod(m);
											handleSubmit();
										}
									}}
								>
									<img src={m.Img} className={m.Enable ? classes.methodImg : classes.methodImgBW} />
									<div className={classes.methodTitle}><h2>{m.Enable ? m.Title : <R id="open_account_platform_coming_soon" translateParameters={{ title: m.Title }} defaultValue="{title} coming soon" />}</h2></div>

								</div>
							</Grid>

						))}
					</Grid>
				</div>
			</form>
		);
	}
}

CYOpenDemoAccountChoosePlatform.propTypes = {
	classes: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	withTheme,
	connect(mapStateToProps, null),
	reduxForm({
		validate: CYOpenDemoAccountChoosePlatform.validate,
	}),
)(CYOpenDemoAccountChoosePlatform);
