import actions from './actions';
import { CaptchaSteps } from '@enums';

const initialState = {
	imageBase64: null,
    id: null,
    value: '',
    step: CaptchaSteps.INITIAL,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
        case actions.reset.REQUEST:
            return {
                ...state,
                step: CaptchaSteps.LOADING_IMAGE,
                value: '',
            };
        case actions.reset.SUCCESS:
            return {
                ...state,
                step: CaptchaSteps.WAITING_FOR_INPUT,
                imageBase64: payload.response.Base64Captcha,
                id: payload.response.Id,
                value: '',
            };
        case actions.reset.FAILURE:
            return {
                ...state,
                step: CaptchaSteps.LOADING_IMAGE_FAILURE,
                imageBase64: null,
                value: '',
            };

        case actions.validate.REQUEST:
            return {
                ...state,
                step: CaptchaSteps.VALIDATING,
            };

        case actions.SET_CAPTCHA_SUCCESS:
            return {
                ...state,
                step: CaptchaSteps.VALIDATING_SUCCESS,
            };

        case actions.SET_CAPTCHA_INPUT:
            return {
                ...state,
                value: payload.value,
            };

        default:
            return state;
	}
};
