import { fork, takeEvery } from 'redux-saga/effects';
import { BaseGetSagaHandler } from '@redux';
import { getCryptocurrencyRates as getCryptocurrencyRatesApi, 
	getStakingRates as getStakingRatesApi
} from '@api';
import actions from './actions';

function* getCryptocurrencyRates({ payload }) {
	yield BaseGetSagaHandler({
		apiMethod: getCryptocurrencyRatesApi,
		errorText: 'Cryptocurrency rates',
		handler: actions.getCryptocurrencyRates,
	}, { payload });
}

function* getStakingRates() {
	yield BaseGetSagaHandler({
		apiMethod: getStakingRatesApi,
		errorText: 'Staking rates',
		handler: actions.getStakingRates,
	}, {});
}

// Watchers
function* watchGetCryptocurrencyRates() {
	yield takeEvery(actions.getCryptocurrencyRates.REQUEST, getCryptocurrencyRates);
}
function* watchGetStakingRates() {
	yield takeEvery(actions.getStakingRates.REQUEST, getStakingRates);
}

// Root saga
const rootSaga = [
	fork(watchGetCryptocurrencyRates),
	fork(watchGetStakingRates),
];

export default {
	rootSaga,
};
