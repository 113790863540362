import { Platform } from "../../../../../../../../utils/platforms";

const OpenAccountOutBase = {
    SelectedLevarage: 500,
	SelectedCurrency: 'USD',
	MaxLeverage: 2000,
}

const OpenAccountOutMt4 = {
    ...OpenAccountOutBase,
	SelectedPlatform: Platform.MT4,
	SelectedType: 'ForexStandart',
}

const OpenAccountOutMt5 = {
    ...OpenAccountOutBase,
	SelectedPlatform: Platform.MT5,
	SelectedType: 'MMA2',
}

export const getOpenAccountOutConfiguration = (platform) => {
    switch (platform) {
        case Platform.MT5:
            return OpenAccountOutMt5;
        case Platform.MT4:
        default:
            return OpenAccountOutMt4;
    }
}