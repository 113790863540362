import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SvgIcon } from '@components';
import PropTypes from 'prop-types';


const styles = theme => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	button: {
		padding: '13px',
	},
	showLess: {
		float: 'left',
	},
});

class ShowMore extends React.Component {
	render() {
		const {
			theme,
			classes,
			submitting,
			onClick,
			onShowLessClick,
			showMoreButton,
			showLessButton,
			color,
			htmlColor
		} = this.props;

		if (!showLessButton && !showMoreButton) {
			return null;
		}

		return (
			<div className={classes.root}>
				{showLessButton && onShowLessClick &&
					<Button className={classes.showLess} onClick={onShowLessClick}>Show Less</Button>
				}
				{showMoreButton && submitting &&
					<div className={classes.button}>
						<CircularProgress
							style={{ color: theme.palette.primary.main }}
							size={18}
						/>
					</div>
				}
				{showMoreButton && !submitting &&
					<IconButton onClick={onClick}>
						<SvgIcon icon="More" color={color} htmlColor={htmlColor}/>
					</IconButton>
				}
			</div>
		);
	}
}

ShowMore.propTypes = {
	theme: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	submitting: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
	showMoreButton: PropTypes.bool,
	showLessButton: PropTypes.bool,
	onShowLessClick: PropTypes.func,
};

ShowMore.defaultProps = {
	submitting: false,
	showMoreButton: true,
	showLessButton: false,
	onShowLessClick: null,
};

export default compose(
	withTheme,
	withStyles(styles),
)(ShowMore);
