import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { BankLogo } from '@components';
import { toUsaMoneyFormat } from '@global';
import moment from 'moment';
import classNames from 'classnames';

const styles = theme => ({
	root: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	leftColumn: {
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'left',
		justifyContent: 'left',
	},
	rightColumn: {
		paddingRight: '2px',
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'center',
		justifyContent: 'center',
	},
	title: {
		...theme.customstyles.body,
		color: theme.palette.text.primary,
	},
	date: {
		...theme.customstyles.body,
		color: theme.palette.text.secondary,
	},
	amount: {
		marginLeft: '5px',
	},
	red: {
		color: theme.palette.error.main,
	},
	green: {
		color: theme.palette.success.main,
	},
	black: {
		color: theme.palette.text.primary,
	},
	grey: {
		color: theme.palette.text.secondary,
	},
});

class FundItem extends React.Component {
	render() {
		const {
			item,
			classes,
		} = this.props;

		const colorCssClass = classNames({
			[classes.red]: item.Color === 'red',
			[classes.green]: item.Color === 'green',
			[classes.black]: item.Color === 'black',
			[classes.grey]: item.Color === 'gray',
			[classes.amount]: true,
		});

		let directionText;

		switch (item.Direction) {
		case 'deposit':
			directionText = 'Deposit';
			break;

		case 'withdrawal':
			directionText = 'Withdraw';
			break;

		default:
			directionText = 'Unknown';
		}

		return (
			<div className={classes.root}>
				<div className={classes.leftColumn}>
					<div className={classes.title}>
						{directionText}
						<span className={colorCssClass}>
							{toUsaMoneyFormat(Math.abs(item.Amount))}
						</span>
					</div>
					<div className={classes.date}>
						{moment.utc(item.OrderDate).format('D MMM YYYY')}
					</div>
				</div>
				<div className={classes.rightColumn}>
					<BankLogo bankAccount={{}} />
				</div>
			</div>
		);
	}
}

FundItem.propTypes = {
	item: PropTypes.object.isRequired,
};

export default compose(withStyles(styles))(FundItem);
