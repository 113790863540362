import React from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { dashboard } from '@redux';
import { scrollToTop } from '@global';
import { template } from '@templates';
import { constants, env } from '@config';

const styles = theme => ({
	root: {
		backgroundColor: theme.palette.text.primary,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		cursor: 'pointer',
		minHeight: '176px',
		padding: '50px 10px 10px 24px',
		'& > div:first-child, & > div:nth-child(2)': {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
	},
	rootLme: {
		backgroundImage: `url(${template.bgLogo}), linear-gradient(-170deg, #1A96FF 0%, #0970C4 93%)`,
		backgroundSize: '125%',
	},
	rootJ2tx: {
		backgroundImage: `url(${template.bgLogo}), linear-gradient(-170deg, #2b4353 0%, #000 93%)`,
		backgroundSize: '125%',
	},
	rootNsr: {
		backgroundImage: `url(${template.bgLogo}), linear-gradient(-170deg, #5F0EA9  0%, #4C068B 93%)`,
	},
	rootDefaultImg: {
		backgroundImage: `url(${template.bgLogo}), linear-gradient(-170deg, #009A63	0%, #287233 93%)`,
	},
});

const mapDispatchToProps = dispatch => ({
	actions: {
		setCurrentPage: page => dispatch(push(page)),
		sidebarClose: () => dispatch(dashboard.actions.sidebarClose()),
	},
});

class SidebarLogo extends React.Component {
	showPage = path => () => {
		const { actions } = this.props;
		actions.setCurrentPage(path);
		scrollToTop();
		actions.sidebarClose();
	};

	render() {
		const { classes } = this.props;
		return (
			<div
				className={classNames(classes.root, {
					[classes.rootLme]: env.firm === constants.LIME_LME,
					[classes.rootJ2tx]: env.firm === constants.FIRM_J2TX,
					[classes.rootNsr]: env.firm === constants.LIME_NSR,
					[classes.rootDefaultImg]: [constants.LIME_NSR, constants.LIME_LME, constants.FIRM_J2TX].every(x => x !== env.firm),
				})}
				onClick={this.showPage('/dashboard')}
			>
			</div>
		);
	}
}

SidebarLogo.propTypes = {
	classes: PropTypes.object.isRequired,
	actions: PropTypes.shape({
		setCurrentPage: PropTypes.func.isRequired,
		sidebarClose: PropTypes.func.isRequired,
	}).isRequired,
};

export default compose(
	withStyles(styles),
	connect(null, mapDispatchToProps)
)(SidebarLogo);
