import { Button, R } from '@dev2t/react-ui-kit/src';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { userAccounts } from '@redux';
import { RequireConfirmationCodeItem, RequireTotpConfirmationCodeItem } from '@shared';
import React from 'react';
import { useSelector } from 'react-redux';

const ConfirmButton = ({
	disabled,
	submitting,
}) => {
	const theme = useTheme();
	return (
		<Button
			fullWidth
			type="submit"
			variant="contained"
			color="primary"
			disabled={disabled}
		>
			{submitting
				? (
					<CircularProgress
						style={{ color: theme.palette.primary.contrastText }}
						size={18}
					/>
				)
				: <R id="common_confirm_order" defaultValue="Confirm" />}
		</Button>
	)
}

const useStyles = makeStyles({
	button: {
		paddingTop: '24px',
	}
});

const RequireTotpOrSmsOrderConfirmationCodeItem = ({
	name,
	submitting,
	customClasses,
	submitDisabled,
	reason,
}) => {

	const classes = useStyles();

	const isTotpEnabled = useSelector(userAccounts.selectors.userInfo)?.IsTotpEnabled;

	if (isTotpEnabled) {
		return (
			<div className={customClasses.inputItem}>
				<RequireTotpConfirmationCodeItem
					name={name}
					disabled={submitting} />
				<div className={classes.button}>
					<ConfirmButton 
						submitting={submitting}
						disabled={submitting || submitDisabled}/>
				</div>
			</div>
		)
	}

	return (
		<RequireConfirmationCodeItem
			customClasses={customClasses}
			name={name}
			buttonTitle={<R id="common_send_button_order" defaultValue="Send" />}
			reason={reason}
			disabled={submitting || submitDisabled}
		>
			<ConfirmButton
				disabled={submitting || submitDisabled}
				submitting={submitting}/>
		</RequireConfirmationCodeItem>
	);
}

export default RequireTotpOrSmsOrderConfirmationCodeItem;