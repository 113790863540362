import actions from './actions';
import authActions from '../auth/actions';

const initialState = {
	openModalData: {
		account: null,
		guid: null,
		personFullName: null,
	},
	submitting: true,
	HasSignes: true,
	SignesHolders: [],
	signatureSubmitted: false,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
	case actions.SHOW_CREATE_SIGNATURE_MODAL:
		return {
			...state,
			openModalData: {
				account: payload.account,
				guid: payload.guid,
				personFullName: payload.personFullName,
			},
		};
	case actions.HIDE_CREATE_SIGNATURE_MODAL:
		return {
			...state,
			openModalData: initialState.openModalData,
		};

	case actions.SAVE_SIGNATURE_REQUEST:
		return {
			...state,
			signatureSubmitted: false,
			submitting: true,
		};
	case actions.SAVE_SIGNATURE_SUCCESS: {
		return {
			...state,
			signatureSubmitted: true,
		};
	}
	case actions.checkSignatures.SUCCESS:
		return {
			...state,
			submitting: false,
			HasSignes: payload.response.HasSignes,
			SignesHolders: payload.response.SignesHolders,
		};
	case actions.SAVE_SIGNATURE_FAILURE:
		return {
			...state,
			submitting: false,
		};

	case authActions.LOGIN_USER:
		return {
			...initialState,
		};
	case authActions.LOGOUT_SUCCESS:
		return { ...initialState };

	default:
		return state;
	}
};
