import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ModalPopups } from '@enums';
import { userAccounts } from '@redux';
import { CYChangePasswordModal } from './components/ProfileModals/components';

const styles = theme => ({

});

const mapStateToProps = state => ({
	userInfo: userAccounts.selectors.userInfo(state),
});

const mapDispatchToProps = dispatch => ({
	actions: {
		refreshUserInfo: () => dispatch(userAccounts.actions.getUserInfo.request()),
	},
});

class BlockModals extends React.Component {

	render() {
		const {
			actions,
			location,
			userInfo: { 
				NeedChangePass,
			}
		} = this.props;

		return (
			<div>
				{NeedChangePass && 
					<CYChangePasswordModal
						key={`${ModalPopups.CY_CHANGE_PASSWORD}Modal`}
						onSuccess={actions.refreshUserInfo}
					/>
				}
			</div>
		);
	}
}

BlockModals.propTypes = {
	actions: PropTypes.object.isRequired,
};

export default compose(
	withRouter,
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(BlockModals);
