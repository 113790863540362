import React from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { dashboard, userAccounts, app } from '@redux';
import { Select, SvgIcon } from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';
import { scrollToTop, toUsaMoneyFormat } from '@global';
import MenuItem from '@material-ui/core/MenuItem';
import { template } from '@templates';
import { constants, env } from '@config';

const styles = theme => ({
	root: {
		backgroundColor: theme.palette.text.primary,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		cursor: 'pointer',
		// backgroundSize: '125%',
		minHeight: '176px',
		padding: '50px 10px 10px 24px',
		'& > div:first-child, & > div:nth-child(2)': {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
	},
	rootLme: {
		backgroundImage: `url(${template.bgLogo}), linear-gradient(-170deg, #1A96FF 0%, #0970C4 93%)`,
		backgroundSize: '125%',
	},
	rootJ2tx: {
		backgroundImage: `url(${template.bgLogo}), linear-gradient(-170deg, #2b4353 0%, #000 93%)`,
		backgroundSize: '125%',
	},
	rootNsr: {
		backgroundImage: `url(${template.bgLogo}), linear-gradient(-170deg, #5F0EA9  0%, #4C068B 93%)`,
	},
	rootDefaultImg: {
		backgroundImage: `url(${template.bgLogo}), linear-gradient(-170deg, #009A63	0%, #287233 93%)`,
	},
	select: {},
	title: {
		...theme.customstyles.body,
		color: theme.palette.common.white,
	},
	equity: {
		...theme.customstyles.headlineSm,
		color: theme.palette.common.white,
	},
	currentAccountContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		cursor: 'pointer',
		[theme.breakpoints.up('sm')]: {
			marginRight: '-12px',
		},
	},
	currentAccount: {
		...theme.customstyles.body,
		color: theme.palette.common.white,
		display: 'inline-block',
	},
	selectButton: {
		...theme.customstyles.body,
		color: theme.palette.common.white,
		stroke: theme.palette.common.white,
	},
	specialMenuItem: {
		fontSize: '14px',
		color: theme.palette.text.secondary,
	},
	accountCloseDemoText: {
		...theme.customstyles.body,
		color: '#ff6567',
		marginLeft: '50px',
		fontSize: '1rem',
	},
	closedAccountLabelText: {
		...theme.customstyles.body,
		color: '#ff6567',
		fontWeight: '500',
		fontSize: '22px',
	},
	splitterTop: {
		borderTop: '1px solid #deeaef',
	},
	splitterBottom: {
		borderBottom: '1px solid #deeaef',
	},
	refreshButton: {
		color: theme.palette.common.white,
		[theme.breakpoints.up('sm')]: {
			marginRight: '-12px',
		},
	},
	lightToolTip: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
		fontSize: 11,
	},
});

const mapDispatchToProps = dispatch => ({
	actions: {
		setCurrentPage: page => dispatch(push(page)),
		setCurrentAccount: account => dispatch(userAccounts.actions.setCurrentAccount(account)),
		sidebarClose: () => dispatch(dashboard.actions.sidebarClose()),
		resetDemoAccount: payload => dispatch(userAccounts.actions.resetDemoAccount.request(payload)),
		showConfirmModal: payload => dispatch(app.actions.showConfirmModal(payload)),
		addDemoAccount: () => dispatch(userAccounts.actions.addDemoAccount.request()),
	},
});

const mapStateToProps = state => ({
	accounts: userAccounts.selectors.accounts(state).filter(a => !a.IsDemo),
	currentAccountData: userAccounts.selectors.currentAccountData(state),
	currentAccount: userAccounts.selectors.currentAccount(state),
	currentAccountDisplayFullName: userAccounts.selectors.currentAccountDisplayFullName(state),
	currentAccountIsDemo: userAccounts.selectors.currentAccountIsDemo(state),
	loading: userAccounts.selectors.loading(state),
	hasDemoAccount: userAccounts.selectors.hasDemoAccount(state),
});

const TotalAccoountValue = () => {
	const accounts = useSelector(userAccounts.selectors.accounts);
	const currentAccountData = useSelector(userAccounts.selectors.currentAccountData);
	const theme = useTheme();
	const accountListLoading = useSelector(userAccounts.selectors.loading);
	const accountInfo = useSelector(userAccounts.selectors.accountInfo);
	const accountInfoLoading = useSelector(userAccounts.selectors.accountInfoLoading);
	const moneyInfo = useSelector(userAccounts.selectors.moneyInfo);
	const usdMoneyInfo = moneyInfo?.length ? moneyInfo[0] : null;

	if (accountListLoading || accountInfoLoading || (env.firm === constants.LIME_NSR && currentAccountData.submitting)) {
		return <CircularProgress style={{ color: theme.palette.common.white }} size={24} />;
	}

	if (env.firm === constants.FIRM_J2TX && !accounts?.length) {
		return toUsaMoneyFormat(0);
	}

	if (!accountInfo.Success || !accounts?.length) {
		return '';
	}

	if (env.firm === constants.LIME_NSR) {
		if(!usdMoneyInfo || !currentAccountData?.baseParams){
			return <CircularProgress style={{ color: 'inherit' }} size={24} />;
		}
		return usdMoneyInfo && toUsaMoneyFormat(usdMoneyInfo.Balance + (currentAccountData.baseParams.MarketValue ?? 0));
	}

	return usdMoneyInfo && toUsaMoneyFormat(usdMoneyInfo.EquityValue);
};

class AccountSelection extends React.Component {
	dontCloseSelect = false;

	constructor(props) {
		super(props);
		this.state = {
			showClosedAccounts: false,
			isSelectOpened: false,
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSelectOpen = this.handleSelectOpen.bind(this);
	}

	handleChange(event) {
		const { actions, accounts } = this.props;
		const { showClosedAccounts } = this.state;
		if (event.target.value === 'toggleClosedAccounts') {
			this.setState({ showClosedAccounts: !showClosedAccounts });
			this.dontCloseSelect = true;
			return;
		}

		if (event.target.value === 'addDemoAccount') {
			actions.addDemoAccount();
			return;
		}

		const account = accounts.find(acc => acc.TradeCode === event.target.value);
		actions.setCurrentPage('/dashboard');
		actions.setCurrentAccount(account);
		scrollToTop();
		actions.sidebarClose();
	}

	handleSelectOpen(e) {
		const { isSelectOpened } = this.state;
		e.stopPropagation();

		if (this.dontCloseSelect) {
			this.dontCloseSelect = false;
			this.setState({ isSelectOpened: true });
			return;
		}
		this.setState({ isSelectOpened: !isSelectOpened });
	}

	handleresetDemoAccount = () => {
		const { actions, currentAccount, accounts } = this.props;

		const account = accounts.find(acc => acc.TradeCode === currentAccount);

		actions.showConfirmModal({
			text: 'Please confirm if you want to reset demo account',
			action: onEndRequest => actions.resetDemoAccount({ account, onEndRequest }),
		});
	};

	showPage = path => () => {
		const { actions } = this.props;
		actions.setCurrentPage(path);
		scrollToTop();
		actions.sidebarClose();
	};

	render() {
		const {
			currentAccountDisplayFullName,
			currentAccountData: { baseParams },
			currentAccountIsDemo,
			classes,
			loading,
			accounts,
			currentAccount,
		} = this.props;
		const { isSelectOpened, showClosedAccounts } = this.state;
		const closedAccountsExist = !!accounts?.some(x => x.IsClosed);

		return (
			<div
				className={classNames({
					[classes.root]: true,
					[classes.rootLme]: env.firm === constants.LIME_LME,
					[classes.rootJ2tx]: env.firm === constants.FIRM_J2TX,
					[classes.rootNsr]: env.firm === constants.LIME_NSR,
					[classes.rootDefaultImg]: [constants.LIME_NSR, constants.LIME_LME, constants.FIRM_J2TX].every(x => x !== env.firm),
				})}
				onClick={this.showPage('/dashboard')}
			>
				<div className={classes.title}>
					{currentAccountIsDemo ? 'Paper money' : <R id="common_total_account_value" defaultValue="Total Account Value" />}
				</div>
				<div>
					<div className={classNames(classes.equity, 'QA-TotalAccountValue')}>
						<TotalAccoountValue />
					</div>
					{!loading && currentAccountIsDemo && (
						<Tooltip title="Reset account back to $100,000, remove positions." classes={{ tooltip: classes.lightToolTip }}>
							<IconButton className={classes.refreshButton} onClick={this.handleresetDemoAccount}>
								<SvgIcon icon="Refresh" />
							</IconButton>
						</Tooltip>
					)}
				</div>
				{baseParams && baseParams.IsClosed && <div className={classes.closedAccountLabelText}>CLOSED</div>}

				{(loading || accounts?.length > 0) && (
					<div className={classes.currentAccountContainer} onClick={this.handleSelectOpen}>
						<div className={classes.currentAccount}>{currentAccountDisplayFullName}</div>
						<div className={classNames(classes.selectButton, 'QA-AccountListButton')}>
							<IconButton>
								<SvgIcon submitting={loading} icon="ArrowDown" />
							</IconButton>
						</div>
						{!loading && (
							<Select
								value={currentAccount}
								onChange={this.handleChange}
								open={isSelectOpened}
								onOpen={this.handleSelectOpen}
								onClose={() => {}}
								isHidden
							>
								{accounts && accounts.map((account, index) => {
									if (
										!account.IsClosed
										|| currentAccount === account.TradeCode
										|| showClosedAccounts
									) {
										if (account.IsClosed) {
											return (
												<MenuItem value={account.TradeCode} key={index}>
													<p>{account.DisplayFullName || account.TradeCode}</p>
													<p className={classes.accountCloseDemoText}>CLOSED</p>
												</MenuItem>
											);
										}
										return (
											<MenuItem value={account.TradeCode} key={index}>
												{account.DisplayFullName || account.TradeCode}
											</MenuItem>
										);
									}
									return '';
								})}
								{closedAccountsExist && (
									<MenuItem
										value="toggleClosedAccounts"
										key={111}
										className={`${classes.specialMenuItem} ${classes.splitterTop}`}
									>
										{showClosedAccounts ? (
											<R id="common_hide_closed_accounts" defaultValue="Hide closed accounts" />
										) : (
											<R id="common_show_closed_accounts" defaultValue="Show closed accounts" />
										)}
									</MenuItem>
								)}
							</Select>
     )}
					</div>
				)}
			</div>
		);
	}
}

AccountSelection.propTypes = {
	currentAccount: PropTypes.string.isRequired,
	currentAccountDisplayFullName: PropTypes.string.isRequired,
	currentAccountData: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	accounts: PropTypes.array.isRequired,
	currentAccountIsDemo: PropTypes.bool,
	hasDemoAccount: PropTypes.bool.isRequired,
	actions: PropTypes.shape({
		setCurrentAccount: PropTypes.func.isRequired,
		addDemoAccount: PropTypes.func.isRequired,
		setCurrentPage: PropTypes.func.isRequired,
		sidebarClose: PropTypes.func.isRequired,
		showConfirmModal: PropTypes.func.isRequired,
		resetDemoAccount: PropTypes.func.isRequired,
	}).isRequired,
};
AccountSelection.defaultProps = {
	currentAccountIsDemo: false,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps)
)(AccountSelection);
