import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = () => ({
	center: {
		display: 'flex',
		justifyContent: 'center',
	},
});

class LoadableScrollbars extends React.Component {
	componentDidUpdate() {
		const {
			canLoadMore,
			loading,
			onLoadMore,
		} = this.props;

		// fill screen with messages when init
		if (!onLoadMore || loading || !canLoadMore) return;

		const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
		const content = this.contentElm.getBoundingClientRect();
		if (viewportHeight >= content.height) {
			onLoadMore();
		}
	}

	getContentElm = (node) => { this.contentElm = node; }

	handleScroll = (e) => {
		const {
			canLoadMore,
			loading,
			onLoadMore,
		} = this.props;

		if (!onLoadMore || loading || !canLoadMore) return;

		const mobileScrollTop = e.target.scrollTop;
		const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
		const loadingMoreOffset = viewportHeight * 0.1; // за 10% до конца вьюпорта подгружаются дополнительные данные
		const content = this.contentElm.getBoundingClientRect();

		if (viewportHeight + loadingMoreOffset + mobileScrollTop >= content.height) {
			onLoadMore();
		}
	}

	render() {
		const {
			children,
			classes,
			loading,
			canLoadMore,
			onLoadMore,
			...props
		} = this.props;
		return (
			<Scrollbars
				{...props}
				onScroll={this.handleScroll}
			>
				<div ref={this.getContentElm}>
					{children}
					{loading && (
						<div className={classes.center}>
							<CircularProgress size={26} />
						</div>
					)}
				</div>
			</Scrollbars>
		);
	}
}

LoadableScrollbars.propTypes = {
	children: PropTypes.any.isRequired,
	loading: PropTypes.bool,
	canLoadMore: PropTypes.bool.isRequired,
	onLoadMore: PropTypes.func,
};

LoadableScrollbars.defaultProps = {
	onLoadMore: null,
	loading: false,
};

export default withStyles(styles)(LoadableScrollbars);
