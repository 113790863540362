import { useDispatch, useSelector } from 'react-redux';
import { depositFunds } from '@redux';
import { useEffect } from 'react';


export const useAccountFundingInfo = ({ currency, tradeCode }) => {
	const dispatch = useDispatch();
	const fundInfo = useSelector(depositFunds.selectors.getAccountFundingInfo)(tradeCode, currency);
	useEffect(() => {
		if (tradeCode && !fundInfo.value && !fundInfo.loading && !fundInfo.error) {
			dispatch(depositFunds.actions.getAccountFundingInfo.request({ tradeCode, currency }));
		}
	}, [fundInfo, tradeCode, dispatch, currency]);
	return fundInfo;
};
