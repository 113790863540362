import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { R } from '@dev2t/react-ui-kit/src/components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';

const ContactsTable = withStyles({
	root: {
		maxWidth: '360px',
		minWidth: '260px',
		padding: '16px 20px',
		overflow: 'hidden',
	},
})(TableContainer);

const ContactsTableRow = withStyles({
	root: {
		display: 'flex',
		justifyContent: 'space-between',
	},
})(TableRow);

const TypeCell = withStyles({
	root: {
		padding: '16px 0',
		borderBottom: 'none',
	},
})(TableCell);

const ValueCell = withStyles(theme => ({
	root: {
		padding: '16px 0',
		minWidth: '17ch',
		borderBottom: 'none',
		textAlign: 'right',
		'& a': {
			textDecoration: 'none',
			color: theme.palette.primary.main,
		},
	},
}))(TableCell);


const ContactsHeader = withStyles({
	root: {
		height: '3.5rem',
		fontSize: '1.1rem',
		display: 'inline-flex',
		textAlign: 'center',
		alignItems: 'center',
		width: '100%',
		justifyContent: 'center',
	},
})(Typography);


function ContactsCard({ title, email, phone, td }) {
	return (
		<ContactsTable>
			<ContactsHeader variant="h6">
				{title}
			</ContactsHeader>
			<Table aria-label="simple table">
				<TableBody>
					<ContactsTableRow>
						<TypeCell><R id="contacts_label_email" />:</TypeCell>
						<ValueCell><a href={`mailto:${email}`}>{email}</a></ValueCell>
					</ContactsTableRow>
					<ContactsTableRow>
						<TypeCell><R id="contacts_label_phone" />:</TypeCell>
						<ValueCell>{phone}</ValueCell>
					</ContactsTableRow>
					<ContactsTableRow>
						<TypeCell><R id="contacts_label_desk" />:</TypeCell>
						<ValueCell>{td}</ValueCell>
					</ContactsTableRow>
				</TableBody>
			</Table>
		</ContactsTable>
	);
}

ContactsCard.propTypes = {
	title: PropTypes.object.isRequired,
	email: PropTypes.string.isRequired,
	phone: PropTypes.string.isRequired,
	td: PropTypes.string.isRequired,
};

export default ContactsCard;
