import { getLanguageCookie } from '@global';
import actions from './actions';

const getCookieShowDicwords = document.cookie
	.split(';')
	.filter(el => el.includes('show_dicwords_keys'))
	.join('');

const initialState = {
	resolution: 'lg',
	confirmModal: {
		isVisible: false,
		title: null,
		text: null,
		buttonTitle: null,
		action: null,
	},
	language: getLanguageCookie(),
	showDicwordsKeys: getCookieShowDicwords[getCookieShowDicwords.length - 1],
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.SET_RESOLUTION:
			return {
				...state,
				resolution: payload.resolution,
			};
		case actions.SHOW_CONFIRM_MODAL:
			return {
				...state,
				confirmModal: {
					isVisible: true,
					...payload,
				},
			};
		case actions.HIDE_CONFIRM_MODAL:
			return {
				...state,
				confirmModal: {
					isVisible: false,
				},
			};
		case actions.changeLanguage.SUCCESS:
			return {
				...state,
				language: payload.request,
			};
		case actions.SHOW_DICWORDS:
			return {
				...state,
				showDicwordsKeys: true,
			};
		case actions.HIDE_DICWORDS:
			return {
				...state,
				showDicwordsKeys: false,
			};
		default:
			return state;
	}
};
