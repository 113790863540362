import actions from './actions';

const initialState = {
	authWidget: null,
	isTryingToLogIn: false,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
	case actions.AUTH_WIDGET:
		return {
			...state,
			authWidget: payload,
		};
	case actions.LOGIN_USER:
		return {
			...state,
			...payload,
		};
	case actions.LOGOUT:
		return {
			...state,
		};
	case actions.SET_AFTER_AUTH_URL:
		return {
			...state,
			afterAuthUrl: payload,
		};
	case actions.checkAuth.REQUEST:
		return {
			...state,
			isTryingToLogIn: true,
		}
	case actions.checkAuth.SUCCESS:
	case actions.checkAuth.FAILURE:
		return {
			...state,
			isTryingToLogIn: false,
		}
	default:
		return state;
	}
};
