import actions from "./actions";

const initialState = {
	captchaIsRequired: false,
	twoFactorIsRequired: false,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.SET_CAPTCHA_IS_REQUIRED:
			return {
				...state,
				captchaIsRequired: payload.value,
			};
		case actions.SET_TWO_FACTOR_IS_REQUIRED:
			return {
				...state,
				twoFactorIsRequired: payload.value,
			};
		default:
			return state;
	}
};
