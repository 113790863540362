import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';
import SafeComponent from 'react-safe-component';
import { dashboard } from '@redux';
import Drawer from '@material-ui/core/Drawer';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { NotificationsContent } from './components';

const drawerWidth = 284;
const maxWidth = 1440;
const styles = theme => ({
	drawerPaper: {
		width: `${drawerWidth}px`,
		maxWidth: `${drawerWidth}px`,
		height: '100%',
		// paddingLeft: '12px',
		// [theme.breakpoints.up('md')]: {
		// 	position: 'fixed',
		// },
		// [theme.breakpoints.down('lg')]: {
		// 	width: 'calc(284px + ((100vw - 1440px) / 4))',
		// },
		'@media (min-width:1440px)': {
			right: `calc((100% - ${maxWidth}px) / 2)`,
		},
		// borderLeft: '1px solid #deeaef',
		// backgroundColor: '#f9fcfd',
	},
	drawerPaperMobile: {
		height: '100%',
		border: 'none',
		minWidth: '280px',
		maxWidth: '450px',
		width: '100%',
	},
	drawerContainer: {
		width: `${drawerWidth}px`,
		height: '100%',
		position: 'relative',
	},
	drawer: {
		height: '100%',
	},
	icon: {
		width: '72px',
		textAlign: 'center',
	},
});

const mapStateToProps = state => ({
	isNotificationsOpen: dashboard.selectors.isNotificationsOpen(state),
});


const mapDispatchToProps = dispatch => ({
	actions: {
		init: () => dispatch(dashboard.actions.init()),
		notificationsOpen: () => dispatch(dashboard.actions.notificationsOpen()),
		notificationsClose: () => dispatch(dashboard.actions.notificationsClose()),
	},
});

class Notifications extends React.Component {
	closeNotifications = () => {
		this.props.actions.notificationsClose();
	}

	render() {
		const {
			classes,
			actions,
			isNotificationsOpen,
			hidden,
		} = this.props;

		return (
			<div>
				<Hidden lgUp>
					<Drawer
						anchor="right"
						variant="temporary"
						open={isNotificationsOpen}
						onClose={actions.notificationsClose}
						classes={{
							paper: classes.drawerPaperMobile,
						}}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
					>
						<NotificationsContent
							mobileView
							mobileViewOpened={isNotificationsOpen}
							onClose={actions.notificationsClose}
						/>
					</Drawer>
				</Hidden>
				{!hidden &&
					<Hidden mdDown implementation="js">
						<div className={classes.drawerContainer}>
							<div className={classes.drawer}>
								<Drawer
									anchor="right"
									variant="permanent"
									open={isNotificationsOpen}
									classes={{
										paper: classes.drawerPaper,
									}}
								>
									<NotificationsContent />
								</Drawer>
							</div>
						</div>
					</Hidden>
				}
			</div>
		);
	}
}

Notifications.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
	SafeComponent,
)(Notifications);
