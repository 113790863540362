import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { profile, userAccounts } from '@redux';
import { connect } from 'react-redux';
import {
	change,
	formValueSelector,
	reduxForm,
	Field,
} from 'redux-form';
import {
	AgreementField,
	Button,
	FormCheckbox,
} from '@components';
import { env } from '@config';

const styles = theme => ({
	root: {
		display: 'block',
		position: 'relative',
	},
	button: {
		paddingTop: '24px',
	},
	labelText: {
		margin: '18px 0',
	},
	additionalText: {
		...theme.customstyles.body,
		color: theme.palette.text.primary,
	},
	inputSelectItem: {
		marginTop: '10px',
		paddingLeft: '24px',
	},
	anketaLink: {
		color: 'red'
	}
});

const checkDocuments = docs => ['nasdaq', 'nyse', 'otc', 'opra', 'cboe']
	.reduce(
		(prev, cur) => ({ ...prev, [cur]: docs ? docs.some(x => x.DocTypeExt === cur) : false }),
		{},
	);

const mapSubscriptions = mdSubscriptions => mdSubscriptions.reduce((prev, cur) => ({ ...prev, [cur.MarketDataName]: cur.IsOn }), {});

const CHANGE_MARKETDATA_SUBSCRIPTIONS_FORM = 'ChangeMarketDataSubscriptionsForm';
const formSelector = formValueSelector(CHANGE_MARKETDATA_SUBSCRIPTIONS_FORM);
const mapStateToProps = (state) => {

	const userProfile = profile.selectors.profile(state);
	const currentAccountData = userAccounts.selectors.currentAccountData(state) || {};
	const hasDocument = checkDocuments(userProfile.Documents);

	return {
		initialValues: {
			...mapSubscriptions(userProfile.MarketDataSubscriptions),
		},
		subscriptions: userProfile.MarketDataSubscriptions.reduce((prev, cur) => ({ ...prev, [cur.MarketDataName]: cur }), {}),
		hasDocument,
		loading: userProfile.submitting || currentAccountData.submitting,
		EquityValue: currentAccountData.baseParams && currentAccountData.baseParams.EquityValue,
		pendingOrder: !userProfile.loading && userProfile.PendingOrders.MarketDataSubscription,
		getFormValue: fieldName => formSelector(state, fieldName),
		currentAccountIsDemo: userAccounts.selectors.currentAccountIsDemo(state),
	};
};

const mapDispatchToProps = dispatch => ({
	actions: {
		setCtaUtp: () =>
			dispatch(change(CHANGE_MARKETDATA_SUBSCRIPTIONS_FORM, 'CTA_UTP', true)),
	},
});


class ChangeMarketDataSubscriberStatusForm extends React.Component {
	static validate(values, props) {
		const errors = {};
		const { subscriptions, initialValues, EquityValue } = props;

		if (!values.AgreeNyse) {
			errors.AgreeNyse = 'required';
		}
		if (!values.AgreeNasdaq) {
			errors.AgreeNasdaq = 'required';
		}
		if (!values.AgreeCboe) {
			errors.AgreeCboe = 'required';
		}
		if (!values.AgreeOpra) {
			errors.AgreeOpra = 'required';
		}
		if (!values.AgreeOtc) {
			errors.AgreeOtc = 'required';
		}
		// if (values.CTA_UTP && EquityValue < 100 && !subscriptions.CTA_UTP.Waiver === 'VIP') {
		// 	errors.CTA_UTP = 'Minimum account equity of $100 required to activate real-time MD subscription';
		// }
		// else if (values.OTC && !values.CTA_UTP) {
		if (values.OTC && !values.CTA_UTP) {
			errors.CTA_UTP = 'CTA/UTP NMS Equities must be selected';
		}
		else if (values.OPRA && !values.CTA_UTP) {
			errors.CTA_UTP = 'CTA/UTP NMS Equities must be selected';
		}

		// if (values.OTC && EquityValue < 100 && !subscriptions.OTC.Waiver === 'VIP') {
		// 	errors.OTC = 'Minimum account equity of $100 required to activate real-time MD subscription';
		// }
		// if (values.OPRA && EquityValue < 100 && !subscriptions.OPRA.Waiver === 'VIP') {
		// 	errors.OPRA = 'Minimum account equity of $100 required to activate real-time MD subscription';
		// }

		return errors;
	}

	render() {
		const {
			actions,
			classes,
			handleSubmit,
			hasDocument,
			invalid,
			pristine: pristineWithHidden,
			submitting,
			getFormValue,
			initialValues,
			currentAccountIsDemo
		} = this.props;

		const pristine = pristineWithHidden || Object.keys(initialValues).every(x => getFormValue(x) === initialValues[x]);
		return (
			<form
				onSubmit={handleSubmit(profile.actions.changeMarketDataSubscriptionsForm)}
				className={classes.root}
			>
				<div className={classes.inputSelectItem}>
					<Field
						name="CTA_UTP"
						component={FormCheckbox}
						label="CTA/UTP NMS Equities realtime"
						validationMessageMode="insteadOfAdditional"
					/>
					<Field
						name="OTC"
						component={FormCheckbox}
						label="OTC stocks realtime - $6 month"
						validationMessageMode="insteadOfAdditional"
						onChange={isChecked => {
							if (isChecked) {
								actions.setCtaUtp();
							}
						}}
					/>
					<Field
						name="OPRA"
						component={FormCheckbox}
						label="OPRA (options) realtime -  $1.50 month"
						validationMessageMode="insteadOfAdditional"
						onChange={isChecked => {
							if (isChecked) {
								actions.setCtaUtp();
							}
						}}
					/>
				</div>
				<div className={classes.inputSelectItem}>
					{getFormValue('CTA_UTP') && !hasDocument.nasdaq &&
						<AgreementField
							name="AgreeNasdaq"
							href="/static/shared/marketdata/NASDAQdataAgreement.pdf"
							docName="NASDAQ Data Agreement"
						/>
					}
					{getFormValue('CTA_UTP') && !hasDocument.nyse &&
						<AgreementField
							name="AgreeNyse"
							href="/static/shared/marketdata/NYSEMarketDataAgreement.pdf"
							docName="NYSE Market Data Agreement"
						/>
					}
					{getFormValue('CTA_UTP') && !hasDocument.cboe &&
						<AgreementField
							name="AgreeCboe"
							href="/static/shared/marketdata/CBOEMarketDataAgreement.pdf"
							docName="CBOE Market Data Agreement"
						/>
					}
					{getFormValue('OTC') && !hasDocument.otc &&
						<AgreementField
							name="AgreeOtc"
							href="/static/shared/marketdata/OTCMarketDataAgreement.pdf"
							docName="OTC Market Data Agreement"
						/>
					}
					{getFormValue('OPRA') && !hasDocument.opra &&
						<AgreementField
							name="AgreeOpra"
							href="/static/shared/marketdata/OPRAdataAgreement.pdf"
							docName="OPRA Data Agreement"
						/>
					}
				</div>
				<p>
					Please note: Market Data is for private use only. Request for activating/deactivating your MD subscription can be done once a calendar day
				</p>
				<div className={classes.button}>
					<Button
						fullWidth
						type="submit"
						variant="contained"
						color="primary"
						disabled={invalid || submitting || pristine || currentAccountIsDemo}
					>
						{submitting ?
							<CircularProgress
								
								size={18}
							/>
							: 'Change Subscriptions'
						}
					</Button>
				</div>
				{currentAccountIsDemo && 
					<p className={classes.anketaLink}>
						This functionality available for clients only. Please <a target="_blank" href={env.AnketaSiteUrl}>open account</a> to become a client.
					</p>
				}
			</form>
		);
	}
}

ChangeMarketDataSubscriberStatusForm.propTypes = {
	classes: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	invalid: PropTypes.bool.isRequired,
	submitting: PropTypes.bool.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: CHANGE_MARKETDATA_SUBSCRIPTIONS_FORM,
		validate: ChangeMarketDataSubscriberStatusForm.validate,
		touchOnChange: true,
		onSubmitSuccess: (result, dispatch, props) => {
			if (props.OnSuccess) {
				props.OnSuccess();
			}
		},
	}),
)(ChangeMarketDataSubscriberStatusForm);
