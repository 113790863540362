import { env, constants } from '@config';
import { getTheme } from '@dev2t/react-ui-kit/src/config';

const themeName = env.firm === constants.FIRM_J2TX ? "j2tx" : "theme";
const theme = getTheme(themeName);

theme.mixins = {
	...theme.mixins,
	stickyButton: {
		bottom: '45px',
        paddingTop: '15px',
		marginTop: '30px', // transparent back color when margin
		position: 'sticky',
	}
}

theme.overrides = {
    ...theme.overrides,
    MuiMenuItem: {
        root: {
            paddingTop: '12px',
            paddingBottom: '12px',
        },
    },
    MuiListItem: {
        root: {
            '&.Mui-selected': {
                backgroundColor: 'rgba(0, 0, 0, 0.14)',
            },
            '&.Mui-selected:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.14)',
            },
        },
        gutters: {
            [theme.breakpoints.up('sm')]: {
                paddingLeft: '24px',
                paddingRight: '24px',
            },
        },
        button: {
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.08)',
            },
        },
    },
}

export default theme;