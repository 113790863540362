import React from 'react';

const Svg = (

	<g fill="none" fillRule="evenodd">
		<path fill="#f9fcfd" d="M31.142 57.203c-3.145-.02-5.805-1.239-7.919-3.505C21.162 51.49 20 48.46 20 45.173V26.068c0-6.943 5.311-13.06 11.722-13.06l.653-.008c-5.071 2.05-7.838 6.467-7.838 13.068v19.105c0 2.126.793 4.054 2.203 5.457 1.428 1.422 3.144 2.174 5.148 2.174 4.234 0 7.841-3.255 7.863-7.323.013-.15.012-.298.003-.732a12.067 12.067 0 0 1-.003-.254v-17.21c0-1.772-.59-2.992-1.702-4.096a4.849 4.849 0 0 0-3.443-1.42c-.07 0-.128.002-.236.005a4.45 4.45 0 0 0-.936.134c.923-1.041 2.236-1.65 3.81-1.65 1.963 0 3.662.502 4.62 1.455 1.379 1.373 2.358 3.134 2.358 4.55v19.182c0 5.415-4.605 10.175-10.502 11.422-.951.203-1.572.303-2.179.329l-.063.002h-.008l-.074.003h-.012l-.069.001h-.173z" opacity=".75" />
		<path fill="#f9fcfd" d="M34.69 13.758a14.767 14.767 0 0 1 3.678-.463c3.799 0 7.057 1.43 9.367 3.737 2.311 2.307 3.67 5.497 3.67 9.02V45.13c0 7.046-7.335 12.817-14.932 12.817h-.12c-.271 0-.541-.007-.81-.022 5.8-1.826 10.167-6.787 10.167-12.48V26.263c0-1.886-1.163-3.98-2.796-5.604-1.285-1.28-3.35-1.889-5.67-1.889-3.411 0-5.882 2.26-6.574 5.53a6.31 6.31 0 0 0-.315 1.974c.005.11 0 .222 0 .332v17.97c0 1.133.495 2.16 1.296 2.901a4.56 4.56 0 0 0 2.945 1.198 4.946 4.946 0 0 0 .411 0c1.209-.045 2.203-.54 2.897-1.307-.881 2.291-3.28 3.948-6.016 3.948-1.598 0-2.94-.588-4.098-1.74-1.128-1.123-1.765-2.671-1.765-4.403V26.068c0-6.193 2.565-10.106 7.329-11.85.464-.17.91-.324 1.336-.46z" />
	</g>

);

export default Svg;
