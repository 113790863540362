import React from 'react';
import PropTypes from 'prop-types';
import BaseMessage from './BaseMessage/BaseMessage';

const InfoMessage = props => (
	<BaseMessage type="info" subtext={props.text} text="" onClick={props.onClick} buttonText={props.buttonText}	/>
);

InfoMessage.defaultProps = {
	buttonText: 'Close',
};

InfoMessage.propTypes = {
	/**
	 * Button's handler, executed after click.
	 */
	onClick: PropTypes.func.isRequired,
	/**
	 * Text in the center of modal.
	 */
	text: PropTypes.node.isRequired,
	/**
	 * Button's text at the bottom of modal.
	 */
	buttonText: PropTypes.string,
};

export default InfoMessage;
