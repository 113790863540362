import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { depositFunds, userAccounts } from '@redux';
import { WireDepositPage, WireDepositInfoForm } from './components';


const styles = {};

const mapStateToProps = state => ({
	userInfo: userAccounts.selectors.userInfo(state),
	depositWireInfo: depositFunds.selectors.depositWireInfo(state),
	depositWireModal: depositFunds.selectors.depositWireModal(state),
});

class WireDeposit extends React.Component {
	render() {
		const {
			depositWireModal,
			depositWireInfo,
			userInfo,
		} = this.props;

		return (userInfo.IsPreopened && !depositWireInfo.isVisible
			? <WireDepositInfoForm />
			: <WireDepositPage bankAccount={depositWireModal.bankAccount} />
		);
	}
}

WireDeposit.propTypes = {
	depositWireModal: PropTypes.object.isRequired,
	depositWireInfo: PropTypes.object.isRequired,
	userInfo: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
)(WireDeposit);
