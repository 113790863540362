import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { env } from '@config';
import { signature, userAccounts } from '@redux';
import { A, ExpansionPanel, SvgIcon } from '@components';
import {
	OpenedBody,
	BriefInfo,
} from './components';

const styles = theme => ({
	root: {
		borderRadius: '10px',
		// backgroundColor: '#ffffff',
		boxShadow: '0 6px 16px 0 rgba(61, 65, 71, 0.12)',
		padding: '10px 24px 29px',
		// margin: '0 -25px',
	},
	header: {
		marginRight: '30px',
	},
	upgrade: {
		...theme.customstyles.button,
		whiteSpace: 'nowrap',
	},
	upgradeIcon: {
		verticalAlign: 'middle',
	},
	pendingInfoText: {
		display: 'inline-block',
		color: theme.palette.error.main,
		margin: '0 0',
	},
});

const mapStateToProps = state => ({
	currentAccountData: userAccounts.selectors.currentAccountData(state),
	currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
	accountStatus: userAccounts.selectors.currentAccountStatus(state),
	accountInfo: userAccounts.selectors.accountInfo(state),
});

const mapDispatchToProps = dispatch => ({
	actions: {
	},
});


class IndividualCashAccount extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isChartPanelOpen: false,
		};
	}

	// showUpgradeModal = () => {
	// 	const { actions, currentTradeAccount, currentAccountData } = this.props;
	// }

	toggleChartPanelOpen = () => {
		this.setState({ isChartPanelOpen: !this.state.isChartPanelOpen });
	};

	render() {
		const {
			classes,
			currentAccountData,
		} = this.props;

		const { baseParams } = currentAccountData;
		const headerTitle =
			(currentAccountData &&
			currentAccountData.baseParams &&
			currentAccountData.baseParams.AccountType &&
			currentAccountData.baseParams.MarginOptionParameters &&
			currentAccountData.baseParams.MarginOptionParameters.MarginType)
				? `${currentAccountData.baseParams.AccountType} ${currentAccountData.baseParams.MarginOptionParameters.MarginType} Account`
				: '';

		const totalGainPercent = currentAccountData && currentAccountData.portfolio &&
			currentAccountData.portfolio.TotalGainPercent;

		return (
			<div className={classes.root}>
				<ExpansionPanel
					header={
						<React.Fragment>
							<A onClick={this.toggleChartPanelOpen}><span className={classes.header}>{headerTitle}</span></A>
							{/* {headerTitle && accountStatus && accountStatus.CanMarginUpgrade
							&& accountInfo && accountInfo.IsPrimaryHolder &&

								<React.Fragment>
									{(accountInfo && accountInfo.PendingOrders && accountInfo.PendingOrders.UpgradeMarginType) ?
										<span className={classes.pendingInfoText}>UPGRADE IN PROGRESS</span>
										:
										<A target="_blank" onClick={this.showUpgradeModal} upper>
											<SvgIcon className={classes.upgradeIcon} icon="Upgrade"	/>
											<span className={classes.upgrade}>upgrade</span>
										</A>
									}
								</React.Fragment>
							} */}
						</React.Fragment>
					}
					id="individual-cash-account"
					collapsedBodyContent={
						<A onClick={this.toggleChartPanelOpen}>
							<BriefInfo
								cashCurrent={baseParams && baseParams.CashCurrentValue}
								positionMarketValue={baseParams && baseParams.PositionMarket}
								totalGainPercent={totalGainPercent}
							/>
						</A>
					}
					onToggleOpen={this.toggleChartPanelOpen}
					isOpen={this.state.isChartPanelOpen}
				>
					<OpenedBody />
				</ExpansionPanel>
			</div>
		);
	}
}

IndividualCashAccount.propTypes = {
	classes: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	accountInfo: PropTypes.object.isRequired,
	accountStatus: PropTypes.object.isRequired,
	currentAccountData: PropTypes.object.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(IndividualCashAccount);
