import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Field } from 'redux-form';
import {
	SvgIcon,
	useTranslate,
} from '@components';
import { FormInputNumber } from '@dev2t/react-ui-kit/src';
import { useSelector } from 'react-redux';
import { app } from '@redux';
import { FreeMarginAmount } from './FreeMarginAmount';
import { isCryptoCurrency } from '@global';
const useStyles = makeStyles(theme => ({
	inputItem: {
		marginTop: '30px',
		paddingLeft: '50px',
	},
	cashToWithdrawInfo: {
		alignItems: 'flex-start', 
		display: 'flex', 
		justifyContent: 'flex-start',
		width: '100%',
	},
	cashToWithdrawInfoText: {
		fontSize: '14px',
		marginTop: 0,
		paddingTop: 0,
		color: theme.palette.text.secondary,
		paddingLeft: '24px',
		whiteSpace: 'pre-wrap',
	},
	cashToWithdrawRefreshIcon: {
		marginLeft: '5px', 
		cursor: 'pointer',
		// Обновление баланса скрыто по задаче WEBCAB-8450
		display: 'none',
	},
	cashToWithdrawLoadingIcon: {
		alignItems: 'center', display: 'flex', justifyContent: 'flex-start', margin: 'auto',
	},
}));

const WithdrawAmount = (props) => {
	const {
		loading,
		availableAmount,
		additionalText,
		withdrawLabelText,
		placeholder,
		disabled,
		name,
		currency,
		onClick,
		precision,
		loadFreeMarginType,
		classes: noProxyClasses, // Это свойство не надо прокидывать в дочерние компоненты
		prefix,
		...otherProps
	} = props;

	const precisions = {
		USD: 2,
		EUR: 2,
		USDT: 8,
		BTC: 8,
		XRP: 8,
		LTC: 8,
		ETH: 8
	}
	const finalPrecision = precision ?? precisions[currency] ?? 2;
	// По задаче https://jira.finam.ru/browse/WEBCAB-8456?focusedCommentId=4316367&page=com.atlassian.jira.plugin.system.issuetabpanels:comment-tabpanel#comment-4316367 
	// для крипты позволяется вводить до 10 знаков после запятой
	const decimalInputScale = isCryptoCurrency(currency) ? 10 : finalPrecision;
	const theme = useTheme();
	const classes = useStyles(props);
	const translate = useTranslate();
	const resolution = useSelector(app.selectors.resolution);

	const innerPlaceholder = resolution === app.CONFIG.RESOLUTIONS_ENUM.xs
		? translate('shared_amount', 'Amount')
		: translate('common_enter_the_amount_here', 'Enter the Amount here');

	return (
		<>
			<Field
				name={name}
				component={FormInputNumber}
				inputProps={{ decimalScale: decimalInputScale, prefix }}
				additionalText={additionalText}
				label={placeholder ?? innerPlaceholder}
				disabled={disabled || loading}
				// для деактивация автозаполнения
				props={{ autoComplete: 'off' }}
				{...otherProps}
			/>
			<div className={classes.cashToWithdrawInfo}>
				{!loading &&
					<div className={classes.cashToWithdrawInfo}>
						<FreeMarginAmount 
							currency={currency} 
							availableAmount={availableAmount} 
							withdrawLabelText={withdrawLabelText}
							loadFreeMarginType={loadFreeMarginType}
							finalPrecision={finalPrecision} />
						<SvgIcon
							className={classes.cashToWithdrawRefreshIcon}
							icon="Refresh"
							htmlColor={theme.palette.secondary.contrastText}
							submitting={loading}
							onClick={() => {
								if (onClick) {
									onClick();
								}
							}}
						/>
					</div>
				}
				{loading &&
					<div className={classes.cashToWithdrawLoadingIcon}>
						<CircularProgress size={24} />
					</div>
				}
			</div>
		</>
	);
};

WithdrawAmount.propTypes = {
	name: PropTypes.string,
	onClick: PropTypes.func,
	classes: PropTypes.object,
	precision: PropTypes.number,
	loading: PropTypes.bool.isRequired,
	availableAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
	withdrawLabelText: PropTypes.string,
	currency: PropTypes.string,
};

WithdrawAmount.defaultProps = {
	name: 'Amount',
	onClick: null,
	classes: {},
	precision: null,
	withdrawLabelText: undefined,
	currency: undefined,
	availableAmount: undefined,
};

export default WithdrawAmount;
