import { Checkbox } from '@components';
import { createComponent, mapError } from '../utils';

export default createComponent(Checkbox, ({
	input: { onChange, value, ...inputProps },
	meta,
	onChange: ignoredOnChange,
	defaultChecked,
	...props
}) => ({
	...mapError({ meta, ...props }),
	...inputProps,
	...props,
	checked: !!value,
	value: value ? 'true' : '',
	onChange: (event, isInputChecked) => {
		onChange(isInputChecked);
	},
}));
