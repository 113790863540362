
import { useSelector } from 'react-redux';
import { userAccounts } from '@redux';
import { constants } from '@config';

function enrichBalance(balance) {
	const accountLimitsInner = (
		balance && balance.Currencies
			&& balance
				.Currencies
				.concat(balance.CryptoCurrencies)
	) || [];
	return accountLimitsInner.filter(x => constants.J2txWalletAllowedCurrencies.includes(x.CurrencyCode)
	|| constants.J2txWalletAllowedCryptoCurrencies.includes(x.CurrencyCode));
}

/**
 * Хук для получения актуального баланса по счету (трейдкоду)
 *
 * @param tradeCode трейдкод нужного счета или массив трейдкодов
 *
 * @returns баланс счета или Map с балансами, если счетов несколько
 *
 * @example
 *
 * import React from 'react'
 * import { useBalance } from '@hooks';
 *
 * export const Component = () => {
 *   const singleBalance = useBalance('TX001064');
 *   const multiBalances = useBalance(['TX001064', 'TX004746']);
 * }
 */
export function useBalance(tradeCode) {
	
	const accountsInfo = useSelector(userAccounts.selectors.accountsInfo);
	const balances = accountsInfo
		? new Map(accountsInfo.map(x => ([x.TradeCode, enrichBalance(x.Balance)])))
		: undefined;
	
	if (!balances || !tradeCode) return undefined;

	// если дали один tradeCode => возвращаем баланс этого счета
	if (!Array.isArray(tradeCode)) return balances.get(tradeCode);

	// если дали несколько tradeCode => возвращаем Map с балансами этих счетов
	else return new Map(tradeCode.map(x => [x, balances.get(x)]));
}
