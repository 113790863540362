const constants = {
	regExpAmount: /^\d+(\.\d{1,2})?$/,
	regExpRoutingNumber: /^(\d{9})$/,
	regExpBankAccountNumber: /^[0-9a-zA-Z]+$/,
	regExpEmail: /^(?:[\w\-+.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/,
	regExpAllTags: /(<([^>]+)>)/ig,
	regExpMt5Password: /^(?=.*[A-Z].*)(?=.*[a-z].*)(?=.*[0-9].*)[A-Za-z0-9]{8,}$/,
	regExpPassword: /(?=^.{6,}$)(?=^[a-zA-Z0-9!\"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]*$)(?=.*\d)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
	regExpBitcoinWallet: /^bc(0([ac-hj-np-z02-9]{39}|[ac-hj-np-z02-9]{59})|1[ac-hj-np-z02-9]{8,87})|[13][a-km-zA-HJ-NP-Z1-9]{25,35}$/,
	warningForNonTransferableSecuritiesHolders: 'Please Note: Accounts holding non-transferable and/or worthless securities will be required to maintain a minimum cash balance of $250.00 in the account (The balance is to offset the fees incurred for holding these types of securities, minimum amount required may be greater than $250.00 and will depend on the number of positions held).',

	EtnaGateConfirmCode: 'EtnaGateConfirmCode',

	LIME_FIN: 'Lime Trading',
	LIME_INT: 'Lime International',
	LIME_NSR: 'Lending Robot',
	LIME_LME: 'Lime LTD',
	FIRM_J2TX: 'J2TX',

	/**
	 * Список валют, с которыми работает J2txWallet (Платформа J2TX:WALLET)
	 */
	J2txWalletAllowedCurrencies: ['USD', 'EUR'],
	J2txWalletAllowedCryptoCurrencies: ['BTC', 'ETH', 'XRP', 'USDT', 'LTC'],
};

export default constants;
