import React from 'react';
import { withStyles, withTheme, makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import NotificationsIcon from '@material-ui/icons/Notifications';
import blue from '@material-ui/core/colors/blue';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withRouter } from 'react-router';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { constants, env } from '@config';
import { AccountTariffs, AccountPlatforms, ModalPopups } from '@enums';
import {
	A,
	Button,
	DropDownMenu,
	LimeLogo,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	SvgIcon,
	HeaderUserInfo,
} from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';
import { addHashToUrl } from '@global';
import {
	app,
	dashboard,
	notifications,
	profile,
	tradePlatforms,
	userAccounts,
} from '@redux';
import { LogOutButton } from './components';
import { SelectLanguage } from '@dev2t/react-ui-kit/src/components';

import j2tPlusImg from './img/j2t-plus.png';
import mtImg from './img/mt5.png';
import stProImg from './img/stpro.png';

const styles = (theme) => {
	const commonlinkStyle = {
		...theme.customstyles.button,
		marginRight: '24px',
		textAlign: 'center',
		'& a': {
			textDecoration: 'none',
			color: theme.palette.primary.main,
		},
	};

	return {
		root: {
			padding: '5px 0px 0px 24px',
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
		leftColumn: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
		dropdown: {
			// marginLeft: '24px',
		},
		rightColumn: {
			display: 'flex',
		},
		tradeLink: {
			...commonlinkStyle
		},
		limeLink: {
			...commonlinkStyle,
			marginLeft: '24px',
		},
		iconLink: {
			display: 'flex',
			// alignItems: 'left',
		},
		openSideBarIcon: {
			marginRight: '15px',
			marginLeft: '-10px',
		},
		notificationsIcon: {

		},
		logo: {
			width: '48px',
		},
		headerImg: {
			width: '24px',
			height: '24px',
			marginRight: '5px',
		},
		doneIcon: {
			marginLeft: '8px',
			stroke: theme.palette.primary.light,
		},
		newNotificationsExists: {
			position: 'absolute',
			left: '28px',
		},
		hidden: {
			display: 'none',
		},
		redButton: {
			padding: '4px 20px',
			backgroundColor: theme.palette.error.dark,
			color: theme.palette.common.white,
			'&:hover': {
				backgroundColor: theme.palette.error.main,
				color: theme.palette.common.white,
				// Reset on mouse devices
				'@media (hover: none)': {
					backgroundColor: theme.palette.error.main,
				},
			},
		},
		platformsButton: {
			backgroundColor: blue[400],
			border: `1px solid ${theme.palette.common.white}`,
			'&:hover': {
				backgroundColor: blue[600],
				border: `1px solid ${blue[400]}`,
				// Reset on mouse devices
				'@media (hover: none)': {
					backgroundColor: blue[600],
					border: `1px solid ${blue[400]}`,
				},
			},
		},
		ml: { marginLeft: '12px' },
		mainText: {
			...theme.customstyles.title,
			textAlign: 'justify',
		},
		warning: {
			padding: '12px 0',
			textAlign: 'justify',
			fontSize: '12px',
			lineHeight: '20px',
		},
		limelogo: {
			position: 'relative',
			width: '48px',
			maxWidth: '48px',
			height: '48px',
		},
		popupContainer: {
			[theme.breakpoints.down('sm')]: {
				left: '-140px',
			},
			[theme.breakpoints.down('xs')]: {
				left: '-180px',
			},
		},
		platformDetails: {
			marginLeft: '12px',
		},
	};
};

const mapStateToProps = state => ({
	profile: profile.selectors.profile(state),
	notifications: notifications.selectors.notifications(state),
	accountInfo: userAccounts.selectors.accountInfo(state),
	currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
	currentAccountStatus: userAccounts.selectors.currentAccountStatus(state),
	currentAccountData: userAccounts.selectors.currentAccountData(state),
	openTradexUrlData: tradePlatforms.selectors.openTradexUrlData(state),
	currentAccountIsDemo: userAccounts.selectors.currentAccountIsDemo(state),
	anyActiveAccountExists: userAccounts.selectors.anyActiveAccountExists(state),
	hasOpenAccount: userAccounts.selectors.hasOpenAccount(state),
	accounts: userAccounts.selectors.accounts(state),
	userInfo: userAccounts.selectors.userInfo(state),
	language: app.selectors.language(state),
});

const mapDispatchToProps = dispatch => ({
	actions: {
		openTradexUrl: domain => dispatch(tradePlatforms.actions.openTradexUrl.request(domain)),
		showJ2tLiteModal: () => dispatch(tradePlatforms.actions.showJ2tLiteModal()),
		showJ2tPlusModal: () => dispatch(tradePlatforms.actions.showJ2tPlusModal()),
		showJ2tProModal: () => dispatch(tradePlatforms.actions.showJ2tProModal()),
		showMt5Modal: () => dispatch(tradePlatforms.actions.showMt5Modal()),
		showStProModal: () => dispatch(tradePlatforms.actions.showStProModal()),
		sidebarOpen: () => dispatch(dashboard.actions.sidebarOpen()),
		sidebarClose: () => dispatch(dashboard.actions.sidebarClose()),
		notificationsOpen: () => dispatch(dashboard.actions.notificationsOpen()),
		notificationsClose: () => dispatch(dashboard.actions.notificationsClose()),
		onChangeLanguage: (lang) => dispatch(app.actions.changeLanguage.request(lang))
	},
});

const useStyles = makeStyles(styles);
const ListItemCheckedIcon = ({ value }) => {
	const classes = useStyles();
	const children = value ? <SvgIcon icon="Done" classes={{ root: classes.doneIcon }} /> : <div />;
	return (
		<ListItemIcon>
			{children}
		</ListItemIcon>
	);
};


class MainHeader extends React.Component {

	showModal = (id) => {
		this.props.history.push(addHashToUrl(this.props.location, id));
	}

	render() {
		const {
			accounts,
			theme,
			classes,
			currentTradeAccount: {
				clearerFirm,
				tradeCode,
			},
			currentAccountStatus,
			currentAccountData: {
				baseParams,
			},
			accountInfo: {
				loading,
				Platforms: {
					ActiveTradePlatform,
					TradeUrl,
				},
				Tariffs,
				TradeLogin,
			},
			openTradexUrlData,
			profile: {
				HasMarketDataDisabled,
			},
			actions: {
				openTradexUrl,
				showJ2tLiteModal,
				showJ2tPlusModal,
				showJ2tProModal,
				showMt5Modal,
				showStProModal,
				sidebarOpen,
				sidebarClose,
				notificationsOpen,
				notificationsClose,
				onChangeLanguage,
			},
			currentAccountIsDemo,
			anyActiveAccountExists,
			hasOpenAccount,
			userInfo,
			language,
		} = this.props;

		const enableSwitch = (env.WEBCAB_5031_switch_trading_platforms && currentAccountStatus.CanPlatformChange
			&& (!HasMarketDataDisabled || ActiveTradePlatform === AccountPlatforms.PLATFORM_MT5SPC));

		const platformsVisibility = { 
			[AccountPlatforms.PLATFORM_TRANSAQ]: enableSwitch && ActiveTradePlatform !== AccountPlatforms.PLATFORM_TRANSAQ,
			[AccountPlatforms.PLATFORM_JUST2TRADE]: false,
			[AccountPlatforms.PLATFORM_MT5SPC]: AccountPlatforms.PLATFORM_MT5SPC === ActiveTradePlatform,
		};
		const anyPlatformVisible = Object.values(platformsVisibility).some(x => x);
		const anyPlatformVisibleAndSelected = Object.entries(platformsVisibility).some(([key, value]) => value && key === ActiveTradePlatform);

		const unreadNotificationsExist = this.props.notifications && this.props.notifications.unreadMessagesCount > 0;
	
		const currentAccount = accounts.find(x => tradeCode === x.TradeCode && clearerFirm === x.ClearingFirm);
		
		const terminalMt5Link = `${env.baseUrl}/static/webterminal.html${(TradeLogin && `?login=${TradeLogin}`) || ''}`;
		const terminalMt4Link = currentAccount && currentAccount.IsForex ? `${env.baseUrl}/static/mt4web.html${(TradeLogin && `?login=${TradeLogin}`) || ''}` : '';

		let platformTradeUrl = TradeUrl;

		return (
			<div className={classes.root}>
				<div className={classes.leftColumn}>
					<Hidden smUp implementation="css">
						<div className={classes.openSideBarIcon}>
							<IconButton onClick={sidebarOpen}>
								<SvgIcon icon="Hamburger" />
							</IconButton>
						</div>
					</Hidden>
					{!loading && 
						<React.Fragment>
							{hasOpenAccount && ([constants.LIME_INT].some(x => x === env.firm)) &&
								<div className={classes.tradeLink}>
									{(openTradexUrlData && openTradexUrlData.submitting) 
										? <CircularProgress
											style={{ color: blue[600] }}
											size={18}
											classes={{ root: classes.progress }}
										/>
										: <A onClick={() => openTradexUrl(env.goToTradingUrl)}><R id="common_goto_trading" defaultValue="Go to Trading" /></A>
									}
								</div>
							}
							{/* {(hasOpenAccount && [constants.LIME_LME, constants.FIRM_J2TX].every(x => x !== env.firm) && [constants.LIME_LME].some(x => x === env.firm) && terminalMt4Link) &&
								<div className={classes.tradeLink}>
									<A href={terminalMt4Link} target="_blank"><R id="common_goto_trading" defaultValue="Go to Trading" /></A>
								</div>
							} */}
							{hasOpenAccount && (ActiveTradePlatform === 'Sterling') &&
								<div className={classes.tradeLink}>
									<a target="_blank" href={env.sterlingPcDownloadUrl} className={classes.iconLink}>
										<img alt="Sterling Trader® Pro logo" src={stProImg} className={classes.headerImg} />
										Sterling Trader® Pro
									</a>
								</div>
							}
						</React.Fragment>
					}
					{!loading &&
						hasOpenAccount &&
						!currentAccountIsDemo &&
						anyPlatformVisible &&
						!currentAccountStatus.IsDisabled &&
						<div className={classes.dropdown}>
							<DropDownMenu
								openPosition="right"
								classes={{ popupContainer: classes.popupContainer }}
								openButton={onClick => (
									<Button
										color="primary"
										variant="contained"
										size="small"
										classes={{ containedPrimary: classes.platformsButton }}
										onClick={onClick}
									>
										Platforms
									</Button>)
								}
							>
								<List>
									{platformsVisibility[AccountPlatforms.PLATFORM_TRANSAQ] &&
										<ListItem button onClick={showJ2tLiteModal}>
											{anyPlatformVisibleAndSelected && <ListItemCheckedIcon value={ActiveTradePlatform === AccountPlatforms.PLATFORM_TRANSAQ} />}
											<ListItemIcon>
												<LimeLogo className={classes.limelogo} />
											</ListItemIcon>
											<ListItemText
												primary="Lime&nbsp;Trader"
											/>
										</ListItem>
									}
									{((false && currentAccountStatus.CanPlatformChange &&
										env.WEBCAB_5031_switch_trading_platforms &&
										clearerFirm === 'vision' && ActiveTradePlatform !== 'Just2Trade')
										|| (false && clearerFirm !== 'vision' && ActiveTradePlatform === 'Just2Trade')) &&
										<ListItem button onClick={showJ2tPlusModal}>
											{anyPlatformVisibleAndSelected && <ListItemCheckedIcon value={ActiveTradePlatform === 'Just2Trade'} />}
											<ListItemIcon>
												<img alt="Just2Trade + logo" src={j2tPlusImg} />
											</ListItemIcon>
											<ListItemText
												primary="Just2Trade&nbsp;+"
											/>
										</ListItem>
									}
									{((false && currentAccountStatus.CanPlatformChange &&
										env.WEBCAB_5031_switch_trading_platforms &&
										clearerFirm === 'vision' && ActiveTradePlatform !== 'Transaq')
										|| (false && clearerFirm !== 'vision' && ActiveTradePlatform === 'Transaq')) &&
										<ListItem button onClick={showJ2tProModal}>
											{anyPlatformVisibleAndSelected && <ListItemCheckedIcon value={ActiveTradePlatform === 'Transaq'} />}
											<ListItemIcon>
												<div className={classes.logo} />
												{/* <img alt="Just2Trade Pro logo" src={j2tProImg} /> */}
											</ListItemIcon>
											<ListItemText
												primary="Just2Trade&nbsp;Pro"
											/>
										</ListItem>
									}
									{platformsVisibility[AccountPlatforms.PLATFORM_MT5SPC] &&
										<ListItem button onClick={showMt5Modal}>
											{anyPlatformVisibleAndSelected && <ListItemCheckedIcon value={ActiveTradePlatform === AccountPlatforms.PLATFORM_MT5SPC} />}
											<ListItemIcon>
												<img alt="MetaTrader 5 logo" src={mtImg} />
											</ListItemIcon>
											<ListItemText
												primary="MetaTrader&nbsp;5"
											/>
											{ActiveTradePlatform === AccountPlatforms.PLATFORM_MT5SPC &&
												<ListItemIcon classes={{ root: classes.platformDetails }}>
													<Button
														color="primary"
														variant="contained"
														size="small"
														onClick={showMt5Modal}
													>
														Details
													</Button>
												</ListItemIcon>
											}
										</ListItem>
									}
									{((false && currentAccountStatus.CanPlatformChange &&
										env.WEBCAB_5031_switch_trading_platforms &&
										clearerFirm === 'vision' && ActiveTradePlatform !== 'Sterling')
										|| (false && clearerFirm !== 'vision' && ActiveTradePlatform === 'Sterling')) &&
										<ListItem button onClick={showStProModal}>
											{anyPlatformVisibleAndSelected && <ListItemCheckedIcon value={ActiveTradePlatform === 'Sterling'} />}
											<ListItemIcon>
												<img alt="Sterling Trader® Pro logo" src={stProImg} />
											</ListItemIcon>
											<ListItemText
												primary="Sterling&nbsp;Trader®"
											/>
										</ListItem>
									}
								</List>
							</DropDownMenu>
						</div>
					}
					{!loading &&
						hasOpenAccount &&
						Tariffs && Tariffs.EnableZero &&
						(Tariffs.ActiveTariff !== AccountTariffs.TARIFF_ZERO) &&
						(Tariffs.PendingTariff !== AccountTariffs.TARIFF_ZERO) &&
						<Button
							color="secondary"
							variant="outlined"
							size="small"
							className={classNames(classes.redButton, classes.ml)}
							onClick={() => this.showModal(ModalPopups.COMMISSION_FREE_MODAL)}
						>
							Go Free
						</Button>
					}
					{!loading && 
						currentAccountIsDemo && 
						!anyActiveAccountExists &&
						<div className={classes.limeLink}>
							<a target="_blank" href={env.AnketaSiteUrl}>Open account</a>
						</div>
					}
				</div>
				<div className={classes.rightColumn} style={{alignItems: 'center'}}>
					<Hidden lgUp implementation="css">
						<div className={classes.notificationsIcon}>
							<IconButton onClick={notificationsOpen}>
								<SvgIcon
									className={unreadNotificationsExist ? classes.newNotificationsExists : classes.hidden}
									icon="Circle"
									htmlColor={theme.palette.primary.main}
								/>
								<NotificationsIcon />
							</IconButton>
						</div>
					</Hidden>
					<SelectLanguage
						config={env.menuLangsConfig}
						currentLang={language}
						onChange={onChangeLanguage}
						size="s"
					/>
					<HeaderUserInfo />
					<div>
						<LogOutButton />
					</div>
				</div>

			</div>
		);
	}
}

MainHeader.propTypes = {
	actions: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
	currentAccountStatus: PropTypes.shape({
		CanPlatformChange: PropTypes.bool.isRequired,
	}).isRequired,
	userInfo: PropTypes.object.isRequired,
};
export default compose(
	withRouter,
	withTheme,
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(MainHeader);
