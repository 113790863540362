import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Input } from '@dev2t/react-ui-kit/src';
import { userAccounts } from '@redux';


const styles = theme => ({
	root: {},
});
const useStyles = makeStyles(styles);

const Mt5InfoForm = (props) => {
	const classes = useStyles(props);
	const accountInfo = useSelector(userAccounts.selectors.accountInfo);
	const tradeLogin = accountInfo && accountInfo.TradeLogin;
	return (
		<div className={classes.root}>
			<Input label="Login" showLabel value={tradeLogin || '-'} disabled />
			<Input label="Server" showLabel value="LimeTrading-MT5" disabled />
		</div>
	);
};
export default Mt5InfoForm;
