import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import onClickOutside from 'react-onclickoutside';
import classNames from 'classnames';

const styles = theme => ({
	root: {
		position: 'relative',
	},
	popupContainer: {
		position: 'absolute',
		backgroundColor: theme.palette.background.default,
		boxShadow: '0 6px 16px 0 rgba(61, 65, 71, 0.12)',
		borderRadius: '4px',
		...theme.customstyles.body,
		color: theme.palette.primary.dark,
		zIndex: 2,
	},
	openRight: {
		'& $popupContainer': {
			top: '-10px',
		},
	},
	openLeft: {
		'& $popupContainer': {
			top: 0,
			right: 0,
		},
	},
	content: {
		display: 'flex',
		alignItems: 'center',
	},
	icon: {
		cursor: 'pointer',
	},
});

class DropDownMenu extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
		};
		this.openPopup = this.openPopup.bind(this);
		this.closePopup = this.closePopup.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	handleClickOutside() {
		this.closePopup();
	}

	openPopup() {
		this.setState({
			open: true,
		});
	}
	closePopup() {
		this.setState({
			open: false,
		});
	}

	render() {
		const {
			classes,
			children,
			openPosition,
			openButton,
		} = this.props;

		const cssClasses = classNames({
			[classes.root]: true,
			[classes.openRight]: openPosition === 'right',
			[classes.openLeft]: openPosition === 'left',
		});

		return (
			<div className={cssClasses}>
				<div className={classes.icon}>
					{openButton ?
						openButton(this.openPopup)
						:
						<MoreVertIcon
							onClick={this.openPopup}
						/>
					}
				</div>

				{this.state.open &&
					<div className={classes.popupContainer}>
						<div className={classes.content} onClick={this.closePopup}>
							{children}
						</div>
					</div>
				}
			</div>
		);
	}
}

DropDownMenu.propTypes = {
	children: PropTypes.any.isRequired,
	openPosition: PropTypes.oneOf(['left', 'right']),
	openButton: PropTypes.func,
};

DropDownMenu.defaultProps = {
	openPosition: 'left',
	openButton: null,
};

export default compose(
	withStyles(styles),
	onClickOutside,
)(DropDownMenu);
