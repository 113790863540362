import React, { useEffect } from 'react';
import ApiKeysPanel from '../Profile/components/ApiKeysPanel/ApiKeysPanel';
import Description from './components/Description';
import { useDispatch } from 'react-redux';
import {
	apiKeys
} from '@redux';

const useActions = () => {
	const dispatch = useDispatch();
	return ({
		getApiKeys: () => dispatch(apiKeys.actions.getApiKeys.request()),
	});
};

export default function ApiKeys() {
	const actions = useActions();
	useEffect(() => {
		actions.getApiKeys();
	}, []);

	return (
		<>
            <Description />
			<ApiKeysPanel />
        </>
	);
}
