import React from 'react';
import PropTypes from 'prop-types';
import { R } from '@dev2t/react-ui-kit/src/components';

import { CYWithdrawalBankModal } from './components';

const CYWithdrawalMalaysianBankModal = ({ onClose }) => (
	<CYWithdrawalBankModal
		title={<R id="orders_selectmethod_malaysianbank_title" defaultValue="Malaysian Bank Account" />}
		onClose={onClose}
		currency="MYR"
		form="CYWithdrawalMalaysianBankForm"
	/>
);

CYWithdrawalMalaysianBankModal.propTypes = {
	onClose: PropTypes.func.isRequired,
};

export default CYWithdrawalMalaysianBankModal;
