import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import SafeComponent from 'react-safe-component';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {
	DateRangePicker,
	Select,
} from '@components';
import { env } from '@config';
import { R } from '@dev2t/react-ui-kit/src/components';
import { snackbar, userAccounts } from '@redux';


const styles = theme => ({
	selectType: {
		marginTop: '5px!important',
		color: theme.palette.primary.main,
		textTransform: 'uppercase',
	},
	inputType: {
		paddingTop: 0,
		paddingBottom: 0,
	},
	menuInput: {
		textTransform: 'uppercase',
	},
	searchInputContainer: {
		color: theme.palette.primary.main,
	},
	searchUnderline: {
		borderBottom: 0,
		'&:hover': {
			borderBottom: 0,
		},
	},
	searchInput: {
		width: '100%',
		'&:before': {
			borderBottom: 0,
		},
		'&:hover': {
			borderBottom: 0,
		},
	},
	searchInputRight: {
		width: '100%',
		textAlign: 'right',
	},
	searchIcon: {
		color: theme.palette.primary.main,
		margin: '3px 0',
		verticalAlign: 'middle',
	},
});

const mapStateToProps = state => ({
	cashActivities: userAccounts.selectors.cashActivities(state),
	currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
});

const mapDispatchToProps = dispatch => ({
	actions: {
		loadPage: payload => dispatch(userAccounts.actions.getTransactionsHistory.request(payload)),
		showError: message => dispatch(snackbar.actions.showErrorMessage({ text: message })),
	},
});

class CYTransactionsFilters extends React.Component {
	startDateChange = (date) => {
		this.props.changeFilter({
			...this.props.filter,
			startDate: date,
		});
	};

	endDateChange = (date) => {
		this.props.changeFilter({
			...this.props.filter,
			endDate: date,
		});
	};

	actionsTypeChange = (event) => {
		this.props.changeFilter({
			...this.props.filter,
			actionsType: event.target.value,
		});
	}

	searchKeyPress = (event) => {
		if (event.keyCode === 13 && this.props.filter.tranSearch !== event.target.value) {
			this.props.changeFilter({
				...this.props.filter,
				tranSearch: event.target.value,
			});
		}
	};

	loseFocus = (event) => {
		if (this.props.filter.tranSearch !== event.target.value) {
			this.props.changeFilter({
				...this.props.filter,
				tranSearch: event.target.value,
			});
		}
	};

	render() {
		const {
			actions,
			classes,
			currentTradeAccount,
			filter: {
				startDate,
				endDate,
				actionsType,
			},
		} = this.props;

		return (
			<React.Fragment>
				<DateRangePicker
					endDate={endDate}
					startDate={startDate}
					onEndDateChange={this.endDateChange}
					onStartDateChange={this.startDateChange}
				/>
				<span>
					<Select
						fullWidth={false}
						value={actionsType}
						className={classes.selectType}
						onChange={this.actionsTypeChange}
						inputProps={{
							className: classes.inputType,
						}}
						MenuProps={{
							MenuListProps: {
								style: {
									textTransform: 'uppercase',
								},
							},
						}}
					>
						<MenuItem value={0} key="trantype11"><R id="common_tran_filter_item_all" defaultValue="All" /></MenuItem>
						<MenuItem value={2} key="trantype31"><R id="common_tran_filter_item_cash" defaultValue="Cash Operations" /></MenuItem>
						<MenuItem value={3} key="trantype41"><R id="common_tran_filter_item_other" defaultValue="Other" /></MenuItem>
					</Select>
				</span>
			</React.Fragment>
		);
	}
}

CYTransactionsFilters.propTypes = {
	classes: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	changeFilter: PropTypes.func.isRequired,
	filter: PropTypes.object.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
	SafeComponent,
)(CYTransactionsFilters);
