import React from "react";
import DemoAccountCardList from "./DemoAccountCardList";
import DemoAccountsStandardList from "./DemoAccountsStandardList";
import CircularProgress from '@material-ui/core/CircularProgress';
import { env } from '@config';
import { useDispatch } from 'react-redux';
import { app } from '@redux';
import { ModalPopups, DemoAccountsTabs } from '@enums';

export const DemoAccountsList = (props) =>{
    const { accounts, balances, viewType } = props;
    const demoAccounts = accounts.filter(acc => acc.IsDemo);
	const mergedByTradeCode = demoAccounts.map(a => ({
			...balances.find((ai) => (ai.TradeLogin === a.Login) && ai),
			...a
		}));

    const dispatch = useDispatch();

    const createTerminalLink = (acc) => {
        if(acc && acc.IsForex){
            return `${env.baseUrl}/static/mt4web.html${(acc.TradeLogin && `?login=${acc.TradeLogin}`) || ''}`;
        }
        return `${env.baseUrl}/static/webterminal.html${(acc.TradeLogin && `?login=${acc.TradeLogin}`) || ''}`;
    }

    const showLeverageDialog = () => dispatch(app.actions.showBaseModal(ModalPopups.CY_NON_TRADING_ORDER_LEVERAGE));
    
    switch (viewType) {
        case DemoAccountsTabs.STANDARD:
            return <DemoAccountsStandardList accounts={mergedByTradeCode} createTerminalLink={createTerminalLink} showLeverageDialog={showLeverageDialog}/>
        case DemoAccountsTabs.CARD:
            return <DemoAccountCardList accounts={mergedByTradeCode} createTerminalLink={createTerminalLink} showLeverageDialog={showLeverageDialog}/>
        default:
            return (<CircularProgress
                size={24}
            />);
    }
}

export default DemoAccountsList;