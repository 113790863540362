/* eslint-disable no-plusplus */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { userAccounts } from '@redux';
import { Chart, Select } from '@components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import { toUsaMoneyFormat } from '@global';
import { BriefInfo, ChangeInfo } from '../';
import moment from 'moment-timezone';

const PERIOD = {
	M1: 43200,
	M3: 129600,
	M6: 259200,
	Y1: 525600,
	ALL: 2147483647,
};

const getDataByPeriod = (dataArray, periodToReturn) => {
	const dateTo = new Date(dataArray[0].D);
	dateTo.setDate(dateTo.getDate() - (periodToReturn / 60 / 24));

	const resultData = [];
	for (let i = dataArray.length - 1; i >= 0; i--) {
		if (dataArray[i] && new Date(dataArray[i].D) >= dateTo) {
			resultData.push(dataArray[i]);
		}
	}
	const change = resultData[resultData.length - 1].V - resultData[0].V;
	const changePercent = (change * 100) / resultData[0].V;
	return {
		Points: resultData,
		ChangePercent: changePercent,
	};
};

const styles = theme => ({
	root: {},
	briefInfo: {
		marginTop: '24px',
		'&:first-child': {
			marginTop: '0px',
		},
	},
	chart: {},
	footer: {
		display: 'flex',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'center',
		},
	},
	industries: {
	},
	periods: {
	},
	periodsTabs: {
		maxWidth: '50px',
		minWidth: '0px',
	},
});


const mapStateToProps = state => ({
	currentAccountData: userAccounts.selectors.currentAccountData(state),
	charts: userAccounts.selectors.charts(state),
});


class OpenedBody extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			period: 'M1',
		};
		this.handlePeriodChange = this.handlePeriodChange.bind(this);
	}

	handlePeriodChange(event, period) {
		this.setState({ period });
	}

	render() {
		const {
			classes,
			charts,
			currentAccountData: { baseParams },

		} = this.props;

		const mainDataForPeriod = charts !== undefined && charts && charts.Data && charts.Data.length > 0 &&
		getDataByPeriod(charts.Data, PERIOD[this.state.period]);

		const mainChartData =
			charts && charts.Data &&
			mainDataForPeriod && mainDataForPeriod.Points &&
			(charts.Data.length > 0) ?
				{
					legendTitle: 'equity',
					chartData: mainDataForPeriod.Points,
				}
				: null;
		const d = charts && charts.Data && charts.Data && charts.Data.length && charts.Data[0].D;
		const offset = moment.tz.zone('America/New_York').utcOffset(d || new Date());
	
		return (
			<div className={classes.root}>
				<div className={classes.briefInfo}>
					<BriefInfo
						showChart={false}
						cashCurrent={baseParams && baseParams.CashCurrentValue}
						positionMarketValue={baseParams && baseParams.PositionMarket}
						/* totalGainPercent={mainDataForPeriod && mainDataForPeriod.ChangePercent} */
					/>
				</div>
				<div className={classes.briefInfo}>
					<ChangeInfo
						points={mainDataForPeriod && mainDataForPeriod.Points}
					/>
				</div>
				{mainChartData &&
					<div className={classes.chart}>
						<Chart
							mainChartdata={mainChartData}
							// YlabelsFormat="{value} $"
							YlabelsFormat={{
								formatter() {
									return toUsaMoneyFormat(this.value);
								},
							}}
							TooltipFormat={{
								formatter() {
									return `${moment(this.x).utc().utcOffset(-offset).format('LL')}<br />${this.series.name}:  <b>${toUsaMoneyFormat(this.y)}</b>`;
								},
							}}
							title="Date"
							color={mainDataForPeriod && mainDataForPeriod.ChangePercent >= 0 ? '#11d87c' : '#ff6567'}
						/>
					</div>
				}
				<div className={classes.footer}>
					<div className={classes.periods}>
						<Tabs
							value={this.state.period}
							onChange={this.handlePeriodChange}
							indicatorColor="primary"
							textColor="primary"
							centered
						>
							<Tab classes={{ root: classes.periodsTabs }} label="1M" value="M1" />
							<Tab classes={{ root: classes.periodsTabs }} label="3M" value="M3" />
							<Tab classes={{ root: classes.periodsTabs }} label="6M" value="M6" />
							<Tab classes={{ root: classes.periodsTabs }} label="1Y" value="Y1" />
							{/* <Tab classes={{ root: classes.periodsTabs }} label="ALL" value="ALL" /> */}
						</Tabs>
					</div>
				</div>

			</div>
		);
	}
}

OpenedBody.propTypes = {};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
)(OpenedBody);
