/* eslint-disable no-plusplus */
import { put, fork, takeEvery, call, select, take, race } from 'redux-saga/effects';
import { SubmissionError, change } from 'redux-form';
import { loginLocal } from '@api';
import { cabinetLog } from '@global';
import { auth, captcha } from '@redux';
import selectors from './selectors';
import { translate } from '@global';
import actions from './actions';

const twoFactorStates = {
	NOT_REQUIRED: 'NOT_REQUIRED',
	REQUIRED_FIRST_TIME: 'REQUIRED_FIRST_TIME',
	REQUIRED_AFTER_WRONG_CODE: 'REQUIRED_AFTER_WRONG_CODE'
}

function* loginFormSubmit({ payload: { username, password, securityCode }}) {
	try {
		const { success, token: captchaToken } = yield* getCaptchaTokenIfNeeded();

		if (!success) return;

		const loginResponse = yield* performLoginRequest(username, password, captchaToken, securityCode);
		
		if (loginResponse.data.Success) {
			yield* authorizeSuccess(username, loginResponse);
			return;
		}

		if (loginResponse.data.Errors[0].Code === 'Authorized') {
			yield* authorizeSuccess(username, loginResponse, 'Authorized auth error');
			return;
		}

		const errors = loginResponse.data
			.Errors
			.filter(x => !!x.Message)
			.map(x => ({ code: x.Code, message: x.Message }));

		yield* handleNeedCaptcha(loginResponse.data.Errors);

		const twoFactorState = yield* handleNeedTwoFactor(loginResponse.data.Errors);

		const lastTwoFactorCodeWasWrong = twoFactorState == twoFactorStates.REQUIRED_AFTER_WRONG_CODE;

		if (lastTwoFactorCodeWasWrong) {
			errors.push({
				code: "Wrong_Two_Factor",
				message: translate('shared_totp_invalid_code', 'The security code is wrong')
			});
		}

		yield* authorizeFailure(errors);	

	} catch (error) {
		Raven.captureMessage('loginFormSubmit exception', { extra: error });

		yield* authorizeFailure([{
			code: 'NETWORK_ERROR', 
			message: 'NETWORK ERROR'
		}]);

		console.error(error);
	}
}

function* authorizeSuccess(username, loginResponse, logMessage = 'success auth') {
	yield put(actions.login.success());
	cabinetLog(logMessage, loginResponse.data);
	yield put(auth.actions.loginLocal({ username, token: loginResponse.data.SignalRToken }));
	yield put(actions.setCaptchaIsRequired(false))
	yield put(actions.setTwoFactorIsRequired(false))
}

function* authorizeFailure(errors) {
	yield put(actions.login.failure(new SubmissionError({
		_error: errors,
	})));
}

function* handleNeedCaptcha(errors) {
	const captchaRequired = errors.some(x => x.Code === 'Need_Captcha')

	const captchaWasRequired = yield select(selectors.captchaIsRequired);

	yield put(actions.setCaptchaIsRequired(captchaRequired))

	if (captchaWasRequired)
	{
		yield put(captcha.actions.reset.request())
	}
}

function* handleNeedTwoFactor(errors) {
	const twoFactorIsRequired = errors.some(x => x.Code === 'Need_Two_Factor')

	const twoFactorWasRequired = yield select(selectors.twoFactorIsRequired);

	yield put(actions.setTwoFactorIsRequired(twoFactorIsRequired))

	if (twoFactorWasRequired) {
		yield put(change('loginForm', 'securityCode', ''));
	};

	return twoFactorIsRequired
		? twoFactorWasRequired
			? twoFactorStates.REQUIRED_AFTER_WRONG_CODE
			: twoFactorStates.REQUIRED_FIRST_TIME
		: twoFactorStates.NOT_REQUIRED;
}

function* getCaptchaTokenIfNeeded() {
	const captchaIsRequired = yield select(selectors.captchaIsRequired);

	if (!captchaIsRequired) return { success: true, token: null };
	
	const { success, token } = yield* getCaptchaToken();
	if (!success)
	{
		yield* authorizeFailure([{
			code: 'VALIDATION_ERROR',
			message: 'An internal server error'
		}]);

		return { success: false }
	}

	if (!token)
	{
		yield* authorizeFailure([{
			code: 'WRONG_CAPTCHA',
			message: translate('shared_captcha_incorrect', 'The code you entered is not valid.')
		}]);

		return { success: false }
	}

	return { success: true, token };
}

function* getCaptchaToken() {
	yield put(captcha.actions.validate.request());

    const { success, failure } = yield race({
		success: take(captcha.actions.validate.SUCCESS),
		failure: take(captcha.actions.validate.FAILURE),
	});
	  
	if (success) {
		return { success: true, token: success.payload.response.Token };
	} else if (failure) {
		return { success: false };
	}
}

function* performLoginRequest(username, password, captchaToken, securityCode) {
	const loginResponse = yield call(loginLocal, username, password, captchaToken, securityCode);

	if (typeof loginResponse.data !== 'string') return loginResponse;

	// if was error with status 200
	Raven.captureMessage('loginResponse.data === string', { extra: { text: loginResponse.data.substr(0, 200) } });

	loginResponse.data = {
		Success: false,
		Errors: [{
			Code: 'Unknown',
			Message: 'An internal server error',
		}],
	};

	return loginResponse;
}

// Watchers
function* watchLoginFormSubmit() {
	yield takeEvery(actions.login.REQUEST, loginFormSubmit);
}

// Root saga
const rootSaga = [
	fork(watchLoginFormSubmit),
];

export default {
	rootSaga,
};
