/* eslint-disable max-len */
import React, { useState } from 'react';
import {
	withStyles,
	withTheme,
} from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
	app,
	dashboard,
	depositFunds,
	profile,
	signature,
	userAccounts,
} from '@redux';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import compose from 'recompose/compose';
import { constants, env } from '@config';
import { SvgIcon, ListItem } from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';
import { ModalPopups } from '@enums';
import { addHashToUrl, changeHashInUrl, scrollToTop } from '@global';

const styles = theme => ({
	root: {},
	listItemText: {
		...theme.customstyles.body,
		// color: theme.palette.text.primary,
	},
});

const mapDispatchToProps = dispatch => ({
	actions: {
		showDepositFundsModal: (bankAccount, tradeAccount) => dispatch(depositFunds.actions.showDepositFundsModal(bankAccount, tradeAccount)),
		setCurrentPage: page => dispatch(push(page)),
		openReportsPage: (page, account) => {
			dispatch(userAccounts.actions.openReportsPage({ page, account }));
		},
		showNonTradingOrderCommon: () => dispatch(app.actions.showBaseModal(ModalPopups.CY_NON_TRADING_ORDER_COMMON)),
		showNonTradingOpenAccount: () => dispatch(app.actions.showBaseModal(ModalPopups.CY_NON_TRADING_OPEN_ACCOUNT)),
		showNonTradingOpenDemoAccount: () => dispatch(app.actions.showBaseModal(ModalPopups.CY_NON_TRADING_OPEN_DEMO_ACCOUNT)),
		sidebarClose: () => dispatch(dashboard.actions.sidebarClose()),
	},
});

const mapStateToProps = state => ({
	currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
	linkedAccounts: userAccounts.selectors.linkedAccounts(state),
	userInfo: userAccounts.selectors.userInfo(state),
	hasBlockingDebts: userAccounts.selectors.hasBlockingDebts(state),
	accounts: userAccounts.selectors.accounts(state),
	loadingAccounts: userAccounts.selectors.loading(state),
	currentAccountData: userAccounts.selectors.currentAccountData(state),
	selectedAccount: userAccounts.selectors.selectedAccount(state),
	currentAccountStatus: userAccounts.selectors.currentAccountStatus(state),
	userProfile: profile.selectors.profile(state),
	language: app.selectors.language(state),
});

class AccountActions extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			openSubMenu: null,
		};
	}

	redirectModal = (from, to) => {
		this.props.history.push(changeHashInUrl(this.props.location, from, to));
	}

	showDepositFundsModal = () => {
		const achBankAccount = this.props.linkedAccounts && this.props.linkedAccounts.find(it => it.Type === 'ACH');
		if (achBankAccount) {
			this.props.actions.showDepositFundsModal(
				achBankAccount,
				this.props.currentTradeAccount,
			);
		} else {
			this.redirectModal(ModalPopups.PLAID_SELECT_ACCOUNT, ModalPopups.ADD_BANK_ACCOUNT);
		}
		this.props.actions.sidebarClose();
	}

	showNonTradingOrderCommonModal = () => {
		this.props.actions.showNonTradingOrderCommon();
		this.props.actions.sidebarClose();
	}

	showNonTradingOpenDemoAccountModal = () => {
		this.props.actions.showNonTradingOpenDemoAccount();
		this.props.actions.sidebarClose();
	}

	showNonTradingOpenAccountModal = () => {
		this.props.actions.showNonTradingOpenAccount();
		this.props.actions.sidebarClose();
	}

	showReportsPage = path => () => {
		const {
			actions,
			currentTradeAccount,
		} = this.props;
		if (currentTradeAccount.clearerFirm === 'cor') {
			actions.openReportsPage(path, currentTradeAccount);
		} else {
			actions.setCurrentPage(path);
		}
		scrollToTop();
		actions.sidebarClose();
	}

	showPage = path => () => {
		this.props.actions.setCurrentPage(path);
		scrollToTop();
		this.props.actions.sidebarClose();
	}

	showDemoAccounts = () => {
		this.showPage('/dashboard');
	}

	showModal = id => {
		this.props.history.push(addHashToUrl(this.props.location, id));
	}

	handleOpenSubMenu = key => {
		const { openSubMenu } = this.state;
		if (openSubMenu === key) {
			this.setState({ openSubMenu: null });
		} else {
			this.setState({ openSubMenu: key });
		}
	};

	render() {
		const {
			language,
			theme,
			classes,
			accounts,
			userInfo,
			currentAccountData: {
				baseParams,
			},
			hasBlockingDebts,
			selectedAccount,
			loadingAccounts,
		} = this.props;

		const userGuid = userInfo.Guid && userInfo.Guid.toUpperCase();
		const isEnableThirdPartyTools = userGuid && (!env.WEBCAB_4509_transaqChangePwdAvalilableFor
			|| env.WEBCAB_4509_transaqChangePwdAvalilableFor.includes(userGuid));
		const { openSubMenu } = this.state;
		const accountsListLoadingOrEmpty = loadingAccounts || !accounts?.length;
		const NotRealClientOrLoading = userGuid == null;
		
		return (
			<div>
				<List>
					{userInfo.IsPreopened
						&& (
							<ListItem button gaUrl="/welcome/" onClick={() => this.showModal(ModalPopups.WELCOME_PAGE)} className="QA-WelcomePage">
								<ListItemIcon>
									<SvgIcon
										icon="Star"
										htmlColor={theme.palette.primary.main}
									/>
								</ListItemIcon>
								<ListItemText
									className={classes.listItemText}
									primary="Welcome Page"
									disableTypography
								/>
							</ListItem>
						)}

					{[constants.LIME_LME].some(x => x === env.firm)
						&& (
							<>
								<ListItem button onClick={() => this.handleOpenSubMenu('accounts')} className="QA-Accounts">
									<ListItemIcon>
										<SvgIcon
											icon="BriefcaseAccount"
											htmlColor={theme.palette.primary.main}
										/>
									</ListItemIcon>
									<ListItemText
										className={classes.listItemText}
										primary={<R id="shared__accounts" defaultValue="Accounts" />}
										disableTypography
									/>
									{openSubMenu === 'accounts' ? <ExpandLess /> : <ExpandMore />}
								</ListItem>
								<Collapse in={openSubMenu === 'accounts'} timeout="auto" unmountOnExit>
									<List component="div" disablePadding>
											<ListItem button onClick={this.showPage('/dashboard')} className="QA-AccountOverview" disabled={NotRealClientOrLoading} >
												<ListItemText
													className={classes.nested}
													primary={<R id="common_menu_account_overview" defaultValue="Account owerview" />}
													disableTypography
												/>
											</ListItem>
											{!hasBlockingDebts && (
												<ListItem button 
													onClick={this.showNonTradingOpenAccountModal} 
													className="QA-OpenAdditionalLiveAccount" disabled={NotRealClientOrLoading}>
														
													<ListItemText
														className={classes.nested}
														primary={<R id="common_menu_add_live_account" defaultValue="Open additional live account" />}
														disableTypography
													/>
												</ListItem>
											)}
											
										{ accounts && accounts.filter(acc => acc.IsDemo).length > 0 &&
											<ListItem button onClick={this.showPage('/dashboard/demoaccounts')} className="QA-DemoAccounts">
												<ListItemText
													className={classes.nested}
													primary={<R id="common_menu_demo_overview" defaultValue="Demo accounts" />}
													disableTypography
												/>
											</ListItem> 
										}
										<ListItem button onClick={this.showNonTradingOpenDemoAccountModal} className="QA-OpenAdditionalDemoAccount">
											<ListItemText
												className={classes.nested}
												primary={<R id="common_menu_add_demo_account" defaultValue="Open additional demo account" />}
												disableTypography
											/>
										</ListItem>
									</List>
								</Collapse>
							</>
						)}
					{/* {!(baseParams && baseParams.IsCashAccount) &&
					<ListItem button gaUrl="/deposit/ach/" onClick={this.showDepositFundsModal}>
						<ListItemIcon>
							<SvgIcon
								icon="Deposit"
								htmlColor={theme.palette.primary.main}
							/>
						</ListItemIcon>
						<ListItemText
							className={classes.listItemText}
							primary="Deposit Funds"
							disableTypography
						/>
					</ListItem>
					} */}
					{[constants.LIME_INT, constants.LIME_NSR].some(x => x === env.firm)
						&& (
							<ListItem button onClick={this.showPage('/dashboard')} className="QA-AccountOverview">
								<ListItemIcon>
									<SvgIcon
										icon="BriefcaseAccount"
										htmlColor={theme.palette.primary.main}
									/>
								</ListItemIcon>
								<ListItemText
									className={classes.listItemText}
									primary={<R id="common_menu_account_overview" defaultValue="Account Overview" />}
									disableTypography
								/>
							</ListItem>
						)}
					{!((baseParams && baseParams.IsCashAccount) || (selectedAccount && selectedAccount.IsDemo))
					&& [constants.LIME_INT, constants.LIME_NSR, constants.LIME_LME].some(x => x === env.firm)
						&& (
							<ListItem button onClick={this.showPage('/dashboard/cashmanagement')} className="QA-CashManagement" disabled={NotRealClientOrLoading}>
								<ListItemIcon>
									<SvgIcon
										icon="Dollar"
										htmlColor={theme.palette.primary.main}
									/>
								</ListItemIcon>
								<ListItemText
									className={classes.listItemText}
									primary={<R id="common_menu_cash_management" defaultValue="Cash management" />}
									disableTypography
								/>
							</ListItem>
						)}
					{[constants.LIME_LME].some(x => x === env.firm)
						&& (
							<ListItem button onClick={this.showPage('/dashboard/tradingplatforms')} className="QA-TradingPlatforms">
								<ListItemIcon>
									<SvgIcon
										icon="App"
										htmlColor={theme.palette.primary.main}
									/>
								</ListItemIcon>
								<ListItemText
									className={classes.listItemText}
									primary={<R id="common_menu_trading_platforms" defaultValue="Trading platforms" />}
									disableTypography
								/>
							</ListItem>
						)}
					{(env.firm === constants.LIME_FIN)
						&& (
							<>
								<ListItem button onClick={this.showPage('/dashboard/documents')} className="QA-DocumentsAndDisclosures">
									<ListItemIcon>
										<SvgIcon
											icon="Document"
											htmlColor={theme.palette.primary.main}
										/>
									</ListItemIcon>
									<ListItemText
										className={classes.listItemText}
										primary={<R id="common_menu_documents_disclosures" defaultValue="Documents & Disclosures" />}
										disableTypography
									/>
								</ListItem>
							</>
						)}
					{[constants.LIME_INT].some(x => x === env.firm)
						&& (
							<>
								<ListItem button onClick={this.showPage('/dashboard/accountfeatures')} className="QA-AccountFeatures" disabled={accountsListLoadingOrEmpty || NotRealClientOrLoading}>
									<ListItemIcon>
										<SvgIcon
											icon="Star"
											htmlColor={theme.palette.primary.main}
										/>
									</ListItemIcon>
									<ListItemText
										className={classes.listItemText}
										primary={<R id="common_menu_account_features" defaultValue="Account features" />}
										disableTypography
									/>
								</ListItem>
							</>
						)}
					{[constants.LIME_INT, constants.LIME_NSR, constants.LIME_LME].some(x => x === env.firm)
						&& (
							<ListItem button onClick={this.showNonTradingOrderCommonModal} className="QA-FreeFormOrder" disabled={accountsListLoadingOrEmpty || NotRealClientOrLoading}>
								<ListItemIcon>
									<SvgIcon
										icon="EditDocument"
										htmlColor={theme.palette.primary.main}
									/>
								</ListItemIcon>
								<ListItemText
									className={classes.listItemText}
									primary={<R id="common_menu_free_form_order" defaultValue="Free form order" />}
									disableTypography
								/>
							</ListItem>
						)}
					{[constants.LIME_INT, constants.LIME_NSR, constants.LIME_LME, constants.LIME_FIN].some(x => x === env.firm)
						&& (
							<ListItem button onClick={this.showPage('/dashboard/settings')} className="QA-MyProfile">
								<ListItemIcon>
									<SvgIcon
										icon="Person"
										htmlColor={theme.palette.primary.main}
									/>
								</ListItemIcon>
								<ListItemText
									className={classes.listItemText}
									primary={<R id="common_menu_my_profile" defaultValue="My Profile" />}
									disableTypography
								/>
							</ListItem>
						)}
					{!(baseParams && baseParams.IsCashAccount)
					&& [constants.LIME_INT, constants.LIME_NSR, constants.LIME_LME].some(x => x === env.firm)
						&& (
							<ListItem button onClick={this.showReportsPage('/dashboard/reports')} className="QA-Reports" disabled={accountsListLoadingOrEmpty || NotRealClientOrLoading}>
								<ListItemIcon>
									<SvgIcon
										icon="Description"
										htmlColor={theme.palette.primary.main}
									/>
								</ListItemIcon>
								<ListItemText
									className={classes.listItemText}
									primary={<R id="common_menu_reports" defaultValue="Reports" />}
									disableTypography
								/>
							</ListItem>
						)}
					{[constants.LIME_INT, constants.LIME_NSR].some(x => x === env.firm)
						&& (
							<>
								<ListItem component="a" style={{ color: "inherit" }}  href={env.disclosuresUrl} target="_blank" className="QA-DocumentsAndDisclosures">
									<ListItemIcon>
										<SvgIcon
											icon="Document"
											htmlColor={theme.palette.primary.main}
										/>
									</ListItemIcon>
									<ListItemText
										className={classes.listItemText}
										primary={<R id="common_menu_disclosures" defaultValue="Disclosures" />}
										disableTypography
									/>
								</ListItem>
							</>
						)}
					{[constants.LIME_LME].some(x => x === env.firm)
						&& (
							<>
								<ListItem button component="a" href={`https://j2t.global/${language}/solutions/calendar/`} target="_blank" className="QA-TradingAssistance" disabled={NotRealClientOrLoading}>
									<ListItemIcon>
										<SvgIcon
											icon="Document"
											htmlColor={theme.palette.primary.main}
										/>
									</ListItemIcon>
									<ListItemText
										className={classes.listItemText}
										primary={<R id="common_menu_trading_assistance" defaultValue="Trading assinstance" />}
										disableTypography
									/>
								</ListItem>
							</>
						)}
					{[constants.LIME_LME].some(x => x === env.firm)
						&& (
							<ListItem button component="a" href={`https://social.j2t.global/portal/login?lang=${language}`} target="_blank" className="QA-SocialTrading" disabled={NotRealClientOrLoading}>
								<ListItemIcon>
									<SvgIcon
										icon="SocialTrading"
										htmlColor={theme.palette.primary.main}
									/>
								</ListItemIcon>
								<ListItemText
									className={classes.listItemText}
									primary={<R id="common_menu_social_trading" defaultValue="Social Trading" />}
									disableTypography
								/>
							</ListItem>
						)}
					{constants.LIME_LME === env.firm
						&& (
							<ListItem button onClick={this.showPage('/dashboard/contacts')} className="QA-Contacts">
								<ListItemIcon>
									<SvgIcon
										icon="Contacts"
										htmlColor={theme.palette.primary.main}
									/>
								</ListItemIcon>
								<ListItemText
									className={classes.listItemText}
									primary={<R id="common_menu_contacts" defaultValue="Contacts" />}
									disableTypography
								/>
							</ListItem>
						)}
					
					{isEnableThirdPartyTools && env.firm === constants.LIME_FIN
						&& (
							<ListItem button onClick={this.showPage('/dashboard/thirdpartytools')} className="QA-ThirdPartyTools">
								<ListItemIcon>
									<SvgIcon
										icon="ThirdPartyTools"
										htmlColor={theme.palette.primary.main}
									/>
								</ListItemIcon>
								<ListItemText
									className={classes.listItemText}
									primary={<R id="common_menu_third_party_tools" defaultValue="Third Party Tools" />}
									disableTypography
								/>
							</ListItem>
						)}
				</List>
			</div>
		);
	}
}

AccountActions.propTypes = {

};

export default compose(
	withRouter,
	withStyles(styles),
	withTheme,
	connect(mapStateToProps, mapDispatchToProps),
)(AccountActions);
