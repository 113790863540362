import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { env } from '@config';
import { toUsaDateFormat } from '@global';
import {
	FileDownload,
	SvgIcon 
} from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';


const useStyles = makeStyles(theme => ({

	reportLink: {
		display: 'flex',
		cursor: 'pointer',
	},
	container: {
		overflowX: 'auto',
	},
	progress: {

	},
	table: {
		width: '100%',
		verticalAlign: 'top',
		borderCollapse: 'collapse',
		'& th': {
			...theme.customstyles.body,
			color: theme.palette.text.secondary,
			padding: '10px',
		},
		'& td': {
			padding: '10px',
		},
		'& tbody': {
			verticalAlign: 'top',
		},
	},
	tableRow: {
		borderBottom: '1px solid #deeaef',
		verticalAlign: 'middle',
		'& td': {
		},
	},
	primaryItem: {
		...theme.customstyles.title,
		color: theme.palette.text.primary,
	},
	secondaryItem: {
		...theme.customstyles.body,
		color: theme.palette.text.secondary,
	},
	loader: {
		color: theme.palette.primary.main,
	},
}));


export const CYReportsTable = ({ items, submitting, showError }) => {

	const theme = useTheme();
	const classes = useStyles();

	const getStatus = (reportStatus) => {
		switch (reportStatus) {
			case 0: return (			
				<CircularProgress classes={{ root: classes.loader }} size={18} />
			);
			case 1: return (
				<span style={{ color: theme.palette.success.main, textTransform: 'uppercase' }}>
					<R id="common_reportstatus_ready" defaultValue="READY" />
				</span>);
			case 2: return (
				<span style={{ color: theme.palette.error.main, textTransform: 'uppercase' }}>
					<R id="common_reportstatus_error" defaultValue="ERROR" />
				</span>);
			default: return 'TEST';
		}
	};

	return (

		<table className={classes.table}>
			<thead>
				<tr>
					<th style={{ textAlign: 'left' }}><R id="shared_createdate" defaultValue="Create Date" /></th>
					<th style={{ textAlign: 'left' }}><R id="shared_period" defaultValue="Period" /></th>
					<th style={{ textAlign: 'left' }}><R id="shared_status" defaultValue="Status" /></th>
					<th style={{ textAlign: 'left' }}><R id="shared_actions" defaultValue="Actions" /></th>
				</tr>
			</thead>
			<tbody>
				{items && items.map((item, index) => (
					<tr className={classes.tableRow} key={index}>
						<td>
							{toUsaDateFormat(item.DateCteate)}
						</td>
						<td>
							<div>
								<div className={classes.secondaryItem}>{toUsaDateFormat(item.DateBegin)}</div>
								<div className={classes.secondaryItem}>{toUsaDateFormat(item.DateEnd)}</div>
							</div>
						</td>
						<td>
							<div className={classes.primaryItem}>
								{getStatus(item.Status)}
							</div>
						</td>
						<td>
							<div className={classes.primaryItem}>
								{item.Status === 1 && (
									<FileDownload
										url={`${env.backendUrl}reports/api_getReportFile/`}
										color="green"
										data={{
											reportId: item.Id,
										}}
										onError={data => showError((data && data.Errors && data.Errors[0].Message) || data)}
										fileName="Broker Report.csv"
										buttonTitle={<SvgIcon icon="DocXls" />}
									/>
								)}
							</div>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
}
