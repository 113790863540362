import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { cabinetLog } from '@global';
import { profile } from '@redux';
import { connect } from 'react-redux';
import {
	ErrorMessage,
	SuccessMessage,
} from '@components';
import { Modal } from '@dev2t/react-ui-kit/src/components';

const styles = theme => ({
	root: {
		display: 'block',
		position: 'relative',
	},
	progress: {
		display: 'flex',
		justifyContent: 'center',
	},
	fileUploader: {
		marginTop: '16px',
	},
	button: {
		paddingTop: '12px',
	},
	loaderRoot: {
		color: theme.palette.primary.main,
	},
});

const mapStateToProps = state => ({
	initialValues: {
		Attachments: [],
	},
	confirmEmail: profile.selectors.confirmEmail(state),
});

const mapDispatchToProps = dispatch => ({
	actions: {
		confirmEmailRequest: token => dispatch(profile.actions.confirmEmailGetUrl.request({ token })),
	},
});

class ConfirmProfileEmailModal extends React.Component {
	componentDidMount() {
		cabinetLog('ConfirmProfileEmailModal token', this.props.match.params.token);
		this.props.actions.confirmEmailRequest(this.props.match.params.token);
	}

	render() {
		const {
			classes,
			onClose,
			confirmEmail,
		} = this.props;

		return (
			<Modal
				title="Confirm Email"
				onClose={onClose}
			>
				{confirmEmail.submitting ?
					<div className={classes.progress}>
						<CircularProgress
							classes={{ root: loaderRoot }}
							size={58}
						/>
					</div> :
					<div>
						{confirmEmail.success ?
							<SuccessMessage
								onClick={onClose}
								text="Your request to change email is processed."
							/>
							:
							<ErrorMessage
								onClick={onClose}
								text={confirmEmail.error}
							/>
						}
					</div>
				}
			</Modal>
		);
	}
}

ConfirmProfileEmailModal.propTypes = {
	classes: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default compose(
	withRouter,
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(ConfirmProfileEmailModal);
