import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { DocumentDebtsAlert, NoAccountsAlert } from './components';

export const CYAlerts = ({ match }) => (
	<>
		<Switch>
			<Route path={`${match.url}/cashmanagement`} component={() => <DocumentDebtsAlert isMoneyOutPage />} />
			<Route path={`${match.url}`} component={() => <DocumentDebtsAlert />} />
		</Switch>
		<NoAccountsAlert />
	</>
);
