import React from 'react';
import PropTypes from 'prop-types';
import { R } from '@dev2t/react-ui-kit/src/components';

import { CYWithdrawalBankModal } from './components';

const CYWithdrawalKenyanBankModal = ({ onClose }) => (
	<CYWithdrawalBankModal
		title={<R id="orders_selectmethod_kenyan_bank_title" defaultValue="Kenyan Bank Account" />}
		onClose={onClose}
		currency="KES"
		form="CYWithdrawalKenyanBankForm"
	/>
);

CYWithdrawalKenyanBankModal.propTypes = {
	onClose: PropTypes.func.isRequired,
};

export default CYWithdrawalKenyanBankModal;
