import { providerWithAuth } from '@api';

export const wireWithdraw = payload =>
	providerWithAuth().post('/api_withdrawal/wire/', payload);

export const transferBetweenAccounts = query =>
	providerWithAuth().post('/api_transferBetweenAccounts', query);

export const stake = query =>
	providerWithAuth().post('/api_stake', query);

export const unstake = query =>
	providerWithAuth().post('/api_unstake', query);

export const exchangeCurrency = query =>
	providerWithAuth().post('/api_exhchangeCurrency', query);
