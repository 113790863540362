import { ModalPopups } from '@enums';
import actions from './actions';
import appActions from '../app/actions';
import auth from '../auth';


const initialState = {
	debtDocs: null,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
	case appActions.SHOW_BASE_MODAL:
		return {
			...state,
			debtDocs: [ModalPopups.CY_NON_TRADING_ORDER_IDENTITY, ModalPopups.CY_NON_TRADING_ORDER_REGISTRATION].includes(payload.modalName)
				? payload.modalData : null,
		};
	case appActions.HIDE_BASE_MODAL:
		return {
			...state,
			debtDocs: [ModalPopups.CY_NON_TRADING_ORDER_IDENTITY, ModalPopups.CY_NON_TRADING_ORDER_REGISTRATION].includes(payload.modalName)
				? null : state.debtDocs,
		};
	case auth.actions.LOGOUT_SUCCESS:
		return { ...initialState };

	default:
		return state;
	}
};

