import React from 'react';
import PropTypes from 'prop-types';
import { R } from '@dev2t/react-ui-kit/src/components';

import { CYWithdrawalBankModal } from './components';

const CYWithdrawalIndonesianBankModal = ({ onClose }) => (
	<CYWithdrawalBankModal
		title={<R id="orders_selectmethod_indonesian_bank_title" defaultValue="Indonesian Bank Account" />}
		onClose={onClose}
		currency="IDR"
		form="CYWithdrawalIndonesianBankForm"
	/>
);

CYWithdrawalIndonesianBankModal.propTypes = {
	onClose: PropTypes.func.isRequired,
};

export default CYWithdrawalIndonesianBankModal;
