import { fork, takeEvery, put } from 'redux-saga/effects';
import {
	makePartnerCabinetRedirectUrl,
	agentRegistration,
} from '@api';
import { BaseGetSagaHandler, userAccounts, app } from '@redux';
import actions from './actions';

function* partnerCabinetRedirectRequestSagaHandler({ payload }) {
	yield BaseGetSagaHandler({
		apiMethod: makePartnerCabinetRedirectUrl,
		errorText: 'Partner Account',
		handler: actions.partnerCabinetRedirect,
	}, { payload });
}

function* agentRegistrationRequestSagaHandler({ payload }) {
	yield BaseGetSagaHandler({
		apiMethod: agentRegistration,
		errorText: 'Partner Account',
		handler: actions.agentRegistration,
	}, { payload });
}
function partnerCabinetRedirectSuccessSagaHandler({ payload }) {
	window.location.href = payload.response.PartnerCabinetRedirectUrl;
}

function* watchAgentRegistrationSuccessSagaHandler({ payload }) {
	const w = window.open(payload.response.PartnerCabinetRedirectUrl, '_blank');
	if (w) {
		yield put(app.actions.hideAgentRegistrationFormModal());
		yield put(userAccounts.actions.getAccountsRequest());
		w.focus();
	} else {
		// Если всплывающие окна заблокированы
		window.location.href = payload.response.PartnerCabinetRedirectUrl;
	}
}
function* watchAgentRegistrationFailureSagaHandler({ payload }) {
	yield put(app.actions.hideAgentRegistrationFormModal());
}

function* watchPartnerCabinetRedirectRequest() {
	yield takeEvery(actions.partnerCabinetRedirect.REQUEST, partnerCabinetRedirectRequestSagaHandler);
}
function* watchPartnerCabinetRedirectSuccess() {
	yield takeEvery(actions.partnerCabinetRedirect.SUCCESS, partnerCabinetRedirectSuccessSagaHandler);
}
function* watchAgentRegistrationRequest() {
	yield takeEvery(actions.agentRegistration.REQUEST, agentRegistrationRequestSagaHandler);
}
function* watchAgentRegistrationSuccess() {
	yield takeEvery(actions.agentRegistration.SUCCESS, watchAgentRegistrationSuccessSagaHandler);
}
function* watchAgentRegistrationFailure() {
	yield takeEvery(actions.agentRegistration.FAILURE, watchAgentRegistrationFailureSagaHandler);
}

// Root saga
const rootSaga = [
	fork(watchPartnerCabinetRedirectRequest),
	fork(watchPartnerCabinetRedirectSuccess),
	fork(watchAgentRegistrationRequest),
	fork(watchAgentRegistrationSuccess),
	fork(watchAgentRegistrationFailure),
];

export default {
	rootSaga,
};
