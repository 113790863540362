import React from 'react';

const Svg = (
	<g fill="none" fillRule="nonzero">
		<path fill="#f9fcfd" d="M32.964 40.5l-7.732-7.732 3.536-3.536 12 12a2.5 2.5 0 0 1 0 3.536l-12 12-3.536-3.536 7.732-7.732H14v-5h18.964z" />
		<path fill="#f9fcfd" d="M37.828 30H58v-4H37.828l8.586-8.586-2.828-2.828-12 12a2 2 0 0 0 0 2.828l12 12 2.828-2.828L37.828 30z" opacity=".75" />
	</g>
);

export default Svg;
