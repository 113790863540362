import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import MUIListItemIcon from '@material-ui/core/ListItemIcon';

const styles = theme => ({
	root: {
		marginRight: '0',
	},
});

class ListItemIcon extends React.Component {
	render() {
		return (
			<MUIListItemIcon
				{...this.props}
			>
				{this.props.children}
			</MUIListItemIcon>
		);
	}
}

ListItemIcon.propTypes = {
	/**
	 * The content of the component, normally `Icon`, `SvgIcon`,
	 * or a `material-ui-icons` SVG icon element.
	 */
	children: PropTypes.element.isRequired,
	/**
	 * Useful to extend the style applied to components.
	 */
	classes: PropTypes.object.isRequired,
};

export default compose(withStyles(styles))(ListItemIcon);
