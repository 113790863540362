import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => {

	let rootStyle = {
		margin: '24px 74px',
		padding: '10px 16px',
		borderRadius: '4px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {
			margin: '24px 0',
			padding: '10px 16px',
		},
	};

	return createStyles({
		success: {
			...rootStyle,
			backgroundColor: theme.palette.success.light,
		},
		warning: {
			...rootStyle,
			backgroundColor: theme.palette.warning.main,
		},
		content: {
			...theme.customstyles.body,
			color: theme.palette.text.primary,
			margin: '1em auto',
		},
		text: {
			marginRight: '5px',
			[theme.breakpoints.down('sm')]: {
				display: 'block',
			},
		},
	});
});


export const PaymentGateCallback = ({title}) => {

	const classes = useStyles();

	return ( 
		<div>
			{title === 'success' &&
				<div className={classes.success}>
					<div className={classes.content}>
						<b>The transaction was successful.</b>
						<p>
							The funds will be credited to your account in a few minutes.
						 	Otherwise contact your manager or send your request to the e-mail <a href="mailto:support@int.lime.co">support@int.lime.co</a>
						 </p>
					</div>
				</div>
			}
			{title === 'fail' &&
				<div className={classes.warning}>
					<div className={classes.content}>
						<span className={classes.text}>Error occured when funding an account.</span>
						<span className={classes.text}>Use one of the alternative ways to retry.</span>
					</div>
				</div>
			}
			{title === 'complete' &&
				<div className={classes.success}>
					<div className={classes.content}>
						Account funding is completed.
					</div>
				</div>
			}
		</div>
	);
}