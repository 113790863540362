
import actions from './actions';
import selectors from './selectors';
import { fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { BaseGetSagaHandler, userAccounts } from '@redux';
import { getSecretAndQrCode, enableTwoFactor, disableTwoFactor } from '@api';

function* getSecretAndQrCodeSagaHandler() {
    yield BaseGetSagaHandler({
        apiMethod: getSecretAndQrCode,
        errorText: 'Error while getting data for TOTP',
        handler: actions.getSecretAndQrCode,
    }, {})
}

function* enableTwoFactorSubmitHandler({ payload }) {
	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: enableTwoFactor,
		errorText: 'Error while enabling 2FA',
		handler: actions.enableTwoFactor,
	}, { payload })

	if (!isSuccess) return;

	yield put(userAccounts.actions.getAccountsRequest());
}

function* disableTwoFactorSubmitHandler({ payload }) {
	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: disableTwoFactor,
		errorText: 'Error while disabling 2FA',
		handler: actions.disableTwoFactor,
	}, { payload })

	if (!isSuccess) return;

	yield put(userAccounts.actions.getAccountsRequest());
}

function* watchGetSecretAndQrCodeRequest() {
	yield takeEvery(actions.getSecretAndQrCode.REQUEST, getSecretAndQrCodeSagaHandler);
}

function* watchEnableTwoFactorSubmit() {
	yield takeEvery(actions.enableTwoFactor.REQUEST, enableTwoFactorSubmitHandler);
}

function* watchDisableTwoFactorSubmit() {
	yield takeEvery(actions.disableTwoFactor.REQUEST, disableTwoFactorSubmitHandler);
}

const rootSaga = [
    fork(watchGetSecretAndQrCodeRequest),
	fork(watchEnableTwoFactorSubmit),
	fork(watchDisableTwoFactorSubmit),
];

export default {
	rootSaga,
};
