export function	scrollTo(elementId) {
	const target = document.getElementById(elementId || 'dashboard-top');
	if (target) {
		setTimeout(() => {
			window.scroll(0, target.offsetTop);
		}, 400);
	}
}

export function	scrollToTop() {
	const target = document.getElementById('dashboard-top');
	if (target) {
		setTimeout(() => {
			window.scroll(0, target.offsetTop);
		}, 300);
	}
}

export function	scrollIntoView(elementId) {
	const target = document.getElementById(elementId);
	target.scrollIntoView();
}
// export const isStateLess = Component => !Component.prototype.render;
