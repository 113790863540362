import React from 'react';

class EmptyLinks extends React.Component {
	render() {
		return (
			<React.Fragment />
		);
	}
}

export default EmptyLinks;
