import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import * as qs from 'query-string';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { userAccounts } from '@redux';
import {
	ProgressContainer,
} from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';
import { addHashToUrl, scrollTo } from '@global';
import { 
	CYAccountCredentialsPanel,
	CYPersonalDocumentsPanel,
	TwoFactorCredentialsPanel
} from './components';

const styles = theme => ({
	root: {
		border: '1px solid red',
	},
	header: {
		...theme.customstyles.headline1,
		padding: '8px 0',
	},
	content: {
		...theme.customstyles.body,
		padding: '16px 72px',
		marginBottom: '30px',
		[theme.breakpoints.down('md')]: {
			padding: '8px 24px',
		},
	},
	mr60: {
		marginRight: '60px',
	},
	expansionPanelStaticContent: {
		margin: '10px 24px 5px 0px',
		color: theme.palette.text.secondary,
	},
	expansionPanelContent: {
		'& $expansionPanelRoot': {
			marginLeft: '24px',
		},
		'& $preFieldLabel': {
			marginLeft: '24px',
		},
		'& $noLeftMarginField': {
			marginLeft: '24px',
		},
	},
	preFieldLabel: {},
	noLeftMarginField: {
		marginLeft: '0px',
	},
	expansionPanelRoot: {
		marginLeft: '0px',
	},
});

const mapStateToProps = state => ({
	hasBlockingDebts: userAccounts.selectors.hasBlockingDebts(state),
	// profile: profile.selectors.profile(state),
	// currentAccountData: userAccounts.selectors.currentAccountData(state),
	// currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
	// accountInfo: userAccounts.selectors.accountInfo(state),
	// accountStatus: userAccounts.selectors.currentAccountStatus(state),
	// userInfo: userAccounts.selectors.userInfo(state),
});


const mapDispatchToProps = dispatch => ({
	actions: {
		// showChangeEmailModal: () => {
		// 	const changeEmailAction = profile.actions.showChangeEmailModal();
		// },
	},
});

class CYProfile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			openPanelId: 'profile-account-credentials',
		};
	}

	componentDidUpdate() {

		if (this.props.location.hash) {
			scrollTo(this.props.location.hash.substr(1));
		} else if (this.props.location.search) {
			const parsed = qs.parse(this.props.location.search);
			if (parsed && parsed.commission && parsed.commission.toLowerCase() === 'zero') {
				scrollTo('profile-commission-plan');
			}
		}
	}

	showModal = (id) => {
		this.props.history.push(addHashToUrl(this.props.location, id));
	}

	toggleOpen = (open, id) => {
		this.setState({ openPanelId: open ? id : null });
	};

	render() {
		const {
			classes,
			hasBlockingDebts,
		} = this.props;

		return (
			<div className={classes.content}>
				<div>
					<div className={classes.header}>
						<R id="common_menu_my_profile" defaultValue="My Profile" />
					</div>
				</div>
				{/* <ProgressContainer submitting={submitting} display="left"> */}
				<ProgressContainer submitting={false} disableCheckChildren display="left">
					<CYAccountCredentialsPanel
						classes={classes}
						onToggle={this.toggleOpen}
						panelId="profile-account-credentials"
						isOpen={this.state.openPanelId === 'profile-account-credentials'} />

					{!hasBlockingDebts && (
						<>
							<CYPersonalDocumentsPanel
								classes={classes}
								onToggle={this.toggleOpen}
								panelId="profile-personal-documents"
								isOpen={this.state.openPanelId === 'profile-personal-documents'} />
						</>
					)}
					
					<TwoFactorCredentialsPanel
						classes={classes}
						onToggle={this.toggleOpen}
						panelId="profile-two-factor"
						isOpen={this.state.openPanelId === 'profile-two-factor'} />
				</ProgressContainer>
			</div>
		);
	}
}

CYProfile.defaultProps = {
};

CYProfile.propTypes = {
	classes: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
};


export default compose(
	withRouter,
	withTheme,
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(CYProfile);
