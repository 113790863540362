import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { constants } from '@config';
import { ModalPopups } from '@enums';
import { profile, userAccounts, auth } from '@redux';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import { connect } from 'react-redux';
import {
	reduxForm,
	Field,
	formValueSelector,
} from 'redux-form';
import {
	A,
	Button,
	ProgressContainer,
	SuccessMessage,
} from '@components';
import { FormInput } from '@dev2t/react-ui-kit/src';
import { RequireSignatureModal } from '../../..';

const styles = (theme) => ({
	root: {
		display: 'block',
		position: 'relative',
	},
	confirmLink: {
		marginTop: '16px',
		paddingLeft: '24px',
	},
	button: {
		paddingTop: '12px',
	},
	inputItem: {
		'& div > div': {
			background: theme.palette.background.paper,	
		},
	}
});

const formSelector = formValueSelector('ChangeProfileEmailForm');

const mapStateToProps = (state) => {
	const modalParams = profile.selectors.modals(state)[ModalPopups.CHANGE_PROFILE_EMAIL];
	const userProfile = (modalParams && modalParams.profile) || profile.selectors.profile(state);
	const loading = userAccounts.selectors.loading(state);
	const userInfo = userAccounts.selectors.userInfo(state);
	const userGuid = !loading && userInfo.Guid && userInfo.Guid.toLowerCase();
	const forceConfirmationEmail = !!(modalParams && modalParams.forceConfirmationEmail);
	const prevEmail = userProfile.PersonalInformation.Email;
	return {
		initialValues: {
			clientId: userProfile.ClientId,
			guid: userProfile.Guid,
			Attachments: [],
			Value: forceConfirmationEmail ? prevEmail : '',
		},
		initialEmailValue: prevEmail,
		emailValue: formSelector(state, 'Value'),
		forceConfirmationEmail,
		selfEdit: userGuid === (userProfile.Guid && userProfile.Guid.toLowerCase()),
		loading,
	};
};

const mapDispatchToProps = dispatch => ({
	actions: {
		sendConfirmationCode: email => dispatch(auth.actions.sendEmailConfirmationCodeRequest( email, 'Confirmation')),
	},
});

const secondsOrMinutes = (n) => {
	if (n > 90) {
		return `${Math.round(0.5 + (n / 60))} minutes`;
	}
	return `${n} seconds`;
};
const isValidEmail = email => (email && constants.regExpEmail.test(email));

class ChangeProfileEmailModal extends React.Component {
	static validate(values, props) {
		const errors = {};

		if (!values.Value) {
			errors.Value = 'required';
		} else if (!(constants.regExpEmail.test(values.Value))) {
			errors.Value = 'Invalid Format';
		}

		if (!props.selfEdit && (!values.ConfirmationCode || values.ConfirmationCode.length < 6)) {
			errors.ConfirmationCode = 'required';
		}
		return errors;
	}

	constructor(props) {
		super(props);
		this.state = {
			hasConfirmationCode: false,
			currentCount: 0,
			intervalId: null,
		};
	}

	componentWillUnmount() {
		// use intervalId from the state to clear the interval
		clearInterval(this.state.intervalId);
	}

	onRequestVerificationCode = () => {
		const { actions, emailValue } = this.props;
		actions.sendConfirmationCode(emailValue);
		const intervalId = setInterval(this.timer, 1000);
		this.setState({
			hasConfirmationCode: true,
			currentCount: 300,
			intervalId,
			showCode: false,
			code: '',
		});
	}

	handleClickShowSmsCode = () => this.setState({ showCode: !this.state.showCode });

	timer = () => {
		if (this.state.currentCount === 0) {
			clearInterval(this.state.intervalId);
		} else {
			this.setState({ currentCount: this.state.currentCount - 1 });
		}
	}

	render() {
		const {
			classes,
			onClose,
			handleSubmit,
			invalid,
			submitting,
			submitSucceeded,
			loading,
			selfEdit,
			emailValue,
			initialEmailValue,
			forceConfirmationEmail, // true - if we want confirm prev email
		} = this.props;
		const {
			hasConfirmationCode,
			currentCount,
		} = this.state;

		const isPristine = forceConfirmationEmail ? false : emailValue === initialEmailValue;

		return (
			<RequireSignatureModal
				title="Change Email"
				onClose={onClose}
				bgColor="#f9fcfd"
				textColor="#345464"
				className={`QA-${ModalPopups.CHANGE_PROFILE_EMAIL}`}
			>
				{submitSucceeded && !selfEdit && <SuccessMessage onClick={onClose} text="Your request to change email is processed." />}
				{submitSucceeded && selfEdit &&
					<SuccessMessage
						onClick={onClose}
						text={
							<React.Fragment>
								<p>
									We have sent a email with a confirmation link to your new email.
								</p>
								<p>
									Please check your email and click on link to complete an update.
								</p>
							</React.Fragment>
						}
					/>
				}
				{!submitSucceeded &&
					<ProgressContainer submitting={loading}>
						<form
							onSubmit={handleSubmit(profile.actions.changeProfileEmailFormSubmitHandler)}
							className={classes.root}
						>
							<div className={classes.inputItem}>
								<Field
									component={FormInput}
									name="Value"
									label="New email"
									inputProps={{ autoComplete: 'off' }}
								/>
							</div>
							{selfEdit &&
								<p>
									You will receive a confirmation email after you submit this form.
									Follow the link in this email to confirm the changes.
								</p>
							}
							{!selfEdit &&
								<div className={classes.inputItem}>
									{hasConfirmationCode &&
										<Field
											component={FormInput}
											name="ConfirmationCode"
											label="Verification Code"
											type={this.state.showCode ? 'text' : 'password'}
											value={this.state.code}
											inputProps={{ autoComplete: 'off' }}
											endAdornment={
												<InputAdornment position="end">
													<IconButton
														aria-label="Toggle password visibility"
														onClick={this.handleClickShowSmsCode}
														// onMouseDown={this.handleMouseDownPassword}
													>
														{this.state.showCode ? <VisibilityOff /> : <Visibility />}
													</IconButton>
												</InputAdornment>
											}
										/>
									}
									<div className={classes.confirmLink}>
										{currentCount === 0 && initialEmailValue !== emailValue && isValidEmail(emailValue) &&
											<A
												upper
												onClick={this.onRequestVerificationCode}
											>
												{hasConfirmationCode ? 'Request Code Again' : 'Request Verification Code'}
											</A>
										}
										{currentCount > 0 &&
											<p>
												You can request new code in {secondsOrMinutes(currentCount)}
											</p>
										}
									</div>
								</div>
							}
							<div className={classes.button}>
								<Button
									fullWidth
									type="submit"
									variant="contained"
									color="primary"
									disabled={
										invalid || submitting || isPristine ||
										(!selfEdit && (!hasConfirmationCode || !currentCount))
									}
								>
									{submitting ?
										<CircularProgress
											
											size={18}
										/>
										: 'Change Email'
									}
								</Button>
							</div>
						</form>
					</ProgressContainer>
				}
			</RequireSignatureModal>
		);
	}
}

ChangeProfileEmailModal.propTypes = {
	classes: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	forceConfirmationEmail: PropTypes.bool.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	invalid: PropTypes.bool.isRequired,
	submitting: PropTypes.bool.isRequired,
	loading: PropTypes.bool.isRequired,
	selfEdit: PropTypes.bool.isRequired,
	submitSucceeded: PropTypes.bool.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: 'ChangeProfileEmailForm',
		validate: ChangeProfileEmailModal.validate,
	}),
)(ChangeProfileEmailModal);
