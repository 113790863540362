import env from '../../config/env';

/**
 * Логирует объект ошибки и name как extra data в Sentry/консоль
 * @param {string} name
 * @param {object} error
 * @param {boolean} [forceSentryLog] если параметр установлен в true, то возможно логирование в Sentry на локалке
 */
export function	captureException(name, error, forceSentryLog = false) {
	if (env.sentryRavenUrl === '') {
		console.error({ name, error });
	} else if ((error && error.message !== 'Unauthorized') || forceSentryLog) {
		window.Raven?.captureException(error, { extra: { title: name } });
	}
}

/**
 * Логирует объект ошибки в Sentry/консоль
 * @param {object} error
 * @param {boolean} [forceSentryLog] если параметр установлен в true, то возможно логирование в Sentry на локалке
 */
export function captureError(error, forceSentryLog = false) {
	if (env.sentryRavenUrl === '') {
		console.error(error);
	} else if ((error && error.message !== 'Unauthorized') || forceSentryLog) {
		window.Raven?.captureException(error);
	}
}

/**
 * Логирует ошибку с extra data в Sentry/консоль
 * @param {string} name имя ошибки
 * @param {object} data дополнительные данные, в формате { extra: { test1: 1, test2: 2 } }
 * @param {boolean} [forceSentryLog] если параметр установлен в true, то возможно логирование в Sentry на локалке
 */
export function captureExceptionWithData(name, data, forceSentryLog = false) {
	if (!env.sentryRavenUrl === '' || forceSentryLog) {
		window.Raven?.captureException(name, data);
	} else if (forceSentryLog) {
		console.error({ name, data });
	}
}
