import { createApiActions } from '../extensions';

const actions = {
	// actions

	SHOW_MESSAGE_MODAL: 'NOTIFICATIONS/SHOW_MESSAGE_MODAL',
	HIDE_MESSAGE_MODAL: 'NOTIFICATIONS/HIDE_MESSAGE_MODAL',

	showMessageModal: message => ({
		type: actions.SHOW_MESSAGE_MODAL,
		payload: {
			message,
		},
	}),
	hideMessageModal: () => ({
		type: actions.HIDE_MESSAGE_MODAL,
	}),

	getNotifications: createApiActions('NOTIFICATIONS/GET_NOTIFICATIONS'),
	getMoreNotifications: createApiActions('NOTIFICATIONS/GET_MORE_NOTIFICATIONS'),

	MARK_NEWS_AS_READ: 'NOTIFICATIONS/MARK_NEWS_AS_READ',

	markNewsAsRead: messageId => ({
		type: actions.MARK_NEWS_AS_READ,
		payload: {
			messageId,
		},
	}),

	markNewsAllAsRead: createApiActions('NOTIFICATIONS/MARK_NEWS_ALL_AS_READ'),
};

export default actions;
