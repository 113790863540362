import { R } from '@dev2t/react-ui-kit/src/components';
import { TwoFactorQrCodeSteps } from '@enums';
import { CircularProgress, IconButton, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { twoFactor } from '@redux';
import appConfig from 'app_config';
import React, { useEffect, useMemo, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
	secretKeyText: {
		color: theme.palette.primary.dark,
		fontWeight: 'bold',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: "10px 10px 10px 20px",
		marginBottom: "10px"
	},
	qrCodeImageError: {
		color: theme.palette.error.main,
	},
	instructionContainer: {
		padding: "0px 20px",
		"& p": {
			fontWeight: 'bold'
		},
		"& ul": {
			paddingLeft: '20px'
		}
	},
	secretKeyTitle: {
		marginTop: '10px',
		marginBottom: '10px'
	},
	imageContainer: {
		marginTop: "20px",
		alignSelf: 'center'
	},
	progress: {
		color: theme.palette.primary.main,
	}
}));

const splitStringToChunks = (str) => str
	? str.match(/(.{1,4})/g).join(' ')
	: str;

const SecretAndQrCode = () => {
	const classes = useStyles();

	const [copied, setCopied] = useState(false);

	const qrCodeLink = useSelector(twoFactor.selectors.qrCode.qrCodeLink);
	const secretCode = useSelector(twoFactor.selectors.qrCode.secretCode);
	const step = useSelector(twoFactor.selectors.qrCode.step);

	const dispatch = useDispatch();

	const splittedCode = useMemo(() => splitStringToChunks(secretCode), [secretCode]);

	useEffect(() => {
		dispatch(twoFactor.actions.getSecretAndQrCode.request())
	}, []);

	if (step === TwoFactorQrCodeSteps.INITIAL || step == TwoFactorQrCodeSteps.LOADING_DATA) {
		return <CircularProgress size={30} className={classes.progress} />
	}

	if (step === TwoFactorQrCodeSteps.LOADING_DATA_FAILURE) {
		return <div className={classes.qrCodeImageError}>
			<R id="_account_totp_error_loading_data" defaultValue="Could not load data" />
		</div>;
	}

	const image = step === TwoFactorQrCodeSteps.LOADING_IMAGE_FAILURE 
		? (
			<div className={classes.qrCodeImageError}>
				<R id="_account_totp_error_loading_image" defaultValue="Cannot load QR-code image" />
			</div>
		)
		: (
			<div className={classes.imageContainer}>
				<img
					src={`${appConfig.backendUrl}/qrcode?link=${qrCodeLink}`}
					alt="qrCode"
					onLoad={() => dispatch(twoFactor.actions.loadImage.success())}
					onError={() => dispatch(twoFactor.actions.loadImage.failure())}
				/>
				{step === TwoFactorQrCodeSteps.LOADING_IMAGE && <CircularProgress size={30} className={classes.progress} />}
			</div>
		);

	return (
		<>
			<Paper className={classes.instructionContainer}>
				<R id="account_totp_secret_key_instruction"
					enableHtml
					defaultValue={
						`<p>How to enable app based authentication:</p>
						 <ul>
							<li>Install an app (such as Google Authenticator) on your mobile device.</li>
							<li>Scan the QR code.</li>
							<li>Enter and verify the authentication code generated by the app.</li>
						</ul>`}
				/>
			</Paper>

			{image}

			<p className={classes.secretKeyTitle}>
				<R id="account_totp_secret_key_title" defaultValue="Your secret key" />
			</p>

			<Paper className={classes.secretKeyText}>
				<div>{splittedCode}</div>

				<CopyToClipboard
					text={secretCode}
					onCopy={() => setCopied(true)}
				>
					<IconButton>
						{copied ? <DoneIcon /> : <FileCopyIcon />}
					</IconButton>
				</CopyToClipboard>
			</Paper>
		</>
	);
};

export default SecretAndQrCode;