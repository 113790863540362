import { providerWithAuth } from '@api';

export const getLinkedAccounts = payload => providerWithAuth().get(`/linkedaccounts/api_list/${payload.tradeCode}`);

export const accountDetails = (tradeCode, token) => providerWithAuth().post('/linkedaccounts/api_accountdetails', {
	TradeCode: tradeCode,
	Token: token,
});
export const accountSelect = (tradeCode, linkId) => providerWithAuth().post('/linkedaccounts/api_accountchoice', {
	TradeCode: tradeCode,
	LinkAccountNo: linkId,
});

export const achLinking = payload =>	providerWithAuth().post('/linkedaccounts/api_achlinking', payload);
export const achUnlinkBankAccount = payload => providerWithAuth().get(`/linkedaccounts/api_remove/${payload.tradeCode}/${payload.bankCode}`);

export const wireLinking = payload =>	providerWithAuth().post('/linkedaccounts/api_wirelinking', payload);
export const wireUnlinkBankAccount = payload =>
	providerWithAuth().get(`/linkedaccounts/api_wireremove/${payload.clearerFirm}/${payload.tradeCode}/${payload.bankCode}`);
export const getIntrnationalBanksRequisites = query =>
	providerWithAuth().post('/linkedaccounts/api_internationalbanksrequisites', { query });
export const getDomesticBanksRequisites = query =>
	providerWithAuth().post('/linkedaccounts/api_domesticbanksrequisites', { query });
