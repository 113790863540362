import React, { useContext, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TranslateContext } from '@dev2t/react-ui-kit/src/components';

const htmlTags = {
	b: <strong/>,
};

export const useTranslate = (showDicwordsKeys = false) => {
	const { t, i18n, ready } = useTranslation();
	const translateContextData = useContext(TranslateContext);

	const extractArgs = (args) => {
		if (!args || args.length === 0) {
			throw new Error('DicWord code not specified');
		}
		if (args.length === 1 && typeof args[0] === 'object') {
			return args[0];
		}
		return {
			id: args[0],
			defaultValue: args.length > 1 ? args[1] : null,
		};
	};
	const retFunc = useMemo(() => (
		(...args) => {
			const {
				id, translateParameters, components, defaultValue, enableHtml,
			} = extractArgs(args);

			const transComponents =
				enableHtml || components
					? {
						...(enableHtml ? htmlTags : {}),
						...(components || {}),
					}
					: undefined;
			if (!ready) {
				return defaultValue || '';
			}
			if (enableHtml && !components) {
				return (
					<span
						// eslint-disable-next-line react/no-danger
						dangerouslySetInnerHTML={{
							__html: translateContextData.t(id, {
								...(translateParameters || {}),
								defaultValue,
								transComponents,
							}),
						}}
					/>
				);
			}

			const res = translateContextData.t(id, {
				...(translateParameters || {}),
				defaultValue,
				transComponents,
			});
			return res;
		}
	), [showDicwordsKeys, translateContextData, ready]);

	return retFunc;
};

export const withTranslate = Component => {
	const WithTranslateComponent = props => {
		const translate = useTranslate();
		return <Component {...props} translate={translate} />;
	};

	return WithTranslateComponent;
};


