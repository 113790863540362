import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import compose from 'recompose/compose';
import { R } from '@dev2t/react-ui-kit/src/components';
import { env } from '@config';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EmailIcon from '@material-ui/icons/Email';

const styles = theme => ({
	content: {
		...theme.customstyles.body,
		padding: '16px 56px',
		marginBottom: '30px',
		[theme.breakpoints.down('md')]: {
			padding: '8px 24px',
		},
	},
	contentItem: {
		marginBottom: '30px',
		'& a': {
			color: theme.palette.primary.main,
			textDecoration: 'none',
		},
	},
	endItem: {
		height: '30px',
	},
	header: {
		...theme.customstyles.headline1,
		...theme.palette.text.primary,
		marginLeft: '16px',
		padding: '8px 0',
	},
});

function ContactsJ2TX(props) {
	const {
		classes,
	} = props;

	return (
		<div className={classes.content}>
			<div>
				<div className={classes.header}>
					<R id="common_menu_contacts" defaultValue="Contacts" />
				</div>
			</div>
			<div className={classes.contentItem}>
				<p>
					<LocationOnIcon fontSize="inherit" color="primary" />
					<R id="shared_address" defaultValue="Address" />
				</p>
				<p>
					<R id="footer_j2tx_address" defaultValue="Chrysanthou Mylona, 10, Magnum House, 3030, Limassol, Cyprus" />
				</p>
				<p>
					<EmailIcon fontSize="inherit" color="primary" />
					&nbsp;
					<R id="contacts_label_email" defaultValue="E-mail" />
					:&nbsp;
					<a href={`mailto:${env.supportMail}`}>{env.supportMail}</a>
				</p>
			</div>
			<div className={classes.endItem} />
		</div>
	);
}

export default compose(
	withRouter,
	withTheme,
	withStyles(styles),
)(ContactsJ2TX);
