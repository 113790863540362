import {env} from '@config';

// locales for dates and ints in app
// Выбираем по одному, чтобы в сборку попали только нужные данные, а не всё вместе
import intlEn from 'react-intl/locale-data/en'; // Английский
import intlEs from 'react-intl/locale-data/es'; // Испанский
import intlMs from 'react-intl/locale-data/ms'; // Малазийский
import intlPl from 'react-intl/locale-data/pl'; // Польский
import intlRu from 'react-intl/locale-data/ru'; // Русский
import intlUk from 'react-intl/locale-data/uk'; // Украинский
import intlZh from 'react-intl/locale-data/zh'; // Китайский
import intlKo from 'react-intl/locale-data/ko'; // Корейский
import intlHi from 'react-intl/locale-data/hi'; // Хинди
import intlBg from 'react-intl/locale-data/bg'; // Болгарский
import intlEl from 'react-intl/locale-data/el'; // Греческий
import intlPt from 'react-intl/locale-data/pt'; // Португальский
import intlId from 'react-intl/locale-data/id'; // Индонезийский
import intlVi from 'react-intl/locale-data/vi'; // Вьетнамский
import intlIt from 'react-intl/locale-data/it'; // Итальянский
import intlTr from 'react-intl/locale-data/tr'; // Турецкий
import intlRo from 'react-intl/locale-data/ro'; // Румынский
import intlFr from 'react-intl/locale-data/fr'; // Французский
import intlDe from 'react-intl/locale-data/de'; // Немецкий

import stEn from '@dev2t/react-ui-kit/builds/i18n/data/translations.en.json';
import stEs from '@dev2t/react-ui-kit/builds/i18n/data/translations.es.json';
import stPl from '@dev2t/react-ui-kit/builds/i18n/data/translations.pl.json';
import stRu from '@dev2t/react-ui-kit/builds/i18n/data/translations.ru.json';
import stZh from '@dev2t/react-ui-kit/builds/i18n/data/translations.zh.json';
import stBg from '@dev2t/react-ui-kit/builds/i18n/data/translations.bg.json';
import stDe from '@dev2t/react-ui-kit/builds/i18n/data/translations.de.json';
import stEl from '@dev2t/react-ui-kit/builds/i18n/data/translations.el.json';
import stFr from '@dev2t/react-ui-kit/builds/i18n/data/translations.fr.json';
import stHi from '@dev2t/react-ui-kit/builds/i18n/data/translations.hi.json';
import stId from '@dev2t/react-ui-kit/builds/i18n/data/translations.id.json';
import stIt from '@dev2t/react-ui-kit/builds/i18n/data/translations.it.json';
import stKo from '@dev2t/react-ui-kit/builds/i18n/data/translations.ko.json';
import stMs from '@dev2t/react-ui-kit/builds/i18n/data/translations.ms.json';
import stPt from '@dev2t/react-ui-kit/builds/i18n/data/translations.pt.json';
import stRo from '@dev2t/react-ui-kit/builds/i18n/data/translations.ro.json';
import stTr from '@dev2t/react-ui-kit/builds/i18n/data/translations.tr.json';
import stUk from '@dev2t/react-ui-kit/builds/i18n/data/translations.uk.json';
import stVi from '@dev2t/react-ui-kit/builds/i18n/data/translations.vi.json';

const allDicWordLanguages = [
	{
		language: 'en',
		displayName: 'English',
		dicWordKey: 'en_lang',
		intlLocale: intlEn,
		sharedTranslate: stEn,
	},
	{
		language: 'ru',
		displayName: 'Русский',
		dicWordKey: 'ru_lang',
		intlLocale: intlRu,
		sharedTranslate: stRu,
	},
	{
		language: 'zh',
		displayName: '中文',
		dicWordKey: 'zh_lang',
		intlLocale: intlZh,
		sharedTranslate: stZh,
	},
	{
		language: 'es',
		displayName: 'Español',
		dicWordKey: 'es_lang',
		intlLocale: intlEs,
		sharedTranslate: stEs,
	},
	{
		language: 'pl',
		displayName: 'Polski',
		dicWordKey: 'pl_lang',
		intlLocale: intlPl,
		sharedTranslate: stPl,
	},
	{
		language: 'uk',
		displayName: 'Українська',
		intlLocale: intlUk,
		dicWordKey: 'uk_lang',
		sharedTranslate: stUk,
	},
	{
		language: 'ms',
		displayName: 'Malay',
		intlLocale: intlMs,
		dicWordKey: 'ms_lang',
		sharedTranslate: stMs,
	},
	{
		language: 'ko',
		displayName: '한국어',
		dicWordKey: 'ko_lang',
		intlLocale: intlKo,
		sharedTranslate: stKo,
	},
	{
		language: 'hi',
		displayName: 'हिंदी',
		dicWordKey: 'hi_lang',
		intlLocale: intlHi,
		sharedTranslate: stHi,
	},
	{
		language: 'bg',
		displayName: 'Български',
		dicWordKey: 'bg_lang',
		intlLocale: intlBg,
		sharedTranslate: stBg,
	},
	{
		language: 'el',
		displayName: 'Ελληνικά',
		dicWordKey: 'el_lang',
		intlLocale: intlEl,
		sharedTranslate: stEl,
	},
	{
		language: 'pt',
		displayName: 'Português',
		dicWordKey: 'pt_lang',
		intlLocale: intlPt,
		sharedTranslate: stPt,
	},
	{
		language: 'id',
		displayName: 'Indonesia',
		dicWordKey: 'id_lang',
		intlLocale: intlId,
		sharedTranslate: stId,
	},
	{
		language: 'vi',
		displayName: 'Tiếng Việt',
		dicWordKey: 'vi_lang',
		intlLocale: intlVi,
		sharedTranslate: stVi,
	},
	{
		language: 'it',
		displayName: 'Italiano',
		dicWordKey: 'it_lang',
		intlLocale: intlIt,
		sharedTranslate: stIt,
	},
	{
		language: 'tr',
		displayName: 'Türkiye',
		dicWordKey: 'tr_lang',
		intlLocale: intlTr,
		sharedTranslate: stTr,
  	},
	{
		language: 'ro',
		displayName: 'Română',
		dicWordKey: 'ro_lang',
		intlLocale: intlRo,
		sharedTranslate: stRo,
	},
	{
		language: 'fr',
		displayName: 'Français',
		dicWordKey: 'fr_lang',
		intlLocale: intlFr,
		sharedTranslate: stFr,
	},
	{
		language: 'de',
		displayName: 'Deutsch',
		dicWordKey: 'de_lang',
		intlLocale: intlDe,
		sharedTranslate: stDe,
	},
];

export const getAllowedLanguages = () => {
	if (env.allowedLangs) {
		return allDicWordLanguages.filter(x => env.allowedLangs.indexOf(x.language) >= 0);
	}
	return allDicWordLanguages.filter(x => x.language === 'en');
};

// // set allowed languages
export const setAlowedLanguages = (langs) => {
	return langs && langs.length ?
		getAllowedLanguages().filter(item => langs.some(x => x === item.language)) :
		getAllowedLanguages();
};

