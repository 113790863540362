import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import compose from 'recompose/compose';
import { LoadableScrollbars, SvgIcon } from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';
import { cabinetLog } from '@global';
import { connect } from 'react-redux';
import { app, notifications, userAccounts } from '@redux';
import { Item } from './components';


const styles = theme => ({
	root: {
		overflow: 'hidden',
		'& div': {
			// фикс для react-custom-scrollbars
			// при уменьшении масштаба из-за позиционирования скроллбара отрицательными margin
			// становится видна дефолтная полоска скроллбара
			scrollbarColor: `${theme.palette.background.default} ${theme.palette.background.default}`
		}
	},
	rootMobile: {
		overflow: 'auto',
	},
	body: {
	},
	caption: {
		...theme.customstyles.title,
		color: theme.palette.primary.dark,
		height: '56px',
		padding: '14px 0 14px 24px',
		marginRight: 'auto',
	},
	itemContainer: {
		padding: '0 16px 0 28px',
		'&:hover': {
			backgroundColor: theme.palette.background.default,
		},
	},
	item: {
		padding: '12px 0',
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	text: {
		...theme.customstyles.title,
		color: theme.palette.text.primary,
		textAlign: 'center',
	},
});

const mapStateToProps = state => ({
	items: notifications.selectors.notifications(state).items,
	pagination: notifications.selectors.pagination(state),
	unreadMessagesCount: notifications.selectors.notifications(state).unreadMessagesCount,
	currentAccount: userAccounts.selectors.currentAccount(state),
	loading: notifications.selectors.loading(state),
});

const mapDispatchToProps = (dispatch) => ({
	actions: {
		loadMore: () => dispatch(notifications.actions.getMoreNotifications.request()),
		markNewsAllAsRead: payload => dispatch(notifications.actions.markNewsAllAsRead.request(payload)),
		showConfirmModal: payload => dispatch(app.actions.showConfirmModal(payload)),
	},
});

class NotificationsContent extends React.Component {
	markAllAsRead = () => {
		const { actions, onClose, unreadMessagesCount } = this.props;
		if (onClose) {
			onClose();
		}
		actions.showConfirmModal({
			text: `Please confirm if you want to mark all notifications (${unreadMessagesCount}) as read`,
			action: onEndRequest => actions.markNewsAllAsRead({
				onEndRequest,
			}),
		});
	}

	render() {
		const {
			classes,
			mobileViewOpened,
			onClose,
			items,
			loading,
			pagination: {
				page,
				pagesCount,
			},
			unreadMessagesCount,
			actions: { loadMore },
		} = this.props;

		const canLoadMore = page < pagesCount;
		const rootNotificationsClass = mobileViewOpened ? classes.rootMobile : classes.root;
		return (
			<div id="notifications" className={rootNotificationsClass}>
				<LoadableScrollbars
					autoHide
					style={{ height: '100vh' }}
					canLoadMore={canLoadMore}
					loading={loading}
					onLoadMore={loadMore}
				>
					<div className={classes.header}>
						<div className={classes.caption}>
							<R id="common_notifications" defaultValue="Notifications" />
						</div>
						{unreadMessagesCount > 0 &&
							<div className={classes.icon}>
								<IconButton onClick={this.markAllAsRead}>
									<SvgIcon icon="MarkAsRead" />
								</IconButton>
							</div>
						}
						{onClose &&
							<div className={classes.icon}>
								<IconButton onClick={onClose}>
									<SvgIcon icon="Close" />
								</IconButton>
							</div>
						}
					</div>
					{items &&
						<div className={classes.body}>
							{items.filter(item => item.AlertType !== 'alert').map((item, index) => (
								<div className={classes.itemContainer} key={index}>
									<div className={classes.item}>
										<Item item={item} />
									</div>
								</div>
							))}
						</div>
					}
					{(!items || items.length === 0) && !loading &&
						<div className={classes.text}><R id="shared_no_data_available" defaultValue="No data available" /></div>
					}
				</LoadableScrollbars>
			</div>
		);
	}
}

NotificationsContent.propTypes = {
	mobileView: PropTypes.bool,
	mobileViewOpened: PropTypes.bool,
	unreadMessagesCount: PropTypes.number.isRequired,
};

NotificationsContent.defaultProps = {
	mobileView: false,
	mobileViewOpened: false,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(NotificationsContent);
