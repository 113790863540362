import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import MUIListItemText from '@material-ui/core/ListItemText';

const styles = theme => ({
	primary: {
		...theme.customstyles.body,
		color: theme.palette.text.primary,
	},
	secondary: {
		...theme.customstyles.body,
		color: theme.palette.text.secondary,
	},

});

class ListItemText extends React.Component {
	render() {
		return (
			<MUIListItemText
				{...this.props}
			>
				{this.props.children}
			</MUIListItemText>
		);
	}
}

ListItemText.propTypes = {
	/**
	 * Alias for the `primary` property.
	 */
	children: PropTypes.element,
	/**
	 * Useful to extend the style applied to components.
	 */
	classes: PropTypes.object.isRequired,
	/**
	 * If `true`, the children won't be wrapped by a typography component.
	 * For instance, that can be useful to can render an h4 instead of a
	 */
	disableTypography: PropTypes.bool,
	/**
	 * If `true`, the children will be indented.
	 * This should be used if there is no left avatar or left icon.
	 */
	inset: PropTypes.bool,
	primary: PropTypes.node,
	secondary: PropTypes.node,
};

ListItemText.defaultProps = {
	disableTypography: false,
	inset: false,
};

export default compose(withStyles(styles))(ListItemText);
