import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import classNames from 'classnames';

const styles = theme => ({
	root: {
		textDecoration: 'none',
		cursor: 'pointer',
		color: theme.palette.primary.main,
	},
	upper: {
		textTransform: 'uppercase',
	},
});

const A = ({
	classes,
	upper,
	theme,
	style,
	...props
}) => {
	const cssClasses = classNames({
		[classes.root]: true,
		[classes.upper]: upper,
	});
	return (
		<a className={cssClasses} style={{
			...style,
		}} {...props}>{props.children}</a>
	);
};

A.propTypes = {
	children: PropTypes.node.isRequired,
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	style: PropTypes.object,
	color: PropTypes.string,
	upper: PropTypes.bool,
};

A.defaultProps = {
	upper: false,
	style: {},
};

export default compose(
	withStyles(styles),
	withTheme,
)(A);
