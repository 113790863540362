import MomentUtils from '@date-io/moment';

class CustomMomentUtils extends MomentUtils {
	getDatePickerHeaderText(date) {
		let curLanguage = date._locale._abbr;
		if (curLanguage === 'zh-cn') {
			return (
				date.format('MMM').substring(0, 3) +
				date.format(' D') +
				'日' +
				', ' +
				date.format('dddd')
			);
		} else {
			return (
				date.format('dddd').substring(0, 3) +
				' ' +
				date.format('MMM').substring(0, 3) +
				date.format(' D')
			);
		}
	}

	getCalendarHeaderText(date) {
		let curLanguage = date._locale._abbr;
		if (curLanguage === 'zh-cn') {
			let month = date.month() + 1;
      return date.format('YYYY') + '年' + month + '月';
		} else {
			return date.format('MMM YYYY');
		}
	}
}

export default CustomMomentUtils;
