import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { CircularProgress } from '@material-ui/core';
import {
	FormattedTick,
	useTranslate,
	ContentCloud,
	LogoIcon, 
	Button,
} from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';
import { AccountType } from '@enums';
import { useMoneyFormat } from '@hooks';
import { userAccounts, rates } from '@redux';
import { useAccount, useBalance } from '@hooks';


import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
	currency: {
		fontSize: '12px',
		lineHeight: '14px',
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	headCell: {
		paddingTop: 0,
		paddingBottom: 0,
		border: 'none',
		fontSize: '12px',
		lineHeight: 1.5,
		verticalAlign: 'top',
		boxSizing: 'border-box',

		[theme.breakpoints.down('sm')]: {
			'&:first-of-type': {
				paddingLeft: 0,
			},
		},
	},
	rowCell: {
		paddingTop: '14px',
		paddingBottom: '14px',
		boxSizing: 'border-box',
		fontSize: '16px',
		color: theme.palette.text.primary,
		textWrap: 'nowrap',
		[theme.breakpoints.down('sm')]: {
			'&:first-of-type': {
				paddingLeft: '4px',
			},
		},
	},
	open: {
		padding: '0 50px 32px',
		[theme.breakpoints.down('sm')]: {
			padding: '0 40px 20px',
		},
	},
	content: {
		overflowX: 'auto',
	},
	button: {
		flex: '1 0 80px',
		minWidth: '80px',
		width: '100%',
		margin: '5px',
	},
	buttonSection: {
		display: 'flex',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		maxWidth: '50%',
		padding: theme.spacing(1),
		alignItems: 'center',
	},
}));

const MyTableRow = withStyles(theme => ({
	root: {
		height: '65px',
		borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
		'&:last-child': {
			borderBottom: 'none',
		},
	},
}))(TableRow);

const MyTableCell= withStyles(theme => ({
	root: {
		borderBottom: 'none',
	},
}))(TableCell);

export default function Balance({ tradeCode }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const translate = useTranslate();
	const moneyFormat = useMoneyFormat({ precision: 8, disableCurrencyCode: true });
	const moneyFormatForTotal = useMoneyFormat({ precision: 2 });
	const { stakingRates, stakableCurrencies, submitting } = useSelector(rates.selectors.staking);

	const [isOpen, setIsOpen] = useState(true);
	const onToggleOpen = () => setIsOpen(open => !open);


	useEffect(() => {
		// на первое открытие, если еще нет истрии операций
		if (!submitting && stakingRates.length === 0) {
			dispatch(rates.actions.getStakingRates.request());
		}
		
		// автообновление списка транзакций и балансов
		if (isOpen) {
			const timer = setInterval(() => {
				dispatch(rates.actions.getStakingRates.request());
				dispatch(userAccounts.actions.getAllAccountsInfo.request());
			},  5 * 60 * 1000);
			return () => clearInterval(timer);	
		}
	}, [isOpen]);

	const balance = useBalance(tradeCode);
	const loading = useSelector(userAccounts.selectors.accountsInfoLoading) ?? true;

	const showStakingModal = (walletTradeCode, stakingTradeCode) => dispatch(userAccounts.actions.showTransferWalletToStakingModal({ tradeCodeFrom: walletTradeCode, tradeCodeTo: stakingTradeCode }));
	const showUnstakingModal = stakingTradeCode => dispatch(userAccounts.actions.showTransferStakingToWalletModal({ tradeCodeFrom: stakingTradeCode }));

// ==================== вычисляем активность кнопок ====================== //
	const walletAccounts = useAccount(AccountType.WALLET).map(x => x.TradeCode);
	const walletBalance = useBalance(walletAccounts);
	
	// можем пополнить стейкинг счет только если есть wallet счет, с валютами, которые можно перевести на стейкинг
	const isAllowedStaking = [...walletBalance?.values() ?? []].flat().some(x => x.IsAllowedStaking);
	
	// перевести со стейкинга только если есть валюты доступные для перевода
	const isAllowedUnstaking = tradeCode && [...balance?.values() ?? []].some(x => x.IsAllowedTransfer);
// ======================================================================= //
		

// показываем только валюты, разрешенные для стэйкинга, даже если баланс по счету 0
	
	const showableBalance = balance?.filter(x => stakableCurrencies?.includes(x.CurrencyCode)) ?? []

	stakableCurrencies?.forEach(currency => {
		if (!showableBalance.find(x => currency === x.CurrencyCode)) {
			showableBalance.push({
				CurrencyCode: currency,
				Balance: 0,
				EarnedYesterday: 0,
				EarnedTotal: 0,
			})
		}
	})

// ======================================================================= //

	return (
		<ContentCloud
			isOpen={isOpen}
			submitting={loading} 
			onToggleOpen={onToggleOpen}
			id={'StakingBalance' + tradeCode}
			header={`${translate('total_value_staked', 'Total value Staked')}: ${moneyFormatForTotal(balance?.find(x => x.CurrencyCode === 'USD')?.EquityValue ?? 0, '$')}`} 
		>
			<Table className={classes.table}>
				<TableHead>
					<TableRow>
						<TableCell classes={{ root: classes.headCell }}>
							<R
								id="common_cryptocurrency"
								defaultValue="Cryptocurrency"
							/>
						</TableCell>
						<TableCell classes={{ root: classes.headCell }}>
							<R
								id="shared_amount"
								defaultValue="Price"
							/>
						</TableCell>
						<TableCell classes={{ root: classes.headCell }}>
							<R
								id="common_apr"
								defaultValue="APR"
							/>
						</TableCell>
						<TableCell classes={{ root: classes.headCell }}>
							<R
								id="common_earned_yesterday"
								defaultValue="Earned yesterday"
								/>
						</TableCell>
						<TableCell classes={{ root: classes.headCell }}>
							<R
								id="common_earned_total"
								defaultValue="Earned Total"
							/>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{showableBalance.map(x => 
						<MyTableRow key={x.CurrencyCode}>
							<MyTableCell>
								<LogoIcon
									currency={x.CurrencyCode}
									classes={{ currency: classes.currency }}
								/>
							</MyTableCell>
							<MyTableCell classes={{ root: classes.rowCell }}>
								{moneyFormat(x.Balance, x.CurrencyCode)}
							</MyTableCell>
							<MyTableCell classes={{ root: classes.rowCell }}>
								{submitting 
									? <CircularProgress size={12}/>
									: <FormattedTick
										value={stakingRates[x.CurrencyCode]}
										compareWithZero
										measureUnit="%"
										initialPositive={true}
									/>
								}
							</MyTableCell>
							<MyTableCell classes={{ root: classes.rowCell }}>
								{moneyFormat(x.EarnedYesterday ?? 0, x.CurrencyCode)}
							</MyTableCell>
							<MyTableCell classes={{ root: classes.rowCell }}>
								{moneyFormat(x.EarnedTotal ?? 0, x.CurrencyCode)}	
							</MyTableCell>
						</MyTableRow>
					)}	
				</TableBody>
			</Table>
			
			<Box className={classes.buttonSection} >
				<Button
					className={classes.button}
					color="primary"
					variant="outlined"
					size="small"
					onClick={() => showStakingModal(walletAccounts[0], tradeCode)}
					disabled={!isAllowedStaking}
				>
					<R id="shared_stake" defaultValue="Stake" />
				</Button>
				<Button
					className={classes.button}
					color="primary"
					variant="outlined"
					size="small"
					onClick={() => showUnstakingModal(tradeCode)}
					disabled={!isAllowedUnstaking}
				>
					<R id="shared_unstake" defaultValue="Unstake" />
				</Button>
			</Box>
		</ContentCloud>
	);
}
