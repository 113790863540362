export default {
	STATE_FIELD_NAME: 'app',
	ACTION_PREFIX: 'APP',
	RESOLUTIONS_ENUM: {
		xs: 1,
		sm: 2,
		md: 3,
		lg: 4,
		xl: 5,
	},
};
