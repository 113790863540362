import React from 'react';

const Svg = (
	<svg viewBox="0 0 0.84 0.84" xmlns="http://www.w3.org/2000/svg">
		<g fill="none" fillRule="evenodd">
			<path fill="#26A17B" d="M.84.42a.42.42 0 0 1-.42.42A.42.42 0 0 1 0 .42a.42.42 0 0 1 .84 0z" />
			<path fill="#FFF" d="M.47.456.419.457.367.456C.265.452.189.434.189.412c0-.021.076-.039.178-.044v.069l.052.002A.334.334 0 0 0 .47.437V.368C.572.373.648.39.648.412.648.433.572.451.47.455m0-.094V.299h.142V.205H.226V.3h.142v.062C.252.367.166.39.166.418c0 .027.087.05.202.056v.199h.103V.474C.586.469.673.446.673.418.673.391.586.368.471.362" />
		</g>
	</svg>
);


export default Svg;
