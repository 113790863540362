import { fork, takeEvery, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { BaseGetSagaHandler, callApi } from '@redux';
import { getNotifications, markNewsAsRead, markNewsAllAsRead } from '@api';
import { ModalPopups } from '@enums';
import { addHashToUrl, removeHashFromUrl, captureError } from '@global';
import actions from './actions';
import selectors from './selectors';

function* getNotificationsRequestSaga() {
	yield BaseGetSagaHandler({
		apiMethod: getNotifications,
		errorText: 'Get notifications',
		handler: actions.getNotifications,
	}, { payload: { currentPage: 1, pageSize: 20 } });
}

function* getMoreNotificationsRequestSaga() {
	const { page, pageSize } = yield select(selectors.pagination);
	yield BaseGetSagaHandler({
		apiMethod: getNotifications,
		errorText: 'Get more notifications',
		handler: actions.getMoreNotifications,
	}, { payload: { currentPage: page + 1, pageSize } });
}

function* getShowMessageModalRequestSaga({ payload: { message } }) {
	try {
		// get current location
		const location = yield select(state => state.router.location);
		// show modal
		yield put(push(addHashToUrl(location, ModalPopups.MESSAGE)));

		if (!message.Read) {
			const response = yield callApi(markNewsAsRead, message.Id);
			if (response.data.Success) {
				yield put(actions.markNewsAsRead(message.Id));
			}
		}
	} catch (error) {
		captureError(error);
	}
}

function* hideMessageModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(removeHashFromUrl(location, ModalPopups.MESSAGE)));
}

function* markNewsAllAsReadSaga({ payload: { onEndRequest, ...others } }) {
	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: markNewsAllAsRead,
		errorText: 'Notifications - Mark all as read',
		handler: actions.markNewsAllAsRead,
		onEndRequest,
	}, { payload: others });

	if (isSuccess) {
		yield put(actions.getNotifications.request());
	}
}

function* watchGetNotificationsRequest() {
	yield takeEvery(actions.getNotifications.REQUEST, getNotificationsRequestSaga);
}

function* watchGetMoreNotificationsRequest() {
	yield takeEvery(actions.getMoreNotifications.REQUEST, getMoreNotificationsRequestSaga);
}

function* watchShowMessageModalRequest() {
	yield takeEvery(actions.SHOW_MESSAGE_MODAL, getShowMessageModalRequestSaga);
}

function* watchHideMessageModal() {
	yield takeEvery(actions.HIDE_MESSAGE_MODAL, hideMessageModal);
}

function* watchMarkNewsAllAsReadRequest() {
	yield takeEvery(actions.markNewsAllAsRead.REQUEST, markNewsAllAsReadSaga);
}

// Root saga
const rootSaga = [
	fork(watchGetNotificationsRequest),
	fork(watchGetMoreNotificationsRequest),
	fork(watchShowMessageModalRequest),
	fork(watchHideMessageModal),
	fork(watchMarkNewsAllAsReadRequest),
];

export default {
	rootSaga,
};
