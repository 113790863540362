import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { push } from 'connected-react-router';
import { profile } from '@redux';
import {
	A,
} from '@components';
import { Modal } from '@dev2t/react-ui-kit/src/components';
import { ModalPopups } from '@enums';

const useStyles = makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
});

const DemoAccountModal = () => {
	const classes = useStyles();

	const handleClick = () => {
		// тут ресет демо аккаунта
	};

	return (
		<Modal
			title="Demo account expired"
			className={`QA-${ModalPopups.DEMO_ACCOUNT_EXPIRATION}`}
		>
			<div className={classes.root}>
				<p>Your demo account has expired. Open an account to maintain access.</p>
				<A onClick={() => handleClick()} href="https://open.lime.co/register">
                    Open an account to maintain access
				</A>
			</div>
		</Modal>
	);
};

export default DemoAccountModal;
