const actions = {

	SHOW_ERROR_MESSAGE: 'SNACKBAR/SHOW_ERROR_MESSAGE',
	HIDE_ERROR_MESSAGE: 'SNACKBAR/HIDE_ERROR_MESSAGE',
	SHOW_MESSAGE: 'SNACKBAR/SHOW_MESSAGE',
	HIDE_MESSAGE: 'SNACKBAR/HIDE_MESSAGE',
	showErrorMessage: payload => ({
		type: actions.SHOW_ERROR_MESSAGE,
		payload,
	}),
	hideErrorMessage: () => ({
		type: actions.HIDE_ERROR_MESSAGE,
	}),
	showMessage: payload => ({
		type: actions.SHOW_MESSAGE,
		payload,
	}),
	hideMessage: () => ({
		type: actions.HIDE_MESSAGE,
	}),
};

export default actions;
