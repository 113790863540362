import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
	userAccounts,
	depositFunds,
} from '@redux';
import {
	reduxForm,
	Field,
	formValueSelector,
} from 'redux-form';
import {
	FormSelect,
	BankLogo,
	Button,
	LimeLogo,
	SuccessMessage,
	SvgIcon,
} from '@components';
import { FormInputNumber } from '@dev2t/react-ui-kit/src';
import { WithdrawInfo, BrokerAccountInfo } from '@shared';
import { toUsaMoneyFormat } from '@global';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import LongArrow from './arrow.svg';
import j2tlogo from './j2t.png';

const styles = theme => ({
	row: {
		display: 'flex',
	},
	amount: {
		marginTop: '30px',
		paddingLeft: '50px',
	},
	amountInput: {
		paddingLeft: '70px',
	},
	bankAccountContainer: {},
	accountContainer: {
		// marginTop: '-15px',
	},
	logo: {
		padding: '16px 16px 4px 16px',
		position: 'relative',
		width: '80px',
		height: '44px',
	},
	sourceAccountInfo: {
		marginTop: '0',
		marginBottom: '-48px',
		paddingLeft: '50px',
	},
	destinationAccountInfo: {
		marginTop: '0',
		paddingLeft: '50px',
	},
	arrow: {
		paddingLeft: '24px',
		height: '84px',
	},
	arrowImg: {
		position: 'relative',
		marginTop: '-18px',
		top: '50%',

	},
	bankAccountLogo: {
		position: 'relative',
		width: '42px',
		height: '42px',
		top: '50%',
		marginTop: '-21px',
	},
	inputContainer: {
		display: 'flex',
		width: '100%',
		...theme.customstyles.title,
	},
	account: {
		width: '100%',
		textAlign: 'left',
	},
	accountBalance: {
		width: '100%',
		textAlign: 'right',
	},
	button: {
		marginTop: '45px',
	},
});

const DEPOSIT_BROKER_FUNDS_FORM = 'depositBrokerFundsForm';
const formSelector = formValueSelector(DEPOSIT_BROKER_FUNDS_FORM);

const mapStateToProps = (state, ownProps) => {
	const currentTradeAccount = userAccounts.selectors.currentTradeAccount(state);
	return {
		initialValues: {
			bankAccount: ownProps.banklAccount,
			bankAccountNumber: ownProps.bankAccount.Number,
			bankAccountInfo: {
				submitting: false,
				cashToWithdrawValue: null,
				totalAccountValue: null,
			},
			TradeCode: currentTradeAccount.tradeCode,
			ClearingFirm: currentTradeAccount.clearerFirm,
			Amount: ownProps.amount,
			Direction: 'OnlineToInc',
		},
		linkedAccounts: userAccounts.selectors.linkedAccounts(state),
		selectedBankAccountNumber: formSelector(state, 'bankAccountNumber'),
		getFormValue: fieldName => formValueSelector(DEPOSIT_BROKER_FUNDS_FORM)(state, fieldName),
	};
};

const mapDispatchToProps = dispatch => ({
	actions: {
		hideDepositFundsModal: () => dispatch(depositFunds.actions.hideDepositFundsModal()),
	},
});

class DepositFundsForm extends React.Component {
	static validate(values) {
		const errors = {};
		if (!values.bankAccountNumber) {
			errors.bankAccountNumber = 'required';
		}
		if (!values.amount) {
			errors.amount = 'required';
		}
		return errors;
	}
	render() {
		const {
			classes,
			actions,
			tradeAccount,
			linkedAccounts,
			handleSubmit,
			selectedBankAccountNumber,
			submitSucceeded,
			bankAccount,
			getFormValue,
			form,
			...props
		} = this.props;

		const linkedBrokerAccounts = [{
			Type: 'BROKER',
			Number: bankAccount.Number,
			displayNumber: bankAccount.Number,
			Balance: undefined,
		}]; // linkedAccounts.filter(it => it.Type === 'BROKER');
		const selectedBankAccount = linkedBrokerAccounts.find(it => it.Number === selectedBankAccountNumber);

		return submitSucceeded ? <SuccessMessage
			onClick={actions.hideDepositFundsModal}
			text="Banking transaction completed"
			buttonText="Thank you"
		/> :
			(
				<form onSubmit={handleSubmit(depositFunds.actions.depositBrokerFundsForm)}>
					<div className={classes.bankAccountContainer}>
						<div className={classes.row}>
							<div className={classes.logo}>
								{selectedBankAccount &&
									<img src={j2tlogo} alt="Just2Trade logo" className={classes.bankAccountLogo} />
								}
							</div>
							<Field component={FormSelect} name="bankAccountNumber" label="Bank Account" disabled>
								{linkedBrokerAccounts &&
								(linkedBrokerAccounts.length > 0) &&
								linkedBrokerAccounts.map((linkedAccount, index) => (
									<MenuItem value={linkedAccount.Number} key={index}>
										<div className={classes.inputContainer}>
											<div className={classes.account}>*{linkedAccount.displayNumber}</div>
											{linkedAccount.Balance &&
												<div className={classes.accountBalance}>
													{toUsaMoneyFormat(linkedAccount.Balance)}
												</div>
											}
										</div>
									</MenuItem>
								))}
							</Field>
						</div>
						{getFormValue('bankAccountNumber') &&
							<BrokerAccountInfo
								classes={{ root: classes.sourceAccountInfo }}
								account={getFormValue('bankAccountNumber')}
								accountInfo={getFormValue('bankAccountInfo')}
								name="bankAccountInfo"
								form={form}
							/>
						}
					</div>
					<div className={classes.arrow}>
						{selectedBankAccount &&
							<img alt="Long Arrow Down" src={LongArrow} className={classes.arrowImg} />
						}
					</div>
					<div className={classes.accountContainer}>
						<div className={classes.row}>
							<div className={classes.logo}>
								<LimeLogo />
							</div>
							<Field component={FormSelect} name="TradeCode" label="Trade Account" disabled>
								<MenuItem value={tradeAccount.tradeCode}>
									<div className={classes.inputContainer}>
										<div className={classes.account}>{tradeAccount.tradeCode}</div>
									</div>
								</MenuItem>
							</Field>
						</div>
						<WithdrawInfo classes={{ root: classes.destinationAccountInfo }} tradeCode={getFormValue('TradeCode')} />
					</div>
					<div className={classes.amount}>
						<Field component={FormInputNumber} name="Amount" label="Enter the Amount here" />
					</div>
					<div className={classes.button}>
						<Button
							fullWidth
							type="submit"
							variant="contained"
							color="primary"
							disabled={props.invalid || props.submitting}
						>
							{props.submitting ?
								<CircularProgress
									
									size={18}
								/>
								: 'Deposit'
							}
						</Button>
					</div>
				</form>
			);
	}
}

DepositFundsForm.propTypes = {
	classes: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	linkedAccounts: PropTypes.array.isRequired,
	bankAccount: PropTypes.object.isRequired,
	tradeAccount: PropTypes.object.isRequired,
	selectedBankAccountNumber: PropTypes.string,
	submitSucceeded: PropTypes.bool,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: DEPOSIT_BROKER_FUNDS_FORM,
		validate: DepositFundsForm.validate,
	}),
)(DepositFundsForm);
