import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { useMoneyFormat } from '@hooks';

const useStyles = makeStyles(theme => ({
	positive: {
		color: theme.palette.success.main,
	},
	negative: {
		color: theme.palette.error.main,
	},
}));

export default function FormattedTick({ value: newValue, measureUnit, isMoney, initialPositive = null, compareWithZero = false }) {
	const classes = useStyles();
	const [value, setValue] = useState(null);
	const [isPositiveChange, setIsPositiveChange] = useState(initialPositive);
	const moneyFormat = useMoneyFormat();

	useEffect(() => {
		const isNumber = typeof newValue === 'number' && !Number.isNaN(newValue);
		if (!isNumber) {
			return;
		}

		setValue(newValue);

		if (newValue === null) {
			return;
		}

		if (compareWithZero) {
			if (newValue === 0) {
				setIsPositiveChange(null); // изменение 0 - бесцветное
			} else {
				setIsPositiveChange(newValue > 0);
			}
		} else if (value !== null) { // не первый рендер, для сохранения initialPositive
			setIsPositiveChange(newValue > value); // если сравниваем не с 0 - сравниваем с предыдущим значением
		}
	}, [newValue]);

	const getFormattedValue = () => {
		if (value === null) {
			return '-';
		}

		if (isMoney) {
			return moneyFormat(value, measureUnit);
		}

		if (!measureUnit) {
			return value;
		}

		if (measureUnit === '%'){
			return `${value}${measureUnit}`;
		}
		
		return `${value} ${measureUnit}`;
	};

	return (
		<span
			className={classNames({
				[classes.positive]: isPositiveChange,
				[classes.negative]: !isPositiveChange && isPositiveChange !== null,
			})}
		>
			{getFormattedValue()}
		</span>
	);
}
