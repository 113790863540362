import React from 'react';
import compose from 'recompose/compose';
import { Field, reduxForm } from 'redux-form';
import { withStyles, withTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { Button } from '@components';
import { R, FormInput, FormInputPhoneIconNumber } from '@dev2t/react-ui-kit/src/components';
import { reset } from '@redux';
import { translate } from '@global';

const styles = theme => ({
	root: {
		position: 'relative',
	},
	divider: {
		marginTop: '30px',
	},
	warning: {
		padding: '12px 16px',
		textAlign: 'justify',
		fontSize: '12px',
		lineHeight: '20px',
	},
	button: {
		paddingTop: '24px',
	},
});


const RESET_BY_OPERATOR_FORM_ID = 'ResetByOperatorForm';
class ResetByOperatorForm extends React.Component {
	static validate(values) {

		const errors = {};
	
		if (!values) {
			return errors;
		}
	
		if (!values.Name) {
			errors.Name = 'required';
		}

		if (!values.Phone) {
			errors.Phone = 'required';
		}

		return errors;
	}
	
	constructor(props) {
		super(props);
		this.state = {
			phoneState: {
				determined: false,
				completed: false,
			},
		};
		this.onPhoneChange = this.onPhoneChange.bind(this);
	}

	onPhoneChange(match) {
		if (match) {
			this.setState({
				phoneState: match,
			});
		}
	}

	render() {
		const {
			classes,
			handleSubmit,
			invalid,
			submitting,
			theme,
		} = this.props;

		const fillInComment = `Please fill in the form below and click on "Send".
		Operator will contact you soon to help to recover password from your account`;

		return (
			<form
				onSubmit={handleSubmit(reset.actions.resetByOperator)}
				className={classes.root}
			>
				<p>
					<R id="reset_form_operator_fillin_comment" defaultValue={fillInComment} />
				</p>
				<Field
					component={FormInput}
					name="Name"
					label={translate('reset_form_full_name', 'Full Name')}
					multiline
				/>
				<Box marginTop="20px">
					<Field
						component={FormInputPhoneIconNumber}
						name="Phone"
						placeholder={translate('reset_form_valid_phone_number', 'Valid phone number')}
						label={translate('reset_form_valid_phone_number', 'Valid phone number')}
						onPhoneChange={this.onPhoneChange}
					/>
				</Box>
				<div className={classes.button}>
					<Button
						fullWidth
						type="submit"
						variant="contained"
						color="primary"
						disabled={invalid || submitting || !this.state.phoneState.completed}
					>
						{submitting ?
							<CircularProgress
								style={{ color: theme.palette.common.white }}
								size={18}
							/>
							: translate('reset_form_send', 'Send')
						}
					</Button>
				</div>
			</form>
		);
	}
}

export default compose(
	withTheme,
	withStyles(styles),
	reduxForm({
		form: RESET_BY_OPERATOR_FORM_ID,
		validate: ResetByOperatorForm.validate,
		onSubmitSuccess: (result, dispatch, props) => {
			if (props.onSuccess && (typeof props.onSuccess === 'function')) {
				props.onSuccess();
			}
		},
	}),
)(ResetByOperatorForm);
