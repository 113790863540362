import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
	profile,
	userAccounts,
} from '@redux';
import compose from 'recompose/compose';
import {
	ExpansionPanel,
	ProgressContainer,
} from '@components';
import { env } from '@config';
import TransaqChangePasswordButton from './components/TransaqChangePasswordButton';

const styles = theme => ({
	root: {
		border: '1px solid red',
	},
	dashboardLink: {
		...theme.palette.common.white,
	},
	header: {
		...theme.customstyles.headline1,
		...theme.palette.text.primary,
		padding: '8px 0',
	},
	subHeader: {
		...theme.customstyles.title,
		color: theme.palette.text.secondary,
	},
	content: {
		...theme.customstyles.body,
		padding: '16px 72px',
		marginBottom: '30px',
		[theme.breakpoints.down('md')]: {
			padding: '8px 24px',
		},
	},
	splitter: {
		height: '30px',
	},
	editable: {
		display: 'flex',
		flexDirection: 'row',
	},
	editItem: {
		display: 'flex',
		padding: '24px 32px 12px 0',
		textAlign: 'right',
	},
	editValue: {
		flexGrow: 2,
	},
	staticValue: {
		flexGrow: 2,
		padding: '24px 32px 12px 0px',
	},
});

const mapStateToProps = state => ({
	profile: profile.selectors.profile(state),
	currentAccountData: userAccounts.selectors.currentAccountData(state),
	currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
	accountInfo: userAccounts.selectors.accountInfo(state),
	accountStatus: userAccounts.selectors.currentAccountStatus(state),
	userInfo: userAccounts.selectors.userInfo(state),
});


const mapDispatchToProps = dispatch => ({
	actions: {
		setCurrentPage: page => dispatch(push(page)),
	},
});

class ThirdPartyTools extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			openPanelId: 'thirdparty-credentials',
		};
	}

	toggleOpen = (open, id) => {
		this.setState({ openPanelId: open ? id : null });
	};

	render() {
		const {
			classes,
			userInfo,
			accountInfo,
		} = this.props;
		const userGuid = userInfo.Guid && userInfo.Guid.toUpperCase();
		const isEnableTransaqChangePassword = userGuid && (!env.WEBCAB_4509_transaqChangePwdAvalilableFor
			|| env.WEBCAB_4509_transaqChangePwdAvalilableFor.includes(userGuid));
		return (
			<div className={classes.content}>
				<div>
					<div className={classes.header}>
						Third Party Tools
					</div>
				</div>
				<ProgressContainer submitting={accountInfo.loading} display="left">
					<ExpansionPanel
						header="Third Party Credentials"
						id="thirdparty-credentials"
						onToggleOpen={this.toggleOpen}
						isOpen={this.state.openPanelId === 'thirdparty-credentials'}
					>
						{accountInfo && accountInfo.Platforms && accountInfo.Platforms.ActiveTradePlatform === 'Transaq' &&
							isEnableTransaqChangePassword &&
							<TransaqChangePasswordButton classes={classes} />
						}
					</ExpansionPanel>
				</ProgressContainer>
			</div>
		);
	}
}


ThirdPartyTools.propTypes = {
	classes: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	accountInfo: PropTypes.object.isRequired,
};


export default compose(
	withTheme,
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(ThirdPartyTools);
