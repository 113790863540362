import { fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { BaseGetSagaHandler, callApi, snackbar, userAccounts, profile as profileModule } from '@redux';
import {
	profile,
	changeAddress,
	changeAddressForClient,
	changeEmail,
	changeEmailForClient,
	changePassword,
	changePhone,
	confirmEmail,
	changeDependents,
	changeEmployment,
	changeEmploymentForClient,
	changeMarketDataSubscriberStatus,
	changeMarketDataSubscriptions,
	enableThirdPartyMarketData,
	getCountries,
	getUsaStates,
} from '@api';
import { ModalPopups } from '@enums';
import { addHashToUrl, cabinetLog, captureException, removeHashFromUrl, captureError } from '@global';
import actions from './actions';

function* profileSaga() {
	try {
		const response = yield callApi(profile);
		if (!response.data.Success) {
			yield put(snackbar.actions.showErrorMessage({
				title: 'Get User Profile',
				text: response.data.Errors[0].Message,
			}));
			yield put(actions.getProfileFailure(response.data.Errors[0].Message));
		} else {
			yield put(actions.getProfileSuccess(response.data.ProfileData));
		}
	} catch (error) {
		captureException('profileSaga', error);
		yield put(snackbar.actions.showErrorMessage({ title: 'Get User Profile', text: error.message }));
		yield put(actions.getProfileFailure(error.message));
	}
}


function* isCurrentUser({ guid, clientId }) {
	if (!guid) {
		return !clientId;
	}
	const userInfo = yield select(userAccounts.selectors.userInfo);
	return 	guid.toLowerCase() === userInfo.Guid.toLowerCase();
}
function* refreshClientOrHolderProfile(isCurrent) {
	if (isCurrent) {
		yield put(actions.getProfileRequest());
	}
}

function* changeAddressFormSubmit({ payload }) {
	try {
		const { clientId, guid, ...otherPayload } = payload;
		const isCurrent = yield* isCurrentUser({ guid, clientId });
		let request;
		if (isCurrent) {
			request = yield callApi(changeAddress, otherPayload);
		} else {
			request = yield callApi(changeAddressForClient, otherPayload, clientId);
		}
		cabinetLog('change address', request);

		if (!request.data.Success) {
			yield put(actions.changeProfileAddressFormFailure(request.data.Errors[0].Message));
			yield put(snackbar.actions.showErrorMessage({ text: request.data.Errors[0].Message }));
		} else {
			yield put(actions.changeProfileAddressFormSuccess());
			yield* refreshClientOrHolderProfile(isCurrent);
		}
	} catch (error) {
		Raven.captureException(error);
		yield put(actions.changeProfileAddressFormFailure(error));
		yield put(snackbar.actions.showErrorMessage({ text: error.message }));
	}
}

function* changeDependentsFormSubmit({ payload }) {
	try {
		const request = yield callApi(changeDependents, payload);
		cabinetLog('change Dependents', request);

		if (!request.data.Success) {
			yield put(actions.changeProfileDependentsFormFailure(request.data.Errors[0].Message));
			yield put(snackbar.actions.showErrorMessage({ text: request.data.Errors[0].Message }));
		} else {
			yield put(actions.changeProfileDependentsFormSuccess());
			yield put(actions.getProfileRequest());
		}
	} catch (error) {
		Raven.captureException(error);
		yield put(actions.changeProfileDependentsFormFailure(error));
		yield put(snackbar.actions.showErrorMessage({ text: error.message }));
	}
}

function* changeEmploymentFormSubmit({ payload }) {
	try {
		const { clientId, guid, ...otherPayload } = payload;
		const isCurrent = yield* isCurrentUser({ guid, clientId });
		let request;
		if (isCurrent) {
			request = yield callApi(changeEmployment, otherPayload);
		} else {
			request = yield callApi(changeEmploymentForClient, otherPayload, clientId);
		}
		cabinetLog('change Employment(', request);

		if (!request.data.Success) {
			yield put(actions.changeProfileEmploymentFormFailure(request.data.Errors[0].Message));
			yield put(snackbar.actions.showErrorMessage({ text: request.data.Errors[0].Message }));
		} else {
			yield put(actions.changeProfileEmploymentFormSuccess());
			yield* refreshClientOrHolderProfile(isCurrent);
		}
	} catch (error) {
		Raven.captureException(error);
		yield put(actions.changeProfileEmploymentFormFailure(error));
		yield put(snackbar.actions.showErrorMessage({ text: error.message }));
	}
}

function* changeEmailFormSubmit({ payload }) {
	try {
		const { clientId, guid, ...otherPayload } = payload;
		const isCurrent = yield* isCurrentUser({ guid, clientId });
		let request;
		if (isCurrent) {
			request = yield callApi(changeEmail, otherPayload);
		} else {
			request = yield callApi(changeEmailForClient, otherPayload, clientId);
		}
		cabinetLog('change Email', request);

		if (!request.data.Success) {
			yield put(actions.changeProfileEmailFormFailure(request.data.Errors[0].Message));
			yield put(snackbar.actions.showErrorMessage({ text: request.data.Errors[0].Message }));
		} else {
			yield put(actions.changeProfileEmailFormSuccess());
			yield* refreshClientOrHolderProfile(isCurrent);
		}
	} catch (error) {
		Raven.captureException(error);
		yield put(actions.changeProfileEmailFormFailure(error));
		yield put(snackbar.actions.showErrorMessage({ text: error.message }));
	}
}

function* changePhoneFormSubmit({ payload }) {
	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: changePhone,
		errorText: 'Change phone',
		handler: actions.changeProfilePhoneFormSubmitHandler,
	}, { payload });
	if (isSuccess) {
		yield put(userAccounts.actions.getAccountsRequest());
	}
}

function* changeMarketDataSubscriberStatusFormSubmit({ payload }) {
	try {
		const request = yield callApi(changeMarketDataSubscriberStatus, payload);
		cabinetLog('change MarketDataSubscriberStatus', request);

		if (!request.data.Success) {
			yield put(actions.changeMarketDataSubscriberStatusForm.failure(request.data.Errors[0].Message));
			yield put(snackbar.actions.showErrorMessage({ text: request.data.Errors[0].Message }));
		} else {
			yield put(actions.changeMarketDataSubscriberStatusForm.success());
			yield put(actions.getProfileRequest());
		}
	} catch (error) {
		Raven.captureException(error);
		yield put(actions.changeMarketDataSubscriberStatusForm.failure(error));
		yield put(snackbar.actions.showErrorMessage({ text: error.message }));
	}
}
function* changeMarketDataSubscriptionsFormSubmit({ payload }) {
	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: changeMarketDataSubscriptions,
		errorText: 'Change Market Data Subscriptions',
		handler: actions.changeMarketDataSubscriptionsForm,
	}, { payload });
}

function* enableThirdPartyMarketDataFormSubmit() {
	try {
		const request = yield callApi(enableThirdPartyMarketData);
		cabinetLog('enable ThirdPartyMarketData', request);

		if (!request.data.Success) {
			yield put(actions.enableThirdPartyMarketDataForm.failure(request.data.Errors[0].Message));
			yield put(snackbar.actions.showErrorMessage({ text: request.data.Errors[0].Message }));
		} else {
			yield put(actions.enableThirdPartyMarketDataForm.success());
			yield put(actions.getProfileRequest());
		}
	} catch (error) {
		Raven.captureException(error);
		yield put(actions.enableThirdPartyMarketDataForm.failure(error));
		yield put(snackbar.actions.showErrorMessage({ text: error.message }));
	}
}

function* hideConfirmEmailModal() {
	const { hash } = yield select(state => state.router.location);
	const request = yield put(push(`/dashboard${(hash || '')}`));
}


function GetModalPopupType(actionType) {
	switch (actionType) {
		case actions.SHOW_CHANGE_ADDRESS_MODAL:
			return ModalPopups.CHANGE_PROFILE_ADDRESS;
		case actions.SHOW_CHANGE_EMAIL_MODAL:
			return ModalPopups.CHANGE_PROFILE_EMAIL;
		case actions.SHOW_CHANGE_PHONE_MODAL:
			return ModalPopups.CHANGE_PROFILE_PHONE;
		case actions.SHOW_CHANGE_DEPENDENTS_MODAL:
			return ModalPopups.CHANGE_DEPENDENTS;
		case actions.SHOW_CHANGE_EMPLOYMENT_MODAL:
			return ModalPopups.CHANGE_EMPLOYMENT;
		case actions.SHOW_CHANGE_MARKETDATA_SUBSCRIBER_STATUS_MODAL:
			return ModalPopups.CHANGE_MARKETDATA_SUBSCRIBER_STATUS_MODAL;
		case actions.SHOW_CHANGE_MARKETDATA_SUBSCRIPTIONS_MODAL:
			return ModalPopups.CHANGE_MARKETDATA_SUBSCRIPTIONS_MODAL;
		case actions.SHOW_ENABLE_THIRD_PARTY_MARKETDATA_MODAL:
			return ModalPopups.ENABLE_THIRD_PARTY_MARKETDATA_MODAL;
		case actions.SHOW_DEMO_ACCOUNT_EXPIRATION_MODAL:
			return ModalPopups.DEMO_ACCOUNT_EXPIRATION;
		default:
			return '';
	}
}

function* showProfileModal({ type }) {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(addHashToUrl(location, GetModalPopupType(type))));
}

function* hideProfileModal({ payload }) {
	const location = yield select(state => state.router.location);
	yield put(push(removeHashFromUrl(location, payload)));
}

function* changePasswordFormSubmitSaga({ payload }) {
	try {
		const request = yield callApi(changePassword, payload);
		if (!request.data.Success) {
			yield put(actions.changePasswordFormSubmit.failure(request.data.Errors[0].Message));
			yield put(snackbar.actions.showErrorMessage({ title: 'Change Password', text: request.data.Errors[0].Message }));
		} else {
			yield put(actions.changePasswordFormSubmit.success());
		}
	} catch (error) {
		captureError(error);
		yield put(actions.changePasswordFormSubmit.failure(error));
	}
}

// Watchers
function* watchProfile() {
	yield takeEvery(actions.GET_PROFILE_REQUEST, profileSaga);
}
function* watchChangeAddressFormSubmit() {
	yield takeEvery(actions.CHANGE_ADDRESS_FORM_SUBMIT_REQUEST, changeAddressFormSubmit);
}
function* watchChangeEmailFormSubmit() {
	yield takeEvery(actions.CHANGE_EMAIL_FORM_SUBMIT_REQUEST, changeEmailFormSubmit);
}
function* watchChangePhoneFormSubmit() {
	yield takeEvery(actions.CHANGE_PHONE_FORM_SUBMIT_REQUEST, changePhoneFormSubmit);
}

function* watchConfirmEmailGetUrl() {
	yield takeEvery(actions.confirmEmailGetUrl.REQUEST, BaseGetSagaHandler, {
		apiMethod: confirmEmail,
		errorText: 'Confirm Email',
		handler: actions.confirmEmailGetUrl,
	});
}
function* watchHideConfirmEmailModal() {
	yield takeEvery(actions.HIDE_CONFIRM_EMAIL_MODAL, hideConfirmEmailModal);
}
function* watchChangeDependentsFormSubmit() {
	yield takeEvery(actions.CHANGE_DEPENDENTS_FORM_SUBMIT_REQUEST, changeDependentsFormSubmit);
}
function* watchChangeEmploymentFormSubmit() {
	yield takeEvery(actions.CHANGE_EMPLOYMENT_FORM_SUBMIT_REQUEST, changeEmploymentFormSubmit);
}

function* watchChangeMarketDataSubscriberStatusFormSubmit() {
	yield takeEvery(actions.changeMarketDataSubscriberStatusForm.REQUEST, changeMarketDataSubscriberStatusFormSubmit);
}
function* watchChangeMarketDataSubscriptionsFormSubmit() {
	yield takeEvery(actions.changeMarketDataSubscriptionsForm.REQUEST, changeMarketDataSubscriptionsFormSubmit);
}
function* watchEnableThirdPartyMarketDataFormSubmit() {
	yield takeEvery(actions.enableThirdPartyMarketDataForm.REQUEST, enableThirdPartyMarketDataFormSubmit);
}

function* watchShowProfileModal() {
	yield takeEvery([
		actions.SHOW_CHANGE_ADDRESS_MODAL,
		actions.SHOW_CHANGE_EMAIL_MODAL,
		actions.SHOW_CHANGE_PHONE_MODAL,
		actions.SHOW_CHANGE_DEPENDENTS_MODAL,
		actions.SHOW_CHANGE_EMPLOYMENT_MODAL,
		actions.SHOW_CHANGE_MARKETDATA_SUBSCRIBER_STATUS_MODAL,
		actions.SHOW_CHANGE_MARKETDATA_SUBSCRIPTIONS_MODAL,
		actions.SHOW_ENABLE_THIRD_PARTY_MARKETDATA_MODAL,
		actions.SHOW_DEMO_ACCOUNT_EXPIRATION_MODAL,
	], showProfileModal);
}
function* watchHideProfileModal() {
	yield takeEvery(actions.HIDE_PROFILE_MODAL, hideProfileModal);
}
function* watchGetCountriesRequest() {
	yield takeLatest(actions.getCountries.REQUEST, BaseGetSagaHandler, {
		apiMethod: getCountries,
		errorText: 'Get countries',
		handler: actions.getCountries,
	});
}
function* watchGetUsaStatesRequest() {
	yield takeLatest(actions.getUsaStates.REQUEST, BaseGetSagaHandler, {
		apiMethod: getUsaStates,
		errorText: 'Get USA states',
		handler: actions.getUsaStates,
	});
}

function* watchChangePasswordFormSubmit() {
	yield takeEvery(actions.changePasswordFormSubmit.REQUEST, changePasswordFormSubmitSaga);
}

// Root saga
const rootSaga = [
	fork(watchProfile),
	fork(watchChangeAddressFormSubmit),
	fork(watchChangeEmailFormSubmit),
	fork(watchChangePhoneFormSubmit),
	fork(watchConfirmEmailGetUrl),
	fork(watchHideConfirmEmailModal),
	fork(watchChangeDependentsFormSubmit),
	fork(watchChangeEmploymentFormSubmit),
	fork(watchChangeMarketDataSubscriberStatusFormSubmit),
	fork(watchChangeMarketDataSubscriptionsFormSubmit),
	fork(watchEnableThirdPartyMarketDataFormSubmit),
	fork(watchShowProfileModal),
	fork(watchHideProfileModal),
	fork(watchGetCountriesRequest),
	fork(watchGetUsaStatesRequest),
	fork(watchChangePasswordFormSubmit)
];

export default {
	rootSaga,
};
