import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { ExpansionPanel	} from '@components';
import { userAccounts } from '@redux';
import { CYReportsFilter, CYReportsPagingTable } from './components';

const useStyles = makeStyles(theme => ({}));

export const CYReport = (props) => {
	const classes = useStyles();
	const currentTradeAccount = useSelector(userAccounts.selectors.currentTradeAccount);

	return (
		<ExpansionPanel
			header={
				<div className={classes.reportHeader}>
					{/* <span>Broker Reports</span> */}
					<CYReportsFilter tradeCode={currentTradeAccount.tradeCode} />
				</div>
			}
			id="report-history"
		>
			<CYReportsPagingTable tradeCode={currentTradeAccount.tradeCode} />
		</ExpansionPanel>

	);
};
