import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { ModalPopups } from '@enums';
import {
	tradePlatforms,
	userAccounts,
} from '@redux';
import {
	Button,
	A,
	InfoMessage,
} from '@components';
import { Modal } from '@dev2t/react-ui-kit/src/components';
import { env } from '@config';
import { connect } from 'react-redux';
import logo from './j2t-pro.png';

const styles = theme => ({
	title: {
		...theme.customstyles.display,
		color: theme.palette.text.primary,
	},
	logo: {
		width: '350px',
	},
	body: {
		marginTop: '24px',
		...theme.customstyles.body,
		color: theme.palette.text.primary,
	},
	description: {
		marginBottom: '5px',
	},
	list: {
		margin: '0',
		listStyleType: 'none',
		lineHeight: '35px',
		padding: '0',
		'& li:': {
			'&:before': {
				content: '"—"',
				marginRight: '20px',
			},
		},
	},
	button: {
		marginTop: '32px',
		backgroundColor: '#e3edf5',
	},
	buttonDownload: {
		marginTop: '32px',
	},
	noteText: {
		marginTop: '32px',
		...theme.customstyles.body,
		color: theme.palette.text.secondary,
	},
});

const mapDispatchToProps = dispatch => ({
	actions: {
		activatePlatform: (account, tradePlatform) =>
			dispatch(tradePlatforms.actions.activatePlatformRequest(account, tradePlatform)),
	},
});

const mapStateToProps = state => ({
	currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
	platforms: userAccounts.selectors.platforms(state),
	submitting: tradePlatforms.selectors.submitting(state),
	currentAccountData: userAccounts.selectors.currentAccountData(state),
});

const PLATFORM = 'Transaq';

class J2tProModal extends React.Component {
	constructor(props) {
		super(props);
		this.activatePlatform = this.activatePlatform.bind(this);
	}

	activatePlatform() {
		this.props.actions.activatePlatform(
			this.props.currentTradeAccount,
			PLATFORM,
		);
	}

	render() {
		const {
			onClose,
			classes,
			submitting,
			platforms: {
				ActiveTradePlatform,
				PendingTradePlatform,
			},
			currentAccountData: { baseParams },
		} = this.props;

		return (
			<div>
				{baseParams && baseParams.HasOptions ?
					<Modal
						onClose={onClose}
						title="Has Options"
						className={`QA-${ModalPopups.J2T_PRO}`}
					>
						<InfoMessage
							onClick={onClose}
							text={
								<p>
									This trading platform doesn&quot;t support options trading.<br />
									In order to switch between platforms you have to close all the option positions in your portfolio.
								</p>
							}
							buttonText="Close"
						/>
					</Modal> :
					<Modal
						title=""
						onClose={onClose}
					>
						<div className={classes.title}>
							<img alt="J2T Pro logo" src={logo} className={classes.logo} />
						</div>
						<div className={classes.body}>
							<p className={classes.description}>
								A fully-fledged trading system, which offers simple, convenient,
								friendly and intuitive interface, high speed and reliable operations.
								This trading platform offers exhaustive list of tools and functions.
							</p>
							<ul className={classes.list}>
								<li>Fast MDI desktop application</li>
								<li>Real-time quotes</li>
								<li>Market depth</li>
								<li>Different types of orders</li>
								<li>Automated triggered orders</li>
								<li>Historical data</li>
								<li>Technical indicators are built into the terminal</li>
								<li>Structured news feed</li>
							</ul>
						</div>
						{PendingTradePlatform && PendingTradePlatform === PLATFORM &&
							<div className={classes.button}>
								<Button
									color="secondary"
									variant="contained"
									fullWidth
									disabled
								>
									ACTIVATION REQUESTED
								</Button>
							</div>
						}
						{!PendingTradePlatform && ActiveTradePlatform !== PLATFORM &&
							<div className={classes.button}>
								<Button
									color="primary"
									variant="contained"
									fullWidth
									onClick={this.activatePlatform}
									disabled={submitting}
								>
									{submitting ?
										<CircularProgress
											
											size={18}
										/>
										: 'Activate'
									}
								</Button>
							</div>
						}
						{ActiveTradePlatform === PLATFORM &&
							<div className={classes.buttonDownload}>
								<A target="_blank" href={env.J2tProPcDownloadUrl}>
									<Button
										color="primary"
										variant="outlined"
										fullWidth
									>
										Download
									</Button>
								</A>
							</div>
						}
						<div className={classes.noteText}>
							Trading platform becomes available on the next day after activation.<br />
							Please note that only one trading platform may be used at a time.
						</div>
					</Modal>
				}
			</div>);
	}
}

J2tProModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(J2tProModal);
