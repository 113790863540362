import React from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles, withTheme } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EmailIcon from '@material-ui/icons/Email';
import Box from '@material-ui/core/Box';
import { R } from '@dev2t/react-ui-kit/src/components';
import { env } from '@config';

const styles = theme => ({
	root: {
		fontSize: '.65rem',
		lineHeight: 2,
		textRendering: 'optimizeLegibility',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'top',
		flexWrap: 'wrap',
		'& a': {
			color: theme.palette.primary.main,
			textDecoration: 'none',
		},
		'& img': {
			maxHeight: '25px',
		},
		'& span': {
			fontSize: '1.7em',
		},
		'& p': {
			margin: '0 0 5px 0',
		},
	},
});

const MainSection = withStyles({
	root: {
		padding: '8px',
		flex: '2 1 300px',
		textAlign: 'justify',
		paddingRight: '20px',
	},
})(Box);

function MainFooterJ2TX({ classes }) {
	return (
		<Paper>
			<Box className={classes.root} px={6} py={3}>
				<MainSection>
					<span><b>© {new Date().getFullYear()} J2TX Ltd</b></span>
					<p>
						{'J2TX Ltd '}
						<R
							id="footer_registration"
							defaultValue="is registered as a CASP with the Cyprus Securities and Exchange Commission" 
						/>
					</p>
				</MainSection>
				<MainSection>
					<p>
						<LocationOnIcon fontSize="inherit" color="primary" />
						<R id="shared_address" defaultValue="Address" />
					</p>
					<p>
						<R id="footer_j2tx_address" defaultValue="Chrysanthou Mylona, 10, Magnum House, 3030, Limassol, Cyprus" />
					</p>
					<p>
						<EmailIcon fontSize="inherit" color="primary" />
						&nbsp;
						<R id="contacts_label_email" defaultValue="E-mail" />
						:&nbsp;
						<a href={`mailto:${env.supportMail}`}>{env.supportMail}</a>
					</p>
				</MainSection>
			</Box>
		</Paper>
	);
}

export default compose(
	withTheme,
	withStyles(styles),
)(MainFooterJ2TX);
