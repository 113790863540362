import React from 'react';

// Регулярные выражения для поиска всяких нехороших символов и сочетаний
const checkNotSecure = [
	// Скобки сами по себе опасны
	new RegExp('[><{}]', 'i'),
	// кавычки или пробел после которых начинается нечто похожее на html - атрибут обработчика события
	new RegExp('[\\s\'"`]+(?=on\\S+\\s*=)', 'i'),
	// двойные слеши, -
	new RegExp('([\\-\\\\\\/])(?=\\1)'),
	// попытки вписать комментарий
	new RegExp('//|\\\\|\\*/|/\\*'),
];

export function	validateSecureString(value) {
	if (!value)	return undefined;
	if (checkNotSecure.some(re => re.test(value))) {
		return 'Invalid characters';
	}
	return undefined;
}
