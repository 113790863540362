import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { constants, env } from '@config';
import { dashboard, userAccounts } from '@redux';
import { getDateTimeEst, dateDiffInDays } from '@global';
import {
	DebtsAlerts,
	CYAlerts,
	BlockModals,
	CYProfile,
	Icons,
	MainHeader,
	MainContent,
	Contacts,
	Documents,
	TradingPlatforms,
	Reports,
	TerminalMt5,
	Notifications,
	Modals,
	Sidebar,
	ThirdPartyTools,
	AccountFeaturesLMI,
	CashManagementContent,
	MainFooterLME,
	Mt5Global,
	OpenMt5Global,
	Wallet,
	Loans,
	Staking,
	Exchange,
	MainContentJ2TX,
	DemoAccounts,
	Disclosures,
	ApiKeys,
} from './components';
import MainFooterJ2TX from './components/MainFooter/MainFooterJ2TX';
import ContactsJ2Tx from './components/Contacts/ContactsJ2Tx';

const styles = theme => ({
	'@global': {
		body: {
			minHeight: '100vh', // for Payment Gate Widget
			backgroundColor: theme.palette.background.default,
		},
	},
	rootContainer: {
		maxWidth: '1440px',
		margin: '0 auto',
	},
	root: {
		flexGrow: 1,
		zIndex: 1,
		overflow: 'hidden',
		position: 'relative',
		display: 'flex',
		width: '100%',
	},
	content: {
		width: '100%',
		padding: '0 12px',
		minWidth: 0,
		minHeight: '100vh',
		position: 'relative',
	},
	demoAccountNotification: {
		margin: '5px 0px 5px 48px',
		fontWeight: 'bold',
	},
	demoAccountExpirationWarning: {
		...theme.customstyles.body,
		color: theme.palette.error.main,
		margin: '5px 0px 5px 48px',
		fontWeight: 'bold',
	},
});

const mapStateToProps = state => ({
	currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
	userInfo: userAccounts.selectors.userInfo(state),
	currentAccountIsDemo: userAccounts.selectors.currentAccountIsDemo(state),
	demoAccount: userAccounts.selectors.getDemoAccount(state),
	anyActiveAccountExists: userAccounts.selectors.anyActiveAccountExists(state),
});

const mapDispatchToProps = dispatch => ({
	actions: {
		init: tradeCode => dispatch(dashboard.actions.init(tradeCode)),
	},
});

function composeExpirationWarningText(days) {
	if (days === 0) {
		return 'Your demo account will expire today';
	}
	return `Your demo account will expire in ${days + 1} days`;
}

const SwitchesByRoute = ({ match }) => (
	<Switch>
		<Route path={`${match.url}/cashmanagement`} component={CashManagementContent} />
		{env.firm === constants.FIRM_J2TX && <Route exact path={`${match.url}/mt5global`} component={Mt5Global} />}
		{env.firm === constants.FIRM_J2TX && <Route exact path={`${match.url}/openmt5global`} component={OpenMt5Global} />}
		{env.firm === constants.FIRM_J2TX && <Route exact path={`${match.url}/wallet`} component={Wallet} />}
		{env.firm === constants.FIRM_J2TX && <Route exact path={`${match.url}/loans`} component={Loans} />}
		{env.firm === constants.FIRM_J2TX && <Route exact path={`${match.url}/staking`} component={Staking} />}
		{env.firm === constants.FIRM_J2TX && <Route exact path={`${match.url}/exchange`} component={Exchange} />}
		{env.firm === constants.FIRM_J2TX && env.WEBCAB_8523_showDisclosures
			&& <Route exact path={`${match.url}/disclosures`} component={Disclosures} />
		}
		{env.firm === constants.FIRM_J2TX && <Route exact path={`${match.url}/apikeys`} component={ApiKeys} />}

		{[constants.LIME_INT, constants.LIME_NSR, constants.LIME_LME, constants.FIRM_J2TX].some(x => x === env.firm)
			&& <Route exact path={`${match.url}/settings`} component={CYProfile} />
		}

		{[constants.LIME_INT, constants.LIME_NSR, constants.LIME_LME, constants.LIME_FIN].some(x => x === env.firm)
			&& <Route exact path={`${match.url}/contacts`} component={Contacts} />
		}
		{env.firm === constants.FIRM_J2TX
			&& <Route exact path={`${match.url}/contacts`} component={ContactsJ2Tx} />
		}
		<Route exact path={`${match.url}/documents`} component={Documents} />
		<Route exact path={`${match.url}/tradingplatforms`} component={TradingPlatforms} />
		<Route exact path={`${match.url}/reports`} component={Reports} />
		<Route exact path={`${match.url}/terminalMt5`} component={TerminalMt5} />
		<Route exact path={`${match.url}/demoaccounts`} component={DemoAccounts} />
		<Route exact path={`${match.url}/thirdpartytools`} component={ThirdPartyTools} />
		{env.firm === constants.LIME_INT &&
			<Route exact path={`${match.url}/accountfeatures`} component={AccountFeaturesLMI} />
		}
		<Route exact path={`${match.url}/icons`} component={Icons} />
		{[constants.LIME_INT, constants.LIME_NSR, constants.LIME_LME, constants.LIME_FIN].some(x => x === env.firm) &&
			<Route path={`${match.url}`} component={MainContent} />
		}
		{env.firm === constants.FIRM_J2TX &&
			<Route path={`${match.url}`} component={MainContentJ2TX} />
		}
	</Switch>
);
class DashboardView extends React.Component {
	componentDidMount() {
		// init application when start (no current account)
		if (this.props.currentAccountIsDemo || !this.props.currentTradeAccount.tradeCode) {
			const currentTradeCode = this.props.match.params.accid;
			this.props.actions.init(currentTradeCode);
		}
	}

	render() {
		const {
			classes,
			match,
			fullWidth,
			userInfo: { NeedChangePass },
			currentAccountIsDemo,
			demoAccount,
			anyActiveAccountExists,
		} = this.props;

		if (NeedChangePass) {
			// forcing the client to do the necessary action
			return (
				<>
					<BlockModals />
				</>
			);
		}

		let days = 0;
		if (demoAccount) {
			const dateNowWithUSOffset = getDateTimeEst();
			const dateExpiration = new Date(demoAccount.ExpirationDate);
			days = dateDiffInDays(dateExpiration, dateNowWithUSOffset);
		}
		
		return (
			<>
				<div className={classes.rootContainer}>
					<div className={classes.root}>
						<Sidebar />
						<div id="dashboard-top" className={classes.content}>
							<Box display="flex" flexDirection="column" height="100%" justifyContent="space-between">
								<Box>
									<MainHeader />
									{[
										constants.LIME_INT, constants.LIME_NSR, constants.LIME_LME, constants.FIRM_J2TX,
									].some(x => x === env.firm) && (
										<CYAlerts match={match} />
									)}
									{currentAccountIsDemo && (
										<p className={classes.demoAccountNotification}>
											Demo account, not real money
										</p>
									)}
									{env.firm === constants.LIME_FIN && currentAccountIsDemo && !anyActiveAccountExists && (
										<p className={classes.demoAccountExpirationWarning}>
											{composeExpirationWarningText(days)}
										</p>
									)}
									{(env.firm === constants.FIRM_J2TX || env.firm === constants.LIME_LME)
										&& (<DebtsAlerts />)
									}
									<SwitchesByRoute match={match} />
								</Box>
								{(constants.LIME_LME === env.firm) && <MainFooterLME />}
								{(constants.FIRM_J2TX === env.firm) && <MainFooterJ2TX />}
							</Box>
						</div>
						<Notifications hidden={fullWidth} />
					</div>
				</div>
				<Modals />
			</>
		);
	}
}

DashboardView.propTypes = {
	classes: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
	fullWidth: PropTypes.bool,
	demoAccount: PropTypes.object,
	anyActiveAccountExists: PropTypes.bool.isRequired,
};

DashboardView.defaultProps = {
	fullWidth: false,
	demoAccount: null,
};

export default compose(
	withRouter,
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(DashboardView);
