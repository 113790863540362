import actions from './actions';
import auth from '../auth';

const initialState = {
	message: null,
	notifications: {
		loading: false,
		pagination: {},
		unreadMessagesCount: 0,
		items: [],
	},
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.SHOW_MESSAGE_MODAL:
			return {
				...state,
				message: payload.message,
			};

		case actions.HIDE_MESSAGE_MODAL:
			return {
				...state,
				message: null,
			};

		case actions.getNotifications.REQUEST:
			return {
				...state,
				/* при реквесте со сменой языка все данные об уведомлениях обнуляются */
				notifications: {
					loading: true,
					pagination: {},
					unreadMessagesCount: 0,
					items: [],
				},
			};

		case actions.getNotifications.SUCCESS:
			return {
				...state,
				notifications: {
					loading: false,
					pagination: {
						page: payload.response.Notifications.CurrentPage,
						pageSize: payload.response.Notifications.CountOnPage,
						pagesCount: payload.response.Notifications.PagesCount,
					},
					unreadMessagesCount: payload.response.Notifications.UnreadMessagesCount,
					items: payload.response.Notifications.Notifications,
				},
			};

		case actions.getNotifications.FAILURE:
		case actions.getMoreNotifications.FAILURE:
			return {
				...state,
				notifications: {
					...state.notifications,
					loading: false,
				},
			};

		case actions.getMoreNotifications.REQUEST:
			return {
				...state,
				notifications: {
					...state.notifications,
					loading: true,
				},
			};

		case actions.getMoreNotifications.SUCCESS:
			return {
				...state,
				notifications: {
					...state.notifications,
					loading: false,
					pagination: {
						page: payload.response.Notifications.CurrentPage,
						pageSize: payload.response.Notifications.CountOnPage,
						pagesCount: payload.response.Notifications.PagesCount,
					},
					items: [
						...state.notifications.items,
						...payload.response.Notifications.Notifications,
					],
				},
			};

		case actions.MARK_NEWS_AS_READ:
			return {
				...state,
				notifications: {
					pagination: state.notifications.pagination,
					unreadMessagesCount: state.notifications.unreadMessagesCount - 1,
					items: state.notifications.items.map(item => ({
						...item,
						Read: (item.Id === payload.messageId) ? true : item.Read,
					})),
				},
			};

		case actions.markNewsAllAsRead.SUCCESS:
			return {
				...state,
				notifications: {
					...initialState.notifications,
				},
			};
		case auth.actions.LOGOUT_SUCCESS:
			return { ...initialState };
		default:
			return state;
	}
};
